import React, { useContext, useState } from 'react';
import { Link ,useHistory,useParams} from 'react-router-dom';
import Footer from '../../components/Footer/footer';
import Header from '../../components/header/header';
import upload from '../../assets/Upload.png'
import art from '../../assets/ image.png'
import './type.css'
import { ScrollToTop } from '../../helpers/misc_func';

const TypeSelect = () => {
    // const { state, fetch_single_article } = useContext(ArtContext);
    let {type} = useParams()
    const [head, changehead] = useState('')
    const history = useHistory();
    React.useEffect(() => {
        ScrollToTop()
        localStorage.removeItem("artData")
        if (type == "print") {
            localStorage.setItem("artmode", "print")
            changehead('What Are You Printing?')
            history.push("print")
        }
        else if(type=='frame'){
            localStorage.setItem("artmode", "frame")
            changehead('What Are You Framing?')
            history.push("frame")
        }
        else{
            history.push(`/404s`)
        }
    }, [type]);
    

    return (
        <div id="type_select">
            <Header />
            <div className="container type_select">
                <h2 className="headmedium mt-3 mb-3 text-center secondarycolor">
                    {head?head:head}
                </h2>
                <div className="type_icons">
                    <Link onClick={() => localStorage.setItem("user_upload", true)} to="/custom-framing/" className="type_icon">
                        <img alt="icon" src={upload} />
                        <p>Digital Upload</p>
                    </Link>
                    <Link onClick={() => localStorage.removeItem("user_upload")} to="/art/" className="type_icon">
                        <img alt="icon" src={art} />
                        <p>Art</p>
                    </Link>
                    {/* <Link to='/' className="type_icon">
                        <img alt="icon" src={insta} />
                        <p>Instagram</p>
                    </Link> */}

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default TypeSelect;
