import React from 'react'
import error from '../../assets/error.jpg'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Header from '../../components/header/header'
import './error.css'

const DataError = () => {
    return (
        <div className="error-page">
            <Header />
            <div className="container error-content">
                <img src={error} />
                <h6 className='title'>Something Went Wrong</h6>
                <p>Unfortunately something went wrong.</p>
                <div className='btns row'>
                    <Link to='/' className='col-sm-8 col-12'> <button className="bgsecondary whitecolor py-2 px-2">Return To Home</button></Link>
                </div>
            </div>
        </div>
    )
}
export default DataError;