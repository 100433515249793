import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { Provider as ArtProvider } from './context/ArtContext';
import { Provider as UserProvider } from './context/UserContext';
import { Provider as ProductProvider } from './context/ProductContext';
import './App.css';
import HomeScreen from './screens/home/home';
import TypeSelect from './screens/typeSelect/typeSelect';
import UploadScreen from './screens/upload/uploadScreen';
import SizeSelect from './screens/sizeSelect/sizeSelect';
import FrameSelect from './screens/frameSelect/frameSelect';
import MyProfile from './screens/profile/myProfile';
import ProfileEdit from './screens/profile/profileEdit';
import ShippingList from './screens/profile/shippingList';
import AddressEdit from './screens/profile/editAddressPage';
import Login from './screens/login/login';
import Cart from './screens/cart/cart';
import Checkout from './screens/checkout/checkout';
import FrameEdit from './screens/frameEdit/frameEdit';
import ProfileDetails from './screens/profile/profileDetails'
import ArtSelect from './screens/art/artSelect';
import BlogListing from './screens/blog/bloglisting';
import BlogDetails from './screens/blog/blogdetails';
import OrderDetails from './components/profiledetails/orderDetails';
import ReactGA from 'react-ga'
import createHistory from 'history/createBrowserHistory'
import Crop from './screens/sizeSelect/crop';
import Error from './screens/Error/Error'
import DataError from './screens/Error/DataError'
import Company from './screens/company'
import Stores from './screens/store'
import ShopArt from './screens/art/shopart';
import ArtTypeSelect from './screens/sizeSelect/typeSelect';
import Faq from './screens/basic/faq';
import Contact from './screens/basic/contact';
import Terms from './screens/basic/terms';
import Privacy from './screens/basic/privacy';
import GallerySelect from './screens/galleryWall/gallerySelect';
import GalleryWall from './screens/galleryWall/galleryWall';
import TagManager from 'react-gtm-module'
import OptOut from './screens/OptOut';
import About from './screens/basic/about';
import Pinterest from './screens/pinterest/Pinterest'
import Partner from './screens/partner/Partner';
import Partnerapply from "./screens/partnerapply/Partnerapply"
import Design from './screens/design/Design';
import { gtmkey } from './helpers/gtmkey';
import { Designart } from './screens/design/Designart';
import { Designwall } from './screens/design/Designwall';
import { Contact as Contactus } from './screens/contact/Contact';
// import { Myaccount } from './screens/account/Account';
// import { Address } from './screens/address/Address';
import GiftingList from './screens/gifting/giftingList';
import GiftingDetails from './screens/gifting/giftingDetails';
import OTPLogin from './screens/login/otplogin';
import PerfectPairs from './screens/art/perfectPair';
import ObjectListing from './screens/objects/objects_listing';
import ObjectDetails from './screens/objects/objectDetails';
import MixedMediaDetails from './screens/mixedMedia/mixedMediaDetails';
import MixedMedia from './screens/mixedMedia/mixedMedia';

const history = createHistory()

ReactGA.initialize('');

history.listen((location, action) => {
  ReactGA.pageview(location.pathname);
  console.log('current path', location.pathname)
});
const tagManagerArgs = {
  gtmId: gtmkey
}
TagManager.initialize(tagManagerArgs)


export default function App() {
  return (
    <Router history={history}>
      <div>
        <Switch>
          <Route path="/" exact>
            <ArtProvider>
              <UserProvider>
                <ProductProvider>
                  <HomeScreen />
                </ProductProvider>
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path="/blog/" exact>
            <ArtProvider>
              <ProductProvider>
                <BlogListing />
              </ProductProvider>
            </ArtProvider>
          </Route>
          <Route path="/blog/:id(\d+)" exact>
            <ArtProvider>
              <ProductProvider>
                <BlogListing />
              </ProductProvider>
            </ArtProvider>
          </Route>
          <Route path="/blog/:nid" exact>
            <ArtProvider>
              <ProductProvider>
                <BlogDetails />
              </ProductProvider>
            </ArtProvider>
          </Route>
          <Route path="/checkout/:stage">
            <ArtProvider>
              <ProductProvider>
                <Checkout />
              </ProductProvider>
            </ArtProvider>
          </Route>
          <Route path="/cart">
            <ArtProvider>
              <Cart />
            </ArtProvider>
          </Route>
          <Route path="/login">
            <ArtProvider>
              <OTPLogin />
            </ArtProvider>
          </Route>
          <Route path="/signup">
            <ArtProvider>
              <OTPLogin />
            </ArtProvider>
          </Route>
          <Route path="/profile/shipping/edit">
            <ArtProvider>
              <AddressEdit />
            </ArtProvider>
          </Route>
          <Route path="/profile/shipping">
            <ArtProvider>
              <ShippingList />
            </ArtProvider>
          </Route>
          <Route path="/profile/edit">
            <ArtProvider>
              <ProfileEdit />
            </ArtProvider>
          </Route>
          <Route path="/frame-edit/:id">
            <ArtProvider>
              <FrameEdit />
            </ArtProvider>
          </Route>
          <Route path="/frame-edit">
            <ArtProvider>
              <FrameEdit />
            </ArtProvider>
          </Route>
          <Route path="/art/theme/:theme/:id">
            <ArtProvider>
              <UserProvider>
                <ProductProvider>
                  <ArtSelect />
                </ProductProvider>
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path="/art/:id(\d+)">
            <ArtProvider>
              <UserProvider>
                <ProductProvider>
                  <ArtSelect />
                </ProductProvider>
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path="/art/:artid">
            <ArtProvider>
              <ProductProvider>
                <ArtTypeSelect />
              </ProductProvider>
            </ArtProvider>
          </Route>
          <Route path="/art">
            <ArtProvider>
              <ProductProvider>
                <UserProvider>
                  <ArtSelect />
                </UserProvider>
              </ProductProvider>
            </ArtProvider>
          </Route>
          <Route path="/frame/:id">
            <ArtProvider>
              <FrameSelect />
            </ArtProvider>
          </Route>
          <Route path="/frame">
            <ArtProvider>
              <FrameSelect />
            </ArtProvider>
          </Route>
          <Route path="/resize">
            <ArtProvider>
              <FrameEdit />
            </ArtProvider>
          </Route>
          <Route path="/size/:artid">
            <ArtProvider>
              <SizeSelect />
            </ArtProvider>
          </Route>
          <Route path="/size">
            <ArtProvider>
              <SizeSelect />
            </ArtProvider>
          </Route>
          <Route path="/start_framing">
            <ArtProvider>
              <ArtTypeSelect />
            </ArtProvider>
          </Route>
          <Route path="/art-collection/">
            <ArtProvider>
              <ProductProvider>
                <ShopArt />
              </ProductProvider>
            </ArtProvider>
          </Route>
          <Route path="/perfect-pairs/">
            <ArtProvider>
              <ProductProvider>
                <PerfectPairs />
              </ProductProvider>
            </ArtProvider>
          </Route>
          <Route path="/gallery-walls/:id">
            <ArtProvider>
              <ProductProvider>
                <GalleryWall />
              </ProductProvider>
            </ArtProvider>
          </Route>
          <Route path="/gallery-walls/">
            <ArtProvider>
              <UserProvider>
                <ProductProvider>
                  <GallerySelect />
                </ProductProvider>
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path="/custom-framing/">
            <ArtProvider>
              <ProductProvider>
                <UploadScreen />
              </ProductProvider>
            </ArtProvider>
          </Route>
          <Route path="/crop">
            <ArtProvider>
              <ProductProvider>
                <Crop />
              </ProductProvider>
            </ArtProvider>
          </Route>
          <Route path="/decor-objects/:id(\d+)">
            <ArtProvider>
              <ProductProvider>
                <UserProvider>
                  <ObjectListing />
                </UserProvider>
              </ProductProvider>
            </ArtProvider>
          </Route>
          <Route path="/decor-objects/:path">
            <ArtProvider>
              <ProductProvider>
                <ObjectDetails />
              </ProductProvider>
            </ArtProvider>
          </Route>
          <Route path="/decor-objects/">
            <ArtProvider>
              <ProductProvider>
                <UserProvider>
                  <ObjectListing />
                </UserProvider>
              </ProductProvider>
            </ArtProvider>
          </Route>
          <Route path="/mixed-media/:id(\d+)">
            <ArtProvider>
              <ProductProvider>
                <UserProvider>
                  <MixedMedia />
                </UserProvider>
              </ProductProvider>
            </ArtProvider>
          </Route>
          <Route path="/mixed-media/:path">
            <ArtProvider>
              <ProductProvider>
                <MixedMediaDetails />
              </ProductProvider>
            </ArtProvider>
          </Route>
          <Route path="/mixed-media/">
            <ArtProvider>
              <ProductProvider>
                <UserProvider>
                  <MixedMedia />
                </UserProvider>
              </ProductProvider>
            </ArtProvider>
          </Route>
          <Route path="/select/:type">
            <ArtProvider>
              <TypeSelect />
            </ArtProvider>
          </Route>
          <Route path="/order/:id">
            <ArtProvider>
              <OrderDetails />
            </ArtProvider>
          </Route>
          <Route path="/account/:id/:order_no" exact>
            <ArtProvider>
              <UserProvider>
                <ProfileDetails />
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path="/account/:id">
            <ArtProvider>
              <UserProvider>
                <ProductProvider>
                  <ProfileDetails />
                </ProductProvider>
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path="/account/">
            <ArtProvider>
              <ProductProvider>
                <MyProfile />
              </ProductProvider>
            </ArtProvider>
          </Route>
          <Route path='/company'>
            <ArtProvider>
              <UserProvider>
                <Company />
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path='/stores'>
            <ArtProvider>
              <UserProvider>
                <Stores />
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path="/faq/">
            <ArtProvider>
              <UserProvider>
                <ProductProvider>
                  <Faq />
                </ProductProvider>
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path="/about/">
            <ArtProvider>
              <UserProvider>
                <ProductProvider>
                  <About />
                </ProductProvider>
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path="/contact/">
            <ArtProvider>
              <UserProvider>
                <ProductProvider>
                  <Contact />
                </ProductProvider>
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path="/terms-of-service/">
            <ArtProvider>
              <UserProvider>
                <ProductProvider>
                  <Terms />
                </ProductProvider>
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path="/privacy-policy/">
            <ArtProvider>
              <UserProvider>
                <ProductProvider>
                  <Privacy />
                </ProductProvider>
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path='/opt_out'>
            <ArtProvider>
              <UserProvider>
                <OptOut />
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path="/partner" exact>
            <ArtProvider>
              <UserProvider>
                <Partner />
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path="/partner/apply" exact>
            <ArtProvider>
              <UserProvider>
                <Partnerapply />
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path="/design" exact>
            <ArtProvider>
              <UserProvider>
                <Design />
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path="/design/art" exact>
            <ArtProvider>
              <UserProvider>
                <Designart />
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path="/design/wall" exact>
            <ArtProvider>
              <UserProvider>
                <Designwall />
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path="/contact-us" exact>
            <ArtProvider>
              <UserProvider>
                <ProductProvider>
                  <Contactus />
                </ProductProvider>
              </UserProvider>
            </ArtProvider>
          </Route>
          {/* <Route path="/my-account" exact>
            <ArtProvider>
              <UserProvider>
                <Myaccount />
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path="/saved-address" exact>
            <ArtProvider>
              <UserProvider>
                <Address />
              </UserProvider>
            </ArtProvider>
          </Route> */}
          <Route path="/gifts/" exact>
            <ArtProvider>
              <UserProvider>
                <ProductProvider>
                  <GiftingList />
                </ProductProvider>
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path="/gifts/:id(\d+)" exact>
            <ArtProvider>
              <UserProvider>
                <ProductProvider>
                  <GiftingList />
                </ProductProvider>
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path="/gifts/:title" exact>
            <ArtProvider>
              <UserProvider>
                <ProductProvider>
                  <GiftingDetails />
                </ProductProvider>
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path="/pinterestf458c" exact>
            <ArtProvider>
              <UserProvider>
                <ProductProvider>
                  <Pinterest />
                </ProductProvider>
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route path="/403" exact>
            <ArtProvider>
              <UserProvider>
                <ProductProvider >
                  <DataError />
                </ProductProvider>
              </UserProvider>
            </ArtProvider>
          </Route>
          <Route>
            <ArtProvider>
              <UserProvider>
                <ProductProvider>
                  <Error />
                </ProductProvider>
              </UserProvider>
            </ArtProvider>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}