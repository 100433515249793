import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useParams } from 'react-router'
import PriceTable from '../checkout/priceTable';
import 'react-accessible-accordion/dist/fancy-example.css';
import { Context as UserContext } from '../../context/UserContext';
// import './profile.css'
import AddressListItem from '../../components/profile/addressListItem';
import Orderlist from './orderList';
import OrderSingleList from './orderSIngleList'


const Openorders = props => {
    const search = useLocation().search;
    const query = new URLSearchParams(search).get('orderno')
    const { order_no } = useParams()
    const { state, single_order } = useContext(UserContext);
    React.useEffect(() => {

        console.log('order-query', order_no)
        single_order(order_no)
    }, []);

    // const [selected, setselected] = useState("6 x 4.25")
    const [selects, setselect] = useState("set")
    const { getid } = useParams()
    console.log('single order', state.singleorder)
    console.log('id in singleorder')
    const ordercontents = {
        title: `Mercer Slim`,
        price: `2,000`,
        dimensions: `20"x26.67"`,
        matstyle: `White`,
        Framesize: `20"x26.67"`,
        qty: `1`
    }

    return (
        <div id="profile-edit" className="profile-edit open-orders">
            {state.singleorder ? <h2 className='mt-4 d-menu-heading'>Order #{state.singleorder.data ? state.singleorder.data.attributes ? state.singleorder.data.attributes.order_number : '' : ''}</h2> : ''}
            <div className="shipping-list-container pad-0 mob">
                {state.singleorder ? state.singleorder.included ? state.singleorder.included.map((item) => {
                    if (item.type === 'commerce_order_item--default')
                        if (item.relationships.purchased_entity.data.type !== "commerce_product_variation--frame")
                            return <OrderSingleList ordercontents={item.attributes} relationships={item.relationships} />
                }) : '' : ''}
            </div>
            <div className="row pad-0 mob">
                <div className='col-sm-6 pr-2 bggrey'>
                    <div className="order-thumb">
                        {/* <div className="shipping-list-container container pad-0 mob">
                                        <CartItem />
                                    </div> */}
                        {state.singleorder ? <div className="container ">
                            <PriceTable data={state.singleorder.data} />
                        </div> : ''}
                    </div>
                </div>
                <div className='col-sm-6 singleorder-ships-table'>
                    {state.singleorder ? state.singleorder.included ?
                        state.singleorder.included.map(data => {
                            if (data.type === 'profile--customer') {
                                console.log('order shipping data', data.attributes)
                                return (
                                    <div className='row bggrey singleorder-ships'>
                                        <div className='col-6'>
                                            <div className='order-list-price'>Personal details </div>
                                            <div className='ship-details'>
                                                {data.attributes ? data.attributes.address ? data.attributes.address.given_name : '' : ''} {data.attributes ? data.attributes.address ? data.attributes.address.family_name : '' : ''}
                                                <br />
                                                {data.attributes ? data.attributes.field_phone_number : ''}
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='order-list-price'>Shipping </div>
                                            <div className='ship-details'>
                                                {data.attributes ? data.attributes.field_contact_name ? <span>
                                                    {data.attributes.field_contact_name} <br />
                                                </span> : "" : ''}
                                                {data.attributes ? data.attributes.field_gstin ? <span>
                                                    {data.attributes.field_gstin} <br />
                                                </span> : "" : ''}
                                                {data.attributes ? data.attributes.field_house_apartment_number : ''}<br />
                                                {data.attributes ? data.attributes.address ? data.attributes.address.address_line1 ? data.attributes.address.address_line1 : "" : '' : ''}
                                                {data.attributes ? data.attributes.address ? data.attributes.address.locality ? ", " + data.attributes.address.locality : '' : "" : ''} <br />
                                                {data.attributes ? data.attributes.field_pincode : ''} <br />
                                                {data.attributes ? data.attributes.field_state : ''} <br />
                                                {data.attributes ? data.attributes.field_email : ''} <br />
                                                {data.attributes ? data.attributes.field_phone_number : ''}
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='order-list-price'>Order Status </div>
                                            <div className='ship-details text-capitalize'>{state.singleorder.data ? state.singleorder.data.attributes ? state.singleorder.data.attributes.state : "" : ''}</div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='order-list-price'>Payment method </div>
                                            <div className='ship-details'>Razor pay</div>
                                        </div>
                                    </div>
                                )
                            }
                        })

                        : "" : ''}

                </div>
            </div>
        </div>
    );
}

export default Openorders;
