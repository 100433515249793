import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
// import AlertTemplate from 'react-alert-template-basic'

import errclose from "./assets/errclose.png"
import erricon from "./assets/err.png"

// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 9999,
  }
}
const AlertTemplate = ({ style, options, message, close }) => (
  <div style={style} className="alert-template">
    {/* {options.type === 'info' && <img className='err-icon' src="/assets/toast/warning.png" />}
    {options.type === 'success' && <img className='err-icon' src="/assets/toast/success.png" />}
    {options.type === 'error' && <img className='err-icon' src="/assets/toast/error.png" />} */}
    {message.length < 16 ? <h6>{message}</h6> : <p>{message}</p>}
    <img className='alert-close' onClick={close} src={errclose} />
  </div>
)

ReactDOM.render(
  <React.StrictMode>
    <AlertProvider template={AlertTemplate} {...options}>
      <App />
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
