import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import empty from '../../assets/Cart Empty.png'

const EmptyCart = () => {
    // const { state, fetch_single_article } = useContext(ArtContext);
    React.useEffect(() => {
    }, []);

    const [selected, setselected] = useState("6 x 4.25")

    return (
        <div id="empty-cart" className="empty-cart">
            <div className="empty-img">
                <img alt="icon" src={empty} />
            </div>
            <h2 className="bag-empty-txt mt-3 mb-2 text-center secondarycolor">
                Your bag is empty
            </h2>
            <p className="sub-title">
                Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.
            </p>
            <button onClick={()=>window.location.href="/"} className="black-btn bgsecondary whitecolor">
                Start Shopping
            </button>
        </div>
    );
}

export default EmptyCart;
