import React, { useEffect } from "react"
import Footer from "../../components/Footer/footer"
import Header from "../../components/header/header"
import { MetaTags } from 'react-meta-tags';



const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="aboutpage">
            <Header />
            <MetaTags>
                <title>Learn About Espravo - Our Story and Philosophy</title>
                <meta name="description" content="Espravo is your trusted partner in art work. Find out about our journey and what makes us unique and how we democratize wall art space." />
                <meta property="og:title" content="About Us" />
                <meta property="og:description" content="At Espravo we believe in the power of art to delight and inspire our daily lives. Art as a form of expression is
                    evocative of one’s personality and buying it should be uniquely experiential. With the vision to breathe life into
                    your walls, we have coalesced an eclectic mix of art prints, gallery walls and picture frames, all customizable and
                    ready to blend with your aesthetics." />
                <meta property="og:image" content="https://esp.tridz.in/static/media/aboutbanner.32a13d3b.png" />
                <meta name="twitter:card" content="" />
                <meta name="twitter:site" content="@twitterid" />
                <meta name="twitter:title" content="About Us" />
                <meta name="twitter:description" content="At Espravo we believe in the power of art to delight and inspire our daily lives. Art as a form of expression is
                    evocative of one’s personality and buying it should be uniquely experiential. With the vision to breathe life into
                    your walls, we have coalesced an eclectic mix of art prints, gallery walls and picture frames, all customizable and
                    ready to blend with your aesthetics." />
                <meta name="twitter:image" content="https://esp.tridz.in/static/media/aboutbanner.32a13d3b.png" />
                <link rel="canonical" href={`${window.location.origin}/about/`} />
            </MetaTags>
            <img className="about-banner w-100" src="/assets/About/aboutbanner.webp" />
            <div className="container py-sm-5 my-sm-5 py-2 my-2">
                <h1 className="GT main-heading text-center pb-3">About Us</h1>
                <p className="brandon desk-only text-center about-details">
                    At Espravo we believe in the power of art to delight and inspire our daily lives. Art as a form of expression is<br />
                    evocative of one’s personality and buying it should be uniquely experiential. With the vision to breathe life into <br />
                    your walls, we have coalesced an eclectic mix of <a href="/art/"> art prints</a>, gallery walls and picture frames, all customizable and <br />
                    ready to blend with your aesthetics.
                </p>
                <p className="brandon mob-only text-justify about-main-details about-details">
                    At Espravo we believe in the power of art to delight and inspire our daily lives. Art as a form of expression is
                    evocative of one’s personality and buying it should be uniquely experiential. With the vision to breathe life into
                    your walls, we have coalesced an eclectic mix of <a href="/art/"> art prints </a>, gallery walls and picture frames, all customizable and
                    ready to blend with your aesthetics.
                </p>
                <div className="mt-5 pt-sm-5 pt-2 row">
                    <img className="col-md-6 col-12 order-md-2 mb-3 mb-md-0" src="/assets/About/about1.webp" />
                    <div className="col-md-6 col-12 order-md-1">
                        <h2 className="GT main-heading pb-3">Inception of Espravo</h2>
                        <p className="brandon text-justify pr-sm-5 pr-0 about-details">
                            With the objective to democratize wall art space and aspiring to make it accessible and affordable to an extensive audience,
                            we conceptualized our flagship store in Kochi in 2017. Reflecting our brand’s philosophy is our mystical logo, which emulates a prehistoric stone figure that metamorphoses into a sculptural beauty. We aspire to build a global artist community to promote art with bespoke services and support.
                        </p>
                    </div>
                </div>
                <div className="row mt-5 pt-sm-5 pt-2 ">
                    <img className="col-md-6 col-12 mb-3 mb-md-0" src="/assets/About/about2.webp" />
                    <div className="col-md-6 col-12">
                        <h3 className="GT main-heading pb-3 pl-sm-5 pl-0">Our Philosophy</h3>
                        <p className="brandon text-justify pl-sm-5 pl-0 about-details">
                            Espravo was built with a shared love for art and the belief that everyone should have the opportunity to enjoy and acquire art in an environment that is both welcoming and inspiring. Our gallery boasts of a vibrant and flourishing ecosystem with the quality first approach.
                            We have crafted a special niche market that aligns with the brand's identity, art for all. Pursuing a wider audience, our digital platform creates a near tangible experience with our state-of-the-art tools and smooth interface.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default About;