import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const EditAddress = () => {
    // const { state, fetch_single_article } = useContext(ArtContext);
    React.useEffect(() => {
    }, []);

    const [selected, setselected] = useState("6 x 4.25")

    return (
        <div id="personal_data">
            <p className="mb-3 small-normal">Keep your addresses and contact details updated</p>
            <div className="fields-sec mx-0 row">
                <input className="profile-input col-md-5 mr-md-4" placeholder="First Name *" />
                <input className="profile-input col-md-5 mr-md-4" placeholder="Last Name *" />
                <input className="profile-input col-md-12 mr-md-4" placeholder="Street Name *" />
                <input className="profile-input col-md-12 mr-md-4" placeholder="House / Apartment number *" />
                <input className="profile-input col-md-5 mr-md-4" placeholder="City *" />
                <input className="profile-input col-md-5 mr-md-4" placeholder="State/Province *" />
                <input className="profile-input col-md-5 mr-md-4" placeholder="Pin-code *" />
                <input className="profile-input col-md-5 mr-md-4" placeholder="Country *" />
                <input className="profile-input col-md-12 mr-md-4" placeholder="Mobile Number" />
                {/* <input className="profile-input" placeholder="Last Name *" />

                <input className="profile-input" placeholder="Your e-mail *" /> */}
            </div>
            <button id="ship-change-details-btn" className="black-btn bgsecondary whitecolor ship-change-details-btn">
                Change Details
            </button>
        </div>
    );
}

export default EditAddress;
