import React, { useContext, useEffect, useState } from 'react';
import { Context as ArtContext } from '../../context/ArtContext';
import { Link, useHistory, useParams } from 'react-router-dom';
import Header from '../../components/header/header';
import Footer from '../../components/Footer/footer';
import parse from 'html-react-parser';
import { base_url } from '../../helpers/baseURL';
import axios from 'axios';
import Loader from '../../components/checkout/loader';
import HTMLReactParser from 'html-react-parser';
import Stay from './stay';
import { MetaTags } from 'react-meta-tags';
import createHistory from 'history/createBrowserHistory'


const history = createHistory()

const BlogListing = () => {
    const { state, getArticles, setoffsetblog } = useContext(ArtContext);
    const [count, setCount] = useState(20)
    const [pages, setPages] = useState([])
    const history = useHistory()
    const [current, setCurrent] = useState(1)
    let params = useParams()
    React.useEffect(() => {
        window.scrollTo(0, 0)
        if (params.id) {
            setCurrent(Number(params.id))
            // getArticles(Number(params.id) == 0 ? Number(params.id) : Number(params.id) - 1)
        }
        else
            setCurrent(1)
        getArticles(0)
        console.log("blog count", state.articles)
        if (state.blogcount) {
            let i, pages = [], no = 1
            for (i = 0; i < Number(state.blogcount); i = i + 8) {
                pages.push(no)
                no = no + 1
            }
            setPages(pages)
        }
    }, []);
    const setPage = (no) => {
        history.push(`/blog/${no == 1 ? "" : `${no}/`}`)
        window.scrollTo({
            top: 0,
            behavior: "smooth",
            left: 0
        })
        setCurrent(no)
        // getArticles(no == 0 ? no : no - 1)
    }
    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            console.log('History change:', location.pathname, action);
            let regex = /^\/blog\/\d+\/?$/;
            console.log("page matches ", regex.test(location.pathname))
            let page = location.pathname.match(/\d+/g)?.length ? location.pathname.match(/\d+/g)[0] : ""
            if (action === "POP")
                setPage(page === "" ? 1 : Number(page))
            console.log("page sis", page)
            window.scrollTo({
                top: 0,
                behavior: "smooth",
                left: 0
            })
            // Perform any actions you want in response to the history change
        });

        // Cleanup function to remove the listener when the component unmounts
        return () => {
            unlisten();
        };
    }, []);
    React.useEffect(() => {
        console.log("blog count", state.articles)
        if (state.articles) {
            let i, pages = [], no = 1
            if (state.articles.count)
                for (i = 0; i < Number(state.articles.count); i = i + 6) {
                    pages.push(no)
                    no = no + 1
                }
            setPages(pages)
        }
    }, [state.articles]);

    // test
    const uploadimg = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            axios({
                method: 'post',
                url: base_url + `/jsonapi/media/image/field_media_image`,
                body: e.target.files[0],
                headers: { "Accept": "application/vnd.api+json", "Content-Type": "application/vnd.api+json", "X-CSRF-Token": "CvDczKHU__d0MWMkPysMpBIN7nfU3RoYp_n61oCuFqE", "Content-Disposition": `file;filename="new.jpg"` }
            })
                .then(res => {
                    if (res.data) {
                        console.log(res.data)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }
    return (
        <div>
            <MetaTags>
                <title>Photo Frames, Gallery walls, Art & Gift Guides | Espravo Blog</title>
                <meta name="description" content="Stay up-to-date with industry trends & company news through Espravo's blog. Explore our posts for insights on art, gallery walls & home decor & more." />
                <link rel="canonical" href={`${window.location.origin}/blog/`} />
            </MetaTags>
            <Header />
            <div id='blog'>
                <div className="py-sm-5 container">
                    {/* <input type="file" onChange={(e) => uploadimg(e)} /> */}
                    <h1 className="main-heading mb-3 mt-sm-0 mt-3 text-center">
                        Blog
                    </h1>
                    <h2 className="brandon hashcolor-text mb-5 text-center galSub">
                        Welcome to Espravo blog! Explore the latest home decor trends, art buying and framing tips and much more.
                    </h2>
                    {state.articles ?
                        state.articles.data ?
                            <div className="blog-list d-flex flex-wrap">
                                {state.articles.data.relationships.items?.data.filter(x => state.articles.included.filter(y => y.id === x.id).length).map((article, ind) => {
                                    let data = state.articles.included.filter(x => x.id === article.id).length ? state.articles.included.filter(x => x.id === article.id)[0] : null
                                    let path = data?.attributes.path?.alias,
                                        media = data?.relationships.field_image?.data ? state.articles.included.filter(x => x.id === data.relationships.field_image.data.id)[0].attributes.image_style_uri.find(obj => obj.inner).inner : null
                                    if (ind >= ((current - 1) * 6) && ind <= ((6 * current) - 4))
                                        return (
                                            <Link to={`${path}/`} onClick={() => { localStorage.setItem("blog_back", current) }} className={"col-12 col-sm-4 mb-4" + ` first ${ind}`}>
                                                <div className='single-blog'>
                                                    <div className="blog-img">
                                                        {media ?
                                                            <img src={media} alt="blog" />
                                                            : <img src="http://ifdb.tridz.in/static/media/homeevent.19dd7d12.jpeg" alt="blog" />}
                                                    </div>
                                                    <div className='p-2'>
                                                        <h6 className="blog-title brandon text-center">{data.attributes.title}</h6>
                                                        <p className='blog-description brandon text-center'>
                                                            {data.attributes.body ? HTMLReactParser(data.attributes.body.value) : ""}
                                                        </p>
                                                        {/* <Link className="read-more-btn brandon text-center" to={`${path}/`}>
                                                            Read More
                                                        </Link> */}
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                })}

                                {/* {state.featuredArticles ?
                                    state.featuredArticles.data.relationships.items?.data.filter(x => state.featuredArticles.included.filter(y => y.id === x.id).length).map((article, ind) => {
                                        let data = state.articles.included.filter(x => x.id === article.id).length ? state.articles.included.filter(x => x.id === article.id)[0] : null
                                        let path = data?.attributes.path?.alias,
                                            media = data?.relationships.field_image?.data ? state.featuredArticles.included.filter(x => x.id === data.relationships.field_image.data.id)[0].attributes.image_style_uri.find(obj => obj.inner).inner : null
                                        if (ind == (current * 2 - 1) || ind == (current * 2 - 2))
                                            return (
                                                <Link to={`${path}/`} onClick={() => { localStorage.setItem("blog_back", current) }} className={"col-12 col-sm-6  mb-4"}>
                                                    <div className='single-blog'>
                                                        <div className="blog-img">
                                                            {media ?
                                                                <img src={media} alt="blog" />
                                                                : <img src="http://ifdb.tridz.in/static/media/homeevent.19dd7d12.jpeg" alt="blog" />}
                                                        </div>
                                                        <div className='p-2'>
                                                            <h6 className="blog-title brandon text-center">{data.attributes.title}</h6>
                                                            <p className='blog-description brandon text-center'>
                                                                {data.attributes.body ? HTMLReactParser(data.attributes.body.value) : ""}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            )
                                    })
                                    : ""
                                } */}

                                {state.articles.data.relationships.items?.data.filter(x => state.articles.included.filter(y => y.id === x.id).length).map((article, ind) => {
                                    let data = state.articles.included.filter(x => x.id === article.id).length ? state.articles.included.filter(x => x.id === article.id)[0] : null
                                    let path = data?.attributes.path?.alias,
                                        media = data?.relationships.field_image?.data ? state.articles.included.filter(x => x.id === data.relationships.field_image.data.id)[0].attributes.image_style_uri.find(obj => obj.inner).inner : null
                                    if (ind > ((6 * current) - 4) && ind < (current * 6))
                                        return (
                                            <Link to={`${path}/`} onClick={() => { localStorage.setItem("blog_back", current) }} className={"col-12 col-sm-4 mb-4" + ` second ${ind}`}>
                                                <div className='single-blog'>
                                                    <div className="blog-img">
                                                        {media ?
                                                            <img src={media} alt="blog" />
                                                            : <img src="http://ifdb.tridz.in/static/media/homeevent.19dd7d12.jpeg" alt="blog" />}
                                                    </div>
                                                    <div className='p-2'>
                                                        <h6 className="blog-title brandon text-center">{data.attributes.title}</h6>
                                                        <p className='blog-description brandon text-center'>
                                                            {data.attributes.body ? HTMLReactParser(data.attributes.body.value) : ""}
                                                        </p>
                                                        {/* <Link className="read-more-btn brandon text-center" to={`${path}/`}>
                                                            Read More
                                                        </Link> */}
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                })}
                                <div className='col-12 pagination'>
                                    <div className='d-flex w-100 mx-auto justify-content-center'>
                                        {pages ? pages.map((no) => {
                                            return (
                                                <p className={current === no ? "current pageno brandon cursor" : "pageno cursor brandon"} onClick={() => setPage(no)} >{no}</p>
                                            )
                                        }) : ""}
                                    </div>
                                </div>
                            </div>
                            : "No Data"
                        : <Loader />}
                </div>
            </div>
            {/* <div className='bg-white stay-page-component'>
                <Stay />
            </div> */}
            <Footer />
        </div>
    );
}

export default BlogListing;