import React, { useEffect, useState } from "react"
import Header from '../../components/header/header'
import Footer from '../../components/Footer/footer'
import cover from '../../assets/eac-banner1.jpg'
import axios from "axios"
import parse from 'html-react-parser';
import { base_url } from "../../helpers/baseURL";
import { Link, useHistory } from "react-router-dom";
import Popup from "reactjs-popup"
import { useAlert } from "react-alert"
import Loader from '../../components/checkout/loader';
import { ValidateEmail } from "../../helpers/misc_func"
import { MetaTags } from "react-meta-tags"



const Contact = (props) => {
    let history = useHistory();
    const [page, setPage] = useState()
    const [loader, setloader] = useState(false)
    const [problem, setProblem] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [mailopen, setMailopen] = useState(false)
    const alert = useAlert()
    const mailClose = () => {
        setMailopen(false)
        setProblem("")
        setEmail("")
        setName("")
        setMessage("")
    }
    const sendEmail = () => {
        if (problem && name && email && message) {
            if (ValidateEmail(email)) {
                setloader(true)
                const body2 = {
                    "contact_form": [{ "target_id": "contact_form" }],
                    "name": [{ "value": name }],
                    "mail": [{ "value": email }],
                    "subject": [{ "value": "Contact mail" }],
                    "message": [{ "value": message }],
                    "field_questions": [{ "value": problem }]
                }
                const body = {
                    "data": {
                        "type": "contact_message--contact_form",
                        "attributes": {
                            "name": name,
                            "field_email_id": email,
                            "field_questions": problem,
                            "message": message,
                            "subject": problem
                        }
                    }
                }
                axios({
                    method: 'post',
                    headers: {
                        'Accept': `application/json`,
                        "Content-type": "application/json",
                    },
                    url: `${base_url}/contact_message?_format=json`,
                    data: body2
                }
                )
                    .then((res) => {
                        console.log("contacted", res.data)
                        setloader(false)
                        mailClose()
                        alert.show("Message Sent Succesfully")
                    })
                    .catch(err => {
                        setloader(false)
                        console.log("contact error", err.message)
                    })
            }
            else
                alert.show("Enter E-mail correctly")
        }
        else
            alert.show("Enter all details")

    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            {loader ? <Loader /> : ''}
            {

            }
            <Header />
            <MetaTags>
                <title>Contact Espravo - Reach Out to Us Today</title>
                <meta name="description" content="At Espravo, customer satisfaction is our priority. Reach out to us through our contact page, or call us on +91 484 270 0090 and our team will assist you." />
                <link rel="canonical" href={`${window.location.origin}/contact/`} />
            </MetaTags>
            <div id='stores' className="pb-sm-5 mb-sm-5 header-container">
                <div className="articledetails align-center-remover">
                    <div className="selectBack pt-3 brandon d-flex align-self-left" onClick={() => history.goBack()}>
                        <img src="/assets/arrow-left.webp" className="selectBackIcon" alt="thumb"></img>
                        <p className='ml-2'>Back</p>
                    </div>
                    <h1 className="GT main-heading w-100 pt-sm-5 pt-0 mt-sm-5 mt-3 mb-2 mb-lg-5 text-center">Contact Us</h1>
                    <div className="row p-0 m-0 justify-content-center align-self-center">
                        <a target="_blank" href="tel:04842700090" className="contact-cards  text-center">
                            <img className="contact-card-icon mb-2 mb-sm-4" src='/assets/contact/calling.webp' />
                            <div className="GT contact-card-title my-2">Call</div>
                            <p>+91 484 270 0090</p>
                            <label className="brandon">Mon - Sat, 10am - 7pm IST</label>
                        </a>
                        <a target="_blank" href="https://wa.me/+919446016000" className="contact-cards  text-center">
                            <img className="contact-card-icon mb-2 mb-sm-4" src='/assets/contact/whatsapping.webp' />
                            <div className="GT contact-card-title my-2">Whatsapp</div>
                            <p>+91 94460 16000</p>
                        </a>
                        <div onClick={() => setMailopen(true)} className="contact-cards cursor  text-center lonely-card">
                            <img className="contact-card-icon mb-2 mb-sm-4" src='/assets/contact/mailing.webp' />
                            <div className="GT contact-card-title my-2">E-mail</div>

                        </div>
                        <a target="_blank" href="https://www.google.com/maps/place/ESPRAVO+KOCHI/@9.9147693,76.3150598,17z/data=!3m1!4b1!4m5!3m4!1s0x3b08724caa6e27cf:0x5407a4a955e22e5b!8m2!3d9.9147693!4d76.3172485" className="contact-cards  text-center">
                            <img className="contact-card-icon mb-2 mb-sm-4" src='/assets/contact/storing.webp' />
                            <div className="GT contact-card-title my-2">Store</div>
                            <p className="brandon card-add">2/478-A, Panangad<br />
                                Kochi, Kerala 682506</p>
                        </a>
                        <a target="_blank" href="https://www.google.com/maps/place/ESPRAVO+KOCHI/@9.9147693,76.3150598,17z/data=!3m1!4b1!4m5!3m4!1s0x3b08724caa6e27cf:0x5407a4a955e22e5b!8m2!3d9.9147693!4d76.3172485" className="contact-cards  text-center lonely-card">
                            <img className="contact-card-icon mb-2 mb-sm-4" src='/assets/contact/locating.webp' />
                            <div className="GT contact-card-title my-2">Google Maps</div>
                        </a>
                        <a target="_blank" href="https://instagram.com/espravo?igshid=MDg3NTkzMDk=" className="contact-cards text-center lonely-card">
                            <img className="contact-card-icon mb-2 mb-sm-4" src='/assets/contact/instagraming.webp' />
                            <div className="GT contact-card-title my-2">Instagram</div>
                        </a>
                    </div>
                </div>
                <Popup open={mailopen} closeOnDocumentClick={false} onClose={() => mailClose()}>
                    <div className="email-popup brandon">
                        <img className="contact-close" src="/assets/contactclose.webp" onClick={() => mailClose()} />
                        <div className="secondary-color mb-2 pb-2 mb-sm-3 pop-head GT bold text-center">Submit a request</div>
                        <select className="mb-2 mb-sm-3 p-sm-2 p-1" style={{ color: problem ? "" : "#acacac" }} placeholder="What can we help you with?" value={problem} onChange={(e) => setProblem(e.target.value)}>
                            <option value="" disabled selected style={{ display: "none" }}>What can we help you with?</option>
                            <option className="brandon" value="Pre-Purchase Questions">Pre-Purchase Questions</option>
                            <option className="brandon" value="Design Support">Design Support</option>
                            <option className="brandon" value="Shop Art">Shop Art</option>
                            <option className="brandon" value="Custom Framing / Frames">Custom Framing / Frames</option>
                            <option className="brandon" value="User Image Uploads">User Image Uploads</option>
                            <option className="brandon" value="Gallery Walls">Gallery Walls</option>
                            <option className="brandon" value="Gifting">Gifting</option>
                            <option className="brandon" value="Delivery / Pin Code Related">Delivery / Pin Code Related</option>
                            <option className="brandon" value="Packaging Requests">Packaging Requests</option>
                            <option className="brandon" value="Website Issues">Website Issues</option>
                            <option className="brandon" value="Promo Code Issues">Promo Code Issues</option>
                            <option className="brandon" value="Other">Other</option>
                        </select>
                        <input className="mb-2 mb-sm-3 p-sm-2 p-1" placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} />
                        <input className="mb-2 mb-sm-3 p-sm-2 p-1" placeholder="E-mail Address" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <textarea className="mb-2 mb-sm-3 p-sm-2 p-1" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} />
                        <div className="submit-btn brandon text-center mb-2 mb-sm-4" onClick={() => sendEmail()}> Submit Form  </div>
                    </div>
                </Popup>
            </div>
            <Footer />
        </div>
    )
}
export default Contact;