import React, { useEffect, useContext, useState } from "react";
import { useAlert } from "react-alert";
import tick from '../../assets/Vector.png'
import Loader from "../../components/checkout/loader";
import axios from "axios";
import { base_url } from "../../helpers/baseURL";
import Popup from 'reactjs-popup';


import { Context as ArtContext } from '../../context/ArtContext';
import { ValidateEmail } from "../../helpers/misc_func";

const SavedAddress = () => {
    const { state, billing_profile, getShipping, removeAddress, EditSavedAddress, RefreshToken } = useContext(ArtContext);
    const [defaultaddress, setdefaultaddress] = useState("")
    const [loader, setloader] = useState(false)
    const [add, setAdd] = useState(false)
    const [editpop, setEditPop] = useState(false)
    const [addrressType, setAddressType] = useState("personal")
    const [pinstatus, setpinstatus] = useState(true)
    const [same, setsame] = useState(true)
    const [area, setArea] = useState('')
    const [billing_type, setbilling_type] = useState("personal")
    const [editting_address, seteditingaddress] = useState("")
    const [states, setState] = useState('')
    const [billing, setbilling] = useState({
        "contact_name": "",
        "first_name": "",
        "mail": localStorage.getItem("userdat") ? JSON.parse(localStorage.getItem("userdat")).details?.attributes.mail : "",
        "street_name": "",
        "house": "",
        "city": "",
        "state": "Kerala",
        "zip": localStorage.getItem("pincode") ? localStorage.getItem("pincode") : "",
        "country": "IN",
        "gst": "",
        "phone": localStorage.getItem("userdat") ? JSON.parse(localStorage.getItem("userdat")).details?.attributes.name : "",
        "address_type": "personal",
        "active": false
    })
    const alert = useAlert();
    useEffect(() => {
        getShipping()
            .then(res => {
                if (res === null) {
                    console.log("response shipping", res)
                    let userdat = localStorage.getItem('userdat') ? JSON.parse(localStorage.getItem('userdat')) : null
                    RefreshToken(userdat?.refresh_token)
                        .then(res => {
                            console.log("response shipping calling", res)
                            getShipping()
                        })
                        .catch(err => {
                            console.log("response shipping calling error", err)
                            localStorage.removeItem('userdat')
                            window.location.href = '/?expired=true'
                        })
                }
            })
            .catch(err => {

            })
    }, [])
    useEffect(() => {
        if (!editpop) {
            setbilling({
                "contact_name": "",
                "first_name": "",
                "mail": localStorage.getItem("userdat") ? JSON.parse(localStorage.getItem("userdat")).details?.attributes.mail : "",
                "street_name": "",
                "house": "",
                "city": "",
                "state": "Kerala",
                "zip": localStorage.getItem("pincode") ? localStorage.getItem("pincode") : "",
                "country": "IN",
                "gst": "",
                "phone": localStorage.getItem("userdat") ? JSON.parse(localStorage.getItem("userdat")).details?.attributes.name : "",
                "address_type": "personal"
            })
        }
    }, [editpop])
    const checkField = () => {
        if (same) {
            if ((billing.address_type == "personal" || billing.company_name) &&
                (billing.address_type == "personal" || billing.gst) &&
                billing.first_name &&
                ValidateEmail(billing.mail) &&
                billing.street_name &&
                billing.house &&
                billing.city &&
                billing.state &&
                billing.zip &&
                billing.country &&
                billing.phone)
                return true
            else
                return false
        }
    }
    const editAddress = () => {
        console.log("billing:", billing,)
        if (checkField()) {
            setEditPop(false)
            setloader(true)
            EditSavedAddress(editting_address, { billing: billing }, defaultaddress === editting_address)
                .then(res => {
                    alert.show("Address Updated Succesfully", { type: "success" })
                    setbilling({
                        "company_name": "",
                        "gst": "",
                        "first_name": "",
                        "mail": "",
                        "contact_name": "",
                        "street_name": "",
                        "house": "",
                        "city": "",
                        "state": "Kerala",
                        "zip": localStorage.getItem("pincode") ? localStorage.getItem("pincode") : "",
                        "country": "IN",
                        "phone": "",
                        "address_type": "personal"
                    })
                    getShipping();
                    setloader(false)
                })
                .catch(err => {
                    setEditPop(true)
                    alert.show("Something Went Wrong")
                    setloader(false)
                })
        }
        else
            alert.show("Enter all details")
    }
    const updateBillingData = (type, val) => {
        let billingdata = billing
        billingdata[type] = val
        console.log("old", billingdata)
        setbilling(old => ({ ...old, type: val }))
    }
    const setEdit = (address, id) => {
        if (address.is_default)
            setdefaultaddress(id)
        seteditingaddress(id)
        setbilling_type(address.field_type ? address.field_type : "personal")
        const billing = {
            "company_name": address.field_type === "business" ? address.field_contact_name : "",
            "gst": address.field_gstin,
            "first_name": address.address?.given_name,
            "mail": address.field_email,
            "street_name": address.address?.address_line1,
            "house": address.field_house_apartment_number,
            "city": address.address.locality,
            "state": address.field_state,
            "zip": address.field_pincode,
            "country": "IN",
            "phone": address.field_phone_number,
            "address_type": address.field_type ? address.field_type : "personal"
        }
        setbilling(billing)
    }

    const AddAddress = () => {
        if (checkField()) {
            setloader(true)
            let userdat = localStorage.getItem("userdat") ? JSON.parse(localStorage.getItem("userdat")) : null
            console.log("user updating address", userdat)
            billing_profile(billing, userdat?.details?.id, null, userdat)
                .then(res => {
                    alert.show("Address added Succesfully", { type: "success" })
                    setbilling({
                        "company_name": "",
                        "gst": "",
                        "first_name": "",
                        "mail": "",
                        "contact_name": "",
                        "street_name": "",
                        "house": "",
                        "city": "",
                        "state": "Kerala",
                        "zip": localStorage.getItem("pincode") ? localStorage.getItem("pincode") : "",
                        "country": "IN",
                        "phone": "",
                        "address_type": "personal"
                    })
                    getShipping();
                    setAdd(false)
                    setloader(false)
                })
                .catch(err => {
                    console.log("error on address creation", err)
                    alert.show("Something Went Wrong")
                    setloader(false)
                })
        }
        else {
            alert.show("Enter All Details")
        }
    }
    const checkpin = (code) => {
        updateBillingData("zip", code)
        if (code.length === 6) {
            axios({
                method: 'get',
                url: base_url + '/jsonapi/taxonomy_term/pincode?filter[productFilter][condition][path]=name&filter[productFilter][condition][value]=' + code
            })
                .then(res => {
                    if (res.data.data.length) {
                        setpinstatus(true)
                        console.log("getting state and city", res.data.data[0].attributes.field_area, res.data.data[0].attributes.field_state)
                        if (res.data.data[0].attributes.field_area) {
                            setArea(res.data.data[0].attributes.field_area)
                        }
                        if (res.data.data[0].attributes.field_state) {
                            setState(res.data.data[0].attributes.field_state)
                        }
                    }
                    else {
                        setpinstatus(false)
                    }
                })
                .catch(err => {
                    console.log(err.code)
                })
        }
        else {
            setpinstatus(false)
        }
    }

    const addressRemove = (id) => {
        setloader(true)
        removeAddress(id)
            .then(res => {
                getShipping()
                alert.show("Address deleted", { type: "success" })
                setloader(false)
            })
            .catch(err => {
                alert.show("Something Went wrong")
                setloader(false)
            })
    }
    return (
        <div className="profile-saved-address">
            {loader ? <Loader /> : ""}
            <h4 className="checkout-heading d-none d-sm-block GT-bold mb-sm-2 mb-sm-4">
                Saved Address
            </h4>
            <div className="row address-row mt-3 mt-sm-0">
                {
                    state.shippingData?.map((address, ind) => {
                        return (
                            <div className={`address-block ${address.attributes.is_default ? "active" : ""}`}>
                                <div className='d-flex justify-content-between'>
                                    <h6 className='brandon text-uppercase'>{address?.attributes.address?.given_name}</h6>
                                    {address.attributes.is_default ? <div className='default-round'>
                                        <img alt="icon" src={tick} />
                                    </div> : ""}
                                </div>
                                <p className='brandon address-details'>
                                    {address.attributes ? address.attributes.field_contact_name ? <span>
                                        {address.attributes.field_contact_name} <br />
                                    </span> : "" : ''}
                                    {address.attributes ? address.attributes.field_gstin ? <span>
                                        {address.attributes.field_gstin} <br />
                                    </span> : "" : ''}
                                    {address.attributes ? address.attributes.field_house_apartment_number : ''}<br />
                                    {address.attributes ? address.attributes.address ? address.attributes.address.address_line1 ? address.attributes.address.address_line1 : "" : '' : ''}
                                    {address.attributes ? address.attributes.address ? address.attributes.address.locality ? ", " + address.attributes.address.locality : '' : "" : ''} <br />
                                    {address.attributes ? address.attributes.field_pincode : ''} <br />
                                    {address.attributes ? address.attributes.field_state : ''} <br />
                                    {address.attributes ? address.attributes.field_email : ''} <br />
                                    {address.attributes ? address.attributes.field_phone_number : ''}
                                </p>
                                <div className='d-flex justify-content-between mt-sm-4 mt-2'>
                                    <div className='d-flex btn-row'>
                                        <button className='underline-btn'
                                            onClick={() => addressRemove(address.id)}
                                        >Remove</button>
                                        <button className='underline-btn'
                                            onClick={() => { setEditPop(true); setEdit(address?.attributes, address?.id) }}
                                        >Edit</button>
                                    </div>
                                </div>
                            </div>
                        )

                    })
                }
            </div>
            {!add ? <p className='brandon d-flex align-items-center cursor address_add-button' onClick={() => { setAdd(true); window.scrollTo(0, 0) }}>
                <img src='/assets/checkout/plus.png' />
                add new address
            </p> : ""}
            <Popup open={add || editpop} closeOnDocumentClick={false} onClose={() => { setAdd(false); setEditPop(false) }} className="load-file">
                <div className='editaddress-pop'>
                    <div className='row justify-content-between'>
                        <h4 className='edit-heading GT-bold blackmain'>{editpop ? "edit address" : "add new address"}</h4>
                        <img src='/assets/close.png' className='close-btn cursor' onClick={() => { setAdd(false); setEditPop(false) }} />
                    </div>
                    <div className='row billing-type mb-sm-4 mb-2'>
                        <div className='d-flex align-items-center' onClick={() => { setbilling_type("personal"); updateBillingData("address_type", "personal") }} >
                            <div className={`tick ${billing.address_type == "personal" ? "active" : ""}`}><img src={tick} /></div>
                            <p className='brandon'>Personal</p>
                        </div>
                        <div className='d-flex align-items-center' onClick={() => { setbilling_type("business"); updateBillingData("address_type", "business") }} >
                            <div className={`tick ${billing.address_type == "business" ? "active" : ""}`}><img src={tick} /></div>
                            <p className='brandon'>Business</p>
                        </div>
                    </div>
                    <div className="fields-sec pt-3">
                        <div className='row'>
                            {billing_type === "business" ? <><label className='brandon'>Company Name*</label>
                                <input value={billing.company_name} onChange={(e) => updateBillingData("company_name", e.target.value)} className="profile-input brandon" placeholder="Enter your Company Name" />
                            </> : ""}
                            {billing_type === "business" ? <><label className='brandon'>Billing GSTIN*</label>
                                <input value={billing.gst} onChange={(e) => updateBillingData("gst", e.target.value)} className="profile-input brandon" placeholder="Enter your GSTIN Number" />
                            </> : ""}
                            <label className='brandon'>{billing_type === "personal" ? "Name*" : "Contact Name*"}</label>
                            <input value={billing.first_name} onChange={(e) => updateBillingData("first_name", e.target.value)} className="profile-input brandon" placeholder="Enter your name" />
                            <label className='brandon'>Email*</label>
                            <input value={billing.mail} onChange={(e) => updateBillingData("mail", e.target.value)} className="profile-input brandon" placeholder="Enter your mail id" />
                            <label className='brandon'>Mobile Number*</label>
                            <input type="number" value={billing.phone} onChange={(e) => updateBillingData("phone", e.target.value)} className="profile-input brandon" placeholder="Enter your Mobile Number" />
                            <label className='brandon'>Address*</label>
                            <input value={billing.house} onChange={(e) => updateBillingData("house", e.target.value)} className="profile-input brandon" placeholder="Flat / House No / Floor / Building" />
                            <input value={billing.street_name} onChange={(e) => updateBillingData("street_name", e.target.value)} className="profile-input brandon" placeholder="Colony / Street / Locality" />
                            <label className='brandon'>Pincode*</label>
                            <input value={billing.zip} onChange={(e) => checkpin(e.target.value)} className="profile-input brandon" placeholder="Pin-code *" />
                            <label className='brandon'>City*</label>
                            <input value={billing.city} onChange={(e) => updateBillingData("city", e.target.value)} className="profile-input brandon" placeholder="City" />
                            <label className='brandon'>State*</label>
                            <select value={billing.state} onChange={(e) => updateBillingData("state", e.target.value)} className="country brandon form-select required" placeholder="Select State">
                                <option className="brandon" value="Andhra Pradesh">Andhra Pradesh</option>
                                <option className="brandon" value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                <option className="brandon" value="Arunachal Pradesh">Arunachal Pradesh</option>
                                <option className="brandon" value="Assam">Assam</option>
                                <option className="brandon" value="Bihar">Bihar</option>
                                <option className="brandon" value="Chandigarh">Chandigarh</option>
                                <option className="brandon" value="Chhattisgarh">Chhattisgarh</option>
                                <option className="brandon" value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
                                <option className="brandon" value="Daman and Diu">Daman and Diu</option>
                                <option className="brandon" value="Delhi">Delhi</option>
                                <option className="brandon" value="Lakshadweep">Lakshadweep</option>
                                <option className="brandon" value="Puducherry">Puducherry</option>
                                <option className="brandon" value="Goa">Goa</option>
                                <option className="brandon" value="Gujarat">Gujarat</option>
                                <option className="brandon" value="Haryana">Haryana</option>
                                <option className="brandon" value="Himachal Pradesh">Himachal Pradesh</option>
                                <option className="brandon" value="Jammu and Kashmir">Jammu and Kashmir</option>
                                <option className="brandon" value="Jharkhand">Jharkhand</option>
                                <option className="brandon" value="Karnataka">Karnataka</option>
                                <option className="brandon" value="Kerala">Kerala</option>
                                <option className="brandon" value="Madhya Pradesh">Madhya Pradesh</option>
                                <option className="brandon" value="Maharashtra">Maharashtra</option>
                                <option className="brandon" value="Manipur">Manipur</option>
                                <option className="brandon" value="Meghalaya">Meghalaya</option>
                                <option className="brandon" value="Mizoram">Mizoram</option>
                                <option className="brandon" value="Nagaland">Nagaland</option>
                                <option className="brandon" value="Odisha">Odisha</option>
                                <option className="brandon" value="Punjab">Punjab</option>
                                <option className="brandon" value="Rajasthan">Rajasthan</option>
                                <option className="brandon" value="Sikkim">Sikkim</option>
                                <option className="brandon" value="Tamil Nadu">Tamil Nadu</option>
                                <option className="brandon" value="Telangana">Telangana</option>
                                <option className="brandon" value="Tripura">Tripura</option>
                                <option className="brandon" value="Uttar Pradesh">Uttar Pradesh</option>
                                <option className="brandon" value="Uttarakhand">Uttarakhand</option>
                                <option className="brandon" value="West Bengal">West Bengal</option>
                            </select>
                        </div>
                    </div>
                    {editpop ? <div class="select-default mt-2 cursor">
                        <div class={`tick-box ${defaultaddress === editting_address ? "active" : ""}`} onClick={() => defaultaddress === editting_address ? setdefaultaddress("") : setdefaultaddress(editting_address)}>
                            <img alt="icon" src={tick} />
                        </div>
                        <p class="brandon">Make it Default Address</p>
                    </div>
                        :
                        <div class="select-default mt-2 cursor">
                            <div class={`tick-box ${billing.active ? "active" : ""}`} onClick={() => updateBillingData("active", !billing.active)}>
                                <img alt="icon" src={tick} />
                            </div>
                            <p class="brandon">Make it Default Address</p>
                        </div>}
                    <button class="w-100 black-btn brandon bgsecondary mt-1 whitecolor" onClick={() => { editpop ? editAddress() : AddAddress() }}>SAVE &amp; CONTINUE</button>
                </div>
            </Popup >
        </div >
    )
}
export default SavedAddress;