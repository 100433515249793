import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import Footer from '../../components/Footer/footer';
import Header from '../../components/header/header';
import matte from '../../assets/Rectangle 26.png'
import matte2 from '../../assets/Rectangle 27.png'
import crop from '../../assets/Rectangle 6.png'
import { Context as ArtContext } from '../../context/ArtContext';
import './size.css'
import Cropper from '../../components/crop/cropper';
import axios from 'axios';
import { base_url } from '../../helpers/baseURL';
import { useAlert } from 'react-alert';
import { ScrollToTop } from '../../helpers/misc_func';
import Loader from '../../components/checkout/loader';
import Price from '../../helpers/price';
import Loading from "react-js-loader";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const SizeSelect = () => {
    const { state, setRatio, getSingleArt, custom_image, calculateDPI, image_upload, setCropImg, calculatePrice, setPadding } = useContext(ArtContext);
    const [artmode, setartmode] = useState(localStorage.getItem("artmode"))
    const [loader, setloader] = useState(false)
    const scrollref = useRef(0)
    // const [cropmode, setcropmode] = useState(true)
    const history = useHistory();
    console.log('history', history)

    let params = useParams();
    const alert = useAlert()

    console.log(params)
    let query = useQuery();
    console.log('query', query.get("image"))
    const imagetype = query.get("image")

    React.useEffect(() => {
        setPadding(0)
        setRatio("0 x 0")
        window.scrollTo(0, 0)
        if (params.artid) {
            getSingleArt(params.artid, "Canvas")
                .then(res => {
                    var image = new Image();
                    console.log('response of art', res)
                    setCropImg(res.url)
                    localStorage.setItem('frameImage', res.url)
                    //Set the Base64 string return from FileReader as source.
                    image.src = res.url;

                    //Validate the File Height and Width.
                    image.onload = function () {
                        var height = this.naturalHeight;
                        var width = this.naturalWidth;
                        calculateDPI(res.height, res.width, "art", history, 'art')
                    };
                })
                .catch(err => {
                    history.push('/404s')
                })
        }
        else {
            console.log("sizes", query.get("height"), query.get("width"),)
            calculateDPI(query.get("height"), query.get("width"), "crop", history, "upload")
        }
    }, []);

    const [mobilehide, sethide] = useState(true)
    const [paper, setpaper] = useState("")
    const [selected, setselected] = useState(state.ratio.text)
    const [continuestatus, setcontinue] = useState(false)
    const [show, setshow] = useState(false)
    React.useEffect(() => {
        console.log("cropping id", query.get("id"))
        if (!params.artid) {
            calculateDPI(query.get("height"), query.get("width"), "crop", history, "upload", paper)
        }
        else {
            getSingleArt(params.artid, "Canvas")
                .then(res => {
                    var image = new Image();
                    console.log('response of art', res)
                    setCropImg(res.url)
                    localStorage.setItem('frameImage', res.url)
                    //Set the Base64 string return from FileReader as source.
                    image.src = res.url;

                    //Validate the File Height and Width.
                    image.onload = function () {
                        var height = this.naturalHeight;
                        var width = this.naturalWidth;
                        calculateDPI(res.height, res.width, "art", history, 'art', paper)
                    };
                })
                .catch(err => {
                    history.push('/404s')
                })
        }
    }, [paper])
    React.useEffect(() => {
        let sqi = state.ratio.width * state.ratio.height
        let framesqi = (state.ratio.width + 3) * (state.ratio.height + 3)
        localStorage.getItem("artmode") === "frame" ? calculatePrice(framesqi, 'frame') : calculatePrice(sqi)
    }, [paper, state.ratio])

    const setData = () => {
        let sqi = state.ratio.width * state.ratio.height
        let framesqi = (state.ratio.width + 3) * (state.ratio.height + 3)
        localStorage.getItem("artmode") === "frame" ? calculatePrice(framesqi, 'frame') : calculatePrice(sqi)
        console.log(state.printVarData)
        let artData = {
            "framesize": `${(state.ratio.width).toFixed(2)} x ${(state.ratio.height).toFixed(2)}`,
            "papertype": paper
        }
        let og_image_name = state.printVarData ? 'original image' : '';
        if (og_image_name)
            localStorage.setItem('og_image_name', og_image_name)

        let custom_data = localStorage.getItem('upload_image_var') ? JSON.parse(localStorage.getItem('upload_image_var')) : null
        artData.user_upload = localStorage.getItem('user_upload') ? true : false
        artData.field_print_or_frame = localStorage.getItem("artmode")
        artData.art_id = params.artid ? params.artid : null
        artData.art_varid = state.printVarData ? state.printVarData.id : null
        artData.height = (state.ratio.height).toFixed(2)
        artData.width = (state.ratio.width).toFixed(2)
        artData.art_title = state.printVarData ? state.printVarData.attributes.title : "User Custom Image"

        artData.art_title = state.printVarData ? state.printVarData.attributes.title : "User Custom Image"
        artData.art_price = state.price ? state.price : Number(custom_data[0].attributes.price.number)
        artData.total_price = state.price ? state.price : Number(custom_data[0].attributes.price.number)
        console.log(artData)
        // artData.cropped_image = state.cropImg
        // artData.og_image = state.printVarData ? state.upImg : null

        localStorage.setItem("artData", JSON.stringify(artData));
    }

    function get_url_extension(url) {
        return url.split(/[#?]/)[0].split('.').pop().trim();
    }
    const setDataPrint = async () => {
        setData()
        let artData = {
            "framesize": `${(state.ratio.width).toFixed(2)} X ${(state.ratio.height).toFixed(2)}`,
            "papertype": paper
        }
        setloader(true)
        let custom_data = localStorage.getItem('upload_image_var') ? JSON.parse(localStorage.getItem('upload_image_var')) : null
        artData.user_upload = localStorage.getItem('user_upload') ? true : false
        artData.cropped_image = localStorage.getItem('user_upload') ? localStorage.getItem("croppedid") : null
        artData.og_image = localStorage.getItem('user_upload') ? localStorage.getItem("uploadid") : null
        artData.field_print_or_frame = localStorage.getItem("artmode")
        artData.art_id = params.artid ? params.artid : null
        artData.art_varid = state.printVarData ? state.printVarData.id : null
        artData.height = Number(state.ratio.height)
        artData.width = Number(state.ratio.width)
        artData.art_title = state.printVarData ? state.printVarData.attributes.title : "User Custom Image"
        artData.art_price = state.price ? state.price : Number(custom_data[0].attributes.price.number)
        artData.total_price = state.price ? state.price : Number(custom_data[0].attributes.price.number)
        artData.dimension = Number(state.ratio.width).toFixed(2) + `"` + " X " + Number(state.ratio.height).toFixed(2) + `"`
        artData.varid = null
        console.log(artData)
        let cartData = localStorage.getItem("cartData") ? JSON.parse(localStorage.getItem("cartData")) : []
        cartData.push(artData)
        localStorage.setItem("cartData", JSON.stringify(cartData));
        let checkoutdata = JSON.parse(localStorage.getItem("checkout_data"))
        if (checkoutdata) {
            checkoutdata.total_no = checkoutdata.total_no ? checkoutdata.total_no + 1 : 0
            checkoutdata.total_price = checkoutdata.total_price ? checkoutdata.total_price : 0
            checkoutdata.total_price = checkoutdata.total_price + artData.total_price
            localStorage.setItem("checkout_data", JSON.stringify(checkoutdata))
        }

        else {
            let checkoutdata = {
                "total_no": 1,
                "total_price": artData.total_price
            }
            localStorage.setItem("checkout_data", JSON.stringify(checkoutdata))
        }
        setloader(false)
        alert.show("added to cart")

    }
    const [pin, changepin] = useState('')
    const [pinstatus, setpinstatus] = useState(true)


    const checkpin = (code) => {
        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex

        if (code === '' || re.test(code)) {
            changepin(code)
        }
        if (code.length === 6) {
            axios({
                method: 'get',
                url: base_url + '/jsonapi/taxonomy_term/pincode?filter[productFilter][condition][path]=name&filter[productFilter][condition][value]=' + code
            })
                .then(res => {
                    if (res.data.data.length) {
                        setpinstatus(true)
                    }
                    else {
                        setpinstatus(false)
                    }
                })
                .catch(err => {
                    console.log(err.code)
                })
        }
        else {
            setpinstatus(false)
        }
    }

    function getImageDimensions(file) {
        return new Promise(function (resolved, rejected) {
            const img = new Image();

            img.src = file;

            img.onload = function () {
                const imgWidth = img.naturalWidth;
                const imgHeight = img.naturalHeight;
                resolved({ w: imgWidth, h: imgHeight })

            };
        })
    }
    const calculate = (item) => {
        console.log('size selected', item)
        let sqi = state.ratio.width * state.ratio.height
        let framesqi = (state.ratio.width + 3) * (state.ratio.height + 3)
        localStorage.getItem("artmode") === "frame" ? calculatePrice(framesqi, 'frame') : calculatePrice(sqi)
    }


    var limit1, limit2 = 0, limit3, sam1 = [], sam2 = [], sam3 = [], sam4 = [];

    var sam = [].concat(state.printsizes);



    sam.map((item, ind) => {
        if (item) {
            var rat = item.split("x")
            console.log(rat)
            let payload = { width: Math.round(Number(rat[0].replace(' ', ''))), height: Number(rat[1].replace(' ', '')) }
            if (payload.width >= payload.height) {
                if (payload.width < 14) {
                    // limit1 = ind;
                    sam1.push(item)
                }
                else if ((payload.width >= 14) && (payload.width < 24)) {
                    // limit2 = ind;
                    // console.log("awr" + ind + " " + payload.width)
                    sam2.push(item)
                }
                else if ((payload.width >= 24) && (payload.width < 36)) {
                    // limit3 = ind;
                    sam3.push(item)
                }
                else
                    sam4.push(item)
            }
            else {
                if (payload.height < 14) {
                    // limit1 = ind;
                    sam1.push(item)
                }
                else if ((payload.height >= 14) && (payload.height < 24)) {
                    // limit2 = ind;
                    // console.log("awr" + ind + " " + payload.height)
                    sam2.push(item)
                }
                else if ((payload.height >= 24) && (payload.height < 36)) {
                    // limit3 = ind;
                    sam3.push(item)
                }
                else
                    sam4.push(item)
            }
            console.log("ratt2", payload.width)
        }
    })
    sam1 = sam1.reverse();
    sam2 = sam2.reverse();
    sam3 = sam3.reverse();
    sam4 = sam4.reverse();
    // limit3 > 0 ? sam3 = sam.slice(0, limit3) : sam3 = []
    // limit2 > 0 ? sam2 = sam.slice(limit3, limit2) : sam2 = sam.slice(0, limit2)
    // sam1 = sam.slice(limit1)

    return (
        <div id="select_size">
            <Header />
            {loader ? <Loader /> : ''}
            {
                // <div className={"container select_size d-flex header-margin margin-bottom"}>
            }
            <div>

            </div>
            <div className={"conatiner sizeSelect-container"}>
                <div className='header-container mt-1'>
                    <Link className="mb-1 pt-4 selectBack brandon d-flex align-items-center" to="/crop">
                        <img src="/assets/arrow-left.webp" className="selectBackIcon" alt="thumb"></img>
                        <p className='ml-2'>Back</p>
                    </Link>
                </div>
                <div className=' col-11 col-lg-11 col-xl-10 px-0 mx-auto pt-2'>
                    <div className='row mt-2 ml-2 ml-xl-4'>
                    </div>

                    <div className="size-container col-sm-12 pb-5">
                        <h2 className="main-heading GT-bold mt-3 mt-lg-5 mb-sm-5 mb-3 text-center dark-main">
                            Choose a media
                        </h2>
                        <div className="paper-cont d-flex justify-content-center">
                            <a href="#sizeSelect">
                                <div onClick={() => { setpaper("Paper"); localStorage.setItem("papertype", "Paper"); getSingleArt(params.artid, "Paper"); setshow(true); }} className="paper-type brandon w-100 mx-sm-2 mx-0">
                                    <img style={{ border: paper === "Paper" ? '4px solid brown' : '' }} alt="icon" src={matte2} />
                                    <p className='secondarycolor'>Paper</p>
                                </div>
                            </a>
                            <a href="#sizeSelect">
                                <div className="paper-type brandon w-100 order-2 mx-sm-2 mx-0" onClick={() => { setpaper("Canvas"); localStorage.setItem("papertype", "Canvas"); getSingleArt(params.artid, "Canvas"); setshow(true) }}>
                                    <img style={{ border: paper === "Canvas" ? '4px solid brown' : '' }} alt="icon" src="/assets/imagesA/Rectangle 27_1.png" />
                                    <p className='secondarycolor'>Canvas</p>
                                </div>
                            </a>
                        </div>
                        {/* {show ? <p className="sub-title">
                            How large should we print your digital photo? Available sizes are based on your photo’s resolution.
                        </p> : ""} */}
                        {show ? <div className="size_box col-11 col-xl-8 mx-auto px-0 text-center">
                            {show ? <div id="sizeSelect" className="headmedium GT-bold sizeSelect-title mt-3 mb-2 text-center dark-main">
                                Select a size
                            </div> : ""}
                            <p className="size_title brandon">
                                Available Print Sizes [Inches]
                            </p>
                            {/* <p className="size_title brandon mb-4">
                                Note: Listed below are print sizes. Your mat & frame will add to the overall size
                            </p> */}
                            {
                                state.printsizes ?

                                    <div className="row px-0">
                                        {
                                            sam1.length ?
                                                <div className='col-12 px-0 mx-auto'>
                                                    <p className='brandon sizeTitle ml-0 pt-4 pb-2'>Small</p>
                                                    {
                                                        sam1.length ?
                                                            <div className="col-lg-10 justify-content-center  px-0 mx-auto row">

                                                                {
                                                                    sam1.map((item, ind) => {

                                                                        var rat = item.split("x")
                                                                        console.log(rat)

                                                                        let payload = { width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }

                                                                        return (
                                                                            <div onClick={() => { setRatio(item); setcontinue(true); setselected(item); calculate(item) }} className={continuestatus && state.ratio.text === item ? "size-tab brandon secondarycolor  selected" : "size-tab brandon secondarycolor"}>
                                                                                {(payload.width).toFixed(2) + "\tx\t" + (payload.height).toFixed(2)}
                                                                            </div>
                                                                        )

                                                                    })

                                                                }

                                                            </div> : <div className="col-lg-10 px-0 mx-auto">
                                                                <div className='row'>
                                                                    {
                                                                        sam1.map((item, ind) => {

                                                                            var rat = item.split("x")
                                                                            console.log(rat)

                                                                            let payload = { width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }

                                                                            return (
                                                                                <div onClick={() => { setRatio(item); setcontinue(true); setselected(item); calculate(item) }} className={continuestatus && state.ratio.text === item ? "size-tab brandon secondarycolor  selected" : "size-tab brandon secondarycolor"}>
                                                                                    {(payload.width).toFixed(2) + "\tx\t" + (payload.height).toFixed(2)}
                                                                                </div>
                                                                            )

                                                                        })

                                                                    }
                                                                </div>
                                                            </div>
                                                    }
                                                </div>

                                                : ""
                                        }


                                        {
                                            sam2.length ?
                                                <div className='col-12 px-0'>
                                                    {console.log(sam2)}
                                                    <p className='brandon sizeTitle ml-0 pt-4 pb-2'>Medium</p>
                                                    {
                                                        sam2.length === 1 || sam2.length % 3 === 1 || sam2.length ?
                                                            <div className="col-lg-10 justify-content-center px-0 mx-auto row">
                                                                {
                                                                    sam2.map((item, ind) => {

                                                                        var rat = item.split("x")
                                                                        console.log(rat)

                                                                        let payload = { width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }

                                                                        return (
                                                                            <div onClick={() => { setRatio(item); setcontinue(true); setselected(item); calculate(item) }} className={continuestatus && state.ratio.text === item ? "size-tab brandon secondarycolor selected" : "size-tab brandon secondarycolor"}>
                                                                                {(payload.width).toFixed(2) + "\tx\t" + (payload.height).toFixed(2)}
                                                                            </div>
                                                                        )
                                                                    })

                                                                }
                                                            </div> : <div className="col-lg-10 px-0 mx-auto row">
                                                                {
                                                                    sam2.map((item, ind) => {

                                                                        var rat = item.split("x")
                                                                        console.log(rat)

                                                                        let payload = { width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }

                                                                        return (
                                                                            <div onClick={() => { setRatio(item); setcontinue(true); setselected(item); calculate(item) }} className={continuestatus && state.ratio.text === item ? "size-tab brandon secondarycolor selected" : "size-tab brandon secondarycolor"}>
                                                                                {(payload.width).toFixed(2) + "\tx\t" + (payload.height).toFixed(2)}
                                                                            </div>
                                                                        )
                                                                    })

                                                                }
                                                            </div>
                                                    }
                                                </div>
                                                : ""}

                                        {
                                            sam3.length ?
                                                <div className='col-12 px-0'>
                                                    <p className='brandon sizeTitle ml-0 pt-4 pb-2'>Large</p>
                                                    {
                                                        sam3.length == 1 || sam3.length % 3 == 1 || sam3.length ?
                                                            (<div className="col-lg-10 justify-content-center px-0 mx-auto row">
                                                                {
                                                                    sam3.map((item, ind) => {

                                                                        var rat = item.split("x")
                                                                        console.log("availr" + limit2)
                                                                        console.log("avail" + sam)
                                                                        console.log("avail1" + sam2)

                                                                        let payload = { width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }

                                                                        return (
                                                                            <div onClick={() => { setRatio(item); setcontinue(true); setselected(item); calculate(item) }} className={continuestatus && state.ratio.text === item ? "size-tab brandon secondarycolor selected" : "size-tab brandon secondarycolor"}>
                                                                                {(payload.width).toFixed(2) + "\tx\t" + (payload.height).toFixed(2)}
                                                                            </div>
                                                                        )
                                                                    })

                                                                }
                                                            </div>)
                                                            :
                                                            (<div className="col-lg-10 px-0 mx-auto row">
                                                                {
                                                                    sam3.map((item, ind) => {

                                                                        var rat = item.split("x")

                                                                        console.log("large" + sam3)

                                                                        let payload = { width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }

                                                                        return (
                                                                            <div onClick={() => { setRatio(item); setcontinue(true); setselected(item); calculate(item) }} className={continuestatus && state.ratio.text === item ? "size-tab brandon secondarycolor selected" : "size-tab brandon secondarycolor"}>
                                                                                {(payload.width).toFixed(2) + "\tx\t" + (payload.height).toFixed(2)}
                                                                            </div>
                                                                        )
                                                                    })

                                                                }
                                                            </div>)
                                                    }
                                                </div>
                                                : ""

                                        }
                                        {
                                            sam4.length ?
                                                <div className='col-12 px-0 mx-auto'>
                                                    <p className='brandon sizeTitle ml-0 pt-4 pb-2'>Extra Large</p>
                                                    {
                                                        sam4.length === 1 || sam4.length % 3 === 1 || sam4.length ?
                                                            <div className="col-lg-10 justify-content-center  px-0 mx-auto row">

                                                                {
                                                                    sam4.map((item, ind) => {

                                                                        var rat = item.split("x")
                                                                        console.log(rat)

                                                                        let payload = { width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }

                                                                        return (
                                                                            <div onClick={() => { setRatio(item); setcontinue(true); setselected(item); calculate(item) }} className={continuestatus && state.ratio.text === item ? "size-tab brandon secondarycolor  selected" : "size-tab brandon secondarycolor"}>
                                                                                {(payload.width).toFixed(2) + "\tx\t" + (payload.height).toFixed(2)}
                                                                            </div>
                                                                        )

                                                                    })

                                                                }

                                                            </div> : <div className="col-lg-10 px-0 mx-auto">
                                                                <div className='row'>
                                                                    {
                                                                        sam4.map((item, ind) => {

                                                                            var rat = item.split("x")
                                                                            console.log(rat)

                                                                            let payload = { width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }

                                                                            return (
                                                                                <div onClick={() => { setRatio(item); setcontinue(true); setselected(item); calculate(item) }} className={continuestatus && state.ratio.text === item ? "size-tab brandon secondarycolor  selected" : "size-tab brandon secondarycolor"}>
                                                                                    {(payload.width).toFixed(2) + "\tx\t" + (payload.height).toFixed(2)}
                                                                                </div>
                                                                            )

                                                                        })

                                                                    }
                                                                </div>
                                                            </div>
                                                    }
                                                </div>

                                                : ""
                                        }


                                    </div>

                                    : <Loading type="spinner-default" bgColor={"#808080"} title={"spinner-circle"} size={60} />}


                        </div> : ""}
                        {show ? <div className="price-cont d-flex justify-content-around">
                            {/* <h2 className="headmedium text-center secondarycolor">
             &#8377; 3500
         </h2> */}
                            {artmode !== "frame" ?
                                <div className="delivery-box">
                                    <div className="input-pin">
                                        <p className="select-title">Select Location For Delivery</p>
                                        <input value={pin} onChange={(e) => checkpin(e.target.value)} placeholder="Enter Pincode For Delivery Availability" />
                                    </div>
                                    {
                                        pin.length ? pin.length === 6 ?
                                            <p className={pinstatus && pin.length === 6 ? "green" : "red"}>{pinstatus && pin.length === 6 ? "Delivery in 7-10 days" : "Delivery Unavailable"}</p>
                                            : '' : ''
                                    }
                                </div>
                                : ''}

                            {artmode === "frame" ?
                                <Link onClick={() => continuestatus ? setData() + localStorage.setItem("back", `/size?width=${query.get("width")}&height=${query.get("height")}&id=${query.get("id")}`) : alert.show("Please choose a size")} className="grey-button brandon" to={continuestatus ? `/frame/${query.get("id")}?type=upload` : `/size?width=${query.get("width")}&height=${query.get("height")}&id=${query.get("id")}`}>
                                    {"CONTINUE"}
                                </Link>
                                :
                                <div className="price_cart d-flex">
                                    {continuestatus ? <p className="frame-price" style={{}}>{state.price ? <span>₹ <Price value={state.price} /></span> : ''}</p> : ''}
                                    <div onClick={() => continuestatus ? setDataPrint() : alert.show("Please choose a size")} className="grey-button brandon" >
                                        {"ADD TO CART"}
                                    </div>
                                </div>
                            }
                            {/* <Link to='/frame' className="browntext">Preview in a sample room</Link> */}
                        </div> : ""}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default SizeSelect;
