



import HTMLReactParser from 'html-react-parser';
import React, { useContext, useEffect, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Popup from 'reactjs-popup';
import { Context as ArtContext } from '../../context/ArtContext';


const CartFramePreview = (props) => {
    useEffect(() => {
        console.log('value', props.value)
        console.log("cartpreview", props.props)
        console.log(localStorage.getItem('ratio'))
        if (props.value) {
            setwidth(props.value)
            setheight(props.value * (1 / localStorage.getItem('ratio')))
            console.log("height in props.value", props.value * (1 / localStorage.getItem('ratio')))
        }
        if (props.props) {
            if (props.props.artid) {
                setPicture(props.props.artid)
            }
            if (props.props.ratio) {
                setheight(props.value * (1 / Number(props.props.ratio)))
                console.log("ratio in props.props", props.value, props.value * (1 / props.props.ratio))
            }
            if (props.props.frameid) {
                setFrame(props.props.frameid.front)
                setSideFrame(props.props.frameid.side)
            }
            if (props.props.padding) {
                setpadding((props.value / props.props.width) * props.props.padding)
                console.log("padding", (props.value / props.props.width) * props.props.padding)
            }
            if (props.props.depth) {
                console.log("padding value", Number(props.props.depth), (props.value / state.ratio.width) * props.props.padding)
                if (localStorage.getItem("papertype") == "Canvas") {
                    setpadding((props.value / Number(props.props.depth)) * 0.26)
                }
                else {
                    if (props.props.padding || props.props.padding == 0)
                        setpadding((props.value / Number(props.props.depth)) * props.props.padding)
                }
            }
        }
        if (props.framing) {
            if (props.framing.depth) {
                console.log("padding value", Number(props.framing.depth), (props.value / state.ratio.width) * state.padding)
                if (props.props) {
                    props.props.padding || props.props.padding == 0 ?
                        setpadding((props.value / Number(props.framing.depth)) * props.props.padding)
                        : setpadding((props.value / Number(props.framing.depth)) * state.padding)
                }
            }
            if (props.framing.frame) {
                console.log("framewidth", props.framing.frame, (props.value / Number(props.framing.depth)) * props.framing.frame)
                setSidewidth(`${(props.value / Number(props.framing.depth)) * props.framing.frame}px`)
                setframewidth((props.value / Number(props.framing.depth)) * (props.framing.frame / 2))
            }
        }
    }, [props])
    const { state, zoomOpen } = useContext(ArtContext);
    const [width, setwidth] = useState(200);
    const [padding, setpadding] = useState("");
    const [height, setheight] = useState();
    const [sidewidth, setSidewidth] = useState("28.6667px");
    // const [sideheight, setSideheight] = useState("387px");
    const [sideframe, setSideFrame] = useState(state.sideFrame);
    const [frame, setFrame] = useState(state.frontFrame);
    const [framewidth, setframewidth] = useState(21.5);
    const [picture, setPicture] = useState('');
    return (
        <div className="frame-edit-preview-edit">
            <div className="App">
                <div id="frame-container">
                    <div id="frame-3d" className="">
                        <div className="three_d_box_container three_d_box_container_10_1 panels-backface-invisible"
                            style={{ width: width + (2 * padding) + (2 * framewidth), height: height + (2 * padding) + (2 * framewidth), transition: `transform 1s ease 0s`, opacity: 1 }}
                        >
                            <figure className="three_dimensional_frame three_dimensional_frame_10_1 front"
                                style={{ borderImageSlice: 85, borderStyle: "solid", borderWidth: `${framewidth}px`, borderImageSource: `url(${frame})`, borderImageWidth: `${framewidth}px`, borderImageRepeat: 'stretch', width: width + (2 * padding), height: height + (2 * padding), background: "transparent", boxShadow: "rgba(0, 0, 0, 0.7) 0px 2px 5px inset", transform: 'translateZ(0px)' }}
                            >
                                {/* <div className="overlay_gradient overlay_gradient_10_1"
                                    style={{ opacity: 1, width: width, height: height }} >
                                </div> */}
                            </figure>
                            {/* <figure className="float_mount three_d_face">
                                <div className="back_float_mount_shadows">
                                </div>
                            </figure> */}
                            <figure className="front_mount three_d_face"
                                style={{ background: `url(${picture}) center ${padding}px / ${width}px no-repeat ${props.props.padding == 0 ? "#333333" : state.padColor}`, width: width + (2 * padding), height: height + (2 * padding), top: framewidth, left: framewidth, boxShadow: `${props.props.padding == 0 ? "#333333" : state.padColor} 0px 0px 0px inset, ${props.props.padding == 0  ? "#333333" : state.padColor} 0px 0px 0px ${padding}px inset` }}></figure>
                            {/* <figure className="right three_d_face"
                                style={{ background: `url(${sideframe}) center 48% / 60.2px no-repeat rgb(51, 51, 51)`, width: sidewidth, height: height + (2*framewidth), top: 0, left: 2 * padding + width + 12.33 }}>
                            </figure> */}
                            {/* <figure className="left three_d_face">
                            </figure> */}
                            {/* <figure className="back three_d_face"
                                style={{ borderImageSlice: 85, borderStyle: "solid", borderWidth: framewidth, borderImageSource: `url(${frame})`, borderImageWidth: framewidth, borderImageRepeat: 'stretch', width: (2 * padding) + width - 2, height: height + (2 * padding), background: `rgb(51, 51, 51)`, boxShadow: "rgba(0, 0, 0, 0.26) 0px 1px 20px 1px inset", transform: 'rotateY(0deg) translateZ(-28.6667px) translateY(0px) translateX(0px)' }}>
                                <div className="back_frame_shadows"
                                    style={{ opacity: 1, top: -framewidth, left: -framewidth, width: width + (2 * padding), height: height + (2 * padding) }}
                                >    
                                </div>
                            </figure> */}
                            {/* <figure className="top three_d_face"></figure>
                            <figure className="bottom three_d_face"></figure>
                            <figure className="top_inner three_d_face"></figure> */}
                            {/* <figure className="right_inner three_d_face"
                                style={{ background: `url(${sideframe}) round`, width: sidewidth, height: (2 * padding) + height + (framewidth*2), top: 0, left: (width + (2 * padding) + (framewidth/2)) }}>
                            </figure> */}
                            {/* <figure className="bottom_inner three_d_face"></figure>
                            <figure className="left_inner three_d_face"></figure>
                            <div className="front_frame_shadows"></div> */}
                        </div>
                    </div>
                    <div className="details brandon">
                        {state.framedata ? state.framedata.attributes ? state.framedata.attributes.body ? HTMLReactParser(state.framedata.attributes.body.value) : "" : '' : ""}
                    </div>
                    <div className="zoom-button-container">
                        <img src="/assets/zoom.png" className="zoom-button" onClick={() => zoomOpen(true)} />
                    </div>
                    {/* <div id="controls" className="col-sm-6">
                        <h4>Frames</h4>
                        <div className="frame-select">
                            {frameArray.map((item, index) => {
                                return <div className="select-thumbs" onClick={() => { setFrame(item.frame); setSideFrame(item.sideframe) }}>
                                    <img alt="icon" src={item.thumbnail} />
                                </div>
                            })}
                        </div>
                        <h4>Pictures</h4>
                        <div className="frame-select">
                            {picArray.map((item, index) => {
                                return <div className="select-thumbs" onClick={() => { setPicture(item) }}>
                                    <img alt="icon" src={item} />
                                </div>
                            })}
                        </div>

                        <h4>padding</h4>
                        <div className="size-input">
                            <input type="number" onChange={(e) => setpadding(Number(e.target.value))} value={padding} />
                        </div>
                        <h4>Pad color</h4>
                        <div className="frame-select">
                            {padColorArray.map((item, index) => {
                                return <div className="select-thumbs" style={{ backgroundColor: item, border: padColor === item ? "2px solid blue" : "1px solid grey" }} onClick={() => { setPadColor(item) }}>
                                </div>
                            })}
                        </div>
                        <h4>Dimensions</h4>
                        <div className="size-input">
                            <span>height</span>
                            <input type="number" onChange={(e) => setheight(Number(e.target.value))} value={height} />
                            <span>width</span>
                            <input type="number" onChange={(e) => setwidth(Number(e.target.value))} value={width} />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default CartFramePreview;