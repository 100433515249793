import React, { useEffect } from "react";
import Share from "../share/share";


const Tools = ({ title, listed, addWish, deleteWish, openZoom, image }) => {
    useEffect(() => {
        // Ensure Pinterest SDK is loaded and buttons are initialized
        const script = document.createElement('script');
        script.src = 'https://assets.pinterest.com/js/pinit.js';
        script.async = true;
        script.defer = true;
        script.onload = () => {
            if (window.PinUtils) {
                window.PinUtils.build();
            }
        };
        document.body.appendChild(script);
    }, []);
    return (
        <div className="d-flex w-100 justify-content-center align-items-center tools">
            {listed ? <img className="cursor listed-heart tool-icon" src='/assets/gray-heart.png' onClick={() => deleteWish(listed?.id)} /> : <img className="cursor tool-icon" src="/assets/heart.png" onClick={() => addWish()} />}
            <Share className="mb-0" imageClassName="tool-icon mb-0" title={title} />
            <a href={`https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(window.location.href)}&method=button&description=${title}&media=${encodeURIComponent(image)}`}
                // data-pin-do="buttonPin"
                data-pin-do="embedPin"
                data-pin-custom="true"
                target="_blank"
            >
                <img src="/assets/push-pin.png" className="tool-icon cursor" />
            </a>
            <img src="/assets/zoom.png" className="zoom-button cursor tool-icon" onClick={() => openZoom(true)} />

        </div>
    )
}
export default Tools;