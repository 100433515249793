import React, { useContext, useEffect, useState } from "react"
import axios from "axios"
import { base_url } from "../../helpers/baseURL"
import Price from '../../helpers/price';
import { useAlert } from 'react-alert';
import dotmenu from '../../assets/3dot.png'
import FrameEditPreview from "../../components/frame/frameEditPreview"
import CartFramePreview from "../../components/frame/cartFramePreview";
import { Context as ArtContext } from "../../context/ArtContext";
const CartData = (props, { size }) => {
    const { state, cartTrig } = useContext(ArtContext);
    const alert = useAlert()
    let art_price = props.data.art_price ? props.data.art_price : 0
    let frame_price = props.data.price ? props.data.price : 0
    const [image, setimage] = useState("")
    const [frames, setframes] = useState("")
    const [frameName, setFrameName] = useState("")
    const [loader, setloader] = useState(false)
    const [value, setValue] = useState(0)
    const [layout, setLayout] = useState()
    const [cart_data, set_cart_data] = useState('')
    const [remove, setRemove] = useState(null)
    const [qty, setQty] = useState(1)
    const [price, setPrice] = useState(null)


    const removeItem = async (ind) => {
        let cartData = JSON.parse(localStorage.getItem("cartData"))
        let checkoutdata = JSON.parse(localStorage.getItem("checkout_data"))
        set_cart_data([])
        if (cartData) {
            let deleted = cartData.splice(ind, 1)
            checkoutdata.total_no = checkoutdata.total_no ? deleted[0].quantity ? checkoutdata.total_no - Number(deleted[0].quantity) : checkoutdata.total_no - 1 : 0
            checkoutdata.total_price = checkoutdata.total_price ? checkoutdata.total_price : 0
            checkoutdata.total_price = checkoutdata.total_price ? deleted[0].quantity ? checkoutdata.total_price - Number(deleted[0].quantity) * deleted[0].total_price : checkoutdata.total_price - deleted[0].total_price : 0
            localStorage.setItem("checkout_data", JSON.stringify(checkoutdata))
            localStorage.setItem("cartData", JSON.stringify(cartData))
            console.log("cartdata new", cartData, "deleted", deleted)
            setRemove(null)
            cartTrig(state.cartTrigger + 1)
            set_cart_data(cartData)
            window.location.reload()
        }
    }
    // console.log("checkout cart", props)
    const changeCartPrice = (price) => {
        let cart = localStorage.getItem("cartData") ? JSON.parse(localStorage.getItem("cartData")) : null,
            checkout_data = localStorage.getItem("checkout_data") ? JSON.parse(localStorage.getItem("checkout_data")) : null
        if (cart) {
            console.log("price to change", price)
            cart[props.remove - 1].total_price = price
            localStorage.setItem("cartData", JSON.stringify(cart))
            let final_price = cart.reduce((a, c) => a + (c.quantity ? Number(c.quantity) * c.total_price : c.total_price), 0)
            checkout_data.total_price = final_price
            localStorage.setItem("checkout_data", JSON.stringify(checkout_data))
            console.log("final price", final_price)
            cartTrig(state.cartTrigger + 1)
        }
    }
    const changeQty = (type, id) => {
        console.log("type and id is", type, " - ", id)
        let cartData = JSON.parse(localStorage.getItem("cartData"))
        let checkoutdata = JSON.parse(localStorage.getItem("checkout_data"))
        if (cartData) {
            setloader(true)
            let data = cartData[id - 1]
            if (type === "increase") {
                if (checkoutdata.total_no >= 30) {
                    alert.show("Maximum Cart limit reached")
                    setloader(false)
                }
                else
                    checkStock(data)
                        .then(resp => {
                            if (resp === "out of stock") {
                                alert.removeAll()
                                setloader(false)
                                alert.show(`${data.title} ,Color: ${data.object_color} is out of stock`)
                            }
                            else {
                                if (checkoutdata.total_no == 29)
                                    alert.show("Maximum Cart Limit is 30")
                                data.quantity = data.quantity ? Number(data.quantity) + 1 : 2
                                checkoutdata.total_price = checkoutdata.total_price + data.total_price
                                checkoutdata.total_no = checkoutdata.total_no + 1
                                cartData[id - 1] = data
                                localStorage.setItem("cartData", JSON.stringify(cartData))
                                localStorage.setItem("checkout_data", JSON.stringify(checkoutdata))
                                cartTrig(state.cartTrigger + 1)
                                setloader(false)
                            }
                        })
                        .catch(err => {
                            console.log("error in stock check in cart increase:", err)
                            alert.removeAll()
                            setloader(false)
                            alert.show(`${data.title} ,Color: ${data.object_color} is out of stock`)
                        })
            }
            else {
                if (data.quantity == null || Number(data.quantity) === 1) {
                    setloader(false)
                }
                else {
                    data.quantity = data.quantity ? Number(data.quantity) - 1 : 1
                    checkoutdata.total_price = checkoutdata.total_price - data.total_price
                    checkoutdata.total_no = checkoutdata.total_no - 1
                    cartData[id - 1] = data
                    localStorage.setItem("cartData", JSON.stringify(cartData))
                    localStorage.setItem("checkout_data", JSON.stringify(checkoutdata))
                    cartTrig(state.cartTrigger + 1)
                    setloader(false)
                }
            }
        }
    }
    const checkStock = (prod) => new Promise((resolve, reject) => {
        if (prod.object_varid)
            axios.get(`${base_url}/jsonapi/commerce_product_variation/object/${prod.object_varid}`)
                .then(res => {
                    let details = res.data.data?.attributes
                    if (details.field_out_of_stock) {
                        alert.show(`${prod.title} ,Color: ${prod.object_color} is out of stock`)
                        resolve('out of stock')
                    }
                    else {
                        console.log('in stock')
                        resolve("in stock")
                    }

                })
                .catch(err => {
                    resolve("")
                })
        else
            resolve("")
    })
    useEffect(() => {
        if (props.data.object_id) {
            axios.get(base_url + `/jsonapi/commerce_product_variation/object/${props.data.object_varid}?include=field_image.thumbnail`)
                .then(
                    res => {
                        console.log(res.data, "getting gallery var")
                        setimage(res.data.included.filter(item => item.type === "file--file")[0].attributes.image_style_uri.find(obj => obj.listing).listing)
                        setLayout(res.data.data.attributes.field_size)
                        setFrameName(res.data.data.attributes.field_frame)
                        let price = res.data.data?.attributes.price.number ? Number(res.data.data?.attributes.price.number) : 0
                        if (Number(props.data.total_price) != price) {
                            setPrice(price)
                            changeCartPrice(price)
                        }
                    })
                .catch(err => {

                })
        }
        if (props.data.mixed_media_id) {
            axios.get(base_url + `/jsonapi/commerce_product_variation/mixed_media/${props.data.mixed_media_varid}?include=field_image.thumbnail`)
                .then(
                    res => {
                        console.log(res.data, "getting mixed media var")
                        setimage(res.data.included.filter(item => item.type === "file--file")[0].attributes.image_style_uri.find(obj => obj.listing).listing)
                        setLayout(res.data.data.attributes.field_size)
                        setFrameName(props.data.mixed_media_color)
                        let price = res.data.data?.attributes.price.number ? Number(res.data.data?.attributes.price.number) : 0
                        if (Number(props.data.total_price) != price) {
                            setPrice(price)
                            changeCartPrice(price)
                        }
                    })
                .catch(err => {
                    console.log("error on mixed Media", err)
                })
        }
        if (props.data.gallery_id) {
            axios.get(base_url + `/jsonapi/commerce_product/gallery_wall/${props.data.gallery_id}?include=field_gallery_image,field_gallery_thumbnail,default_variation`)
                .then(
                    res => {
                        console.log(res.data, "getting gallery var")
                        let variation = res.data.included.find(x => x.type === "commerce_product_variation--gallery_wall")
                        let price = variation?.attributes.price.number ? Number(variation?.attributes.price.number) : 0
                        if (Number(props.data.total_price) != price) {
                            setPrice(price)
                            changeCartPrice(price)
                        }
                        setimage(res.data.included.filter(item => item.type === "file--file")[0].attributes.image_style_uri.find(obj => obj.listing).listing)
                        setLayout(res.data.data.attributes.field_layout_size)
                        setFrameName(res.data.data.attributes.field_frame)
                    })
                .catch(err => {
                    console.log("error on gallery", err)
                })
        }
        if (props.data.gifting_id) {
            axios.get(base_url + `/jsonapi/commerce_product/gifting/${props.data.gifting_id}?include=field_gifting_image,default_variation`)
                .then(
                    res => {
                        console.log(res.data, "getting gifting var")
                        setimage(res.data.included.filter(item => item.type === "file--file")[0].attributes.image_style_uri.find(obj => obj.listing).listing)
                        setLayout(res.data.data.attributes.field_layout_size)
                        setFrameName(res.data.data.attributes.field_frame)
                        let variation = res.data.included.find(x => x.type === "commerce_product_variation--gifting")
                        let price = variation?.attributes.price.number ? Number(variation?.attributes.price.number) : 0
                        if (Number(props.data.total_price) != price) {
                            setPrice(price)
                            changeCartPrice(price)
                        }
                    })
                .catch(err => {

                })
        }
        if (!props.data.user_upload) {
            axios.get(base_url + `/jsonapi/commerce_product/default/${props.data.art_id}?include=field_image.field_media_image`)
                .then(
                    res => {
                        console.log("cart art", base_url + res.data.included.filter(item => item.type === "file--file")[0].attributes.image_style_uri.find(obj => obj.listing).listing)
                        setimage(res.data.included.filter(item => item.type === "file--file")[0].attributes.image_style_uri.find(obj => obj.listing).listing)
                    })
                .catch(err => {

                })
        }
        else {
            console.log("cropped image", props.data.cropped_image)
            axios.get(base_url + `/jsonapi/file/file/${props.data.cropped_image}`)
                .then(
                    res => {
                        console.log("cropped get image", base_url + res.data.data.attributes.uri.url)
                        setimage(base_url + res.data.data.attributes.uri.url)
                    })
                .catch(err => {

                })
        }
        if (props.data.varid) {
            let divs = props.size ? props.size : (document.body.scrollWidth * 0.27) * 0.27
            if ((props.data.width / props.data.height) < 0.40)
                props.data.papertype === "Canvas" ? setValue(divs * 0.40) : setValue(divs * 0.36)
            else if ((props.data.width / props.data.height) >= 0.40 && (props.data.width / props.data.height) < 0.52)
                props.data.papertype === "Canvas" ? setValue(divs * 0.40) : setValue(divs * 0.36)
            else if ((props.data.width / props.data.height) >= 0.52 && (props.data.width / props.data.height) < 0.72)
                props.data.papertype === "Canvas" ? setValue(divs * 0.56) : setValue(divs * 0.48)
            else if ((props.data.width / props.data.height) >= 0.72 && (props.data.width / props.data.height) < 1)
                props.data.papertype === "Canvas" ? setValue(divs * 0.75) : setValue(divs * 0.70)
            else if ((props.data.width / props.data.height) >= 1 && (props.data.width / props.data.height) < 1.4)
                props.data.papertype === "Canvas" ? setValue(divs) : setValue(divs * 0.82)
            else if ((props.data.width / props.data.height) >= 1.4)
                props.data.papertype === "Canvas" ? setValue(divs) : setValue(divs)
            axios.get(base_url + `/jsonapi/commerce_product_variation/frame/${props.data.varid}?include=product_id,product_id.field_front,product_id.field_side`)
                .then(
                    res => {
                        let frontframearray = res.data.included.filter(item => item.id === res.data.included[0].relationships.field_front.data.id)
                        let sideframearray = res.data.included.filter(item => item.id === res.data.included[0].relationships.field_side.data.id)
                        setframes({ "front": base_url + frontframearray[0].attributes.uri.url, "side": base_url + sideframearray[0].attributes.uri.url })
                        console.log("framess", base_url + frontframearray[0].attributes.uri.url, base_url + sideframearray[0].attributes.uri.url)
                    })
                .catch(err => {

                })

        }
    }, [])

    let details = { "ratio": props.data.ratio ? props.data.ratio : (props.data.width / props.data.height), "artid": image, "width": props.data.width, "padding": props.data.matting, "frameid": frames, "upload": props.data.user_upload, "cropped": props.data.cropped_image }


    return (
        <div id="cart-item" className="cart-main">
            <div className={`cart-items-container position-relative ${loader ? "blur" : ""}`}>
                {loader ? <div className="blur-box" /> : ""}
                <div className={`cart-item `}>
                    <div className="checkout-cart-item-img">
                        {image ? props.data.field_print_or_frame == "frame" ?
                            <CartFramePreview value={value} props={details} framing={{ "depth": 32, "frame": 3 }} /> :
                            <img src={image} className="image" /> :
                            ""}
                        {/* <img style={{ height: 'auto' }} src={cartitem} /> */}
                    </div>
                    <div className="cart-item-details cart-item-detail-overall d-flex flex-column">
                        <div className="cart-item-titleprice flex-column">
                            <div>
                                {!props.data.gallery_id && !props.data.gifting_id && !props.data.object_id && !props.data.mixed_media_id ? <p className="cart-item-title GT-bold">
                                    {props.data.user_upload ? `Custom framing on ${props.data.papertype}` : ""}
                                    {!props.data.user_upload ? props.data.field_print_or_frame == "frame" ? `Framed Print on ${props.data.papertype}` : `Print on ${props.data.papertype}` : ""}
                                </p> :
                                    <div>
                                        <p className="cart-item-title GT-bold">
                                            {props.data.gifting_id ? props.data.title !== "Custom Frame" ? `Custom Framed Gifts` : `Thoughtful Gifts` : ""}
                                            {props.data.gallery_id ? `Gallery Walls` : ""}
                                            {props.data.object_id ? `Object` : ""}
                                            {props.data.mixed_media_id ? `Mixed Media` : ""}
                                        </p>
                                        <p className="cart-item-description brandon">{props.data.title !== "Custom Frame" ? props.data.title : ""}</p>
                                        {props.data.gallery_id || props.data.gifting_id ? <p className="cart-item-description brandon">{frameName ? `Framed on ${frameName}` : ""}</p>
                                            : ""}
                                        <p className="cart-item-description brandon">{props.data.object_id ? props.data.object_color : props.data.mixed_media_color ? props.data.mixed_media_color : ""}</p>
                                    </div>
                                }
                                {!props.data.user_upload ? props.data.art_title ?
                                    <p className="cart-item-description brandon mt-1">{props.data.art_title}</p>
                                    : "" : ""}
                                {props.data.field_print_or_frame == "frame" ?
                                    <p className="cart-item-description brandon mt-1">{`Framed on ${props.data.title}`}</p>
                                    : ""}

                                {props.data.gallery_id || props.data.gifting_id ? <div className="cart-item-style">
                                    <p className="cart-item-description brandon mb-auto">{layout ? `${layout}` : ""}</p>
                                </div> : ""}
                                {props.data.gallery_id || props.data.gifting_id ? "" :
                                    <div className="cart-item-style">
                                        {props.data.field_print_or_frame == "frame" ? <p className="cart-item-description mt-1 brandon">
                                            {props.data.framesize ? props.data.framesize.replace(/(\d+\.\d+)\s*X\s*(\d+\.\d+)/g, '$1" X $2"') : ""}
                                        </p> :
                                            <p className="cart-item-description mt-1 brandon">
                                                {props.data.dimension ? props.data.dimension : ""}
                                            </p>
                                        }
                                    </div>
                                }
                                <p className="cart-item-description mt-1 brandon">
                                    &#8377; {<Price value={price ? price : props.data.total_price} />}
                                </p>
                            </div>
                            <div className="mt-auto">
                                {props.remove ? <div className="quantity-type-container d-flex">
                                    <p className="cart-item-description mt-1 brandon">Qty</p>
                                    <div className="quantity-container brandon mt-1 d-flex">
                                        <p className={`reduce ${props.data.quantity === null || Number(props.data.quantity) === 1 ? "disabled" : ""}`} onClick={(e) => { if (loader) e.preventDefault(); else changeQty("reduce", props.remove) }}  >-</p><p className="qty brandon">{props.data.quantity ? props.data.quantity : 1}</p><p className="increase" onClick={(e) => { if (loader) e.preventDefault(); else changeQty("increase", props.remove) }}>+</p>
                                    </div>
                                    {/* <input type="number" clas value={qty} id="quantity" name="quantity" min={1} /> */}
                                </div>
                                    :
                                    <div className="d-flex justify-content-start">
                                        <p className="cart-item-description mt-1 brandon">Qty:</p>
                                        <p className="cart-item-description mt-1 brandon pl-2">
                                            {props.data.quantity ? props.data.quantity : 1}
                                        </p>
                                    </div>}
                            </div>
                            <div>
                                {props.remove ?
                                    <div className="remove-cart brandon cursor-pointer mt-2 justify-content-end" onClick={(e) => { if (loader) e.preventDefault(); else setRemove(props.remove) }} ><u>Remove</u></div>
                                    : ""}
                            </div>
                        </div>
                        {remove === props.remove ? <div className="remove-container">
                            <p className='remove-note brandon mb-sm-3 mb-2'>Are you sure you want to remove this item?</p>
                            <div className="btns px-0">
                                <button onClick={() => setRemove(null)} className="brandon px-0 secondarycolor bgwhite keep-shop">
                                    Cancel
                                </button>
                                <button onClick={() => removeItem((props.remove - 1))} className="brandon p-1 bgsecondary whitecolor cart-remove-button">
                                    Remove
                                </button>
                            </div>
                        </div> : ""}
                    </div>
                </div>
            </div>

        </div>
    )
}
export default CartData;