import React, { useContext, useEffect, useRef, useState } from 'react';
import Footer from '../../components/Footer/footer';
import Header from '../../components/header/header';

import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import matte from '../../assets/Rectangle 26.png'
import matte2 from '../../assets/Rectangle 27.png'
import crop from '../../assets/Rectangle 6.png'
import { Context as ArtContext } from '../../context/ArtContext';
import './size.css'
import Cropper from '../../components/crop/cropper';
import axios from 'axios';
import { base_url } from '../../helpers/baseURL';
import { useAlert } from 'react-alert';
import { ScrollToTop } from '../../helpers/misc_func';
import "./crop.css"
import Loader from '../../components/checkout/loader';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}


const Crop = () => {
    const alert = useAlert()
    const upref = useRef(0)
    const { state, setRatio, getSingleArt, custom_image, calculateDPI, image_upload, setCropImg, setUpImg } = useContext(ArtContext);
    let params = useParams();
    const history = useHistory();
    let query = useQuery();
    console.log('query', query.get("image"))
    const imagetype = query.get("image")
    const [loader, setloader] = useState(false)
    const [allow, setAllow] = useState(false)
    const [closeit, closeiet] = useState(false)

    function getImageDimensions(file) {
        return new Promise(function (resolved, rejected) {
            const img = new Image();

            img.src = file;

            img.onload = function () {
                const imgWidth = img.naturalWidth;
                const imgHeight = img.naturalHeight;
                resolved({ w: imgWidth, h: imgHeight })

            };
        })
    }
    useEffect(() => {
        console.log("allow stat", allow)
    }, [allow])
    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            console.log("image props", e.target.files)
            let og_image_name = e.target.files[0].name,
                size = e.target.files[0].size
            console.log('imgsize', e.target.files[0].size)
            reader.addEventListener('load', () => {
                //Initiate the JavaScript Image object.
                var image = new Image();
                console.log(reader.result)
                //Set the Base64 string return from FileReader as source.
                image.src = reader.result;
                setUpImg(reader.result);
                //Validate the File Height and Width.
                image.onload = function () {
                    console.log('loading')
                    var height = this.naturalHeight;
                    var width = this.naturalWidth;
                    console.log("image",)
                    calculateDPI(height, width, "upload", history)
                        .catch(res => {
                            alert.show(res, {
                                timeout: closeit ? 200 : 0,
                                onOpen: () => {

                                },
                            })
                        })
                };
                localStorage.setItem("og_image_name", og_image_name);
            }
            );
            reader.readAsDataURL(e.target.files[0]);
            setUpImg(reader.result);
        }
    };
    const dataURLtoFiles = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }
    React.useEffect(() => {

        if (state.artData)
            setCropImg(state.artData.img)
        ScrollToTop()

        if (params.artid) {
            getSingleArt(params.artid, "Canvas")
                .then(res => {
                    var image = new Image();

                    //Set the Base64 string return from FileReader as source.
                    image.src = res;

                    //Validate the File Height and Width.
                    image.onload = function () {
                        var height = this.naturalHeight;
                        var width = this.naturalWidth;
                        calculateDPI(height, width, "upload", history, imagetype)

                    };
                })
        }

        if (localStorage.getItem('user_upload')) {
            custom_image()
        }
    }, []);
    const cropdone = async () => {
        // alert(state.cropImg)
        if (allow) {
            var dimensions = await getImageDimensions(state.cropImg)
            console.log("dimensions", dimensions)
            dimensions.h = JSON.parse(localStorage.getItem('scales')).height
            dimensions.w = JSON.parse(localStorage.getItem('scales')).width
            console.log("finalDimension", dimensions)
            setloader(true)
            image_upload(state.cropImg, state.upImg)
                .then(res => {
                    localStorage.setItem("uploadid", res[1])
                    localStorage.setItem("croppedid", res[0])
                    console.log("croppedid", res[0])
                    setloader(false)
                    let id = res[0]
                    calculateDPI(Math.round(dimensions.h), Math.round(dimensions.w), "crop", history, imagetype)
                        .then(res => {
                            ScrollToTop()
                            history.push(`/custom-framing?width=${Math.round(dimensions.w)}&height=${Math.round(dimensions.h)}&id=${id}`)
                            // history.push(`/size?width=${Math.round(dimensions.w)}&height=${Math.round(dimensions.h)}&id=${id}`)
                        })
                        .catch(res => {
                            alert.show(res)
                        })
                })
                .catch(err => {
                    setloader(false)
                    alert.show("Something went wrong on image upload")
                })
        }
    }

    return (
        <div id="select_size">
            <Header />
            {loader ? <Loader /> : ''}
            <div className="col-12 d-flex cropContainer py-5" onClick={() => alert.removeAll()}>
                <div className="cropsec container  mx-auto py-3">
                    <div className={"crop-section col-sm-12 mt-lg-0"}>
                        <h2 className="heading GT-bold cropTitle mb-2 text-center dark-main">
                            Crop Your Photo
                        </h2>
                        <h4 className='brandon cropSub pb-3 text-center secondarycolor'>It’s optional. Once done, tap continue.</h4>
                        <div className="crop-img col-8 col-lg-4 mx-auto">
                            {params.artid ?
                                state.printVarData && state.artData ?
                                    <Cropper img={state.artData.img} />
                                    : ''
                                : <Cropper />
                            }
                        </div>
                    </div>
                    <div className='brandon checkcrop-container mb-3 mb-sm-4 mt-2 mt-sm-3'>
                        <input type='checkbox' className='checkcrop' value={allow} onChange={(e) => setAllow(!allow)} />
                        I confirm to have all necessary permissions for printing the uploaded image.</div>
                    <div className="mb-3 row justify-content-center">
                        <div onClick={() => cropdone()} className="grey-button brandon mt-2" style={{ cursor: allow ? "pointer" : "not-allowed" }} >
                            {"CONTINUE"}
                        </div>
                    </div>
                    <div className='row'>
                        <label className="mb-1 col-lg-2 col-3 cursor cropBack brandon text-center px-0 mx-auto" onClick={() => upref.current.click()}>
                            Change Photo
                        </label>
                        <input id="file-input" style={{ display: "none" }} ref={upref} type="file" accept="image/*" name='Change Photo' onChange={onSelectFile} />

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Crop;