import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Footer from '../../components/Footer/footer';
import Header from '../../components/header/header';
import arrow from '../../assets/Footer Right.png'
import { Context as ArtContext } from '../../context/ArtContext';
import './profile.css'

const MyProfile = () => {
    const { state, fetch_single_article } = useContext(ArtContext);
    const history = useHistory()
    React.useEffect(() => {
    }, []);
    const logout = () => {
        localStorage.removeItem('userdat')
        window.location.href = '/?logged_out=true'
    }
    return (
        <div id="select_size">
            <Header />
            <div className="mobile-profile">
                {state.loginStatus ?
                    <div className='user-popup-container d-block brandon'>
                        <div className='section'>
                            <h6 className='container headmedium mt-5 mb-2 secondarycolor welcome-text GT-bold'>Welcome </h6>
                            <p className='container headmedium mb-3 secondarycolor welcome-text GT-bold'>{localStorage.getItem("userdat") ? JSON.parse(localStorage.getItem("userdat"))?.details?.attributes.display_name : ""}</p>
                        </div>
                        <div className='section'>
                            <Link to='/account/profile/' >
                                <div className='profile-menu-item'>
                                    <p>
                                        My Account
                                    </p>
                                    <img alt="icon" src={arrow} />
                                </div>
                            </Link>
                            <Link to='/account/orders/'>
                                <div className='profile-menu-item'>
                                    <p>
                                        Orders
                                    </p>
                                    <img alt="icon" src={arrow} />
                                </div>
                            </Link>
                            {/* <Link to='/account/wishlist/'>
                                <div className='profile-menu-item'>
                                    <p>
                                        Wishlist
                                    </p>
                                    <img alt="icon" src={arrow} />
                                </div>
                            </Link> */}
                            {/* <Link to='/profile-details/saved_address'>
                                <div className='profile-menu-item'>
                                    <p>
                                        Saved Addresses
                                    </p>
                                    <img alt="icon" src={arrow} />
                                </div>
                            </Link> */}
                        </div>
                        <div className='section'>
                            <Link to="/contact/">
                                <div className='profile-menu-item'>
                                    <p>
                                        Contact Us
                                    </p>
                                    <img alt="icon" src={arrow} />
                                </div>
                            </Link>
                            <div className='profile-menu-item cursor'>
                                <p className='brandon' onClick={() => { logout() }}>
                                    Logout
                                </p>
                            </div>
                        </div>
                    </div> :
                    ""
                }
                {/* <h2 className="container headmedium mt-5 mb-3 secondarycolor">
                    My Profile
                </h2>
                <div className="profile-menu">
                    <Link to="/account/orders/">
                        <div className="profile-menu-item">
                            <p>
                                My Orders
                            </p>
                            <img alt="icon" src={arrow} />
                        </div>
                    </Link>
                    <Link to="/account/profile/">
                        <div className="profile-menu-item">
                            <p>
                                Change Details
                            </p>
                            <img alt="icon" src={arrow} />
                        </div>
                    </Link>
                    <Link to="/">
                        <div className="profile-menu-item" onClick={() => { localStorage.removeItem("userdat") }}>
                            <p>
                                Logout
                            </p>
                        </div>
                    </Link>
                </div> */}
            </div>
        </div>
    );
}

export default MyProfile;
