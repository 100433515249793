import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cartitem from '../../assets/cartitem.png'

const Orderlist = (props) => {
    // const { state, fetch_single_article } = useContext(ArtContext);
    React.useEffect(() => {
    }, []);

    const [selected, setselected] = useState("6 x 4.25")
    console.log('order box', props.ordercontents)
    return (
        <div id="order-list-item" className="order-list">
            <div className="order-list-item">
                <div className="order-list-image">
                    <img alt="icon" src={cartitem} />
                </div>
                <div className="order-list-image-details row mx-0 order-list-image-details-di">
                    <div className="order-list-title col-md-12 col-sm-12 px-0">
                        {props.ordercontents ? props.ordercontents.attributes ? props.ordercontents.attributes.order_number ? "Order # " + props.ordercontents.attributes.order_number : 'NA' : '' : ''}
                    </div>
                    <div className="order-list-title col-md-12 col-sm-12 px-0">
                        {props.ordercontents ? props.ordercontents.attributes ? props.ordercontents.attributes.field_total_products ? "Quantity " + props.ordercontents.attributes.field_total_products : '' : '' : ''}
                    </div>
                    <div className="order-list-title col-md-12 col-sm-12 align-top px-0">
                        {/* {props.ordercontents ? props.ordercontents.attributes ? props.ordercontents.attributes.field_title ? props.ordercontents.attributes.field_title : 'NA' : '' : ''} */}
                        <div className="order-list-price">{props.ordercontents ? props.ordercontents.attributes ? props.ordercontents.attributes.total_price ? "Total: " + props.ordercontents.attributes.total_price.formatted : '' : '' : ''}</div>
                    </div>
                </div>
                <div className="order-list-image-details row mx-0 order-list-image-details-mob">
                    <div className="order-list-title col-md-12 col-sm-12 align-top px-0">
                        {props.ordercontents ? props.ordercontents.attributes ? props.ordercontents.attributes.order_number ? "Order # " + props.ordercontents.attributes.order_number : 'NA' : '' : ''}

                        {/* {props.ordercontents ? props.ordercontents.attributes ? props.ordercontents.attributes.field_title ? props.ordercontents.attributes.field_title : 'NA' : '' : ''} */}
                    </div>
                    <div className="order-list-price col-md-12 col-sm-12 px-0">
                        {props.ordercontents ? props.ordercontents.attributes ? props.ordercontents.attributes.field_total_products ? "Qty " + props.ordercontents.attributes.field_total_products : '' : '' : ''}
                    </div>

                    <div className=" order-list-price col-md-12 col-sm-12 px-0">
                        {props.ordercontents ? props.ordercontents.attributes ? props.ordercontents.attributes.total_price ? props.ordercontents.attributes.total_price.formatted : '' : '' : ''}
                    </div>

                    <div className="order-list-dimensions pt-3 list-mob col-6 col-sm-6 align-bottom px-0"><span className="order-list-image-details-span">Dimensions</span>
                        {props.ordercontents ? props.ordercontents.attributes ? props.ordercontents.attributes.field_dimension ? props.ordercontents.attributes.field_dimension : 'NA' : '' : ''}
                    </div>
                    <div className="order-list-matstyle pt-3 col-6 list-mob col-sm-6 align-bottom px-0"><span className="order-list-image-details-span">Mat Style</span>
                        {props.ordercontents ? props.ordercontents.attributes ? props.ordercontents.attributes.field_matting ? props.ordercontents.attributes.field_matting : 'NA' : '' : ''}
                    </div>
                    <div className="order-list-framesize pt-3 col-md-12 list-mob col-sm-12 align-bottom px-0"><span className="order-list-image-details-span">Frame Size</span>
                        {props.ordercontents ? props.ordercontents.attributes ? props.ordercontents.attributes.field_frame_size ? props.ordercontents.attributes.field_frame_size : 'NA' : '' : ''}
                    </div>
                    <div className="order-list-view col-md-12 col-sm-12 align-bottom px-0">
                        View
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Orderlist;
