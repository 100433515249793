import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import close from '../../assets/close.png'

const AddressListItem = (props) => {
    // const { state, fetch_single_article } = useContext(ArtContext);
    React.useEffect(() => {
    }, []);

    const [selected, setselected] = useState("6 x 4.25")

    return (
        <div id="address-list-item" className="address-list-item">
            <p className="mt-3 small-normal">
                {props.address}
            </p>
            <div className="btns">
                <div className="delete-add-mob">
                    <img alt="icon" src={close} />
                </div>
                <button className="black-btn bgsecondary whitecolor">
                    Change
                </button>
                <button className="bgwhite delete-add-desk secondarycolor">
                    Delete
                </button>
            </div>
        </div>
    );
}

export default AddressListItem;
