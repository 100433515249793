import React, { useEffect, useState } from "react"
import Header from '../../components/header/header'
import Footer from '../../components/Footer/footer'
import cover from '../../assets/eac-banner1.jpg'
import axios from "axios"
import parse from 'html-react-parser';
import { base_url } from "../../helpers/baseURL"
import { MetaTags } from 'react-meta-tags';



const Privacy = () => {
    const [page, setPage] = useState()
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get(base_url + "/jsonapi/node/page/778b53d3-b198-4a11-b6cd-ff669cae870e")
            .then(data => {
                console.log("basic page", data.data.data)
                setPage(data.data.data)
            })
    }, [])
    return (
        <div>
            <Header />
            <MetaTags>
                <title>Privacy Policy | Espravo</title>
                <meta name="description" content="Check out privacy policy of Espravo for it's customers. This Policy sets out how Espravo uses any information that you give to us when you use website. " />
                <meta property="og:title" content="Privacy Policy | Espravo" />
                <meta property="og:description" content={page ? page.attributes.body ? page.attributes.body.value.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 200) : "" : ""} />
                <meta property="og:image" content="https://esp.tridz.in/static/media/aboutbanner.32a13d3b.png" />
                <meta name="twitter:card" content="" />
                <meta name="twitter:site" content="@twitterid" />
                <meta name="twitter:title" content={page ? page.attributes.title ? page.attributes.title : "" : ""} />
                <meta name="twitter:description" content={page ? page.attributes.body ? page.attributes.body.value.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 200) : "" : ""} />
                <meta name="twitter:image" content="https://esp.tridz.in/static/media/aboutbanner.32a13d3b.png" />
                <link rel="canonical" href={`${window.location.origin}/privacy-policy/`} />
            </MetaTags>
            <div id='stores' className="py-sm-5 my-sm-5 container">
                <div className="articledetails">
                    <h1 className="headmedium text-center GT-bold mb-2 secondarycolor">{page ? page.attributes.title ? page.attributes.title : "" : ""}</h1>
                    <div className="blog-img my-sm-5 my-2">
                        <img src="/assets/eac-banner1.webp" alt="blog" />
                    </div>
                    <p style={{ color: "#404040" }} className="blog-detail brandon">
                        {page ? page.attributes.body ? parse(page.attributes.body.value) : "" : ""}
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Privacy;