import React from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/header/header";
import "./partner.css"


const Partner = () => {
    return (
        <div className="partner-container ">
            <Header />

            <div className="container partner-subcontainermain">
                <div className="partner-subcontainer text-center">
                    <div className="partner-title art-love text-center letspace">
                        Partner Program: {'\n'}Architects & Designers
                    </div>
                    <div className="partner-subtitle text-center brandon">
                        Join out partner program for dedicated account support, special pricing & priority shipping.
                    </div>
                    <a href="/partner/apply">
                        <button className="brandon partner-button">APPLY NOW</button>
                    </a>
                </div>
                <div className=" partner-subcontainer1 mx-auto">
                    <div className="partner-title art-love text-center">
                        Member Benefits
                    </div>
                    <div className="d-md-flex justify-content-between mx-auto">
                        <div className="text-center">
                            <img className="partner-image" src="/assets/partner/benefit1.png" />
                            <div className="partner-subtitle1 text-center art-love-details brandon">Dedicated Account Support</div>
                        </div>
                        <div className="text-center">
                            <img className="partner-image" src="/assets/partner/benefit2.png" />
                            <div className="partner-subtitle1 text-center art-love-details brandon">Special Pricing</div>
                        </div>
                        <div className="text-center">
                            <img className="partner-image" src="/assets/partner/benefit3.png" />
                            <div className="partner-subtitle1 text-center art-love-details brandon">Priority Shipping</div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Partner;
