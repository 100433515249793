import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer/footer';
import Header from '../../components/header/header';
import arrow from '../../assets/Footer Right.png'
import { Context as UserContext } from '../../context/UserContext';
import 'react-accessible-accordion/dist/fancy-example.css';
// import './profile.css'
import AddressListItem from '../../components/profile/addressListItem';
import Orderlist from './orderList';
import Loader from "react-js-loader";

const Myorders = () => {

    // const { state, fetch_single_article } = useContext(ArtContext);
    const { state, order_history } = useContext(UserContext);
    React.useEffect(() => {
        let userdata = JSON.parse(localStorage.getItem("userdat"))

        console.log('userid', userdata.details.id)
        if (userdata.details.id)
            order_history(userdata.details.id)
    }, []);

    // const [selected, setselected] = useState("6 x 4.25")
    const [selects, setselect] = useState("set")
    console.log('orders', state.orders)
    const ordercontents = [{
        title: `Mercer Slim`,
        price: `2,000`,
        dimensions: `20"x26.67"`,
        matstyle: `White`,
        Framesize: `20"x26.67"`,
        qty: `1`
    },

    {
        title: `Mercer Slim`,
        price: `2,000`,
        dimensions: `20"x26.67"`,
        matstyle: `White`,
        Framesize: `20"x26.67"`,
        qty: `1`
    },

    {
        title: `Mercer Slim`,
        price: `2,000`,
        dimensions: `20"x26.67"`,
        matstyle: `White`,
        Framesize: `20"x26.67"`,
        qty: `1`
    },

    {
        title: `Mercer Slim`,
        price: `2,000`,
        dimensions: `20"x26.67"`,
        matstyle: `White`,
        Framesize: `20"x26.67"`,
        qty: `1`
    },
    ]

    return (
        <div id="profile-edit" className="profile-edit">
            {/* <p className="mt-3 small-normal pad-0 mx-3 mx-sm-0">
                Check the details and status of your orders in the online store. You can also cancel your order or request a return.
            </p> */}
            <div className="shipping-list-container pad-0 mob my-orders-list">
                {state.orders ? state.orders.data.map((item, ind) => {
                    return (
                        <Link to={`/account/orders/${item.attributes?.order_number}/`}><Orderlist ordercontents={item} /></Link>
                    )
                }) : <Loader type="spinner-default" bgColor={"#808080"} title={"spinner-circle"} size={100} />}
            </div>
        </div>
    );
}

export default Myorders;
