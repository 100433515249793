//devel
// export const base_url="https://esp-be.tridz.in"
export const base_url="https://esp-be.hzdev.tridz.in"

//live (stage)
// export const base_url = "https://stg-esp-be.tridz.in"
//live
// export const base_url = "https://pl-be.espravo.com"
// export const dev_url = "http://espakhiltest.tridz.in"

// migrate
// export const base_url = "https://esp-bemig.tridz.in"
