import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer/footer';
import Header from '../../components/header/header';
import arrow from '../../assets/Footer Right.png'
import 'react-accessible-accordion/dist/fancy-example.css';
import './profile.css'
import AddressListItem from '../../components/profile/addressListItem';

const ShippingList = () => {

    // const { state, fetch_single_article } = useContext(ArtContext);
    React.useEffect(() => {
    }, []);

    const [selected, setselected] = useState("6 x 4.25")
    const address = [`John Doe
    South 23rd East Street
    84109 Salt Lake County
    United States`,
        `John Doe
    South 23rd East Street
    84109 Salt Lake County
    United States`,
        `John Doe
    South 23rd East Street
    84109 Salt Lake County
    United States`,
    `John Doe
South 23rd East Street
84109 Salt Lake County
United States`,
`John Doe
South 23rd East Street
84109 Salt Lake County
United States`,
`John Doe
South 23rd East Street
84109 Salt Lake County
United States`]

    return (
        <div id="profile-edit" className="profile-edit">
            <Header />
            <div onClick={() => {
                this.props.history.goBack();
            }}
                className="container profile-header">
                <img alt="icon" src={arrow} />
                <p className="small-bold">Shipping Details</p>
            </div>
            <p className="container mt-3 small-normal">
                Manage all the shipping addresses you want (work place, home address ...) This way you won't have to enter the shipping address manually with each order.
                </p>
            <div className="shipping-list-container container mob">
                {address.map((item, ind) => {
                    return (
                        <AddressListItem address={item} />
                    )
                })}
            </div>
            <div className="container absolute-btn">
                <button className="black-btn w-100 bgsecondary whitecolor">
                    Add New Adddress
                </button>
            </div>
        </div>
    );
}

export default ShippingList;
