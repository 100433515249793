import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer/footer';
import Header from '../../components/header/header';
import arrow from '../../assets/Footer Right.png'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import './profile.css'
import PersonalData from '../../components/profile/personalData';
import PassWord from '../../components/profile/password';
import EditAddress from '../../components/profile/editAddress';

const AddressEdit = () => {

    // const { state, fetch_single_article } = useContext(ArtContext);
    React.useEffect(() => {
    }, []);

    const [selected, setselected] = useState("6 x 4.25")
    const datasmall = ["6 x 4.25", "6 x 4.55", "6 x 4.75"]
    const datalg = ["7 x 4.25", "6 x 4.45", "6 x 4.95"]

    return (
        <div id="profile-edit" className="profile-edit">
            <Header />
            <div onClick={() => {
                this.props.history.goBack();
            }}
                className="container profile-header">
                <img alt="icon" src={arrow} />
                <p className="small-bold">Shipping Details</p>
            </div>
            <div className="container profile-edit-contianer mob">
                <EditAddress/>
            </div>
        </div>
    );
}

export default AddressEdit;
