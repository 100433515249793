import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from '../../components/header/header';
import { Context as ArtContext } from '../../context/ArtContext';
import { ValidateEmail } from '../../helpers/misc_func';
import { useAlert } from 'react-alert'
import Loader from '../../components/checkout/loader';
import eye from "../../assets/eye.png"
import eyeopen from "../../assets/eyeopen.png"
import tick from "../../assets/Vector.png"
import './login.css'
import axios from 'axios';
import { base_url } from '../../helpers/baseURL';

const Login = (props) => {
    const alert = useAlert()
    const { state, login_signup, forgot_send, reset_pwd, verify } = useContext(ArtContext);

    const [remember, setremember] = useState(false)
    const [login, setLogin] = useState(true)
    const [send_pwd, set_send] = useState(false)
    const [email, setemail] = useState('')
    const [forgot, setforgot] = useState(false)
    const [for_email, setfor_email] = useState('')
    const [tmp_pwd, change_tmp] = useState('')
    const [new_pwd, change_new] = useState('')
    const [loader, setloader] = useState(false)
    const [togglepass, settogglepass] = useState(false)
    const [toggleconfirmpass, settoggleconfirmpass] = useState(false)
    const [togglenewpass, settogglenewpass] = useState(false)
    const [toggletemppass, settoggletemppass] = useState(false)
    const [password, setpass] = useState('')
    const [otpwidnow, setOTPWindow] = useState(false)
    const [otp, setOtp] = useState("")
    const [confirmpass, setconfirmpass] = useState('')
    const history = useHistory();

    React.useEffect(() => {
        console.log('history', history.location)
        if (history.location.pathname === "/signup")
            setLogin(false)
        else
            setLogin(true)
    }, [history.location.pathname]);
    React.useEffect(() => {
        console.log("href in login", window.location.href)
    }, []);
    React.useEffect(() => {
        setemail('')
        setpass('')
        console.log("form clearing")
        // if (window.innerWidth < 576) {
        //     if (login)
        //         history.push("/login")
        //     else if (!login) {
        //         history.push("/signup")
        //     }
        // }
    }, [login]);
    const handleKey = (event) => {
        if (event.keyCode == 13) {
            loginSubmit()
        }
    }
    const checkVerification = () => {
        const data = {
            "mail": email
        }
        axios({
            method: 'post',
            url: base_url + '/email_verifications/account_check',
            data: data
        })
            .then((response) => {
                if (response.data.data.account_status) {
                    if (response.data.data.account_status === "1")
                        alert.show("The email is already taken")
                    else {
                        alert.show("The email is already taken,Email verification is pending")
                        setOTPWindow(true)
                    }
                }
            })
    }
    const loginSubmit = () => {
        if (email && password) {
            setloader(true)
            if (ValidateEmail(email)) {
                login_signup(login, email, password, confirmpass)
                    .then(res => {
                        setloader(false)
                        if (res === "Otp") {
                            alert.show("Otp has been sent to your mail")
                            setOTPWindow(true)
                        }
                        else {
                            alert.show("Logged In")
                            console.log("href in login", window.location.href)
                            if (window.location.href.includes("redirect=checkout"))
                                window.location.href = '/checkout/shipping/'
                            else if (window.location.href.includes("/login")) {
                                window.location.href = '/'
                            }
                            if (window.location.href.includes("/checkout/details")) {
                                window.location.href = '/checkout/shipping/'
                            }
                            else {
                                if (window.innerWidth > 565)
                                    props.closeLogin(false)
                            }
                        }
                    })
                    .catch(err => {
                        setloader(false)
                        console.log('error', err)
                        if (err === "exist") {
                            alert.show(`The username ${email} is already taken`)
                        }
                        else if (err === 'invalid_credentials') {
                            alert.show("Email or Password is incorrect")
                        }
                        else if (err === 'user error') {
                            checkVerification()
                        }
                        else if (err === "password doesn't match.")
                            alert.show(`Password and confirm password do not match.`)
                        else if (err == "token error") {
                            alert.show("You need to verify email")
                            setOTPWindow(true)
                        }
                        else {
                            alert.show("Try Later!")
                        }
                    })
            }
            else {
                setloader(false)
                alert.show('Invalid Email')
            }
        }
        else {
            if (email === '') {
                alert.show('Enter an email')
            }
            if (password === '') {
                alert.show('Enter a password')
            }
        }
    }

    const forgot_pwd = () => {
        if (ValidateEmail(for_email)) {
            setloader(true)
            forgot_send(for_email)
                .then(res => {
                    setloader(false)
                    alert.show("Temporary password sent to mail")
                    set_send(true)
                })
                .catch(err => {
                    setloader(false)
                    alert.show("This User was not found or invalid")
                })
        }
        else {
            alert.show('Invalid Email')
        }
    }

    const reset = () => {
        if (tmp_pwd && new_pwd) {
            setloader(true)
            reset_pwd(for_email, tmp_pwd, new_pwd)
                .then(res => {
                    setloader(false)
                    alert.show("password reset successful")
                    setforgot(false)
                    set_send(false)
                })
                .catch(err => {
                    setloader(false)
                    alert.show("Incorrect temporary password")
                })
        }
        else {
            alert.show('Enter temporary and new password')
        }
    }

    const verifyOTP = () => {
        if (otp) {
            setloader(true)
            verify(otp, localStorage.getItem("email"))
                .then(res => {
                    setloader(false)
                    alert.show("Email verified Succesfully")
                    setOTPWindow(false)
                    setLogin(true)
                    if (window.location.pathname === "/signup") {
                        history.push("/login")
                    }
                })
                .catch(err => {
                    setloader(false)
                    alert.show("Incorrect OTP")
                })
        }
        else {
            alert.show("Enter OTP before verifying")
        }
    }
    React.useEffect(() => {
        if (togglepass) {
            settogglepass(false)
        }
        if (toggleconfirmpass) {
            settoggleconfirmpass(false)
        }
    }, [login])

    const changeLogin = () => {
        console.log("login change is triggering", window.location.pathname)
        if (window.location.pathname == "/signup")
            history.push("/login")
        else if (window.location.pathname == "/login") {
            history.push("/signup")
        }

    }

    return (
        <div id="login-signup">
            <Header />
            {loader ? <Loader /> : ''}
            {forgot ?
                send_pwd ?
                    <div className="login awrContainer forget_pwd">
                        <h3 className="GT-bold login-heading mb-5">
                            Request<br />
                            a password reset
                        </h3>
                        <form autoComplete='off'>
                            <div className='d-flex w-100 font-400'>
                                <input className="profile-input profile-inputAwr font-400 eye-input brandon" value={tmp_pwd} type={toggletemppass ? "text" : "password"} onChange={(e) => change_tmp(e.target.value)} placeholder="Temporary password" />
                                <div className='eye-container cursor' onClick={() => settoggletemppass(!toggletemppass)}><img src={toggletemppass ? eyeopen : eye} /></div>
                            </div>
                            <div className='d-flex w-100 font-400'>
                                <input className="profile-input profile-inputAwr font-400 eye-input brandon" value={new_pwd} type={togglenewpass ? "text" : "password"} onChange={(e) => change_new(e.target.value)} placeholder="New password" />
                                <div className='eye-container cursor' onClick={() => settogglenewpass(!togglenewpass)}><img src={togglenewpass ? eyeopen : eye} /></div>
                            </div>

                            {/* <input className="profile-input profile-inputAwr brandon" value={new_pwd} onChange={(e) => change_new(e.target.value)} placeholder="New password" />*/}
                            <button disabled={tmp_pwd && new_pwd ? false : true} onClick={() => reset()} className={'black-btn brandon'}>
                                RESET PASSWORD
                            </button>
                        </form>
                        <p onClick={() => setforgot(false)} className="brandon mt-40px cursor font-500 login-page-change bottom-link">
                            {"Back to sign in"}
                        </p>
                    </div>
                    :
                    <div className="login awrContainer forget_pwd">
                        <h3 className="GT-bold login-heading">
                            Enter your email
                        </h3>
                        <input className="profile-input profile-inputAwr brandon font-400" value={for_email} onChange={(e) => setfor_email(e.target.value)} placeholder="Email" />
                        <button onClick={() => forgot_pwd()} className={'black-btn brandon'}>
                            SEND RESET EMAIL
                        </button>
                        <p onClick={() => setforgot(false)} className="brandon mt-40px cursor font-500 login-page-change bottom-link">
                            {"Back to sign in"}
                        </p>
                    </div>
                :
                <div className="login awrContainer">
                    {/* <h2 className="headmedium mt-5 mb-sm-3 secondarycolor">
                        {login ? "Login" : "Register"}
                    </h2> */}
                    {otpwidnow ?
                        <div>
                            <div className='login-heading GT-bold text-center'>Enter OTP</div>
                            <input className="profile-input profile-inputAwr  font-400 brandon" value={otp} onChange={(e) => setOtp(e.target.value)} placeholder="Enter OTP" />
                            <input type="button" value={"VERIFY"} onClick={() => verifyOTP()} className={login ? 'black-btn brandon' : 'black-btn brandon'} />
                        </div> :
                        <div className='login-or-sign-up'>
                            <div className='login-heading GT-bold text-center'>{login ? "Sign in" : "Create an account"}</div>
                            <form autoComplete={!login ? "off" : ""} onSubmit={() => loginSubmit()} onKeyPress={(event) => { handleKey(event) }}>
                                <div className="inp-fields">
                                    <input className="profile-input profile-inputAwr  font-400 brandon" value={email} onChange={(e) => setemail(e.target.value)} placeholder="Email" />
                                    <div className='d-flex w-100 font-400'>
                                        <input autoComplete={!login ? "off" : ""} className="profile-input profile-inputAwr font-400 eye-input brandon" onChange={(e) => setpass(e.target.value)} value={password} type={togglepass ? "text" : "password"} placeholder="Password" onKeyDown={(event) => { handleKey(event) }} />
                                        <div className='eye-container cursor' onClick={() => settogglepass(!togglepass)}><img src={togglepass ? eyeopen : eye} /></div>
                                    </div>
                                    {!login ? <div className='d-flex w-100 font-400'>
                                        <input autoComplete={!login ? "off" : ""} className="profile-input profile-inputAwr font-400 eye-input brandon" onChange={(e) => setconfirmpass(e.target.value)} value={confirmpass} type={toggleconfirmpass ? "text" : "password"} placeholder="Confirm Password" onKeyDown={(event) => { handleKey(event) }} />
                                        <div className='eye-container cursor' onClick={() => settoggleconfirmpass(!toggleconfirmpass)}><img src={toggleconfirmpass ? eyeopen : eye} /></div>
                                    </div> : ""}
                                </div>
                                {login ? <div className='remember-container'>
                                    <div style={{ backgroundColor: remember ? 'black' : '' }} onClick={() => setremember(!remember)} className='tick-box'>
                                        {remember ? <img className='d-block' src={tick} /> : ""}
                                    </div>
                                    <p className='brandon remember-text'>Remember me</p>
                                </div> : ""}
                                <input type="button" value={login ? "SIGN IN" : 'CREATE ACCOUNT'} onClick={() => loginSubmit()} className={login ? 'black-btn brandon' : 'black-btn brandon'}>
                                </input>
                            </form>
                            <div className='remember-container justify-content-center bottom-link mt-40px '>
                                <p onClick={() => { setLogin(!login); changeLogin() }} className="brandon cursor font-500  login-page-change desk-only">
                                    {login ? <label className='mb-0 cursor'>Create an account</label> : <label className='ml-13p cursor mb-0'>Back to sign in</label>}
                                </p>
                                <p onClick={() => { setLogin(!login); changeLogin() }} className="brandon cursor font-500 login-page-change mobile-only">
                                    {login ? "Register Now" : "Back to sign in"}
                                </p>
                                <p className="brandon line-break px-4">
                                    {login ? ` | ` : ""}
                                </p>
                                {login ?
                                    <p onClick={() => setforgot(true)} className="brandon cursor font-500 login-page-change">
                                        Reset your password
                                    </p>
                                    : ''}
                            </div>
                        </div>
                    }

                </div>
            }

        </div>
    );
}

export default Login;
