import React from "react";

const FallBack = () => {
    return (
        <div className="row py-5 gallery-wall">
            <div className="col-sm-6 pl-sm-0 col-12 " >
                <div className="col-12 col-md-11 mx-md-auto px-0 px-md-3 mb-4 mb-md-0">
                    <div className="loader-grey gallery-image-loader"></div>
                </div>
            </div>
            <div className="col-sm-6 pl-sm-0 col-12">
                <div className="loader-grey mt-1 second-head-load mb-4" />
                <div className="mb-4">
                    <div className="loader-grey brandon-text-load mb-3" />
                    <div className="loader-grey brandon-text-load mb-3" />
                    <div className="loader-grey brandon-text-load mb-3" />
                </div>
                <div className="loader-grey pin-code-input-loader mt-5" />
                <div className="loader-grey w-25 brandon-text-load mb-2" />
                <div className="d-flex pt-3 justify-content-between align-items-center price_cart price-add-loader">
                    <div className="loader-grey w-25 my-0 second-head-load" />
                    <div className="loader-grey w-25 add-cart-loader" />
                </div>
                <div className="row mb-2">
                    <div className="pl-0 col-2">
                        <div className="loader-grey brandon-text-load mb-2" />
                    </div>
                    <div className="col-2">
                        <div className="loader-grey brandon-text-load mb-2" />
                    </div>

                </div>
                <div className="loader-grey second-head-load mb-4" />
            </div>
        </div>
    )
}
export default FallBack;