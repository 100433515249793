import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import { Context as ArtContext } from '../../context/ArtContext';
import Footer from '../../components/Footer/footer';
import Header from '../../components/header/header';
import "./shopart.css"
import larrow from '../../assets/Home/larrow.png'
import rarrow from '../../assets/Home/rarrow.png'
import Slider from 'react-slick'
import { MetaTags } from 'react-meta-tags';
import HTMLReactParser from 'html-react-parser';
import { base_url } from '../../helpers/baseURL';



const ShopArt = () => {
    const { state, shopArtBlog, ShopartTerms, ArtStyles, Spaces } = useContext(ArtContext);
    const [terms, setTerms] = useState(null)
    const [styles, setStyles] = useState(null)
    const [spaces, setSpaces] = useState(null)
    const settings1 = {
        dots: false,
        infinite: false,
        slidesToShow: window.innerWidth > 575 ? 4 : 2,
        slidesToScroll: window.innerWidth > 575 ? 2 : 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    }
    const settings3 = {
        dots: false,
        infinite: false,
        slidesToShow: window.innerWidth > 575 ? 3 : 2,
        slidesToScroll: window.innerWidth > 575 ? 2 : 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    }
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: window.innerWidth > 575 ? 5 : 2,
        slidesToScroll: window.innerWidth > 575 ? 2 : 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    }
    function SampleNextArrow(props) {
        const { className, style, onClick } = props
        return (
            <img
                src={rarrow}
                className={className}
                style={{ ...style, position: 'absolute' }}
                onClick={onClick}
            />
        )
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props
        return (
            <img
                src={larrow}
                className={className}
                style={{ ...style, position: 'absolute', zIndex: '9' }}
                onClick={onClick}
            />
        )
    }

    const bullets = [
        { title: "Free Shipping", image: "/assets/shopart/bullet1.png", desc: "Across India to Selected Pin Codes" },
        { title: "The Finest Quality", image: "/assets/shopart/bullet2.png", desc: "No more waiting for sales. You've got better things to do!" },
        { title: "100% Satisfaction Guarantee", image: "/assets/shopart/bullet3.png", desc: "Don't adore it? Return it for free within 45 days." },
    ]
    useEffect(() => {
        shopArtBlog()
        ShopartTerms()
            .then(res => {
                setTerms(res)
            })
            .catch(err => {
                console.log("err", err)
            })
        ArtStyles()
            .then(res => {
                console.log("style data", res)
                setStyles(res)
            })
            .catch(err => {
                console.log("err", err)
            })
        Spaces()
            .then(res => {
                setSpaces(res)
            })
            .catch(err => {
                console.log("err", err)
            })
    }, [])
    return (
        <div className="shop_art">
            <Header />
            <MetaTags>

            </MetaTags>
            <div className="row flex-sm-row flex-column-reverse">
                <div className="gift-heading col-sm-6 col-12 d-flex flex-column align-items-center justify-content-center">
                    <h2 className="shopart-heading GT-bold secondarycolor mb-1 mb-sm-2 mt-sm-0 mt-3 text-center">
                        Collections
                    </h2>
                    <Link to="/art/" onClick={() => sessionStorage.setItem("artFilters", `[]`)} className="view-all_btn brandon secondarycolor text-decoration-underline">View All</Link>
                </div>
                <div className="col-sm-6 px-0 col-12">
                    <div className="shopart-banner">
                        <img src="/assets/shopart/banner1.png" />
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='gap-t'>
                    <Slider {...settings3}>
                        {terms?.data?.map(theme => {
                            if (theme.type === "taxonomy_term--collection") {
                                let image = terms.included?.find(x => x.id === theme.relationships.field_collection_image?.data?.id)?.attributes.uri.url
                                return <Link to={`/art/?theme=${theme.attributes.name}`} className='term-collection px-sm-4 px-2'>
                                    <img src={image ? base_url + image : "/assets/shopart/theme.png"} />
                                    <p className='text-center GT slider-title'>{theme.attributes.name}</p>
                                </Link>
                            }
                        })}
                    </Slider>
                </div>
                <div className='row justify-content-center gap-t'>
                    {terms?.data?.map(artist => {
                        if (artist.type === "taxonomy_term--artist") {
                            let name = `${artist.attributes.name.split(",")[1]} ${artist.attributes.name.split(",")[0]}`,
                                image = terms.included?.find(x => x.id === artist.relationships.field_thumbnail?.data?.id)?.attributes.uri.url
                            return (<Link to={`/art/?artist=${name}`} className='col-sm-4 col-6 px-sm-4 px-2 term-artist'>
                                <img src={image ? base_url + image : '/assets/shopart/artist.png'} />
                                <p className='text-center GT slider-title'>{name}</p>
                            </Link>)
                        }
                    })}
                </div>
            </div>
            <div className='d-flex justify-content-center align-items-center gap-y banner-2 w-100' style={{ backgroundImage: `url("/assets/shopart/banner2.png")` }}>
                <Link to={`/art/?theme=Botanical`} className="GT btn banner-button">Botanical</Link>
            </div>
            <div className='container gap-y'>
                <Slider {...settings1} className='space-slider-container'>
                    {styles?.data?.map((style, ind) => {
                        let image = styles.included?.find(x => x.id === style.relationships.field_thumbnail?.data?.id)?.attributes.uri.url
                        return <Link to={`/art/?style=${style.attributes.name}`} className="styles-slider px-sm-4 px-2">
                            <img src={image ? base_url + image : "/assets/curated.png"} />
                            <p className='text-center GT slider-title'>{style.attributes.name}</p>
                        </Link>
                    })}
                </Slider>
            </div>
            <div className='light-grey-bg'>
                <div className='container pad-y'>
                    <h2 class="shopart-heading GT-bold secondarycolor mb-3 text-center">Art for All Spaces</h2>
                    <Slider {...settings} className='space-slider-container'>
                        {spaces?.data?.map((space, ind) => {
                            let image = spaces.included?.find(x => x.id === space.relationships.field_thumbnail?.data?.id)?.attributes.uri.url
                            return <Link to={`/art/?space=${space.attributes.name}`} className="space-slider px-sm-4 px-2">
                                <img src={image ? base_url + image : "/assets/curated.png"} />
                                <p className='text-center GT slider-title'>{space.attributes.name}</p>
                            </Link>
                        })}
                    </Slider>
                </div>
            </div>
            <div className='banner-3-container container-sm container-fluid gap-t '>
                <div className='banner-3 w-100 d-flex justify-content-center align-items-center' style={{ backgroundImage: `url("/assets/shopart/banner3.png")` }}>
                    <Link to="/perfect-pairs/" onClick={() => sessionStorage.setItem("artFilters", `[]`)} className="GT btn banner-button">Perfect Pair</Link>
                </div>
            </div>
            <div className='bg-grey'>
                <div className='container gap-y'>
                    <div className='row mx-0 pad-y shopart-bullet'>
                        {bullets?.map((bullet, index) => {
                            return (<div className='bullet align-items-center column col-sm-4 col-12 mb-sm-0 mb-5 text-center'>
                                <div className=''>
                                    <img alt='icon' src={bullet.image} />
                                </div>
                                <h2
                                    className={
                                        index == 1
                                            ? 'head GT second-head secondarycolor'
                                            : 'head GT other-head secondarycolor'
                                    }
                                >
                                    {bullet.title}
                                    {/* <div> {bullet[index].second}</div> */}
                                </h2>
                                <p className='brandon'>
                                    {bullet.desc}
                                </p>
                            </div>)
                        })}
                    </div>
                </div>
            </div>
            <div className='container gap-y'>
                <div className='row art-blog-list'>
                    {state.shopArtBlog?.data?.map((blog, ind) => {
                        let image = blog.relationships.field_image.data ? state.shopArtBlog.included?.find(x => x.id === blog.relationships.field_image.data.id) : ""
                        return (<Link to={`${blog.attributes.field_path_alias}/`} className='art-blog col-sm-4 col-12 mb-sm-0 mb-4'>
                            <img src={image ? base_url + image.attributes.uri.url : ""} />
                            <h6 className='title GT mt-3'>{blog.attributes.title}</h6>
                            <p className='desc brandon'>{blog.attributes.body ? HTMLReactParser(blog.attributes.body.value) : ""}</p>
                        </Link>)
                    })}

                </div>
            </div>
            <Footer />
        </div >
    )
}
export default ShopArt;
