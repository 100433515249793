import React, { useContext, useEffect, useState } from "react";
import { Context as UserContext } from '../../context/UserContext';
import { Context as ProductContext } from '../../context/ProductContext';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import Loading from "react-js-loader";
import "./wishlist.css"
import { MetaTags } from "react-meta-tags";
import Loader from "../../components/checkout/loader";


const Wishlist = (props) => {
    const { state, getWishlist } = useContext(UserContext)
    let PContext = useContext(ProductContext)
    const alert = useAlert()
    const [loader, setloader] = useState(false)
    useEffect(() => {
        getWishlist()
    }, [])
    useEffect(() => {
        console.log("wishlist data recieved", state.wishlist)
    }, [state.wishlist])
    const getType = (type) => {
        if (type === "commerce_product--default")
            return "Art Prints"
        else if (type === "commerce_product--mixed_media")
            return "Mixed Media Art"
        else if (type === "commerce_product--gifting")
            return "Gifts"
        else if (type === "commerce_product--gallery_wall")
            return "Gallery Walls"
        else if (type === "commerce_product--object")
            return "Decor Objects"


    }
    const deleteWish = (id) => {
        setloader(true)
        PContext.deleteFromWish(id)
            .then(res => {
                if (res === "item deleted") {
                    alert.show("Removed from Wishlist", { type: "success" });
                    getWishlist()
                    setloader(false)
                }
            })
            .catch(err => {
                setloader(false)
            })
    }
    return (<div className="wishlist-page">
        <MetaTags>
            <title>Wishlist</title>
            <link rel="canonical" href={`${window.location.origin}/account/wishlist/`} />
        </MetaTags>
        {loader ? <Loader /> : ""}
        <div className="main-heading mb-5 mt-sm-0 mt-3 text-center">
            Wishlist
        </div>
        <div className="row">
            {state.wishlist ? state.wishlist.wishlist_variation?.length ? state.wishlist?.wishlist_variation.map((item) => {
                let variation_id = state.wishlist?.included?.find(x => x.id === item.id)?.relationships.purchasable_entity?.data?.id
                let variation = state.wishlist?.included.find(x => x.id === variation_id)
                let product_id = state.wishlist?.included?.find(x => x.id === variation_id)?.relationships.product_id?.data
                let product = state.wishlist?.included?.find(x => x.id === product_id?.id)
                let image, media
                if (product.type === "commerce_product--mixed_media" || product.type === "commerce_product--object")
                    media = variation?.relationships?.field_image?.data?.length ? state.wishlist?.included?.find(x => x.id === variation?.relationships?.field_image?.data[0]?.id) : ""
                else
                    media = product?.relationships.field_image?.data ? state.wishlist?.included?.find(x => x.id === product.relationships.field_image.data.id) : ""
                if (product.type === "commerce_product--gallery_wall")
                    image = product?.relationships.field_gallery_image?.data ? state.wishlist?.included?.find(x => x.id === product.relationships.field_gallery_image.data.id) : ""
                else if (product.type === "commerce_product--gifting")
                    image = product?.relationships.field_gifting_image?.data?.length ? state.wishlist?.included?.find(x => x.id === product.relationships.field_gifting_image.data[0].id) : ""
                else
                    image = media ? state.wishlist.included.find(x => x.id === media.relationships.field_media_image?.data?.id) : ""
                if (product.type === "commerce_product--mixed_media")
                    console.log("image issue", media)

                console.log("product details", product.type)
                return (<Link to={`${product?.attributes.path?.alias.replace("object/", "decor-objects/")}/`} onClick={(e) => { if (e.target.className.includes("remove-btn") || e.target.className.includes("heart-symbol")) e.preventDefault() }} className="frame-ind bordering cursor mb-4 col-md-6 col-6">
                    <div className="frame-item">
                        {
                            image?.attributes?.image_style_uri.length ? < img alt="icon" className='image-hi' src={image?.attributes.image_style_uri.find(obj => obj.listing)?.listing} /> :
                                <img alt="icon" className='image-hi' src="assets/images/dummy.jpeg" />
                        }
                    </div>
                    <div className="name-price brandon pt-lg-2 pb-1 pt-sm-3">
                        <div className="custName name w-100 text-center">
                            {product?.attributes.title}
                        </div>
                        <p className="brandon custArtist artist-name d-flex justify-content-center">
                            {getType(product?.type)}
                        </p>
                    </div>
                    {/* {product.type === "commerce_product--gifting" ? <p className="brandon text-center secondarycolor gift-frameName">
                        {product.attributes.field_frame ? product.attributes.field_frame : ""}
                    </p> : ""} */}
                    <div className="remove-btn" onClick={(e) => deleteWish(item?.id)}>
                        <img src="/assets/grey-close.png" className='heart-symbol grey-close' />
                    </div>
                </Link>)
            }) : <p className="no-products mx-auto my-5"><h3 className="brandon">No items added in wishlist</h3></p> :
                <div className='container py-5 my-5'> <Loading type="spinner-default" bgColor={"#808080"} title={"spinner-circle"} size={60} /></div>}
        </div>
    </div>)
}
export default Wishlist;