import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from '../../components/header/header';
import './cart.css'
import EmptyCart from '../../components/cart/empty';
import close from '../../assets/close.png'
import dotmenu from '../../assets/3dot.png'
import CartItem from '../../components/cart/cartItem';
import { Context as ArtContext } from '../../context/ArtContext';
import Price from '../../helpers/price';


const Cart = (props) => {
    const { state, cartTrig } = useContext(ArtContext);
    const [cart_data, set_cart_data] = useState('')
    const [remove, setRemove] = useState(null)
    React.useEffect(() => {
        console.log("cartData", JSON.parse(localStorage.getItem("cartData")))
        let cart_data = JSON.parse(localStorage.getItem("cartData"))
        set_cart_data(cart_data)
    }, []);

    const history = useHistory();

    const removeItem = async (ind) => {
        let cartData = JSON.parse(localStorage.getItem("cartData"))
        let checkoutdata = JSON.parse(localStorage.getItem("checkout_data"))
        set_cart_data([])
        if (cartData) {
            let deleted = cartData.splice(ind, 1)
            checkoutdata.total_no = checkoutdata.total_no ? checkoutdata.total_no - 1 : 0
            checkoutdata.total_price = checkoutdata.total_price ? checkoutdata.total_price : 0
            checkoutdata.total_price = checkoutdata.total_price ? checkoutdata.total_price - deleted[0].total_price : 0
            localStorage.setItem("checkout_data", JSON.stringify(checkoutdata))
            localStorage.setItem("cartData", JSON.stringify(cartData))
            console.log("cartdata new", cartData, "deleted", deleted)
            setRemove(null)
            cartTrig(state.cartTrigger + 1)
            set_cart_data(cartData)
        }
    }

    const cartList = ["item 1"]

    return (
        <div id="cart">
            {cart_data ? cart_data.length ?
                <div className="cart-main">
                    <h2 className="headmedium mt-3 mb-2 text-center secondarycolor">
                        Shopping Cart
                    </h2>
                    <div className="cart-items-container">
                        {cart_data.map((item, ind) => {
                            return (
                                <div className="cart-items">
                                    <img style={{ display: "none" }} src={close} onClick={() => setRemove(ind)} />
                                    <CartItem menu={true} data={item} index={ind} />
                                    <div className="remove-cart bgsecondary whitecolor" onClick={() => setRemove(ind)} >Remove Item</div>
                                    {remove === ind ? <div className="remove-container">
                                        <p className='remove-note'>Are you sure you want to remove this item?</p>
                                        <div className="btns">
                                            <button onClick={() => setRemove(null)} className="secondarycolor bgwhite keep-shop">
                                                Cancel
                                            </button>
                                            <button onClick={() => removeItem(ind)} className="bgsecondary whitecolor">
                                                Remove
                                            </button>
                                        </div>
                                    </div> : ""}
                                </div>
                            )
                        })}
                    </div>
                    {cart_data ?
                        <div className="product-price">
                            <div className="cart-prods d-flex">
                                <div className="cart-item-title-grey">
                                    Total Products
                                </div>
                                <div className="cart-item-details">
                                    {JSON.parse(localStorage.getItem("cartData")).length}
                                </div>
                            </div>
                            <div className="cart-total d-flex">
                                <div className="cart-item-title-grey cart-item-title-grey-total">
                                    Total
                                </div>
                                <div className="cart-item-details cart-item-details-price">
                                    &#8377;{localStorage.getItem("checkout_data") ? <Price value={JSON.parse(localStorage.getItem("checkout_data")).total_price} /> : ''}
                                </div>
                            </div>
                        </div>
                        : ""}
                    {/* <div className="text-grey text-center secondarygrey cart-bot-text">
                        Before final discounts, promo codes, and taxes.
                    </div> */}
                    <div className="cart-btns">
                        <div className="btns">
                            <button onClick={() => { history.push('/checkout/details'); props.sideMenu(false) }} className="bgsecondary whitecolor">
                                Checkout
                            </button>
                            <button onClick={() => props.sideMenu(false)} className="desk-only secondarycolor bgwhite keep-shop">
                                Keep Shopping
                            </button>
                            <button className='mobile-only secondarycolor bgwhite keep-shop' onClick={() => props.sideMenu(false)}>Keep Shopping</button>
                        </div>
                    </div>
                </div>
                :
                <div className="empty_cart">
                    <EmptyCart />
                </div> :
                <div className="empty_cart">
                    <EmptyCart />
                </div>
            }
        </div>
    );
}

export default Cart;
