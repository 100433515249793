import React, { useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Link } from 'react-router-dom';
import { ValidateEmail } from '../../helpers/misc_func';
import Loader from '../../components/checkout/loader';
import { Context as ArtContext } from '../../context/ArtContext';

const PassWord = () => {
    const { state, forgot_send, reset_pwd } = useContext(ArtContext);
    React.useEffect(() => {
        let userdata = JSON.parse(localStorage.getItem("userdat"))
        let email = userdata.details.attributes.name
        setfor_email(email)
    }, []);
    const alert = useAlert()
    const [send_pwd, set_send] = useState(false)
    const [forgot, setforgot] = useState(false)
    const [for_email, setfor_email] = useState('')
    const [tmp_pwd, change_tmp] = useState('')
    const [new_pwd, change_new] = useState('')
    const [loader, setloader] = useState(false)

    const forgot_pwd = () => {

        if (ValidateEmail(for_email)) {
            setloader(true)
            forgot_send(for_email)
                .then(res => {
                    setloader(false)
                    alert.show("Temporary password sent to mail")
                    set_send(true)
                })
                .catch(err=>{
                    setloader(false)
                    alert.show("Email id is incorrect")
                })
        }
        else {
            alert.show('Invalid Email')
        }
    }

    const reset = () => {
        if (tmp_pwd && new_pwd) {
            reset_pwd(for_email, tmp_pwd, new_pwd)
                .then(res => {
                    alert.show("password reset successful")
                    setforgot(false)
                    set_send(false)
                })
                .catch(err => {
                    alert.show("Incorrect temporary password")
                })
        }
        else {
            alert.show('Enter temporary and new password')
        }
    }
    return (
        <div id="personal_data">
            {loader ? <Loader /> : ''}
            {!send_pwd ?
                <div>
                    <p className="small-normal">If you want to change the password to access your account, enter the folowing information:Your current email address is {for_email}</p>
                    {/* <div className="fields-sec mx-0 row">
                        <input className="profile-input col-md-12 mr-md-4" value={for_email} onChange={(e) => setfor_email(e.target.value)} placeholder="E-mail" />
                    </div> */}
                    <button onClick={() => forgot_pwd()} className="black-btn bgsecondary whitecolor change-details-btn">
                        Send Temporary Password to Email
                    </button>
                </div> :
                <div>
                    <p className="small-normal">If you want to change the password to access your account, enter the folowing information:Your current email address is {for_email}</p>
                    <div className="fields-sec mx-0 row">
                        <input className="profile-input col-md-5 mr-md-4" value={tmp_pwd} onChange={(e) => change_tmp(e.target.value)} placeholder="Temporary password" />
                        <input className="profile-input col-md-5 mr-md-4" value={new_pwd} onChange={(e) => change_new(e.target.value)} placeholder="New password" />
                    </div>
                    <button disabled={tmp_pwd && new_pwd ? false : true} onClick={() => reset()} className="black-btn bgsecondary whitecolor change-details-btn">
                        Change Password
                    </button>
                </div>
            }

        </div>
    );
}

export default PassWord;
