import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';


export function useRedirect(url) {
  const [isPresent, setIsPresent] = useState(false);
  const history = useHistory();
  history.push(url)
  return null;
}

export function ValidateEmail(mail) {
  if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
    return (true)
  }
  return (false)
}

export function ScrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}