import React from "react"
import Footer from "../components/Footer/footer"
import Header from "../components/header/header"


const OptOut = () => {
    return (
        <div>
            <Header />
            <div className="container my-5">
                <div className="w-50 m-auto py-5">
                    <div className="main-heading text-center">Opt Out from Subscription</div>
                    <div className="container mt-5">
                        <div className="col-6 mx-auto mb-3">
                            <input placeholder="Email" className="mx-auto w-100" />
                        </div>
                        <div className="col-6 mx-auto mb-5">
                            <input type="button" className="grey-button px-4 brandon mx-auto w-100" value="Opt Out" placeholder="Opt Out"></input>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

        </div>
    )
}
export default OptOut;