import React, { useState, useRef } from "react"
import "./share.css"
import { useEffect } from "react"
import { useAlert } from "react-alert";

const Share = (props) => {
    let title = props.title
    const refbox = useRef()
    const refbtn = useRef()
    const [share, setShare] = useState(false)
    const alert = useAlert()
    const settingShare = () => {
        let currentURL = window.location.href;
        const isMobileOrTablet = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        if (isMobileOrTablet) {
            // If on mobile or tablet, use native sharing
            if (navigator.share) {
                navigator.share({
                    title: title,
                    text: 'Check this out',
                    url: currentURL,
                })
                    .then(() => console.log('Shared successfully'))
                    .catch((error) => console.error('Error sharing:', error));
            } else {
                // Fallback for browsers that do not support the Web Share API
                // alert.show('Web Share API is not supported on this browser');
                setShare(!share)
            }
        } else {
            // If on desktop,open share box
            console.log('Show additional share options for desktop');
            setShare(!share)
        }
    }
    const shareTo = (to) => {
        let currentURL = window.location.href
        if (to === "insta")
            alert.show("Open Instagram and share the link manually.");
        else if (to === "pinterest")
            window.open(`https://pinterest.com/pin/create/button/?url=${encodeURIComponent(currentURL)}`);
        else if (to === "whatsapp")
            window.open(`https://wa.me/?text=Check%20this%20out%0A${encodeURIComponent(currentURL)}`);
        else if (to === "copy") {
            navigator.clipboard.writeText(currentURL);
            alert.show("Link copied to clipboard!", { type: "success" });
        }
        else if (to === "gmail") {
            window.open(`https://mail.google.com/mail/?view=cm&fs=1&su=Espravo&body=Check%20this%20out%20%0A${encodeURIComponent(currentURL)}`);
        }
    }
    useEffect(() => {
        if (share)
            window.addEventListener("click", (data) => {
                if (data.target !== refbox.current && data.target !== refbtn.current) {
                    setShare(false)
                    console.log("data clicked is")
                }
            })
    })
    useEffect(() => {

    }, [])
    return (
        <div className={`d-flex justify-content-end position-relative align-items-center ${props.className ? props.className : ""}`}>
            <img ref={refbtn} className={`share-btn ${props.imageClassName ? props.imageClassName : ""}`} src="/assets/share.png" onClick={() => settingShare(true)} />
            {share ? <>
                <span className="diamond-arrow"></span>
                <div className="share-box d-flex flex-wrap" ref={refbox}>
                    <img className="" src="/assets/share-whatsapp.png" onClick={() => shareTo("whatsapp")} />
                    <img className="" src="/assets/gmail.png" onClick={() => shareTo("gmail")} />
                    <img className="" src="/assets/copy-link.png" onClick={() => shareTo("copy")} />
                </div>
            </> : ""}
        </div>
    )
}
export default Share;