import React, { useContext, useState } from 'react';
import { Context as ArtContext } from '../../context/ArtContext';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import { Context as UserContext } from '../../context/UserContext';
import arrow from '../../assets/Footer Right.png'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import Orderlist from './orderList';
import OrderSingleList from './orderSIngleList';
import PriceTable from '../checkout/priceTable';
import CartItem from '../cart/cartItem';

const OrderDetails = () => {
    let params = useParams();
    const search = useLocation().search;
    const query = new URLSearchParams(search).get('orderno')
    const { order_no } = useParams()
    const { state, single_order } = useContext(UserContext);
    const [details, setDetail] = useState(true)
    React.useEffect(() => {
        single_order(order_no)
    }, []);

    let history = useHistory();
    const ordercontents = [{
        title: `Mercer Slim`,
        price: `2,000`,
        dimensions: `20"x26.67"`,
        matstyle: `White`,
        Framesize: `20"x26.67"`,
        qty: `1`
    }]
    return (
        <div>
            {/* <Header /> */}
            <div id='order_details' className="">
                <div className="order-number container d-flex my-5">
                    {state.singleorder ? <p className="small-bold">Order #{state.singleorder.data ? state.singleorder.data.attributes ? state.singleorder.data.attributes.order_number : '' : ''}</p> : ''}
                    <p className="green1">Confirmed</p>
                </div>
                <div className="container order-accordions">
                    <div className="accordion__item">
                        <div className="AccordionItemHeading accordion__button" aria-expanded={details} aria-hidden={!details} onClick={() => details ? setDetail(false) : setDetail(true)}>
                            Order details
                        </div>
                        {details ? <div className="AccordionItemPanel">
                            <div className="order-thumb" id="order-details-list">
                                <div className="shipping-list-container pad-0 mob">
                                    {state.singleorder ? state.singleorder.included ? state.singleorder.included.map((item) => {
                                        if (item.type === 'commerce_order_item--default')
                                            if (item.relationships.purchased_entity.data.type !== "commerce_product_variation--frame")
                                                return <OrderSingleList ordercontents={item.attributes} relationships={item.relationships} />
                                    }) : '' : ''}
                                </div>
                                {state.singleorder ? <div className="">
                                    <PriceTable data={state.singleorder.data} />
                                </div> : ''}
                            </div>
                        </div> : ''}
                    </div>
                    <Accordion preExpanded="0" allowMultipleExpanded={true}>
                        <AccordionItem uuid="1">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Shipping Address
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                {state.singleorder ? state.singleorder.included ?
                                    state.singleorder.included.map(data => {
                                        if (data.type === 'profile--customer') {
                                            console.log('order shipping data', data.attributes)
                                            return (
                                                <div className="container pb-2">
                                                    {data.attributes ? data.attributes.address ? data.attributes.address.given_name : '' : ''} {data.attributes ? data.attributes.address ? data.attributes.address.family_name : '' : ''}
                                                    <br />
                                                    {data.attributes ? data.attributes.address ? data.attributes.address.address_line1 ? data.attributes.address.address_line1 : "" : '' : ''}<br />
                                                    {data.attributes ? data.attributes.address ? data.attributes.address.address_line2 ? data.attributes.address.address_line2 : "" : '' : ''}<br />
                                                    {data.attributes ? data.attributes.address ? data.attributes.address.locality ? data.attributes.address.locality : '' : "" : ''} <br />
                                                    {data.attributes ? data.attributes.field_phone_number : ''}
                                                </div>
                                            )
                                        }
                                    })

                                    : "" : ''}
                            </AccordionItemPanel>
                        </AccordionItem>
                        {/* <AccordionItem uuid="2">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Billing Address
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                {state.singleorder ? state.singleorder.included ?
                                    state.singleorder.included.map(data => {
                                        if (data.type === 'profile--customer') {
                                            console.log('order shipping data', data.attributes)
                                            return (
                                                <div className="container pb-2">
                                                    {data.attributes ? data.attributes.address ? data.attributes.address.given_name : '' : ''} {data.attributes ? data.attributes.address ? data.attributes.address.family_name : '' : ''}
                                                    <br />
                                                    {data.attributes ? data.attributes.address ? data.attributes.address.address_line1 ? data.attributes.address.address_line1 : "" : '' : ''}<br />
                                                    {data.attributes ? data.attributes.address ? data.attributes.address.address_line2 ? data.attributes.address.address_line2 : "" : '' : ''}<br />
                                                    {data.attributes ? data.attributes.address ? data.attributes.address.locality ? data.attributes.address.locality : '' : "" : ''} <br />
                                                    {data.attributes ? data.attributes.field_phone_number : ''}
                                                </div>
                                            )
                                        }
                                    })

                                    : "" : ''}
                            </AccordionItemPanel>
                        </AccordionItem> */}
                        <AccordionItem uuid="3">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Payment Method
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="body_links">
                                    Razor pay
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </div>
    );
}

export default OrderDetails;