import React, { useContext, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { TokenCheck } from '../../network/tokenChecker'
import { Context as AppLoadContext } from '../../context/AppLoadContext'
import ham from '../../assets/ menu.png'
import Logo from '../../assets/Home/logo.svg'
import close from '../../assets/close.png'
import cart from '../../assets/Home/shopping-cart.png'
import Carts from '../../screens/cart/cart'
import closecart from '../../assets/common.png'
import user from '../../assets/Home/user.png'
import './header.css'
import Popup from 'reactjs-popup'
import { Media, Modal, ModalBody } from 'react-bootstrap'
import Login from '../../screens/login/login'
import cross from '../../assets/cross.png'
import { Context as ArtContext } from '../../context/ArtContext'
import { Context as ProductContext } from '../../context/ProductContext'
import ModalHeader from 'react-bootstrap/esm/ModalHeader'
import OTPLogin from '../../screens/login/otplogin'
import { useAlert } from 'react-alert'

const Header = props => {
  const ref = useRef()
  const popref = useRef()
  let PContext = useContext(ProductContext)
  const {
    state,
    checkLoginStatus,
    cartMenu,
    setArtFilters,
    setcounta,
    setoffseta
  } = useContext(ArtContext)
  const alert = useAlert()

  const [show, setShow] = useState(false)

  const handleShow = () => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }
  const [menu, sideMenu] = useState(false)
  const [logedin, setlogin] = useState(false)
  const [sticky, setSticky] = useState(false)
  let history = useHistory()
  const [users, login] = useState(false)
  const [userpop, openUser] = useState(false)
  const [count, setCount] = useState(
    localStorage.getItem('checkout_data')
      ? JSON.parse(localStorage.getItem('checkout_data')).total_no
      : ''
  )
  const [search, setSearch] = useState("")
  const [searchOpen, setOpenSearch] = useState(false)
  React.useEffect(() => {
    let userdata = localStorage.getItem('userdat')
    if (userdata) {
      userdata = JSON.parse(userdata)
      checkLoginStatus(userdata)
    } else {
      checkLoginStatus(false)
    }
  }, menu)
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })
  React.useEffect(() => {
    window.addEventListener('click', callClick)
  })
  const callClick = click => {
    if (popref.current && !popref.current.contains(click.target)) {
      if (userpop) {
        openUser(false)
      }
    }
  }
  const handleScroll = () => {
    var w = window.innerWidth
    var h = document.getElementById('root').offsetHeight
    // console.log(window.pageYOffset, h)

    if (h < 740) {
      if (window.pageYOffset > 80) {
        // alert('fixed')
        setSticky(true)
      }
      if (window.pageYOffset === 0) setSticky(false)
    } else {
      if (window.pageYOffset > 80) {
        // alert('fixed')
        setSticky(true)
      } else {
        setSticky(false)
      }
    }
  }
  React.useEffect(() => {
    console.log('new path', window.location.pathname)
    if (
      window.location.pathname === "/art/" ||
      window.location.pathname.startsWith('/shop_art_type') ||
      window.location.pathname.startsWith('/shop_art') ||
      window.location.pathname.startsWith('/frame') ||
      window.location.pathname.startsWith('/frame-edit')
    ) {
      console.log('in shopart flow')
    } else {
      console.log('not in shopart flow')
      setArtFilters([])
      setcounta(0)
      setoffseta(0)
    }
    if (
      window.location.pathname === '/' ||
      window.location.pathname === "/about/" ||
      window.location.pathname === "/faq/" ||
      window.location.pathname === "/contact/" ||
      window.location.pathname === "/terms-of-service/" ||
      window.location.pathname === "/privacy-policy/" ||
      window.location.pathname.startsWith('/checkout')
    )
      document.title = 'Espravo - Online Art Gallery | Buy Art, Picture Frames & Decor Objects'
    else {
      if (
        window.location.pathname === "/gallery-walls/" ||
        window.location.pathname.startsWith('/gallery_walls')
      )
        document.title = 'Gallery Walls'
      else if (window.location.pathname.startsWith("/gifts/"))
        document.title = 'Gifts'
      else if (localStorage.getItem('flowtype') === 'shopart')
        document.title = 'Shop Art Prints'
      else if (localStorage.getItem('flowtype') === 'framing')
        document.title = 'Custom Frames Online'
      else {
        document.title = 'Espravo - Online Art Gallery | Buy Art, Picture Frames & Decor Objects'
      }
    }
  }, [window.location.pathname])
  React.useEffect(() => {
    console.log('moving', sticky)
  }, [sticky])
  React.useEffect(() => {
    console.log('cart trigger', state.cartTrigger)
    setCount(
      localStorage.getItem('checkout_data')
        ? JSON.parse(localStorage.getItem('checkout_data')).total_no
        : ''
    )
  }, [state.cartTrigger])
  if (users) {
    document.body.style.overflow = 'hidden'
  }
  const mobileuserpop = () => {
    login(true)
    sideMenu(false)
  }
  const logout = () => {
    localStorage.removeItem('userdat')
    window.location.href = '/?logged_out=true'
  }
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      // console.log("User stopped typing:", search);
      PContext.searchProducts(search, window.location.pathname)
    }, 700);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [search]);

  const focusSearch = () => {
    let element = document.getElementById("search-bar")
    console.log("found is", element)
    setTimeout(() => {
      element.focus()
    }, 100)
  }

  return (
    // <AppLoadContext>
    <div id='header' className={sticky ? 'fixedheader header-all' : 'header-all'}>
      <div id='mobile_header'>
        <div className={'mobile_header header-container'}>
          <div className='hamburger'>
            {menu ? (
              <img
                className='close-menu'
                alt='icon'
                src="/assets/close.webp"
                height='15px'
                width='15px'
                onClick={() => {
                  sideMenu(false)
                  cartMenu(false)
                }}
              />
            ) : (
              <img
                className='open-menu'
                alt='icon'
                src="/assets/menu.webp"
                onClick={() => {
                  sideMenu(true)
                  cartMenu(false)
                }}
              />
            )}
          </div>
          <div className='mob_logo'>
            <Link
              to='/'
              onClick={() => {
                cartMenu(false)
                window.scroll({ top: 0, left: 0, behavior: 'smooth' })
              }}
            >
              <img alt='icon' src={Logo} />
            </Link>
            <div className='menu-desk d-lg-flex d-none justify-content-center'>
              <Link
                to="/art-collection/"
                className='brandon'
                onClick={() => {
                  localStorage.setItem('artmode', 'shopart')
                  localStorage.setItem('flowtype', 'shopart')
                  sessionStorage.setItem("artFilters", `[]`)
                }}
              >
                Art Prints
              </Link>

              <Link to="/decor-objects/" className="brandon">
                Decor Objects
              </Link>
              <Link to="/mixed-media/" className="brandon">
                Mixed Media Art
              </Link>
              <Link to="/gallery-walls/" className='brandon'>
                Gallery Walls
              </Link>
              <Link
                to="/custom-framing/"
                className='brandon'
                onClick={() => {
                  localStorage.setItem('artmode', 'frame')
                  localStorage.setItem('flowtype', 'framing')
                }}
              >
                Custom Framing
              </Link>
            </div>
          </div>
          {users || state.cart ? (
            <div className='overlay'>
              <div style={{ textIndent: '-9999px' }}>
                {(document.body.style.overflow = 'hidden')}
              </div>
            </div>
          ) : (
            ''
          )}
          {users ? (
            <div style={{ textIndent: '-9999px' }}>
              {(document.body.style.overflow = 'hidden')}
            </div>
          ) : (
            ''
          )}
          {/* <Popup open={users && !state.loginStatus} onClose={() => login(false)}>
                        <div className='popup-container'>
                            <img className='close-cross' src={cross} onClick={() => login(false)} />
                            <Login />
                        </div>
                    </Popup> */}
          <Popup
            ref={ref}
            open={users && !state.loginStatus}
            closeOnDocumentClick={false}
          >
            <div className='popup-container login-popup'>
              <img
                className='login-close zoom-close'
                src='/assets/contactclose.png'
                onClick={() => login(false)}
              />
              <OTPLogin closeLogin={login} />
            </div>
          </Popup>
          <div
            className='cart_icon d-flex align-items-center position-relative justify-content-end ml-auto'
            ref={popref}
          >
            <div className='position-relative'>
              <div className='head-search-box position-relative' style={{ width: searchOpen ? "200px" : "20px" }}>
                <img className={`searchbtn ${!searchOpen ? "d-block d-sm-none" : "d-none"}`} onClick={() => { setOpenSearch(true); focusSearch() }} src='/assets/head-search.png' />
                <img className={`searchbtn ${!searchOpen ? "d-sm-block d-none" : "d-none"}`} onClick={() => { setOpenSearch(true); focusSearch() }} src='/assets/Search.png' />
                <input id='search-bar' className={`search-bar brandon`} style={{ display: searchOpen ? "block" : "none" }} placeholder="Search.." value={search} onChange={(e) => setSearch(e.target.value)} />
                <img className='search-close' style={{ display: searchOpen ? "block" : "none" }} onClick={() => { setOpenSearch(false); setSearch("") }} src='/assets/close.png' />
              </div>
              {PContext.state.suggestions?.length && Array.isArray(PContext.state.suggestions) ? <div className={`suggest-box ${searchOpen || search !== "" ? "d-block" : "d-none"}`}>
                {PContext.state.suggestions?.map(data => {
                  return <Link className="brandon" onClick={() => setSearch("")} to={`${data.url.replace("/object/", "/decor-objects/")}/`}>{data.title}</Link>
                })}
              </div> : PContext.state.suggestions?.length && search !== "" ? <p className='suggest-box text-left py-1 px-1 brandon'>No results found..</p> : ""}
              {PContext.state.suggestions?.length == 0 && search ? <div className='suggest-box border-none px-1'>
                <div className='loader-grey pl-1 my-1 search-loader' />
                <div className='loader-grey pl-1 my-1 last-load search-loader' />
              </div> : ""}
            </div>
            <img
              className='user-log'
              src="/assets/user.webp"
              onClick={() => openUser(!userpop)}
            // onClick={() => { setShow(true) }}
            />
            <div className={userpop ? 'user-popup' : 'd-none'}>
              {state.loginStatus ? (
                <div className='user-popup-container d-block brandon'>
                  <div className='section'>
                    <h6 className='welcome-text brandon'>
                      Welcome{' '}
                      {localStorage.getItem('userdat')
                        ? JSON.parse(localStorage.getItem('userdat'))?.details
                          ?.attributes.field_full_name
                        : 'Guest'}
                    </h6>
                    <p className='pe-none'>
                      {localStorage.getItem('userdat')
                        ? JSON.parse(localStorage.getItem('userdat'))?.details
                          ?.attributes.name
                        : ''}
                    </p>
                  </div>
                  <div className='section'>
                    <p
                      className='brandon'
                      onClick={() => {
                        openUser(false)
                        history.push('/account/profile/')
                      }}
                    >
                      My Account
                    </p>
                    <p
                      className='brandon'
                      onClick={() => {
                        openUser(false)
                        history.push('/account/orders/')
                      }}
                    >
                      Orders
                    </p>
                    {/* <p
                      className='brandon'
                      onClick={() => {
                        openUser(false)
                        history.push('/account/wishlist/')
                      }}
                    >
                      Wishlist
                    </p> */}

                  </div>
                  <div className='section'>
                    <p
                      className='brandon'
                      onClick={() => {
                        openUser(false)
                        history.push("/contact/")
                      }}
                    >
                      Contact Us
                    </p>
                    <p
                      className='brandon'
                      onClick={() => {
                        logout()
                      }}
                    >
                      Logout
                    </p>
                  </div>
                </div>
              ) : (
                <div className='user-popup-container d-block brandon'>
                  <div className='section'>
                    <h6 className='welcome-text brandon'>Welcome Guest</h6>
                    <p className='brandon pe-none'>
                      To access account and manage orders
                    </p>
                    <div
                      className='login-button brandon'
                      onClick={() => {
                        login(true)
                        openUser(false)
                      }}
                    >
                      Login / Signup
                    </div>
                  </div>
                  <div className='section'>
                    <p
                      className='brandon'
                      onClick={() => history.push("/contact/")}
                    >
                      Contact Us
                    </p>
                  </div>
                </div>
              )}
            </div>
            <Link onClick={(e) => { if(!localStorage.getItem("userdat")){e.preventDefault(); alert.show("Login to access wishlist")}  }} to='/account/wishlist/'>
              <img alt="icon" className='wishlist-icon' src="/assets/heart_gray.png" />
            </Link>
            {window.innerWidth < 576 ? (
              state.cart ? (
                <div className='cart-open'>
                  <img
                    className='cart-icon cursor'
                    onClick={() => history.push("/checkout/shopping-bag/")}
                    alt='icon'
                    src={close}
                  />
                </div>
              ) : (
                <div className='cart-open closed-one'>
                  {localStorage.getItem('checkout_data') ? (
                    count ? (
                      <p className='cart-count'>{count}</p>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                  <img
                    className='cart-icon cursor'
                    onClick={() => history.push("/checkout/shopping-bag/")}
                    alt='icon'
                    src="/assets/bag_light.png"
                  />
                </div>
              )
            ) : (
              <div className='cart-open'>
                {localStorage.getItem('checkout_data') ? (
                  count ? (
                    <p className='cart-count'>{count}</p>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                <img
                  onClick={() => history.push("/checkout/shopping-bag/")}
                  className='cursor'
                  alt='icon'
                  src="/assets/bag_light.png"
                />
              </div>
            )}
          </div>
        </div>
      </div >
      {
        state.cart ? (
          <div className='overlay'>
            <div className='close-cart-container' onClick={() => cartMenu(false)}>
              <div className='close-cart'>
                <img src='/assets/close-cart.png' />
              </div>
            </div>
            <div className='cart-menu'>
              <Carts sideMenu={cartMenu} />
            </div>
          </div>
        ) : (
          ''
        )
      }
      {
        menu ? (
          <div className='menu'>
            <div style={{ textIndent: '-9999px', height: '0px' }}>
              {(document.body.style.overflow = 'hidden')}
            </div>
            <div className='menu-list flex-wrap'>
              <Link
                to="/art-collection/"
                onClick={() => {
                  localStorage.setItem('artmode', 'shopart')
                  localStorage.setItem('flowtype', 'shopart')
                  sessionStorage.setItem("artFilters", `[]`)
                  cartMenu(false)
                  sideMenu(false)
                }}
              >
                Shop Art
              </Link>
              <Link
                to="/custom-framing/"
                onClick={() => {
                  cartMenu(false)
                  sideMenu(false)
                  localStorage.setItem('artmode', 'frame')
                  localStorage.setItem('flowtype', 'framing')
                }}
              >
                Start Framing
              </Link>
              <Link
                to="/gallery-walls/"
                onClick={() => {
                  cartMenu(false)
                  sideMenu(false)
                }}
              >
                Gallery Walls
              </Link>
              <Link
                to="/mixed-media/"
                onClick={() => {
                  cartMenu(false)
                  sideMenu(false)
                }}
              >
                Mixed Media Art
              </Link>
              <Link
                to="/decor-objects/"
                onClick={() => {
                  cartMenu(false)
                  sideMenu(false)
                }}
              >
                Decor Objects
              </Link>
              <Link
                to="/gifts/"
                onClick={() => {
                  cartMenu(false)
                  sideMenu(false)
                }}
              >
                Gifts
              </Link>
              {/* <Link to='/select/print' onClick={() => { cartMenu(false); sideMenu(false) }}>Start Printing</Link> */}
              {/* <Link to="/blog/" onClick={() => { cartMenu(false); sideMenu(false) }}>Blogs</Link> */}
              {localStorage.getItem('userdat') ? (
                <div className='login'>
                  <Link
                    onClick={() => {
                      cartMenu(false)
                      sideMenu(false)
                    }}
                    to='/account/'
                  >
                    User Profile
                  </Link>
                </div>
              ) : (
                <div className='login'>
                  <div
                    to='/login'
                    onClick={() => {
                      mobileuserpop()
                    }}
                  >
                    Login or Signup
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div style={{ textIndent: '-9999px', height: '0px' }}>
            {(document.body.style.overflow = 'auto')}
          </div>
        )
      }

      <Modal
        className='sign-modal'
        show={show}
        onHide={handleClose}
        centered
      // size="lg"
      >
        <ModalHeader className='d-flex ml-auto border-0'>
          <img src='/assets/modal-close.png' />
        </ModalHeader>
        <ModalBody className='d-flex flex-column'>
          <h2 className='GT text-center'>Sign in</h2>
          <input type='email' className='modal-mail' />
          <input type='password' className='modal-password' />
          <input type='radio' className='modal-remember' />
        </ModalBody>
      </Modal>
    </div >
    // </AppLoadContext>
  )
}

export default Header
