import React, { useContext, useState, useEffect, useRef } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import { Context as ArtContext } from '../../context/ArtContext';
import { Context as ProductContext } from '../../context/ProductContext';
import Footer from "../../components/Footer/footer"
import Header from "../../components/header/header"
import axios from "axios";
import { base_url } from "../../helpers/baseURL";
import Price from "../../helpers/price";
import parse from "html-react-parser";
import Loader from '../../components/checkout/loader';
import Popup from "reactjs-popup";
import { useAlert } from "react-alert";
import { MetaTags } from 'react-meta-tags';
import Fallback from "./giftingFallback";
import Share from "../../components/share/share";
import Tools from "../../components/tools/tools";


const GiftingDetails = () => {
    const { state, getSingleGifting, cartTrig } = useContext(ArtContext);
    let PContext = useContext(ProductContext)
    const [policy, setpolicy] = useState('')
    const [care, setCare] = useState("")
    const history = useHistory();
    const alert = useAlert()
    const upref = useRef()
    const [img, setImg] = useState("")
    const [img_name, setImgName] = useState("")
    const [pin, changepin] = useState('')
    const [selected, setselected] = useState(0)
    const [media, setMedia] = useState([])
    const [tab, settab] = useState("description")
    const [pinstatus, setpinstatus] = useState(false)
    const [zoom, openZoom] = useState(false)
    const [data, setData] = useState()
    const [add, openAdd] = useState(false)
    const [loader, setloader] = useState(false)
    const [included, setIncluded] = useState([])
    const { title } = useParams();
    const [listed, setListed] = useState()

    useEffect(() => {
        console.log("gift back", typeof localStorage.getItem("gift_back"))
        window.scrollTo(0, 0)
        if (localStorage.getItem("pincode")) {
            changepin(localStorage.getItem("pincode") ? localStorage.getItem("pincode") : "")
            checkpin(localStorage.getItem("pincode"))
        }
        PContext.getPolicy()
            .then(resp => {
                let data = resp?.data
                console.log("response of Return in gallery wall", data)
                setpolicy(data)
            })
            .catch(err => {
                console.error("error in Return gallery wall", err)
            })
        PContext.getCare()
            .then(resp => {
                let data = resp?.find(x => x.type === "gifts_care")?.data
                console.log("response of Care in gallery wall", data)
                setCare(data)
            })
            .catch(err => {
                console.error("error in Care gallery wall", err)
            })
        getSingleGifting(title)
            .then(details => {
                setData(details.data)
                let variation_id = details?.data?.relationships.default_variation.data ? details.included.find(x => x.id === details.data.relationships.default_variation.data.id)?.id : ""
                PContext.checkWish(variation_id)
                    .then(resp => {
                        setListed(resp)
                    })
                    .catch(err => {
                        console.error("error on check in wish", err)
                    })
                setIncluded(details.included)
                let media = details.data.relationships.field_gifting_image.data.length ? details.included.filter(x => x.id === details.data.relationships.field_gifting_image.data[0].id).length ? details.included.filter(x => x.id === details.data.relationships.field_gifting_image.data[0].id) : [] : []
                setMedia(media)
            })
            .catch(err => {
                console.error("error on gifting details", err)
                history.push("/404s")
            })
    }, [title])
    const checkAdd = () => {
        if (!pinstatus) {
            let element = document.getElementById('pincode-input');
            element.classList.add('error');
            element.focus()
            // if (pin.length == 0) {
            //     alert.show("Please enter a pincode to check delivery availability")
            // }
            // else if (pin.length < 6)
            //     alert.show("Invalid Pin")
            // else {
            //     alert.show("Sorry, the pincode is not serviceable")
            // }
        }
        else {
            if (data.attributes.field_gifting_type === "custom") {
                if (!img)
                    alert.show("Please upload an image")
                else {
                    upload_image()
                        .then(res => {
                            setCart(res)
                        })
                        .catch(err => {
                            alert.show("Error on image uploading,Try again")
                        })
                }
            }
            else {
                setCart()
            }
        }
    }
    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }
    const upload_image = () => new Promise((resolve, reject) => {
        setloader(true)
        axios.get(base_url + '/rest/session/token')
            .then(res => {
                let rest_token = res.data
                var file = dataURLtoFile(img, img_name);
                var data = file
                var config = {
                    method: 'post',
                    url: base_url + '/jsonapi/media/image/field_media_image',
                    headers: {
                        'Accept': 'application/vnd.api+json',
                        'Content-Type': 'application/octet-stream',
                        'X-CSRF-Token': rest_token,
                        'Content-Disposition': `file;filename="${img_name}"`,
                    },
                    data: data
                };

                axios(config)
                    .then(response => {
                        setloader(false)
                        console.log("gift uploaded image", response.data);
                        let image = { id: response.data.data.id, url: response.data.data.attributes.uri.url }
                        resolve(image)
                    })
                    .catch(function (error) {
                        setloader(false)
                        alert.show("Error on image uploading,Try again")
                        console.log("gift image upload", error);
                        reject(error)
                    });
            })
    })
    const setCart = (image) => {
        let gifting_data = {
            "gifting_varid": data.relationships.default_variation.data ? included.filter(x => x.id === data.relationships.default_variation.data.id).length ? included.filter(x => x.id === data.relationships.default_variation.data.id)[0].id : "" : "",
            "title": data.attributes.title,
            "item_name": data.attributes.title,
            "frame": data.attributes.field_frame,
            "dimension": data.attributes.field_layout_size,
            "item_id": data.relationships.default_variation.data ? included.filter(x => x.id === data.relationships.default_variation.data.id).length ? included.filter(x => x.id === data.relationships.default_variation.data.id)[0].id : "" : "",
            "total_price": data.relationships.default_variation.data ? included.filter(x => x.id === data.relationships.default_variation.data.id).length ? Number(included.filter(x => x.id === data.relationships.default_variation.data.id)[0].attributes.price.number) : "" : "",
            "gifting_id": data.id ? data.id : "",
            "image_id": image ? image.id : data.relationships.field_gifting_image?.data?.length ? data.relationships.field_gifting_image?.data[0].id : "",
            "image_url": image ? image.url : ""
        }
        console.log("")
        let checkoutdata = JSON.parse(localStorage.getItem("checkout_data"))
        let cartData = localStorage.getItem("cartData") ? JSON.parse(localStorage.getItem("cartData")) : []
        if (cartData) {
            let checkoutdata = localStorage.getItem("checkout_data") ? JSON.parse(localStorage.getItem("checkout_data")) : { total_no: 0 }
            if (Number(checkoutdata?.total_no) == 29) {
                alert.show("Maximum Cart Limit is 30")
                console.log("maximum achieved")
            }
            if (Number(checkoutdata.total_no) < 30) {
                if (cartData.findIndex(x => x.gifting_id == gifting_data.gifting_id && x.image_id === image) >= 0) {
                    let i = cartData.findIndex(x => x.gifting_id == gifting_data.gifting_id)
                    if (cartData[i].quantity)
                        cartData[i].quantity = Number(cartData[i].quantity) + 1
                    else
                        cartData[i].quantity = 2
                }
                else {
                    cartData.push(gifting_data)
                }
                localStorage.setItem("cartData", JSON.stringify(cartData));
                if (checkoutdata) {
                    checkoutdata.total_no = checkoutdata.total_no ? checkoutdata.total_no + 1 : 1
                    checkoutdata.total_price = checkoutdata.total_price ? checkoutdata.total_price : 0
                    checkoutdata.total_price = checkoutdata.total_price + gifting_data.total_price
                    localStorage.setItem("checkout_data", JSON.stringify(checkoutdata))
                    cartTrig(state.cartTrigger + 1)
                }

                else {
                    let checkoutdata = {
                        "total_no": 1,
                        "total_price": gifting_data.total_price
                    }
                    localStorage.setItem("checkout_data", JSON.stringify(checkoutdata))
                    cartTrig(state.cartTrigger + 1)
                }
                setloader(false)
                openAdd(true)
            }
            else
                alert.show("Maximum Cart limit reached")
        }
    }

    const checkpin = (code) => {
        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex

        if (code === '' || re.test(code)) {
            let element = document.getElementById("pincode-input")
            if (element)
                element.classList.remove("error")
            changepin(code)
        }
        if (code.length === 6) {
            axios({
                method: 'get',
                url: base_url + '/jsonapi/taxonomy_term/pincode?filter[productFilter][condition][path]=name&filter[productFilter][condition][value]=' + code
            })
                .then(res => {
                    if (res.data.data.length) {
                        setpinstatus(true)
                        localStorage.setItem("pincode", code)
                    }
                    else {
                        setpinstatus(false)
                    }
                })
                .catch(err => {
                    console.log(err.code)
                })
        }
        else {
            setpinstatus(false)
        }
    }
    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            console.log("image props", e.target.files)
            let og_image_name = e.target.files[0].name,
                size = e.target.files[0].size
            console.log('imgsize', e.target.files[0].size)
            reader.addEventListener('load', () => {
                //Initiate the JavaScript Image object.
                var image = new Image();
                console.log(reader.result)
                //Set the Base64 string return from FileReader as source.
                image.src = reader.result;
                setImg(reader.result);
                setImgName(og_image_name)
                //Validate the File Height and Width.
                image.onload = function () {
                    console.log('loading')
                    var height = this.naturalHeight;
                    var width = this.naturalWidth;
                    console.log("image",)
                };
            }
            );
            reader.readAsDataURL(e.target.files[0]);
            setImg(reader.result);
        }
    };
    const addWish = () => {
        let variation_id = data.relationships.default_variation.data?.id
        setloader(true)
        PContext.addToWish(variation_id)
            .then(res => {
                if (res === "item added") {
                    PContext.checkWish(variation_id)
                        .then(resp => {
                            setListed(resp)
                            setloader(false)
                            alert.show("Added to Wishlist", { type: "success" });
                        })
                        .catch(err => {
                            setloader(false)
                            console.error("error on check in wish", err)
                        })
                }
            })
            .catch(err => {
                if (err === "logged_out") {
                    alert.show("Login to add to wishlist")
                }
                console.log('error on adding to wishlist', err)
                setloader(false)
            })
    }
    const deleteWish = (id) => {
        setloader(true)
        PContext.deleteFromWish(id)
            .then(res => {
                if (res === "item deleted") {
                    let variation_id = data.relationships.default_variation.data ? included.filter(x => x.id === data.relationships.default_variation.data.id).length ? included.filter(x => x.id === data.relationships.default_variation.data.id)[0].id : "" : ""
                    PContext.checkWish(variation_id)
                        .then(resp => {
                            setListed(resp)
                            setloader(false)
                            alert.show("Removed from Wishlist", { type: "success" });
                        })
                        .catch(err => {
                            setloader(false)
                            console.error("error on check in wish", err)
                        })
                }
            })
            .catch(err => {
                setloader(false)
            })
    }
    return (
        <div className="giftingDetails-page">
            <Header />
            {data ? data.attributes.metatag_normalized ? data.attributes.metatag_normalized.length ?
                <MetaTags>
                    {data.attributes.metatag_normalized.map((item) => {
                        if (item.tag === "meta")
                            if (item.attributes.name === "title")
                                return (
                                    <title>{item.attributes.content}</title>
                                )
                            else
                                return <meta name={`${item.attributes.name}`} content={`${item.attributes.content}`} />
                        else if (item.tag === "link") {
                            if (item.attributes?.rel === "canonical")
                                return <link rel={`${item.attributes.rel}`} href={`${window.location.href}`} />
                            else
                                return <link rel={`${item.attributes.rel}`} href={`${item.attributes.href}`} />
                        }
                    })}
                    <meta property="og:type" content="og:product" />
                    <meta property="og:title" content={data ? data.attributes.title : ""} />
                    <meta property="og:image" content={data.relationships.field_gifting_image.data.length ? included.filter(item => item.id === data.relationships.field_gifting_image.data[0].id).length ? base_url + included.filter(item => item.id === data.relationships.field_gifting_image.data[0].id)[0].attributes.uri.url : "" : ""} />
                    <meta property="og:description" content={`Individual Frame Sizes: ${data.attributes.field_individual_frames ? data.attributes.field_individual_frames.value ? data.attributes.field_individual_frames.value.replace(/<\/?[^>]+(>|$)/g, "") : "" : ""}`} />
                    <meta property="og:url" content={`/gallery_walls/${title}`} />
                    <meta property="og:site_name" content="Espravo" />
                    <meta name="twitter:card" content="" />
                    <meta name="twitter:site" content="@twitterid" />
                    <meta name="twitter:title" content={data ? data.attributes.title : ""} />
                    <meta name="twitter:description" content={`Individual Frame Sizes: ${data.attributes.field_individual_frames ? data.attributes.field_individual_frames.value ? data.attributes.field_individual_frames.value.replace(/<\/?[^>]+(>|$)/g, "") : "" : ""}`} />
                    <meta name="twitter:image" content={data.relationships.field_gifting_image.data.length ? included.filter(item => item.id === data.relationships.field_gifting_image.data[0].id).length ? base_url + included.filter(item => item.id === data.relationships.field_gifting_image.data[0].id)[0].attributes.uri.url : "" : ""} />

                </MetaTags>
                : "" : '' : ""
            }
            {loader ? <Loader /> : ''}
            <div className="header-container pt-3 pb-5">
                <Link className="mb-1 selectBack brandon d-flex align-items-center" to={`/gifts/${localStorage.getItem("gift_back") ? localStorage.getItem("gift_back") !== "1" ? `${localStorage.getItem("gift_back")}/` : "" : ""}`}>
                    <img src="/assets/arrow-left.webp" className="selectBackIcon" alt="thumb"></img>
                    <p className='ml-2'>Back</p>
                </Link>
                <div className="col-12 px-0 mx-auto">
                    {data ? <div className="row GifitngData py-sm-5 py-3">
                        <div className="col-sm-6 pl-sm-0 col-12">
                            <div className="frame_prev_mob mob-only">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    {media.length ? <img className="gifting-img" src={media[0].attributes.image_style_uri.find(obj => obj.inner)?.inner} /> : <img className="gifting-img" src="/assets/meticulous.png" />}
                                    <Tools title={data.attributes.title} addWish={addWish} deleteWish={deleteWish} listed={listed} openZoom={openZoom} image={media.length ? base_url + media[0].attributes.uri.url : ""} />
                                </div>
                            </div>
                            <div className="select-carou desk-only row">
                                {/* <div className="col-sm-2 px-0 thumb-cont">
                                    {data.relationships.field_gifting_image.data ?
                                        data.relationships.field_gifting_image.data.map((item, ind) => {
                                            return (
                                                <div onClick={() => setselected(ind)} className={selected === ind && !state.zoom ? "thumb mb-2 active" : "thumb mb-2"}>
                                                    <img src="/assets/images/thumb2.png" alt="thumb mb-2" />
                                                </div>
                                            )
                                        })
                                        : ""}
                                </div> */}
                                <div className="col-12">
                                    <div className="imageContainer w-100">
                                        <div className="imageBox flex-column">
                                            {media.length ? <img className="gifting-img" src={media[0].attributes.image_style_uri.find(obj => obj.inner)?.inner} /> : <img className="gifting-img" src="/assets/meticulous.png" />}
                                            {/* <img className="gifting-img" src="/assets/gifting/banner.png" /> */}
                                            <Tools title={data.attributes.title} addWish={addWish} deleteWish={deleteWish} listed={listed} openZoom={openZoom} image={media.length ? base_url + media[0].attributes.uri.url : ""} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-2 mt-sm-0 col-12">
                            <div className="d-flex justify-content-between w-sm-80 w-100">
                                <h1 className="main-heading mt-3 mt-sm-0 ">
                                    {data.attributes.title ? data.attributes.title : ""}
                                </h1>
                            </div>
                            <div className="gift-details">
                                <div className="brandon mb-2 d-flex">
                                    <p className="title">Frame Size: </p>
                                    <p className="detail pl-sm-3 pl-2">{data.attributes.field_layout_size ? data.attributes.field_layout_size : ""}</p>
                                </div>
                                <div className="brandon mb-2 d-flex">
                                    <p className="title">Frame: </p>
                                    <p className="detail pl-sm-3 pl-2">{data.attributes.field_frame ? data.attributes.field_frame : ""}</p>
                                </div>
                                <input id="file-input" style={{ display: "none" }} ref={upref} type="file" accept="image/*" name='Change Photo' onChange={onSelectFile} />
                                {data.attributes.field_gifting_type ? data.attributes.field_gifting_type === "custom" ?
                                    <div>
                                        {img ? <div className="d-flex uploaded_div">
                                            <p className="brandon name_text">{img_name}</p>
                                            <img className="uploaded_sign" src="/assets/gifting/uploaded.png" />
                                            <p className="brandon cursor change_text" onClick={() => upref.current.click()}>change</p>
                                        </div> :
                                            <div className="d-flex upload_div" onClick={() => upref.current.click()}>
                                                <img className="upload_image" src="/assets/gifting/upload.png" />
                                                <p className="brandon upload_text">Upload Image</p>
                                            </div>
                                        }
                                    </div> : "" : ''}
                            </div>
                            <div className="input-pin">
                                {/* <p className="select-title">Select Location For Delivery</p> */}
                                <input id="pincode-input" value={pin} className="brandon pincode-input" onChange={(e) => checkpin(e.target.value)} placeholder="Enter Pincode For Delivery Availability" />
                            </div>
                            {
                                pin.length ? pin.length === 6 ?
                                    <p className={pinstatus && pin.length === 6 ? "green" : "red"}>{pinstatus && pin.length === 6 ? "Delivery in 7-10 days" : "Delivery Unavailable"}</p>
                                    : '' : ''
                            }
                            {data.relationships.default_variation.data ? <div className="price_cart d-flex w-sm-80 w-100">
                                <p className="frame-price add-price font-weight-bold brandon">
                                    {included.filter(x => x.id === data.relationships.default_variation.data.id).length ? <span>Rs. <Price value={included.filter(x => x.id === data.relationships.default_variation.data.id)[0].attributes.price.number} /></span> : ''}
                                </p>
                                <div onClick={() => checkAdd()} className="add-cart-button brandon">
                                    ADD TO CART
                                </div>
                            </div> : ""}
                            <div className="brandon desc-review-box w-sm-80 w-100">
                                <div className="d-flex tab">
                                    <p className="description pb-1" style={{ borderBottom: tab === "description" ? "1.5px solid #1D1F22" : "", color: tab === "description" ? "#1D1F22" : "#8D8F9A" }} onClick={() => settab("description")}>Description</p>
                                    {/* <p className="description ml-3 pb-1" style={{ borderBottom: tab === "review" ? "1.5px solid #1D1F22" : "", color: tab === "review" ? "#1D1F22" : "#8D8F9A" }} onClick={() => settab("review")}>Read reviews</p> */}
                                    <p className="description ml-3 pb-1" style={{ borderBottom: tab === "care" ? "1.5px solid #1D1F22" : "", color: tab === "care" ? "#1D1F22" : "#8D8F9A" }} onClick={() => settab("care")}>Care Instructions</p>
                                    <p className="description ml-3 pb-1" style={{ borderBottom: tab === "return" ? "1.5px solid #1D1F22" : "", color: tab === "return" ? "#1D1F22" : "#8D8F9A" }} onClick={() => settab("return")}>Return Policy</p>
                                </div>
                                <div className="pl-1 py-2">
                                    {tab == "description" ? <p>{data.attributes.body ? parse(data.attributes.body.value) : ""}</p> : ""}
                                    {tab === "review" ? <p>There are no review for this products</p> : ""}
                                    {tab === "care" ? <p>{parse(care.attributes.body ? care.attributes.body.value : "No care instruction for this product")}</p> : ""}
                                    {tab === "return" ? <p>{parse(policy.attributes.body ? policy.attributes.body.value : "No Return Policy for this product")}</p> : ""}
                                </div>
                            </div>
                        </div>
                    </div> :
                        <Fallback />
                    }
                </div>
                {zoom ? <div className='overlay zoom-image-overlay'></div> : ""}
                <Popup className="zoompopup" open={zoom} onClose={() => openZoom(false)}>
                    <div className="d-flex zoompopup-container flex-column ">
                        <img className="zoom-close cursor ml-auto mb-3 d-flex justify-content-end" role="button" src="/assets/contactclose.png" onClick={() => openZoom(false)} />
                        {data ? data.relationships.field_gifting_image.data.length ? included.filter(x => x.id === data.relationships.field_gifting_image.data[0].id).length ? <img className="gift-zoom-img zoom-img" src={base_url + included.filter(x => x.id === data.relationships.field_gifting_image.data[0].id)[0].attributes.uri.url} /> : ""
                            : "" : ""
                        }
                    </div>
                </Popup>
                <Popup open={add} onClose={() => openAdd(false)}>
                    <div className="alert-popup">
                        <img className="zoom-close cursor ml-auto d-flex justify-content-end" role="button" src="/assets/contactclose.png" onClick={() => openAdd(false)} />
                        <div className="GT alert-heading">
                            Added to cart
                        </div>
                        <p className="brandon alert-desc">
                            Product successfully added to your shopping cart.
                        </p>
                        <div className="d-flex">
                            <div className="alert-continue cursor py-2 brandon" onClick={() => openAdd(false)}>Continue shopping</div>
                            <div className="alert-proceed py-2 cursor brandon" onClick={() => { history.push('/checkout/shopping-bag/'); }}>Proceed to checkout</div>
                        </div>
                    </div>
                </Popup>
            </div>
            <Footer />
        </div>
    )
}
export default GiftingDetails