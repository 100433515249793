import React, { useEffect, useImperativeHandle } from 'react';

import { useTimer } from 'react-timer-hook';

const Timer = ({ expiryTimestamp, trigger, fireExpire }) => {
    useEffect(() => {
        if (trigger) {
            restart(expiryTimestamp, true);
            console.log("firing trigger")
        }
    }, [trigger])
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({ expiryTimestamp, onExpire: () => fireExpire(true) });


    return (
        <div className='text-center'>
            <p className="otp-time brandon"><span>{minutes}</span>:<span>{seconds}</span> Sec</p>
        </div>
    )
}
export default Timer;