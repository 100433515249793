import React, { useEffect, useState } from "react"
import Footer from "../components/Footer/footer";
import Header from "../components/header/header";
import cover from '../assets/eac-banner1.jpg'
import axios from "axios"
import parse from 'html-react-parser';
import { base_url } from "../helpers/baseURL"


const Stores = () => {
    const [page, setPage] = useState()
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get(base_url + "/jsonapi/node/page/912d5fcf-5f85-44e3-a29b-d91786dceaf1")
            .then(data => {
                console.log("basic page", data.data.data)
                setPage(data.data.data)
            })
    }, [])
    return (
        <div>
            <Header />
            <div id='stores' className="py-sm-5 my-sm-5 container">
                <div className="articledetails">
                    <h2 className="headmedium mb-2 secondarycolor">{page ? page.attributes.title ? page.attributes.title : "" : ""}</h2>
                    <div className="blog-img my-sm-5 my-2">
                        <img src={cover} alt="blog" />
                    </div>
                    <p style={{ color: "#404040" }} className="blog-detail">
                        {page ? page.attributes.body ? parse(page.attributes.body.value) : "" : ""}
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Stores;