import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Footer from '../../components/Footer/footer';
import Header from '../../components/header/header';
import tap from '../../assets/tapupload.png'
import { Context as ArtContext } from '../../context/ArtContext';
import './upload.css'
import { ScrollToTop } from '../../helpers/misc_func';
import { useAlert } from 'react-alert';
import TypeSelect from '../sizeSelect/typeSelect';
import { MetaTags } from 'react-meta-tags';


const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const UploadScreen = () => {
    const query = useQuery()
    const alert = useAlert()
    const { state, setUpImg, calculateDPI } = useContext(ArtContext);
    const [type, setType] = useState(true)
    React.useEffect(() => {
        localStorage.setItem("user_upload", true)
        ScrollToTop()
        if (query.get("id"))
            setType(false)
        else
            setType(true)
    }, []);

    const history = useHistory();

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            console.log("image props", e.target.files)
            let og_image_name = e.target.files[0].name,
                size = e.target.files[0].size
            console.log('imgsize', e.target.files[0].size)
            reader.addEventListener('load', () => {
                //Initiate the JavaScript Image object.
                var image = new Image();
                console.log(reader.result)
                //Set the Base64 string return from FileReader as source.
                image.src = reader.result;
                setUpImg(reader.result);
                //Validate the File Height and Width.
                image.onload = function () {
                    console.log('loading')
                    var height = this.naturalHeight;
                    var width = this.naturalWidth;
                    console.log("image",)
                    calculateDPI(height, width, "upload", history)
                        .catch(res => {
                            alert.show(res, { timeout: 0 })
                        })
                };
                localStorage.setItem("og_image_name", og_image_name);
            }
            );
            reader.readAsDataURL(e.target.files[0]);
            setUpImg(reader.result);
        }
    };

    return (

        type ? <div id="upload_select">
            <MetaTags>
                <title>The Best Online Store for Custom Photo Frames | Espravo</title>
                <meta name="keywords" content="custom photo frames, custom photo frames online," />
                <meta name="description" content="Online custom photo framing made easy with Espravo. Frame any image online from the comfort of your home with frames that are trend driven & timeless." />
                <link rel="canonical" href={`${window.location.origin}/custom-framing/`} />
            </MetaTags>
            <div className="my-0">
                <Header />
            </div>
            <div className="bgcolor-pages" onClick={() => alert.removeAll()}>
                <div className="container pt-5 pb-5">
                    <h2 className="main-heading mt-5 mb-3 text-center dark-main">
                        Upload a photo
                    </h2>
                    <div className="type_icons">
                        <div className="type_icon bgcolor-pages">
                            <div class="image-upload">
                                <label for="file-input">
                                    <img alt="icon" src={tap} />
                                </label>
                                <input id="file-input" type="file" accept="image/*" onChange={onSelectFile} />
                            </div>
                            <p className="brandon tap-upload">Tap to Upload</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div> :
            <TypeSelect />
    );
}

export default UploadScreen;
