import React, { useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Link, useHistory } from 'react-router-dom';
import { Context as ArtContext } from '../../context/ArtContext';
import { ValidateEmail } from '../../helpers/misc_func';
import Loader from '../checkout/loader';
import "./profile.css"

const PersonalData = () => {
    const { state, getUser, user_patch, getGuestOTP, getToken } = useContext(ArtContext);

    const alert = useAlert()
    const history = useHistory();

    const [email, setemail] = useState('')
    const [password, setpass] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [altphone, setAltPhone] = useState("")
    const [login, setLogin] = useState(true)
    const [loader, setloader] = useState(false)
    React.useEffect(() => {
        let userdata = JSON.parse(localStorage.getItem("userdat"))
        setName(userdata.details.attributes.field_full_name ? userdata.details.attributes.field_full_name : "")
        setemail(userdata.details.attributes.mail ? userdata.details.attributes.mail : "")
        setPhone(userdata.details.attributes.name ? userdata.details.attributes.name : "")
        setAltPhone(userdata.details.attributes.field_alternate_number ? userdata.details.attributes.field_alternate_number : "")
    }, []);
    const submitChange = () => {
        var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
        var mobileno = /^((\\+91-?)|0)?[0-9]{10}$/;
        if (ValidateEmail(email) && ((phone.match(phoneno)) || (phone.match(mobileno)))) {
            if (altphone !== "" && ((!altphone.match(phoneno)) || (!altphone.match(mobileno)))) {
                alert.show('Enter a valid alternaltive number', { type: "info" })
            }
            else {
                setloader(true)
                user_patch(email, name, phone, altphone)
                    .then(res => {
                        alert.show("Details updated successfully", { type: "success" })
                        console.log(res)
                        let userdat = localStorage.getItem("userdat") ? JSON.parse(localStorage.getItem("userdat")) : null
                        if (userdat) {
                            userdat.details = res.data
                            localStorage.setItem("userdat", JSON.stringify(userdat))
                        }
                        setName(res.data.attributes.field_full_name)
                        setemail(res.data.attributes.mail)
                        setPhone(res.data.attributes.name)
                        setAltPhone(res.data.attributes.field_alternate_number)
                        setloader(false)
                        relogin()
                    })
                    .catch(err => {
                        console.log("error on user patch", err.response.data.errors ? err.response.data.errors[0].detail : "")
                        let error = err.response.data.errors[0]
                        if (error.detail.includes("The username") && error.detail.includes("is already taken"))
                            alert.show("Mobile number is already taken")
                        else if (error.detail.includes("Users can only update their own account")) {
                            alert.show("Login Expired")
                            localStorage.removeItem('userdat');
                            window.location.href = "/"
                        }
                        else {
                            alert.show("Something went wrong")
                        }
                        setloader(false)
                    })
            }
        }
        else {
            if (!ValidateEmail(email))
                alert.show('Invalid Email', { type: "info" })
            if ((!phone.match(phoneno)) || (!phone.match(mobileno)))
                alert.show('Enter a valid Mobile Number', { type: "info" })
            // if (altphone && (!altphone.match(phoneno)) || (!altphone.match(mobileno)))
            //     alert.show('Enter a valid alternaltive number', { type: "info" })

        }
    }
    const relogin = () => {
        console.log("calling relogin")
        getGuestOTP(phone, "mobile")
            .then(res => {
                console.log("response in otp", JSON.parse(res))
                localStorage.setItem("email", JSON.parse(res).mobile)
                setloader(false)
                getToken(JSON.parse(res).mobile, JSON.parse(res).otp)
                    .then(res => {

                    })
                    .catch(err => {
                        console.log("error in token get", err)
                    })
            })
            .catch(err => {
                console.log("error on get otp", err)
            })
    }
    return (
        <div id="personal_data">
            {loader ? <Loader /> : ""}
            <h4 className='main-heading mx-auto text-center d-none d-sm-block'>
                My Account
            </h4>
            <div className='my-account-box mx-auto mt-3 mt-sm-0'>
                <h5 className='d-menu-heading GT-bold text-center'>Personal Information</h5>
                <form>
                    <label className='brandon'>Name*</label>
                    <input type="text" className='brandon' placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} ></input>

                    <label className='brandon'>Email*</label>
                    <input type="text" className='brandon' placeholder='Email' value={email} onChange={(e) => setemail(e.target.value)} ></input>

                    <label className='brandon'>Mobile Number*</label>
                    <input type="text" className='brandon' placeholder='Mobile Number' value={phone} onChange={(e) => setPhone(e.target.value)} ></input>

                    <label className='brandon'>Alternative Number</label>
                    <input type="text" className='brandon' placeholder='Alternative Number' value={altphone} onChange={(e) => setAltPhone(e.target.value)} ></input>

                    <input type={"button"} value="Save" onClick={() => submitChange()} className="change-details-btn brandon" />
                </form>
            </div>
        </div>
    );
}

export default PersonalData;
