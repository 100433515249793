



import HTMLReactParser from 'html-react-parser';
import React, { useContext, useEffect, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Context as ArtContext } from '../../context/ArtContext';


const FramePopup = (props) => {

    useEffect(() => {
        console.log('value', props.value)
        console.log("cartpreview", props.props)
        console.log(localStorage.getItem('ratio'))
        if (props.value) {
            if (props.place === "single_page" && localStorage.getItem("papertype") == "Paper") {
                if (props.framing.depth) {
                    console.log("working depth", props.framing.depth, "padding", state.padding, "framesize", props.framing.frame)
                    console.log("working final depth", props.framing.depth + 2 * state.padding + 2 * props.framing.frame)
                    let finaldepth = props.framing.depth + 2 * state.padding + 2 * props.framing.frame
                    let padding = (props.value / Number(finaldepth)) * state.padding
                    let framewidth = (props.value / Number(finaldepth)) * (props.framing.frame)
                    let width = (props.value / Number(finaldepth)) * props.framing.depth
                    console.log("working final calculated: depth", width, "or", "padding", padding, "framesize", framewidth)
                    if (width > 0) {
                        setwidth(width)
                        setheight((width * (1 / localStorage.getItem('ratio'))))
                        setpadding(padding)
                        setframewidth(framewidth)
                    }
                    else {
                        setwidth(50)
                        setheight(50 * (1 / localStorage.getItem('ratio')))
                    }
                    console.log("final padding and frame", padding, framewidth, "and width", (props.value - (2 * padding) - framewidth))
                }
            }
            else {
                setwidth(props.value)
                setheight(props.value * (1 / localStorage.getItem('ratio')))
            }
            console.log("height in popup", props.value * (1 / localStorage.getItem('ratio')))
            if (props.value == 40)
                setSidewidth("4px")
            else if (props.value == 80)
                setSidewidth("7px")
            else if (props.value == 150)
                setSidewidth("11px")
        }
        if (props.props) {
            if (props.props.artid) {
                setPicture(props.props.artid)
            }
            if (props.props.ratio) {
                setheight(props.value * (1 / Number(props.props.ratio)))
                console.log("ratio in props.props popup", props.value, props.value * (1 / props.props.ratio))
            }
            if (props.props.frameid) {
                setFrame(props.props.frameid.front)
                setSideFrame(props.props.frameid.side)
            }
            if (props.props.padding) {
                if (localStorage.getItem("papertype") == "Canvas") {
                    setpadding((props.value / props.props.width) * 0.26)
                }
                else
                    setpadding((props.value / props.props.width) * props.props.padding)
                console.log("padding", (props.value / props.props.width) * props.props.padding)
            }
            if (props.props.depth) {
                console.log("padding value", Number(props.props.depth), (props.value / state.ratio.width) * props.props.padding)
                if (localStorage.getItem("papertype") == "Canvas") {
                    setpadding((props.value / Number(props.props.depth)) * 0.26)
                }
                else {
                    if (props.props.padding || props.props.padding == 0)
                        setpadding((props.value / Number(props.props.depth)) * props.props.padding)
                    else
                        setpadding((props.value / Number(props.props.depth)) * state.padding)
                }
            }
        }
        if (props.framing) {
            if (props.framing.depth) {
                console.log("padding value", Number(props.framing.depth), (props.value / state.ratio.width) * state.padding)
                if (localStorage.getItem("papertype") == "Canvas") {
                    setpadding((props.value / Number(props.framing.depth)) * 0.26)
                }
                else {
                    if (props.place !== "single_page")
                        if (props.props) {
                            props.props.padding || props.props.padding == 0 ?
                                setpadding((props.value / Number(props.framing.depth)) * props.props.padding)
                                : setpadding((props.value / Number(props.framing.depth)) * state.padding)
                        }
                        else {
                            setpadding((props.value / Number(props.framing.depth)) * state.padding)
                        }
                }
            }
            if (props.framing.frame) {
                console.log("framewidth", props.framing.frame, (props.value / Number(props.framing.depth)) * props.framing.frame)
                setSidewidth(`${(props.value / Number(props.framing.depth)) * props.framing.frame}px`)
                if (props.place !== "single_page" || localStorage.getItem("papertype") == "Canvas")
                    setframewidth((props.value / Number(props.framing.depth)) * (props.framing.frame / 2))
            }
        }
    }, [props])
    const { state } = useContext(ArtContext);
    const [width, setwidth] = useState(200);
    const [padding, setpadding] = useState("");
    const [height, setheight] = useState();
    const [sidewidth, setSidewidth] = useState("28.6667px");
    // const [sideheight, setSideheight] = useState("387px");
    const [frame, setFrame] = useState(state.frontFrame);
    const [framewidth, setframewidth] = useState(21.5);
    const [picture, setPicture] = useState('');
    const [sideframe, setSideFrame] = useState(state.sideFrame);
    const [padColor, setPadColor] = useState("#fff");
    const [crop, setCrop] = useState({ aspect: 16 / 9 });

    const frameArray = [
        {
            frame: "https://d29mtkonxnc5fw.cloudfront.net/site_assets/natural-walnut-face2-darker.jpg",
            sideframe: "https://d29mtkonxnc5fw.cloudfront.net/site_assets/walnut-darker-side2.jpg",
            thumbnail: "https://d29mtkonxnc5fw.cloudfront.net/site_assets/swatches/natural-walnut2-swatch.jpg"
        },
        {
            frame: "https://d29mtkonxnc5fw.cloudfront.net/site_assets/milk-chocolate-face5.jpg",
            sideframe: "https://d29mtkonxnc5fw.cloudfront.net/site_assets/hazelnut-side.jpg",
            thumbnail: "https://d29mtkonxnc5fw.cloudfront.net/site_assets/swatches/hazelnut-swatch.jpg"
        }
    ]

    const padColorArray = [
        "#fff", "#333"
    ]


    const picArray = [
        "https://d29mtkonxnc5fw.cloudfront.net/images/H3ii92zzgHfYFpRYiWE3rw_cover.jpg",
        "https://d29mtkonxnc5fw.cloudfront.net/images/OMzO4BUbyvPzZ_49QYQBew_cover.jpg"
    ]
    React.useEffect(() => {
        console.log('final', state.cropImg)
        if (localStorage.getItem("user_upload")) {
            setPicture(localStorage.getItem('cropped'))
        }
        else {
            if (props.image) {
                setPicture(props.image)
            }
            else if (state.artData) {
                setPicture(state.artData.img)
                console.log('artData.img', state.artData.img)
            }
            else {
                setPicture(localStorage.getItem('showimage'))
            }
        }

    }, []);
    React.useEffect(() => {
        console.log("calculated height in popup", height)
        console.log("calculated padding in popup", padding)
    }, [height, padding])
    return (
        <div className="frame-edit-preview-edit">
            <div className="App">
                <div id="frame-container">
                    <div id="frame-3d" className="">
                        <div className="three_d_box_container three_d_box_container_10_1 panels-backface-invisible"
                            style={{ width: width + (2 * padding) + (2 * framewidth), height: height + (2 * padding) + (2 * framewidth), transition: `transform 1s ease 0s`, opacity: 1 }}
                        >
                            <figure className="three_dimensional_frame three_dimensional_frame_10_1 front"
                                style={{ borderImageSlice: 85, borderStyle: "solid", borderWidth: `${framewidth}px`, borderImageSource: `url(${frame})`, borderImageWidth: `${framewidth}px`, borderImageRepeat: 'stretch', width: width + (2 * padding), height: height + (2 * padding), background: "transparent", boxShadow: "rgba(0, 0, 0, 0.7) 0px 2px 5px inset", transform: 'translateZ(0px)' }}
                            >
                            </figure>

                            <figure className="front_mount three_d_face"
                                style={{ background: `url(${picture}) center ${padding}px / ${width}px no-repeat ${localStorage.getItem("papertype") == "Canvas" ? "#333333" : state.padColor}`, width: width + (2 * padding), height: height + (2 * padding), top: framewidth, left: framewidth, boxShadow: `${localStorage.getItem("papertype") == "Canvas" ? "#333333" : state.padColor} 0px 0px 0px inset, ${localStorage.getItem("papertype") == "Canvas" ? "#333333" : state.padColor} 0px 0px 0px ${padding}px inset` }}></figure>
                        </div>
                        {/* <img className='water-mark' src='/assets/watermark.png' /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FramePopup;