import axios from 'axios';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import starRatings from 'react-star-ratings/build/star-ratings';
import Review from '../../components/checkout/review';
import Footer from '../../components/Footer/footer';
import FramePreview from '../../components/frame/framePreview';
import Header from '../../components/header/header';
import { Context as ArtContext } from '../../context/ArtContext';
import { base_url } from '../../helpers/baseURL';
import Price from '../../helpers/price';
import { useAlert } from 'react-alert';
import './frameedit.css'
import { ScrollToTop } from '../../helpers/misc_func';
import Loader from '../../components/checkout/loader';
import { set } from 'react-ga';
import downarrow from "../../assets/Down.png"
import Popup from 'reactjs-popup';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}


const FrameEdit = (props) => {
    const alert = useAlert()
    const upref = useRef(0)
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const { onClickOutside } = props;
    const { state, setPadding, setRatio, getSingleFrame, setPadColor, cartTrig, setCropImg, calculatePrice, nullPrice, calculateDPI, setUpImg } = useContext(ArtContext);
    const { id } = useParams();
    const query = useQuery()
    let artData = ""
    const [loader, setloader] = useState(false)
    const [sizebox, openSizes] = useState(false)
    const [matts, openMatt] = useState(null)
    const history = useHistory();
    const [artdatas, setartdatas] = useState(JSON.parse(localStorage.getItem("artData")) ? { "height": JSON.parse(localStorage.getItem("artData")).height, "width": JSON.parse(localStorage.getItem("artData")).width }
        : "")
    const [pin, changepin] = useState('')
    const [pinstatus, setpinstatus] = useState(false)
    const [sqi, changesqi] = useState('')
    const [slidervalue, setslider] = useState("")
    const [thumb, setThumb] = useState("2")
    const [matting, setmatt] = useState(true)
    let availSizes = JSON.parse(localStorage.getItem("availSizes"))
    const [selected, setselected] = useState(state.ratio.text)
    const [finalsize, setfinal] = useState()
    const [framesize, setframe] = useState(0.8)
    const [options, setOptions] = useState([]);
    const [add, openAdd] = useState(false)


    const [artName, setArtName] = useState('');

    let arr = availSizes;




    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref1.current && !ref1.current.contains(event.target)) {
                onClickOutside && onClickOutside();
                openSizes(false)
            }
            if (ref2.current && !ref2.current.contains(event.target)) {
                onClickOutside && onClickOutside();
                openMatt(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);


    // const calculate = (item) => {
    //     console.log('size selected', item)
    //     let sqi = state.ratio.width * state.ratio.height
    //     let framesqi = (state.ratio.width + 3) * (state.ratio.height + 3)
    //     localStorage.getItem("artmode") === "frame" ? calculatePrice(framesqi, 'frame') : calculatePrice(sqi)
    // }
    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            console.log("image props", e.target.files)
            let og_image_name = e.target.files[0].name,
                size = e.target.files[0].size
            console.log('imgsize', e.target.files[0].size)
            reader.addEventListener('load', () => {
                //Initiate the JavaScript Image object.
                var image = new Image();
                console.log(reader.result)
                //Set the Base64 string return from FileReader as source.
                image.src = reader.result;
                setUpImg(reader.result);
                //Validate the File Height and Width.
                image.onload = function () {
                    console.log('loading')
                    var height = this.naturalHeight;
                    var width = this.naturalWidth;
                    console.log("image",)
                    calculateDPI(height, width, "upload", history)
                        .catch(res => {
                            alert.show(res, { timeout: 0 })
                        })
                };
                localStorage.setItem("og_image_name", og_image_name);
            }
            );
            reader.readAsDataURL(e.target.files[0]);
            setUpImg(reader.result);
        }
    };
    React.useEffect(() => {
        if (JSON.parse(localStorage.getItem("artData")).papertype == "Paper") {
            if (artdatas.width > artdatas.height) {
                console.log("")
                if (artdatas.width > 52 || artdatas.height > 32) {
                    setPadding(Number("0"))
                    setslider("0")
                    setmatt(false)
                }
                else {
                    setmatt(true)
                    goldenratio("start")
                }
            }
            else {
                if (artdatas.width > 32 || artdatas.height > 52) {
                    setPadding(Number("0"))
                    setslider("0")
                    setmatt(false)
                }
                else {
                    setmatt(true)
                    goldenratio("start")
                }
            }
        }
        else {
            setPadding(Number("0"))
            setslider("0")
            setmatt(false)
        }
    }, [selected])
    console.log("matbox", matts)
    React.useEffect(() => {
        if (localStorage.getItem("pincode")) {
            changepin(localStorage.getItem("pincode") ? localStorage.getItem("pincode") : "")
            checkpin(localStorage.getItem("pincode"))
        }
        setPadColor("#fefcf8")
        console.log("state", state)
        console.log("cropped image", localStorage.getItem("cropped"), "url", localStorage.getItem("cropped") ? localStorage.getItem("cropped").startsWith("http") : "")
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
        if (state.artData) {
            setCropImg(artData.img)
            console.log('art in frame', artData.img)
        }

        getSingleFrame(id, "Small")
        ScrollToTop()
        // console.log("frr",JSON.parse(localStorage.getItem("artData")))
        console.log("artmode", localStorage.getItem("artmode"))
        artData = localStorage.getItem("artData")
        console.log("papertype", JSON.parse(localStorage.getItem("artData")).papertype)

        var temp = JSON.parse(localStorage.getItem("artData")).art_title;
        var temp1 = temp.substr(0, temp.indexOf('-'));
        console.log("artName", temp1)
        setArtName(temp1)

        setartdatas(JSON.parse(localStorage.getItem("artData")))
        setselected(JSON.parse(localStorage.getItem("artData")).framesize)
        setRatio(JSON.parse(localStorage.getItem("artData")).framesize)
    }, []);
    React.useEffect(() => {
        if (JSON.parse(localStorage.getItem("artData")).papertype == "Canvas") {
            if (artdatas) {
                setfinal((Number(artdatas.width) + 2 * Number(slidervalue) + 0.80).toFixed(2) + `"` + " X " + (Number(artdatas.height) + (2 * Number(slidervalue)) + 0.80).toFixed(2) + `"`)
                setframe(0.8)
            }
        }
        else {
            if (artdatas) {
                if (artdatas.width > artdatas.height) {
                    if (artdatas.width > artdatas.height) {
                        if (artdatas.width < 24) {
                            setfinal((Number(artdatas.width) + 2 * Number(slidervalue) + 1.60).toFixed(2) + `"` + " X " + ((Number(artdatas.height) + (2 * Number(slidervalue))) + 1.60).toFixed(2) + `"`)
                            setframe(1.6)
                        }
                        else {
                            setfinal((Number(artdatas.width) + 2 * Number(slidervalue) + 2.10).toFixed(2) + `"` + " X " + (Number(artdatas.height) + (2 * Number(slidervalue)) + 2.10).toFixed(2) + `"`)
                            setframe(2.1)
                        }
                    }
                }
                else {

                    if (artdatas.height < 24) {
                        setfinal((Number(artdatas.width) + 2 * Number(slidervalue) + 1.60).toFixed(2) + `"` + " X " + (Number(artdatas.height) + (2 * Number(slidervalue)) + 1.60).toFixed(2) + `"`)
                        setframe(1.6)
                    }
                    else {
                        setfinal((Number(artdatas.width) + 2 * Number(slidervalue) + 2.10).toFixed(2) + `"` + " X " + (Number(artdatas.height) + (2 * Number(slidervalue)) + 2.10).toFixed(2) + `"`)
                        setframe(2.1)
                    }
                }
            }
        }
    }, [slidervalue, selected])
    React.useEffect(() => {
        if (artdatas && framesize && slidervalue) {
            nullPrice(0)
            console.log("calculating price", artdatas.height, artdatas.width, slidervalue, framesize)

            let sqi = Number(Number(artdatas.height) + (2 * Number(slidervalue)) + framesize).toFixed(2) * Number(Number(artdatas.width) + (2 * Number(slidervalue)) + framesize).toFixed(2)

            if (sqi) {
                changesqi(sqi)
                console.log("sqi", sqi)
                calculatePrice(sqi, 'frame')
            }
        }

    }, [slidervalue, selected]);
    const datasmall = ["/assets/images/Rectangle 19.png", "/assets/images/Rectangle 20.png", "/assets/images/Rectangle 12.png", "/assets/images/Rectangle 17.png", "/assets/images/Rectangle 16.png", "/assets/images/Rectangle 15.png", "/assets/images/Rectangle 14.png", "/assets/images/Rectangle 13.png", "/assets/images/Rectangle 12.png"]
    const setSliderValue = (value) => {
        console.log('slider', value)
        setThumb(value)
        if (value == 1) {
            setslider("0")
            setPadding(Number("0"))
        }
        else if (value == 2) {
            setslider("1.5")
            setPadding(Number("1.5"))
        }
        else if (value == 3) {
            setslider("2")
            setPadding(Number("2"))
        }
        else if (value == 4) {
            setslider("2.5")
            setPadding(Number("2.5"))
        }
        else if (value == 5) {
            setslider("3")
            setPadding(Number("3"))
        }
        else if (value == 6) {
            setslider("3.5")
            setPadding(Number("3.5"))
        }
        else if (value == 7) {
            setslider("4")
            setPadding(Number("4"))
        }
    }
    // useEffect(() => {
    //     setPadding(Number(slidervalue))
    // }, [state.Carousel])
    console.log('paading', state.Carousel)
    const frameList = () => {
        return (
            state.frameList ? state.frameList.data.length ?
                <div id='blog' className="container">
                    {/* <input type="file" onChange={(e) => uploadimg(e)} /> */}
                    <h2 className="headmedium mb-2 secondarycolor">
                        We Also Recommend
                    </h2>
                    <div className="frame-container">
                        <div className="frame-list">
                            {state.frameList ? state.frameList.data.length ? state.frameList.data.slice(0, 5).map((frame, ind) => {
                                // if (frame.id !== id)
                                return (
                                    <Link to={`/frame-edit/` + frame.id} className="frame-ind mb-4 col-12 col-sm-3">
                                        <div className="frame-item">
                                            {frame.relationships.field_thumbnail.data ?
                                                <img alt="icon" src={base_url + state.frameList.included.filter(item => item.id === frame.relationships.field_thumbnail.data.id)[0].attributes.uri.url} /> :
                                                <img alt="icon" src="assets/images/dummy.jpeg" />
                                            }
                                            <div className="name-price d-flex justify-content-between">
                                                <p className="name">
                                                    {frame.attributes.title}
                                                </p>
                                                {/* <p className="price">
                                                &#state.Carousel377; 2000
                                            </p> */}
                                            </div>
                                        </div>
                                    </Link>
                                )
                            }) : '' : ''}
                        </div>
                    </div>
                </div> : '' : ''
        )
    }
    const framethumbs = () => {
        return (
            <div className="frame-thumbs d-flex">
                {datasmall.map((thumb, ind) => {
                    return (
                        <div className="thumb-select">
                            <img src={thumb} alt="thumb" />
                        </div>
                    )
                })}
            </div>
        )
    }

    const checkAdd = () => {
        if (!pinstatus) {
            let element = document.getElementById('pincode-input');
            element.classList.add('error');
            element.focus()
            // if (pin.length == 0) {
            //     alert.show("Please enter a pincode to check delivery availability")
            // }
            // else if (pin.length < 6)
            //     alert.show("Invalid Pin")
            // else {
            //     alert.show("Sorry, the pincode is not serviceable")
            // }
        }
        else
            setData2()
    }

    const checkpin = (code) => {
        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex

        if (code === '' || re.test(code)) {
            let element = document.getElementById("pincode-input")
            if (element)
                element.classList.remove("error")
            changepin(code)
        }
        if (code.length === 6) {
            axios({
                method: 'get',
                url: base_url + '/jsonapi/taxonomy_term/pincode?filter[productFilter][condition][path]=name&filter[productFilter][condition][value]=' + code
            })
                .then(res => {
                    if (res.data.data.length) {
                        setpinstatus(true)
                        localStorage.setItem("pincode", code)
                    }
                    else {
                        setpinstatus(false)
                    }
                })
                .catch(err => {
                    console.log(err.code)
                })
        }
        else {
            setpinstatus(false)
        }
    }
    const commaCreator = (num) => {
        var x = Math.round(num);
        x = x.toString();
        var lastThree = x.substring(x.length - 3);
        var otherNumbers = x.substring(0, x.length - 3);
        if (otherNumbers != '')
            lastThree = ',' + lastThree;
        var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
        return res
    }
    const goldenratio = (message) => {
        let area = Number(artdatas.height) * Number(artdatas.width)
        console.log('sizes', artdatas.height, 'X', artdatas.width)
        console.log('area', area)
        let goldenarea = Number(area) * 1.618
        console.log('golden area', goldenarea)
        let sqrt = Math.sqrt(goldenarea)
        console.log('sqrt', sqrt)
        let moundborderwidth = sqrt - Number(artdatas.width)
        let moundborderheight = sqrt - Number(artdatas.height)
        let moundborder = (moundborderwidth + moundborderheight) / 4
        console.log(moundborderwidth, moundborderheight, moundborder)
        // console.log(Math.round(1.6 / 0.5) * 0.5)
        if (moundborder <= 1.5) {
            setslider("1.5")
            setPadding(Number("1.5"))
            setThumb('2')
            setOptions([
                { "name": "Without Mat", "value": "0" },
                { "name": "With Mat", "value": "1.5" },
                // { "name": "2", "value": "2" },
                // { "name": "2.5", "value": "2.5" },
                // { "name": "3", "value": "3" },
                // { "name": "3.5", "value": "3.5" },
                // { "name": "4", "value": "4" }
            ])
        }
        if (moundborder > 1.5 && moundborder <= 2) {
            setslider("2")
            setThumb('3')
            setPadding(Number("2"))
            setOptions([
                { "name": "Without Mat", "value": "0" },
                { "name": "With Mat", "value": "2" },
                // { "name": "2.5", "value": "2.5" },
                // { "name": "3", "value": "3" },
                // { "name": "3.5", "value": "3.5" },
                // { "name": "4", "value": "4" }
            ])
        }
        if (moundborder > 2 && moundborder <= 2.5) {
            setslider("2.5")
            setThumb('4')
            setPadding(Number("2.5"))
            setOptions([
                { "name": "Without Mat", "value": "0" },
                { "name": "With Mat", "value": "2.5" },
                // { "name": "3", "value": "3" },
                // { "name": "3.5", "value": "3.5" },
                // { "name": "4", "value": "4" }
            ])
        }
        if (moundborder > 2.5 && moundborder <= 3) {
            setslider("3")
            setThumb('5')
            setPadding(Number("3"))
            setOptions([
                { "name": "Without Mat", "value": "0" },
                { "name": "With Mat", "value": "3" },
                // { "name": "3.5", "value": "3.5" },
                // { "name": "4", "value": "4" }
            ])
        }
        if (moundborder > 3 && moundborder <= 3.5) {
            setslider("3.5")
            setThumb('6')
            setPadding(Number("3.5"))
            setOptions([
                { "name": "Without Mat", "value": "0" },
                { "name": "With Mat", "value": "3.5" },
                // { "name": "4", "value": "4" }
            ])
        }
        if (moundborder > 3.5) {
            setslider("4")
            setThumb('7')
            setPadding(Number("4"))
            setOptions([
                { "name": "Without Mat", "value": "0" },
                { "name": "With Mat", "value": "4" }
            ])
        }
        if (message != "start")
            alert.show("Golden ratio applied")
    }
    const setpadding = (value) => {
        setPadding(Number(value))
        setslider(`${value}`)
    }
    const setData2 = async () => {
        if (state.price) {
            setloader(true)
            console.log('artorupload', state.printVarData)
            console.log(localStorage.getItem("croppedid"), "uppimg", localStorage.getItem("uploadid"))
            let artData = JSON.parse(localStorage.getItem("artData"))
            artData.field_print_or_frame = "frame"
            artData.matting = slidervalue
            artData.height = state.ratio.height
            artData.width = state.ratio.width
            artData.pincode = pin
            artData.padcolor = state.padColor
            artData.varid = state.frameVarData.id
            artData.title = state.framedata.attributes ? state.framedata.attributes.title : ""
            artData.item_name = localStorage.getItem('user_upload') ? `Custom Framing ${JSON.parse(localStorage.getItem("artData")).papertype}` : `Framed Print on ${JSON.parse(localStorage.getItem("artData")).papertype} - ${JSON.parse(localStorage.getItem("artData")).art_title}`
            artData.item_id = localStorage.getItem('user_upload') ? localStorage.getItem("croppedid") : artData.art_id
            artData.cropped_image = localStorage.getItem('user_upload') ? localStorage.getItem("croppedid") : null
            artData.og_image = localStorage.getItem('user_upload') ? localStorage.getItem("uploadid") : null
            artData.qty = Math.ceil(sqi)
            artData.price = state.price
            artData.total_price = state.price
            artData.scales = localStorage.getItem('user_upload') ? JSON.parse(localStorage.getItem('scales')) : null
            if (artData) {
                artData.framesize = finalsize
                artData.height = Number(artdatas.height).toFixed(2)
                artData.width = Number(artdatas.width).toFixed(2)
                artData.dimension = `${(Number(artdatas.width).toFixed(2))}" X ${(Number(artdatas.height).toFixed(2))}"`
                console.log('artData after frame edit', artData)
                let cartData = localStorage.getItem("cartData") ? JSON.parse(localStorage.getItem("cartData")) : []
                setloader(false)
                // cart should'nt exceed 30 element
                if (cartData.length == 29) {
                    alert.show("Maximum Cart Limit is 30") //message to show you cant add next elemnt
                    console.log("maximum achieved")
                }
                if (cartData.length < 30) {
                    cartData.push(artData)
                    localStorage.setItem("cartData", JSON.stringify(cartData));
                    let checkoutdata = JSON.parse(localStorage.getItem("checkout_data"))
                    // let checkoutdata = localStorage.getItem("checkout_data") ? JSON.parse(localStorage.getItem("checkout_data")) : ""
                    if (checkoutdata) {
                        checkoutdata.total_no = checkoutdata.total_no ? checkoutdata.total_no+1 : 1
                        checkoutdata.total_price = checkoutdata.total_price ? checkoutdata.total_price : 0
                        checkoutdata.total_price = checkoutdata.total_price + artData.total_price
                        localStorage.setItem("checkout_data", JSON.stringify(checkoutdata))
                        cartTrig(state.cartTrigger + 1)
                    }

                    else {
                        let checkoutdata = {
                            "total_no": 1,
                            "total_price": artData.total_price
                        }
                        localStorage.setItem("checkout_data", JSON.stringify(checkoutdata))
                        cartTrig(state.cartTrigger + 1)
                    }
                    setloader(false)
                    openAdd(true)
                }
                else
                    alert.show("Maximum Cart limit reached")
            }
            console.log('addcart artdata', artData)
        }
    }


    const setFrame = (item) => {
        let rat = item.split("x")
        let load = { width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }
        var artData = JSON.parse(localStorage.getItem("artData"))
        artData.height = load.height
        artData.width = load.width
        artData.framesize = item;
        localStorage.setItem("artData", JSON.stringify(artData));
        setartdatas(JSON.parse(localStorage.getItem("artData")))
        console.log("test" + artdatas.width)
    }



    return (
        <div id="frame_edit">
            <Header />
            {loader ? <Loader /> : ''}
            <div className='header-container'>
                <Link className="mt-4 mb-1 selectBack brandon d-flex align-items-center" to={`/frame/${query.get("id")}?type=${query.get("type")}`}>
                    <img src="/assets/arrow-left.webp" className="selectBackIcon" alt="thumb"></img>
                    <p className='ml-2'>Back</p>
                </Link>
            </div>
            {state.framedata && state.frameVarData ?
                <div className="frame_edit_container">
                    <div className="frame-thumb-container container">
                        {/* {framethumbs()} */}
                    </div>
                    {/* <Link className="see-all-frames d-flex container" to="/frame">
                        <img src="/assets/arrow-left.webp" alt="thumb" />
                        <p>See All Frames</p>
                    </Link> */}
                    <div className="container d-flex flex-wrap">
                        <div className="col-sm-8 detailed-frame frame-edit-carousel col-12">
                            {state.frontFrame && state.sideFrame ?
                                <FramePreview width={{ "art": artdatas.width, "frame": framesize }} />
                                : ''}
                        </div>
                        <div className="col-sm-4 col-12 frame-controls">
                            <div className="frame-header d-flex flex-wrap">
                                <div className="main-heading fnt-24 GT-bold px-0 col-12">
                                    {!localStorage.getItem("user_upload") ? localStorage.getItem("artData") ? JSON.parse(localStorage.getItem("artData")).art_title : '' : ""}
                                    {localStorage.getItem("user_upload") ? state.framedata.attributes ? state.framedata.attributes.title : '' : ""}
                                    {/* <p>Clean black gallery frame</p> */}
                                </div>
                                {/* <div className="review-stars col-12">
                                    <div className='d-flex'>
                                        <div className='star-rating'>
                                            <div className='row star-div'>
                                                <div className='starring'>
                                                    <starRatings
                                                        starDimension="20px"
                                                        starSpacing="0px"
                                                        rating={4}
                                                        starRatedColor="black"
                                                        numberOfStars={5}
                                                        name='rating'
                                                    /></div><p className="rating-count">(state.Carousel)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <p className='brandon'>
                                {!localStorage.getItem("user_upload") ? localStorage.getItem("artData") ? JSON.parse(localStorage.getItem("artData")).artist.replace(",", " ") : '' : ""}
                            </p>

                            <div className="art-type-right mb-4">
                                {localStorage.getItem("user_upload") ? "" :
                                    <p className="brandon text-capitalize mb-2 mb-sm-3 art-type">
                                        {state.framedata.attributes ? `Framed On ${state.framedata.attributes.title}` : ''}
                                    </p>
                                }
                                {/* <div className="d-flex art-type-bottom mb-1 mb-sm-2 brandon">
                                    <input id="file-input" style={{ display: "none" }} ref={upref} type="file" accept="image/*" name='Change Photo' onChange={onSelectFile} />
                                    {localStorage.getItem("user_upload") ? <p className="pr-3" onClick={() => upref.current.click()}>Change</p> : <Link className="pr-3" to="/art/">Change</Link>}
                                    {localStorage.getItem("user_upload") ? <Link className="pr-3" to='/crop' > Crop</Link> : ''}
                                    {localStorage.getItem("flowtype") == "framing" ? <Link className="pr-3" to={localStorage.getItem("flowtype") == "framing" ? "/custom-framing/" : "/art/"}>Remove</Link> : ""}
                                </div> */}
                            </div>
                            <div className="position-relative bgcolor-box mb-3 px-0 py-2 col-12 col-sm-9" ref={ref1} >
                                <div onClick={() => { openSizes(true) }}>
                                    <p className="brandon px-3">
                                        Image Size
                                    </p>
                                    <img className="down-arrow mr-3" src={downarrow} />
                                    {!sizebox ? <p className="brandon px-3">
                                        {(Number(artdatas.width).toFixed(2)) + '" X ' + (Number(artdatas.height).toFixed(2)) + '"'}
                                    </p> : ""}
                                </div>
                                {sizebox ? <div className="drop-box first-drop-box ml-0 px-0 col-12 col-sm-12" style={{ display: sizebox ? "" : "none" }} onClick={() => openSizes(false)}>
                                    {sizebox ?
                                        arr.map((item, ind) => {

                                            console.log("selected and list", selected, item)
                                            if (item) {
                                                let rat = item.split("x")
                                                console.log(rat)
                                                let load = { width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }
                                                return (
                                                    <p className="brandon p-2" onClick={() => { openSizes(false); setRatio(item); setFrame(item); setselected(item); }} style={{ color: selected !== item ? "" : "grey" }}>
                                                        {(load.width).toFixed(2) + " X " + (load.height).toFixed(2)}
                                                    </p>
                                                )
                                            }

                                        })
                                        : ""}
                                </div> : ""}
                            </div>
                            {/* <div className="type-select">
                                <p className="select-title">Dimensions</p>
                                <select><option>Mat Style</option></select>
                            </div> */}
                            {/* {artdatas ? artdatas.framesize ?
                                <div className="bgcolor-box mb-3 py-2 col-12 col-sm-9">
                                    <p className="">
                                        Image Size
                                    </p>
                                    <select className="sizesList col-12 px-0 bgcolor-box" value={selected} defaultChecked={`${(Number(artdatas.width))} x ${(Number(artdatas.height))}`} defaultValue={`${(Number(artdatas.width))} x ${(Number(artdatas.height))}`} onChange={(e) => { setRatio(e.target.value); setFrame(e.target.value); setselected(e.target.value) }}>
                                        <option value={artdatas.framesize} style={{ color: "grey", display: "none" }}>
                                            {(Number(artdatas.width).toFixed(2)) + " X " + (Number(artdatas.height).toFixed(2))}
                                        </option>
                                        {
                                            arr.map((item, ind) => {

                                                console.log("selected and list", selected, item)
                                                if (item) {
                                                    let rat = item.split("x")
                                                    console.log(rat)
                                                    let load = { width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }
                                                    return (
                                                        <option key={load.width} value={item} style={{ color: selected !== item ? "" : "grey" }}>
                                                            {(load.width).toFixed(2) + " X " + (load.height).toFixed(2)}
                                                        </option>
                                                    )
                                                }

                                            })
                                        }
                                    </select>
                                </div>

                                : '' : ""} */}
                            {/* {matting ? <div className="type-select">
                                <p className="select-title">Mat Style</p>
                                <select value={state.padcolor} onChange={(e) => setPadColor(e.target.value)}>
                                    <option value="white">White</option>
                                    <option value="black">Black</option>
                                </select>
                            </div> : <div style={{ height: "30px" }} />} */}
                            {matting ?
                                <div className="position-relative bgcolor-box py-2 col-12 col-sm-9" ref={ref2} style={{ marginBottom: matts ? "" : "1rem" }}>
                                    <div onClick={() => openMatt(true)}>
                                        <p className="brandon">Mat Style</p>
                                        <img className="down-arrow" src={downarrow} />
                                        <p className="brandon" style={{ display: matts ? "none" : "" }}>{slidervalue == "0" ? "Without Mat" : 'With Mat'}</p>
                                    </div>
                                    {/* <input type="range" defaultChecked='1' step='1' onChange={(e) => setSliderValue(e.target.value)} min="1" max="7" class="slider" id="myRange" value={thumb} />
                                <p className="padtxt">{slidervalue}"</p> */}
                                    {/* <button onClick={() => goldenratio()} className="bgsecondary golden-ratio whitecolor ml-5">Apply Golden Ratio</button> */}
                                    {matts ? <div className="drop-box first-drop-box ml-0 px-0 mb-3 col-12" style={{ display: matts ? "" : "none", marginTop: "" }}>
                                        {options ? options.map((data, ind) => {
                                            return <p className="brandon p-2" onClick={() => { setpadding(`${data.value}`); openMatt(false) }} style={{ color: slidervalue !== data.value ? "" : "grey" }}>{data.name}</p>

                                        }) :
                                            <p className="brandon p-2" value={0} onClick={() => { setpadding(`0`); openMatt(false) }}>{"0"}</p>
                                        }
                                    </div> : ""}
                                </div> : ""}
                            {artdatas ?
                                !matting ?
                                    <div className="bgcolor-box mb-3 py-2 col-12 col-sm-9">
                                        <p className="brandon">
                                            Frame Size
                                        </p>
                                        <p className="brandon">
                                            {
                                                (Number(artdatas.width) + framesize).toFixed(2) + `"` + " X " + (Number(artdatas.height) + framesize).toFixed(2) + `"`
                                            }
                                        </p>
                                    </div>
                                    :
                                    <div className="bgcolor-box mb-3 py-2 col-12 col-sm-9">
                                        <p className="brandon">Frame Size</p>
                                        <p className="brandon"> {finalsize}</p>
                                    </div>

                                : ''}
                            <div className="input-pin">
                                {/* <p className="select-title">Select Location For Delivery</p> */}
                                <input id="pincode-input" value={pin} className="brandon black-main pincode-input" onChange={(e) => checkpin(e.target.value)} placeholder="Enter Pincode For Delivery Availability" />
                            </div>
                            {
                                pin.length ? pin.length === 6 ?
                                    <p className={pinstatus && pin.length === 6 ? "green" : "red"}>{pinstatus && pin.length === 6 ? "Delivery in 7-10 days" : "Delivery Unavailable"}</p>
                                    : '' : ''
                            }
                            <div className="price_cart d-flex">
                                <p className="frame-price add-price font-weight-bold brandon">
                                    {/* {JSON.parse(localStorage.getItem("artData")).art_price ?
                                        "₹" + new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format((Number(state.frameVarData.attributes.price.number) * Math.ceil(sqi)) + Number(JSON.parse(localStorage.getItem("artData")).art_price))
                                        : "₹" + new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format((Number(state.frameVarData.attributes.price.number) * Math.ceil(sqi)))
                                    } */}
                                    {/* {state.price ? "₹" + (commaCreator(state.price)) : ''} */}
                                    {state.price ? <span>Rs. <Price value={state.price} /></span> : ''}
                                </p>
                                <div onClick={() => checkAdd()} className="bgsecondary add-cart-button brandon whitecolor">
                                    ADD TO CART
                                </div>
                            </div>
                            <div className='pt-2 pt-sm-3'>
                                <Review data={state.frameComments} />
                            </div>
                        </div>
                    </div>
                </div>
                : null}
            {/* {frameList()} */}
            <Popup open={add} onClose={() => openAdd(false)}>
                <div className="alert-popup">
                    <img className="zoom-close cursor ml-auto d-flex justify-content-end" role="button" src="/assets/contactclose.png" onClick={() => openAdd(false)} />
                    <div className="GT alert-heading">
                        Added to cart
                    </div>
                    <p className="brandon alert-desc">
                        Product successfully added to your shopping cart.
                    </p>
                    <div className="d-flex">
                        <div className="alert-continue cursor py-2 brandon" onClick={() => openAdd(false)}>Continue shopping</div>
                        <div className="alert-proceed py-2 cursor brandon" onClick={() => { history.push('/checkout/shopping-bag/'); }}>Proceed to checkout</div>
                    </div>
                </div>
            </Popup>
            <Footer />
        </div >
    );
}

export default FrameEdit;
