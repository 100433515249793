import createDataContext from './createDataContext';
import { TokenCheck } from '../network/tokenChecker';

let token=true

const backendReducer = (state = {}, action) => {
    switch (action.type) {
        case 'set_login':
            return {
                ...state, login_state: action.payload
            };
        default:
            return state;
    }
};

const log_out = dispatch => async () => {
    return new Promise((resolve, reject) => {
    localStorage.removeItem('starter_data')
    TokenCheck(token)
        .then(resp => {
            if (resp)
                dispatch({ type: 'set_login', payload: resp });
            else
                dispatch({ type: 'set_login', payload: false });
        })
        resolve("success")
    })
};


const login_state = dispatch => async () => {
    TokenCheck(token)
        .then(resp => {
            if (resp)
                dispatch({ type: 'set_login', payload: resp });
            else
                dispatch({ type: 'set_login', payload: false });
        })
};

export const { Provider, Context } = createDataContext(
    backendReducer,
    { login_state,log_out },
    {
        login_state:null
    }
);