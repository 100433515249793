import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Link, useHistory } from 'react-router-dom';
import { Context as ArtContext } from '../../context/ArtContext';
import { useAlert } from 'react-alert';
import { base_url, dev_url } from '../../helpers/baseURL';
import axios from 'axios'
import "./cropper.css"
const delay = ms => new Promise(res => setTimeout(res, ms));

const Cropper = (props) => {
    const history = useHistory();
    const { state, setUpImg, setCropImg, dataURLtoFile } = useContext(ArtContext);
    const alert = useAlert()
    const toDataURL = (url, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }
    function getImageDimensions(file) {
        return new Promise(function (resolved, rejected) {
            const img = new Image();

            img.src = file;

            img.onload = function () {
                const imgWidth = img.naturalWidth;
                const imgHeight = img.naturalHeight;
                resolved({ w: imgWidth, h: imgHeight })

            };
        })
    }

    // React.useEffect(() => {
    //     if (state.artData) {
    //         console.log(state.artData, "state.printVarData")
    //         toDataURL(state.artData.img, function (dataUrl) {
    //             setUpImg(dataUrl)
    //         })
    //     }
    // }, [state.artData]);

    function getRandomString(length) {
        var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var result = '';
        for (var i = 0; i < length; i++) {
            result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        return result;
    }
    const dataURLtoFiles = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }
    function generateDownload(canvas, crop) {
        if (!crop || !canvas) {
            return;
        }

        canvas.toBlob(
            (blob) => {
                const previewUrl = window.URL.createObjectURL(blob);
                alert(blob)
                console.log(previewUrl, "blob")
                const anchor = document.createElement('a');
                anchor.download = 'cropPreview.png';
                anchor.href = URL.createObjectURL(blob);
                anchor.click();

                window.URL.revokeObjectURL(previewUrl);
            },
            'image/png',
            1
        );
    }

    const [upImg, setUpImg2] = useState('');
    const [dimension, setDimen] = useState('');
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop1, setCrop] = useState({ unit: '%', width: 100, height: 100, x: 0, y: 0 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [dragged, ondrag] = useState(null);

    console.log("cropdone", crop1)
    console.log("dragged", dragged, completedCrop)

    const onLoad = useCallback((img) => {
        imgRef.current = img;
        console.log("anw" + img)
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }
        // if (crop1.aspect !== state.ratio.ratio)
        //     setCrop({ unit: '%', width: crop1.width, aspect: state.ratio.ratio })
        const image = imgRef.current;
        const data = state.upImg
        const upimg = new Image();

        upimg.src = state.upImg;

        upimg.onload = function () {
            const imgWidth = upimg.naturalWidth;
            const imgHeight = upimg.naturalHeight;
            console.log("updimension", imgWidth, imgHeight, image.naturalWidth, image.naturalHeight)
            setDimen({ "width": imgWidth, "height": imgHeight })

        };
        const crop = completedCrop;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        console.log("crop values", crop)
        let scale
        if (dragged)
            scale = { "height": crop.height * scaleY, "width": crop.width * scaleX, "x": crop.x * scaleX, "y": crop.y * scaleY }
        else
            scale = { "height": image.naturalHeight, "width": image.naturalWidth, "x": 0, "y": 0 }

        localStorage.setItem('scales', JSON.stringify(scale))

        console.log('scales', scale)
        const canvas = previewCanvasRef.current;
        canvas.height = crop.height;
        canvas.width = crop.width;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        console.log('scale', scaleX, scaleY)
        ctx.imageSmoothingQuality = 'high';


        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
        var dataURL = canvas.toDataURL("image/png", 1.0);
        // var image2 = new Image();
        // //Set the Base64 string return from FileReader as source.
        // image2.src = dataURL;
        // //Validate the File Height and Width.
        // image.onload = function () {
        //     var height = this.naturalHeight;
        //     var width = this.naturalWidth;
        //     alert.show(height)
        //     alert.show(width)
        // };
        setCropImg(dataURL);
        console.log('sizeimg', dataURL)

    }, [completedCrop, state.ratio]);
    const checkup = async () => {
        await delay(2000);
        if (!state.upImg)
            history.push("/custom-framing/")
    }
    useEffect(() => {
        setCrop({ unit: '%', width: 100, height: 100, x: 0, y: 0 })
        // checkup()
    }, [state.upImg])
    return (
        <div id="upload_select">
            <div className="App">
                <ReactCrop
                    src={state.upImg}
                    onImageLoaded={onLoad}
                    onDragStart={ondrag}
                    crop={crop1}
                    onChange={(c) => { setCrop(c); console.log("ratt", state) }}
                    onComplete={(c) => { setCompletedCrop(c); }}
                    className="cropper-container"
                    imageStyle={{ maxHeight: "40vh" }}
                />
                <div style={{ display: 'none' }}>
                    <canvas
                        ref={previewCanvasRef}
                        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                        style={{
                            width: Math.round(completedCrop?.width ?? 0),
                            height: Math.round(completedCrop?.height ?? 0)
                        }}
                    />
                </div>
                {/* <button
                    type="button"
                    disabled={!completedCrop?.width || !completedCrop?.height}
                    onClick={() =>
                        generateDownload(previewCanvasRef.current, completedCrop)
                    }
                >
                    Download cropped image
                </button> */}
            </div>
        </div>
    );
}

export default Cropper;
