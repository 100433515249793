import React, { useEffect, useState } from "react"
import Header from '../../components/header/header'
import Footer from '../../components/Footer/footer'
import accopen from '../../assets/accopen.png'
import acclose from '../../assets/acclose.png'
import axios from "axios"
import parse from 'html-react-parser';
import { base_url } from "../../helpers/baseURL"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import "./basic.css"
import { useHistory } from "react-router"
import { MetaTags } from "react-meta-tags"


const Faq = () => {
    const [tab, settab] = useState(1)
    const history = useHistory()
    const faqs = [
        { "question": "Shipping & Delivery", "answer": "We provide free delivery throughout India except some regions which are not covered by our logistics partner(s). You can enter the pincode of the area where you want the product to be delivered to see delivery availability and estimated shipping time." },
        { "question": "Packaging", "answer": "It is of utmost importance for us to secure the products while in transit. We secure most of the goods with multilayer packaging involving bubble sheets, foam sheets & corrugated boxes. Unframed prints are rolled and shipped in cardboard tubes." },
        { "question": "Order Cancellation", "answer": "All our products are customized as per your specifications. Custom orders begin production immediately upon order. They cannot be cancelled or changed. " },
        { "question": "Track My Order", "answer": "Your order status is updated via email and SMS at every step. For any details or queries give us a call on +91 484 270 0090, Monday - Saturday (10:00 AM - 7:00 PM) or drop us an email at support@espravo.com. An email is sent to you after the order is shipped that contains the tracking number and the service provider." },
        { "question": "Change Delivery Address", "answer": "Kindly call us on +91 484 270 0090, Monday - Saturday (10:00 AM - 7:00 PM) or drop us an email at support@espravo.com." },
        { "question": "Returns", "answer": "At Espravo, we take pride in the exceptional quality of our custom made products. We carefully inspect each item prior to shipment, and also require your inspection and signature upon delivery. As the products are custom made orders are non-refundable. If you ’re not 100% happy with your order for any reason let us know and we’ll make it right. Your happiness is our top priority. Please contact us at support@espravo.com with your order number, a brief description & photo illustrating the issue." },
        { "question": "Expedited Delivery", "answer": "Kindly drop us an email at support@espravo.com.. We will try our best to accommodate your request wherever possible." },

    ]
    const faq1 = [
        { "question": "Shipping & Delivery", "answer": "We provide free delivery throughout India except some regions which are not covered by our logistics partner(s). You can enter the pincode of the area where you want the product to be delivered to see delivery availability and estimated shipping time." },
        { "question": "Order Cancellation", "answer": "All our products are customized as per your specifications. Custom orders begin production immediately upon order. They cannot be cancelled or changed. " },
        { "question": "Change Delivery Address", "answer": "Kindly call us on +91 484 270 0090, Monday - Saturday (10:00 AM - 7:00 PM) or drop us an email at support@espravo.com." },
        { "question": "Expedited Delivery", "answer": "Kindly drop us an email at support@espravo.com.. We will try our best to accommodate your request wherever possible." },
    ]
    const faq2 = [
        { "question": "Packaging", "answer": "It is of utmost importance for us to secure the products while in transit. We secure most of the goods with multilayer packaging involving bubble sheets, foam sheets & corrugated boxes. Unframed prints are rolled and shipped in cardboard tubes." },
        { "question": "Track My Order", "answer": " An email is sent to you after the order is shipped that contains the tracking number and the service provider. For any details or queries give us a call on +91 484 270 0090, Monday - Saturday (10:00 AM - 7:00 PM) or drop us an email at support@espravo.com." },
        { "question": "Returns", "answer": "At Espravo, we take pride in the exceptional quality of our custom made products. We carefully inspect each item prior to shipment, and also require your inspection and signature upon delivery. As the products are custom made orders are non-refundable. If you ’re not 100% happy with your order for any reason let us know and we’ll make it right. Your happiness is our top priority. Please contact us at support@espravo.com with your order number, a brief description & photo illustrating the issue." },
    ]
    const [page, setPage] = useState()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="faq">
            <MetaTags>
                <title>Frequently Asked Questions (FAQ) | Espravo</title>
                <meta name="description" content="Find answers to frequently asked questions about Espravo's. Get insights into our products, services, and more. " />
                <link rel="canonical" href={`${window.location.origin}/faq/`} />
            </MetaTags>
            <Header />
            <div className="header-container">
                <div className="selectBack pt-3 brandon d-flex align-self-left" onClick={() => history.goBack()}>
                    <img src="/assets/arrow-left.webp" className="selectBackIcon" alt="thumb"></img>
                    <p className='ml-2'>Back</p>
                </div>
            </div>
            <div id='stores' className="pb-sm-5 my-sm-5 py-2 my-2 container">
                <h1 className="GT main-heading text-center mt-3 mb-3 mb-md-5 pb-2">Frequently Asked Questions</h1>
                <div className="row d-flex d-sm-none">
                    {faqs.map((data, ind) => {
                        return (
                            <div className="col-sm-6 col-12 mb-3" >
                                <div className="accordion p-3">
                                    <div className="brandon question" onClick={() => { tab === ind + 1 ? settab(0) : settab(ind + 1) }}>
                                        {data.question} {tab === ind + 1 ? <div className="button active"> <img className="accopen" src={accopen} /></div> : <div className="button"><img className="acclose" src={acclose} /></div>}
                                    </div>
                                    {ind + 1 === tab ?
                                        < p className="brandon answer pt-1">
                                            {data.answer}
                                        </p> : ""}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="row d-none d-sm-flex">
                    <div className="col-6">
                        {faq1.map((data, ind) => {
                            return (
                                <div className="mb-3" >
                                    <div className="accordion p-4">
                                        <div className="brandon question" onClick={() => { tab === ind + 1 ? settab(0) : settab(ind + 1) }}>
                                            {data.question} {tab === ind + 1 ? <div className="button active"> <img className="accopen" src={accopen} /></div> : <div className="button"><img className="acclose" src={acclose} /></div>}
                                        </div>
                                        {ind + 1 === tab ?
                                            < p className="brandon answer pt-1">
                                                {data.answer}
                                            </p> : ""}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="col-6">
                        {faq2.map((data, ind) => {
                            return (
                                <div className="mb-3" >
                                    <div className="accordion p-4">
                                        <div className="brandon question" onClick={() => { tab === ind + 5 ? settab(0) : settab(ind + 5) }}>
                                            {data.question} {tab === ind + 5 ? <div className="button active"> <img className="accopen" src={accopen} /></div> : <div className="button"><img className="acclose" src={acclose} /></div>}
                                        </div>
                                        {ind + 5 === tab ?
                                            < p className="brandon answer pt-1">
                                                {data.answer}
                                            </p> : ""}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div >
            <Footer />
        </div >
    )
}
export default Faq;