import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cartitem from '../../assets/cartitem.png'
import FrameEditPreview from '../frame/frameEditPreview';
import CartFramePreview from '../frame/cartFramePreview';
import axios from 'axios';
import { base_url } from '../../helpers/baseURL';
import { Context as UserContext } from '../../context/UserContext';

const OrderSingleList = (props) => {
    const [image, setimage] = useState("")
    const [frames, setframes] = useState("")
    const [datas, setdatas] = useState("")
    const { state, single_order } = useContext(UserContext);
    const [value, setValue] = useState(0)

    React.useEffect(() => {
        window.scroll(0, 0)
        let rat = props.ordercontents.field_dimension
        let payload
        if (rat) {
            rat = rat.split("X")
            console.log("ratt", rat)

            let rat2 = Number(rat[0].replace('" ', '')) / Number(((rat[1].replace('"', '')).replace(' ', '')))
            console.log("ratt", rat2)
            payload = { ratio: rat2, text: props.ordercontents.field_dimension, width: Number(rat[0].replace('" ', '')), height: Number((rat[1].replace('"', '').replace(' ', ''))) }
            console.log("ratt2", payload)
            setdatas(payload)
        }
        if (props.relationships.field_cropped.data) {
            axios.get(base_url + `/jsonapi/file/file/${props.relationships.field_cropped.data.id}`)
                .then(
                    res => {
                        console.log("cropped get image", base_url + res.data.data.attributes.uri.url)
                        setimage(base_url + res.data.data.attributes.uri.url)
                    })
                .catch(err => {

                })
        }
        else if (props.ordercontents.field_product_type == "art") {
            let id = props.relationships.purchased_entity.data ? props.relationships.purchased_entity.data.id : null
            axios.get(base_url + `/jsonapi/commerce_product_variation/Default/${id}?include=product_id.field_thumbnail,attribute_media`)
                .then(
                    res => {
                        console.log("art image", base_url + res.data.included.filter(item => item.type === "file--file")[0].attributes.uri.url)
                        setimage(base_url + res.data.included.filter(item => item.type === "file--file")[0].attributes.uri.url)
                    })
                .catch(err => {

                })
        }
        else if (props.ordercontents.field_product_type == "object") {
            console.log("getting object imager")
            let id = props.relationships.purchased_entity.data ? props.relationships.purchased_entity.data.id : null
            axios.get(base_url + `/jsonapi/commerce_product_variation/object/${id}?include=product_id.field_object_image.thumbnail`)
                .then(
                    res => {
                        console.log("aa art image", base_url + res.data.included.filter(item => item.type === "file--file")[0].attributes.uri.url)
                        setimage(base_url + res.data.included.filter(item => item.type === "file--file")[0].attributes.uri.url)
                    })
                .catch(err => {

                })
        }
        else if (props.ordercontents.field_product_type === "mixed_media") {
            let id = props.relationships.purchased_entity.data ? props.relationships.purchased_entity.data.id : null
            console.log("mixed media is", id)
            axios.get(base_url + `/jsonapi/commerce_product_variation/mixed_media/${id}?include=field_image.thumbnail`)
                .then(
                    res => {
                        console.log("aa mixed media image", base_url + res.data.included.filter(item => item.type === "file--file")[0].attributes.uri.url)
                        setimage(base_url + res.data.included.filter(item => item.type === "file--file")[0].attributes.uri.url)
                    })
                .catch(err => {
                    console.log("error on mixed media image", err)
                })
        }
        else if (props.relationships.purchased_entity.data.type === "commerce_product_variation--gifting") {
            let id = props.relationships.purchased_entity.data ? props.relationships.purchased_entity.data.id : null
            console.log("id in gifting", id)
            axios.get(base_url + `/jsonapi/commerce_product_variation/gifting/${id}?include=product_id.field_gifting_image`)
                .then(
                    res => {
                        console.log("art image", base_url + res.data.included.filter(item => item.type === "file--file")[0].attributes.uri.url)
                        setimage(base_url + res.data.included.filter(item => item.type === "file--file")[0].attributes.uri.url)
                    })
                .catch(err => {

                })
        }
        else {
            let id = props.relationships.purchased_entity.data ? props.relationships.purchased_entity.data.id : null
            axios.get(base_url + `/jsonapi/commerce_product_variation/gallery_wall/${id}?include=product_id.field_gallery_image,product_id.field_gallery_thumbnail`)
                .then(
                    res => {
                        console.log("art image", base_url + res.data.included.filter(item => item.type === "file--file")[0].attributes.uri.url)
                        setimage(base_url + res.data.included.filter(item => item.type === "file--file")[0].attributes.uri.url)
                    })
                .catch(err => {

                })
        }
        if (props.ordercontents.field_print_or_frame == "frame") {
            if (payload) {
                if (payload.ratio <= 0.40)
                    props.ordercontents.field_matt_size ? setValue(60) : setValue(70)
                else if (payload.ratio > 0.40 && payload.ratio < 0.52)
                    props.ordercontents.field_matt_size ? setValue(80) : setValue(100)
                else if (payload.ratio >= 0.52 && payload.ratio < 0.72)
                    props.ordercontents.field_matt_size ? setValue(100) : setValue(120)
                else if (payload.ratio >= 0.72 && payload.ratio < 1)
                    props.ordercontents.field_matt_size ? setValue(120) : setValue(130)
                else if (payload.ratio >= 1 && payload.ratio < 1.4)
                    props.ordercontents.field_matt_size ? setValue(130) : setValue(140)
                else if (payload.ratio >= 1.4)
                    props.ordercontents.field_matt_size ? setValue(130) : setValue(140)
            }
            state.singleorder.included.map((item) => {
                if (item.type === 'commerce_order_item--default')
                    if (item.relationships.purchased_entity.data.type === "commerce_product_variation--frame") {
                        console.log("group", props.ordercontents.field_group)
                        if (props.ordercontents.field_group == item.attributes.field_group) {
                            let id = item.relationships.purchased_entity.data ? item.relationships.purchased_entity.data.id : null
                            axios.get(base_url + `/jsonapi/commerce_product_variation/Frame/${id}?include=product_id,product_id.field_front,product_id.field_side`)
                                .then(
                                    res => {
                                        console.log("")
                                        let frontframearray = res.data.included.filter(item => item.id === res.data.included[0].relationships.field_front.data.id)
                                        let sideframearray = res.data.included.filter(item => item.id === res.data.included[0].relationships.field_side.data.id)
                                        setframes({ "front": base_url + frontframearray[0].attributes.uri.url, "side": base_url + sideframearray[0].attributes.uri.url })
                                        console.log("framess", base_url + frontframearray[0].attributes.uri.url, base_url + sideframearray[0].attributes.uri.url)
                                    })
                        }
                    }
            })
        }
    }, [props.ordercontents, props.relationships]);
    let details = { "artid": image, "frameid": frames, "padding": props.ordercontents.field_matt_size, "ratio": datas.ratio, "width": datas.width }

    const [selected, setselected] = useState("6 x 4.25")
    console.log('order box', props.ordercontents)
    console.log("order item relation", props.relationships)
    return (
        <div id="order-list-item" className="order-list">
            <div className="order-list-item">
                <div className="order-list-image">
                    {props.ordercontents.field_print_or_frame == "frame" ?
                        <CartFramePreview value={value} props={details} framing={{ "depth": 32, "frame": 3 }} /> :
                        <img alt="icon" className="images" src={image} />
                    }

                </div>
                <div className="order-list-image-details row mx-0 order-list-image-details-di">
                    <div className="order-list-title col-md-12 col-sm-12 align-top px-0">
                        <div className="order-list-title GT-bold">
                            {props.relationships.field_cropped.data ? `Custom Framing on ${props.ordercontents.field_art_media_type}` :
                                props.ordercontents.field_art_media_type ? props.ordercontents.field_print_or_frame == "frame" ? `Framed Print on ${props.ordercontents.field_art_media_type}` : `Print on ${props.ordercontents.field_art_media_type}` : ""}
                            {props.ordercontents.field_product_type === "object" ? "Object" : props.ordercontents.field_product_type === "gallery_wall" ? "Gallery Walls" :
                                props.ordercontents.field_product_type === "gifting" ? props.relationships.field_original_image.data ? "Thoughtful Gifts" : "Custom Framed Gifts" : props.ordercontents.field_product_type == "mixed_media" ? "Mixed Media" : ""}
                        </div>
                        <div className="order-list-price brandon">
                            {props.relationships.field_cropped.data ? "" : props.ordercontents ? props.ordercontents.title ? props.ordercontents.title : 'NA' : ''}
                        </div>
                        <div className="order-list-price brandon">{props.ordercontents ? props.ordercontents.total_price ? props.ordercontents.total_price.formatted : '' : ''}</div>
                    </div>
                    <div className="col-md-12 col-sm-12 px-0">
                    </div>
                    {props.ordercontents.field_product_type == "gallery_wall" || props.ordercontents.field_product_type == "object" || props.ordercontents.field_product_type == "gifting" || props.ordercontents.field_product_type == "mixed_media" ? "" : <div className="order-list-dimensions col-md-6 col-sm-6 align-bottom px-0"><span className="order-list-image-details-span">Dimensions</span>
                        {props.ordercontents ? props.ordercontents.field_dimension ? props.ordercontents.field_dimension : 'NA' : ''}
                    </div>}
                    {props.ordercontents.field_product_type == "gallery_wall" ? "" : ""}
                    {props.ordercontents.field_product_type == "gallery_wall" || props.ordercontents.field_product_type == "object" || props.ordercontents.field_product_type == "gifting" || props.ordercontents.field_product_type == "mixed_media" ? "" : <div className="order-list-framesize col-md-12 col-sm-12 align-bottom px-0"><span className="order-list-image-details-span">Frame Size</span>
                        {props.ordercontents ?
                            props.ordercontents.field_print_or_frame == "frame" ?
                                props.ordercontents.field_frame_size ?
                                    props.ordercontents.field_frame_size : 'NA'
                                : 'No Frame' : ""}
                    </div>}
                </div>
                <div className="order-list-image-details row mx-0 order-list-image-details-mob">
                    <div className=" order-list-price col-md-12 col-sm-12 px-0">
                        {props.ordercontents ? props.ordercontents.title ? props.ordercontents.title : 'NA' : ''}
                    </div>
                    <div className="order-list-price">{props.ordercontents ? props.ordercontents.total_price ? props.ordercontents.total_price.formatted : '' : ''}</div>
                    {props.ordercontents.field_product_type == "gallery_wall" ? "" : <div className="order-list-dimensions col-md-6 col-sm-6 align-bottom px-0"><span className="order-list-image-details-span">Dimensions</span>
                        {props.ordercontents ? props.ordercontents.field_dimension ? props.ordercontents.field_dimension : 'NA' : ''}
                    </div>}
                    {props.ordercontents.field_product_type == "gallery_wall" ? "" : <div className="order-list-matstyle col-md-6 col-sm-6 align-bottom px-0"><span className="order-list-image-details-span">Mat Style</span>
                        {props.ordercontents ?
                            props.ordercontents.field_matt_color ?
                                props.ordercontents.field_art_media_type == "Paper" ? props.ordercontents.field_matt_color : 'No Matting' : "NA" : ''}
                    </div>}
                    {props.ordercontents.field_product_type == "gallery_wall" ? "" : ""}
                    {props.ordercontents.field_product_type == "gallery_wall" ? "" : <div className="order-list-framesize col-md-12 col-sm-12 align-bottom px-0"><span className="order-list-image-details-span">Frame Size</span>
                        {props.ordercontents ?
                            props.ordercontents.field_print_or_frame == "frame" ?
                                props.ordercontents.field_frame_size ?
                                    props.ordercontents.field_frame_size : 'NA'
                                : 'No Frame' : ""}
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default OrderSingleList;
