import React, { useContext, useEffect, useState } from 'react';

const PriceTable = (props) => {
    console.log('price details order', props.data)
    return (
        <div className="pricetable">
            <h6 className="bold ship-totals">Totals</h6>
            <div className="table-list">
                <div className="table-row">
                    <p className="column1">
                        Products:
                    </p>
                    <p className="column2">
                        {props.data.attributes ? props.data.attributes.field_total_products ? props.data.attributes.field_total_products : props.data.relationships ? props.data.relationships.order_items ? props.data.relationships.order_items.data ? props.data.relationships.order_items.data.length : '' : '' : '' : ''}
                    </p>
                </div>
                <div className="table-row">
                    <p className="column1">
                        Subtotal:
                    </p>
                    {props.data ? <p className="column2">
                        {props.data.attributes ? props.data.attributes.total_price ? props.data.attributes.total_price.formatted : '' : ''}
                    </p> : ''}
                </div>
                <div className="table-row">
                    <p className="column1">
                        Tax:
                    </p>
                    <p className="column2">
                        Included
                    </p>
                </div>
                <div className="table-row">
                    <p className="column1">
                        Shipping:
                    </p>
                    <p className="column2">
                        Included
                    </p>
                </div>

                <div className="table-row total">
                    <p className="column1">
                        Total
                    </p>
                    {props.data ? <p className="column2">
                        {props.data.attributes ? props.data.attributes.total_price ? props.data.attributes.total_price.formatted : '' : ''}
                    </p> : ''}
                </div>
            </div>
        </div>
    );
}

export default PriceTable;
