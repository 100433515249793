import React from 'react'
import error from '../../assets/error.jpg'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Header from '../../components/header/header'
import './error.css'

const Error = () => {
    const history = useHistory();
    const location = useLocation();
    console.log('history', history)
    const back = () => {
        if (location.pathname === "/404s")
            history.go(-2)
        else if (location.pathname === "/404c")
            history.go(-2)
        else if (location.pathname === "/404p")
            history.go('account/profile')
        else if (location.pathname === "/404f")
            history.go(-3)
        else
            history.goBack()
    }
    return (
        <div className="error-page">
            <Header />
            <div className="container error-content">
                <img src={error} />
                <h6 className='title'>We can’  t find the page</h6>
                <p>Unfortunately we can’t find the page you are looking for.</p>
                <div className='btns row'>
                    <Link to='/' className='col-sm-8 col-12'> <button className="bgsecondary whitecolor py-2 px-2">Return To Home</button></Link>
                    <Link className='back-btn pt-2 col-sm-4 col-12' onClick={() => back()}>Back</Link>
                </div>
            </div>
        </div>
    )
}
export default Error;