import React, { useContext } from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/header/header";
import { Context as ArtContext } from '../../context/ArtContext';
import { base_url } from '../../helpers/baseURL'
import HtmlParser from 'html-react-parser'

import "../partner/partner.css";
import "./Design.css"


const Design = () => {
    const { state, homeblock, homeblock2, homeblock3, getGallery } = useContext(ArtContext);
    const bullet = [
        { "first": "Free", "second": "Shipping" },
        { "first": "The Finest", "second": "Quality" },
        { "first": "Happiness", "second": "Guarantee" }
    ]
    return (
        <div className="partner-container ">
            <Header />
            <div className="container designservice-container">
                <div className="partner-subcontainer text-center">
                    <div className="partner-title art-love text-center">
                        Design Services
                    </div>
                    <div className="partner-subtitle text-center brandon">
                        Choose a service below to get started
                    </div>

                </div>
                <div className=" design-subcontainer1">

                    <div className="d-md-flex design-subcontainer  mx-auto justify-content-between">
                        <div className="text-center col-md-6 designbenefit-maincontainer px-0 mx-md-2 mx-xl-3 ">
                            <div className="designimg-container position-relative">
                                <img className="design-image w-100" src="/assets/design/design1.png" />
                                <div className="position-absolute design-price">Rs. 4900</div>
                            </div>
                            <div className="designbenefit-container">
                                <div className="design-subtitle1 text-center  GT ">Art Advisory</div>
                                <ul className="m-0 p-0">
                                    <li className="brandon designlisting">Select the best art for your home</li>
                                    <li className="brandon designlisting">With mockups & hanging instructions</li>
                                </ul>
                                <a href="/design/art">
                                    <button className="brandon design-button">GET STARTED</button>
                                </a>
                            </div>
                        </div>
                        <div className="text-center col-md-6 designbenefit-maincontainer px-0 mx-md-2 mx-xl-3 ">
                            <div className="designimg-container position-relative">
                                <img className="design-image w-100" src="/assets/design/design2.png" />
                                <div className="position-absolute design-price">Rs. 4900</div>
                            </div>
                            <div className="designbenefit-container">
                                <div className="design-subtitle1 text-center  GT ">Gallery Wall Consultation</div>
                                <ul className="m-0 p-0">
                                    <li className="brandon designlisting">Create a gallery wall for your space</li>
                                    <li className="brandon designlisting">With mockups & hanging instructions</li>
                                </ul>
                                <a href="/design/wall">
                                    <button className="brandon design-button">GET STARTED</button>
                                </a>
                            </div>
                        </div>


                    </div>
                    <div className="design-subcontainer d-flex mx-auto col-12 brandon design-note">
                        * The design fee will be applied as a credit towards your purchase if made within 90 days.
                        Discounts and gift cards cannot be applied to this design fee.
                    </div>

                </div>

                <div className="design-steps">
                    <div className="partner-title art-love text-center">
                        How It Works
                    </div>

                    <div className="d-md-flex justify-content-between">
                        <div className="designcount-container mx-md-3 mx-auto  text-center px-0">
                            <p class="designcount brandon mx-auto">1</p>
                            <div className="GT designcount-title">Tell us about the project</div>
                            <div className="brandon designcount-sub">Fill out the form to tell us about your project, area dimensions, wall colours and your art style preferences. You can upload photos of the spaces along with a note. We’ll connect you with a designer via  email/phone.</div>
                        </div>
                        <div className="designcount-container mx-md-3 mx-auto text-center">
                            <p class="designcount brandon mx-auto">2</p>
                            <div className="GT designcount-title">We’ll design your space</div>
                            <div className="brandon designcount-sub">Fill out the form to tell us about your project, area dimensions, wall colours and your art style preferences. You can upload photos of the spaces along with a note. We’ll connect you with a designer via  email/phone.</div>
                        </div>
                        <div className="designcount-container mx-md-3 mx-auto text-center">
                            <p class="designcount brandon mx-auto">3</p>
                            <div className="GT designcount-title">Order shipped</div>
                            <div className="brandon designcount-sub">Fill out the form to tell us about your project, area dimensions, wall colours and your art style preferences. You can upload photos of the spaces along with a note. We’ll connect you with a designer via  email/phone.</div>
                        </div>
                    </div>


                </div>

            </div>


            <div className="designcerti-container">

                <div className="bullet row py-sm-5 py-5 px-10 px-5 justify-content-between ">

                    <div className="bullet-cont column col-sm-4 col-xl-3 col-12 text-center">
                        <div className='d-table-cell designimg2'>
                            <img alt="icon" className="design-truck" src="/assets/design/truck.png" />
                        </div>
                        <h2 className="head second-head secondarycolor GT " >
                            <div className="designmotto">Free<br />
                                Shipping</div>
                        </h2>

                        <p className="brandon">
                            Across India to Selected Pin Codes
                        </p>
                    </div>
                    <div className="bullet-cont column col-sm-4 col-xl-3 col-12 text-center">
                        <div className='d-table-cell designimg2'>
                            <img alt="icon" className="design-tag" src="/assets/design/tag.png" />
                        </div>
                        <h2 className="head second-head secondarycolor GT " >
                            <div className="designmotto">The Finest Quality</div>
                        </h2>

                        <p className="brandon">
                            Meticulously Crafted
                            Artworks & Frames
                        </p>
                    </div>
                    <div className="bullet-cont column col-sm-4 col-xl-3 col-12 text-center">
                        <div className='d-table-cell designimg2'>
                            <img alt="icon" className="design-thumb" src="/assets/design/thumb.png" />
                        </div>
                        <h2 className="head second-head secondarycolor GT " >
                            <div className="designmotto">Happiness Guarantee</div>
                        </h2>

                        <p className="brandon">
                            Your Happiness is our
                            Top Priority
                        </p>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Design;
