import React, { useContext, useState } from 'react'
import { Context as ArtContext } from '../../context/ArtContext'
import { Context as UserContext } from '../../context/UserContext'
import { base_url } from '../../helpers/baseURL'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Header from '../../components/header/header'
import Footer from '../../components/Footer/footer'
import { Carousel } from 'react-bootstrap'
import larrow from '../../assets/Home/larrow.png'
import rarrow from '../../assets/Home/rarrow.png'
import './home.css'
import HtmlParser from 'html-react-parser'
import Slider from 'react-slick'
import axios from 'axios'
import { useAlert } from 'react-alert'
import { MetaTags } from 'react-meta-tags'

const Home = () => {
  const search = useLocation().search
  const history = useHistory()
  const query = new URLSearchParams(search)
  const alert = useAlert()
  const { state, homeblock, homeblock2, homeblock3, getGallery, setArtFilters } =
    useContext(ArtContext)
  let Ucontext = useContext(UserContext)
  const [offset, setoff] = useState(0)
  const [index, changeIndex] = useState(0)
  const [mobindex, changeMobIndex] = useState(0)
  // console.log('state', state)
  const imageget = () => { }
  const settings = window.innerWidth <= 992 ? {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  } : {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }
  const settingsg = window.innerWidth <= 992 ? {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  } : {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 2,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }
  const settings1 = {
    dots: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }
  const changecarousel = {
    afterChange: function (index) {
      changeIndex(index + 1)
    }
  }

  const changecarousel2 = {
    afterChange: function (index) {
      // console.log(
      //     `index paged: ${index + 1},`
      // );
      changeMobIndex(index + 1)
    }
  }
  const bannersettings = {
    dots: true,
    infinite: true,
    fade: true,
    swipe: true,
    swipeToSlide: true,
    afterChange: function (index) { },
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    speed: 400,
    autoplaySpeed: 5000,
    appendDots: dots => <ul>{dots}</ul>,

    customPaging: function (i) {
      return <div className='ft-slick__dots--custom'></div>
    }
  }
  function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <img
        src={rarrow}
        className={className}
        style={{ ...style, position: 'absolute', right: '-1%' }}
        onClick={onClick}
      />
    )
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <img
        src={larrow}
        className={className}
        style={{ ...style, position: 'absolute', left: '-1%', zIndex: '9' }}
        onClick={onClick}
      />
    )
  }
  const bullet = [
    { first: 'Free', second: 'Shipping' },
    { first: 'The Finest', second: 'Quality' },
    { first: 'Happiness', second: 'Guarantee' }
  ]
  const custom = [
    { image: '/assets/Home/banner1.webp' },
    { image: '/assets/Home/banner2.webp' },
    { image: '/assets/Home/banner3.webp' },
    { image: '/assets/Home/banner4.webp' },
    { image: '/assets/Home/banner5.webp' }
  ]
  const design = [
    { image: '/assets/Design-Studio1.png' },
    { image: '/assets/Design-Studio2.png' },
    { image: '/assets/Design-Studio3.png' },
  ]
  const frames = [
    { image: "/assets/Home/Matte-Black.webp", title: 'Matte Black', description: 'Black Gallery Frame' },
    { image: "/assets/Home/Matte-White.webp", title: 'Matte White', description: 'White Gallery Frame' },
    {
      image: "/assets/Home/Nordic.webp",
      title: 'Nordic',
      description: 'Oakwood Tone Minimal Frame'
    },
    {
      image: "/assets/Home/Vintage-Black.webp",
      title: 'Vintage-Black',
      description: 'Black Frame With Vintage Aesthetic'
    },
    {
      image: "/assets/Home/Rustic-Ash.webp",
      title: 'Rustic Ash',
      description: 'Grey Tone Rustic Frame'
    },
    { image: "/assets/Home/Mocha.webp", title: 'Mocha', description: 'Walnut Tone Frame' },
    // { "image": frame7, "title": "Truffle", "description": "Deep Wood Tone Frame" },
    // { image: frame7, title: 'Glam Gold', description: 'Semi Gloss Gold Frame' },
    // {
    //   image: frame8,
    //   title: 'Brushed Gold',
    //   description: 'Gold Frame With Brushed Effect'
    // }
  ]
  React.useEffect(() => {
    console.log("version react 18.2.0")
    let api = base_url + '/product/count'
    if (query.get('logged_out')) {
      alert.show('Logged Out Successfully', { type: 'success' })
      query.delete('logged_out')
      history.replace({
        search: query.toString(),
      })
    }
    else if (query.get('expired')) {
      alert.show('Login Expired')
      query.delete('expired')
      history.replace({
        search: query.toString(),
      })
    }
    axios
      .get(api, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        console.log('art count', res.data.data[0].product_count)
      })
      .catch(err => { })
    window.scrollTo(0, 0)
    homeblock()
    homeblock2()
    homeblock3()
    Ucontext.shopartBanner()
    Ucontext.viewStore()
    Ucontext.homeblock4()
    Ucontext.framedream()
    imageget()
    getGallery()
    Ucontext.testimonials()
    Ucontext.artLove()
  }, [])
  return (
    <div id='nhome'>
      <Header />
      <MetaTags>
        <meta property='og:type' content='website' />
        <meta
          property='og:description'
          content='At Espravo we believe in the power of art to delight and inspire our daily lives.
With the vision to breathe life into your walls, we have coalesced an eclectic mix
of art prints, customizable and ready to blend with your aesthetics.'
        />
        <meta property='og:image' content='Page featured image link' />
        <meta name='twitter:card' content='' />
        <meta name='twitter:site' content='@twitterid' />
        <meta name='twitter:title' content='Espravo' />
        <meta
          name='twitter:description'
          content='At Espravo we believe in the power of art to delight and inspire our daily lives.
With the vision to breathe life into your walls, we have coalesced an eclectic mix
of art prints, customizable and ready to blend with your aesthetics.'
        />
        <link rel="canonical" href={`${window.location.origin}/`} />
        {Ucontext.state.homeBlock1
          ? Ucontext.state.homeBlock1.included
            ? Ucontext.state.homeBlock1.included.map(data => {
              return (
                <meta
                  name='twitter:image'
                  content={base_url + data.attributes.uri.url}
                />
              )
            })
            : ''
          : ''}
        {Ucontext.state.homeBlock1
          ? Ucontext.state.homeBlock1.included
            ? Ucontext.state.homeBlock1.included.map(data => {
              return (
                <meta
                  property='og:image'
                  content={base_url + data.attributes.uri.url}
                />
              )
            })
            : ''
          : ''}
      </MetaTags>
      <div className='shop-art-banner p-relative mob-only'>
        {Ucontext.state.homeBlock1 ? (
          <Slider {...bannersettings} {...changecarousel2}>
            {Ucontext.state.homeBlock1.included
              ? Ucontext.state.homeBlock1.included.map(data => {
                return (
                  <div className='shopart-banner-img'>
                    <img
                      src={base_url + data.attributes.uri.url}
                      className=''
                    />
                  </div>
                )
              })
              : ''}
          </Slider>
        ) : (
          ''
        )}
        <div className='button p-absolute'>
          {mobindex == 3 ? (
            <Link
              to="/gifts/"
              className='banner-button'
              onClick={() => {
                localStorage.setItem('artmode', 'shopart')
                localStorage.setItem('flowtype', 'shopart')
              }}
            >
              GIFTS
            </Link>
          ) : (
            <Link
              to="/art/"
              className='banner-button'
              onClick={() => {
                localStorage.setItem('artmode', 'shopart')
                localStorage.setItem('flowtype', 'shopart')
                sessionStorage.setItem("artFilters", `[]`)
              }}
            >
              SHOP ART
            </Link>
          )}
        </div>
      </div>
      <div className='shop-art-banner p-relative desk-only'>
        {Ucontext.state.homeBlock1 ? (
          <Slider {...bannersettings} {...changecarousel}>
            {Ucontext.state.homeBlock1.included
              ? Ucontext.state.homeBlock1.included.map(data => {
                return (
                  <div className='shopart-banner-img'>
                    <img
                      src={base_url + data.attributes.uri.url}
                      className=''
                    />
                  </div>
                )
              })
              : ''}
          </Slider>
        ) : (
          ''
        )}
        <div className='button p-absolute'>
          {index == 3 ? (
            <Link
              to="/gifts/"
              className='banner-button'
              onClick={() => {
                localStorage.setItem('artmode', 'shopart')
                localStorage.setItem('flowtype', 'shopart')
              }}
            >
              EXPLORE GIFTS
            </Link>
          ) : (
            <Link
              to="/art/"
              className='banner-button'
              onClick={() => {
                localStorage.setItem('artmode', 'shopart')
                localStorage.setItem('flowtype', 'shopart')
                sessionStorage.setItem("artFilters", `[]`)
              }}
            >
              SHOP ART
            </Link>
          )}
        </div>
      </div>
      <div className='art-love-container'>
        <div className='art-love mb-1'>Find Art You Love</div>
        <p className='brandon container art-love-details text-center desk-only'>
          At Espravo we believe in the power of art to delight and inspire our
          daily lives.
          <br />
          With the vision to breathe life into your walls, we have coalesced an
          eclectic mix <br />
          of art prints, customizable and ready to blend with your aesthetics.
        </p>
        <p className='brandon art-love-details font-12 text-center mob-only'>
          “At Espravo, we make it our mission to help you
          <br />
          discover and buy the best art prints from around the <br />
          world. Whether you’re looking to add a statement piece <br />
          to your home, or custom frame a beautiful moment,
          <br />
          Espravo is your portal to get that perfect artwork <br />
          beautifully framed.”
        </p>
      </div>
      <div className='container art-carousel my-5 desk-only'>
        {Ucontext.state.homeBlock3 ? (
          <Slider {...settings}>
            {Ucontext.state.homeBlock3.data.map(data => {
              return (
                <Link
                  to={`/art/`}
                  onClick={() => { setArtFilters([{ "value": data.attributes.name, "attribute": "field_collections" }]); sessionStorage.setItem("artFilters", JSON.stringify([{ "value": data.attributes.name, "attribute": "field_collections" }])) }}
                  className='carousel item px-sm-3 px-2 inner'
                >
                  <div className='art-carousel-item'>
                    <div className='image'>
                      {data.relationships.field_collection_image.data ? (
                        <img
                          src={
                            base_url +
                            Ucontext.state.homeBlock3.included.filter(
                              x =>
                                x.id ===
                                data.relationships.field_collection_image.data
                                  .id
                            )[0].attributes.uri.url
                          }
                          className='art-carousel-img p-sm-3 p-2'
                        />
                      ) : (
                        <img
                          src={data.image}
                          className='art-carousel-img p-sm-3 p-2'
                        />
                      )}
                    </div>
                    <div className='art-head GT-bold text-center'>
                      {data.attributes.name ? data.attributes.name : ''}
                    </div>
                  </div>
                </Link>
              )
            })}
          </Slider>
        ) : (
          ''
        )}
      </div>
      <div className='container art-carousel my-3 mob-only'>
        {Ucontext.state.homeBlock3 ? (
          <Slider {...settings1}>
            {Ucontext.state.homeBlock3.data.map(data => {
              return (
                <Link
                  to={`/art?filter=${data.attributes.name}`}
                  className='carousel item px-sm-3 px-2 container inner'
                >
                  <div className='art-carousel-item'>
                    <div className='image'>
                      {data.relationships.field_collection_image.data ? (
                        <img
                          src={
                            base_url +
                            Ucontext.state.homeBlock3.included.filter(
                              x =>
                                x.id ===
                                data.relationships.field_collection_image.data
                                  .id
                            )[0].attributes.uri.url
                          }
                          className='art-carousel-img p-sm-3 p-2'
                        />
                      ) : (
                        <img
                          src={data.image}
                          className='art-carousel-img p-sm-3 p-2'
                        />
                      )}
                    </div>
                    <div className='art-head GT-bold text-center'>
                      {data.attributes.name ? data.attributes.name : ''}
                    </div>
                  </div>
                </Link>
              )
            })}
          </Slider>
        ) : (
          ''
        )}
      </div>
      <div className='custom-frame-container'>
        <div className='art-love px-sm-5'>Custom Framing</div>
        <p className='brandon custom-frame-details text-center pb-sm-5 pb-3 pt-2 desk-only'>
          Framing memories, achievements or inspirations have a touch of
          belonging to them. Whether it’s <br />
          an instant joyous moment, a tempting culinary delicacy or a
          passionately crafted artwork, you can <br />
          frame any image online from the comfort of your home.
          <br />
        </p>
        <p className='brandon font-12 custom-frame-details custom-frame-details-only text-center pb-sm-5 pb-3 mob-only'>
          Framing memories, achievements or inspirations have a<br />
          touch of belonging to them. Whether it’s an instant <br />
          joyous moment, a tempting culinary delicacy or a <br />
          passionately crafted artwork, you can frame any image
          <br />
          online from the comfort of your home.
        </p>
        <div className='steps flex-nowrap px-sm-4 px-2'>
          <div className='one brandon'>
            <p className='count mob-font-10'>1</p>
            <p className='brandon mob-font-10 detail pl-sm-3 pl-1'>
              Upload a photo
            </p>
          </div>
          <div className='two'>
            <p className='count mob-font-10'>2</p>
            <p className='brandon mob-font-10 detail pl-sm-3 pl-1'>
              Select a frame
            </p>
          </div>
          <div className='three'>
            <p className='count mob-font-10'>3</p>
            <p className='brandon detail pl-sm-3 pl-1 d-none d-sm-flex'>
              Frame is delivered ready to hang
            </p>
            <p className='brandon mob-font-10 detail pl-sm-3 pl-1 mob-only d-sm-none d-flex'>
              Frame is delivered
            </p>
          </div>
        </div>
      </div>
      <div className='start-frame-banner'>
        <div className='start-frame-banner-img-container'>
          <img src="/assets/Home/Framing.webp" />
        </div>
        <div className='button p-absolute'>
          <Link
            to="/custom-framing/"
            onClick={() => {
              localStorage.setItem('artmode', 'frame')
              localStorage.setItem('flowtype', 'framing')
            }}
            className='banner-button'
          >
            Start Framing
          </Link>
        </div>
      </div>
      <div className='container frame-carousel my-5 desk-only'>
        <Slider {...settings}>
          {frames.map(data => {
            // console.log("text-length", data.description.length)
            return (
              <div className='carousel item px-sm-3 px-2 inner'>
                <div className='art-carousel-item'>
                  <div className='image'>
                    <img
                      src={data.image}
                      className='frame-carousel-img p-sm-3 p-2'
                    />
                  </div>
                  <div className='art-head GT-bold text-center'>
                    {data.title}
                  </div>
                  <div
                    className={
                      data.description.length > 70
                        ? 'descr px-2 mt-2 mb-3'
                        : 'px-2 descr mt-2 mb-3'
                    }
                  >
                    <p className='brandon text-center'>{data.description}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
      <div className='container frame-carousel my-2 mob-only'>
        <Slider {...settings1}>
          {frames.map(data => {
            return (
              <div className='carousel item px-sm-3 px-2 inner'>
                <div className='art-carousel-item'>
                  <div className='image'>
                    <img
                      src={data.image}
                      className='frame-carousel-img p-sm-3 p-2'
                    />
                  </div>
                  <div className='art-head GT-bold text-center'>
                    {data.title}
                  </div>
                  <p className='brandon text-center pb-2'>{data.description}</p>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
      <div className='bullet row py-sm-5 py-5 px-10 px-5'>
        {state.homeBLock3
          ? state.homeBLock3.data
            ? state.homeBLock3.data.map((data, index) => {
              let array = data.attributes.info.split(' ')
              // console.log('arraylist', array)
              const get_field_icon_set = state.homeBLock3.included.filter(
                x => x.id === data.relationships.field_icon_set.data[0].id
              )[0].relationships.thumbnail.data.id
              let image_url =
                base_url +
                state.homeBLock3.included.filter(
                  x => x.id === get_field_icon_set
                )[0].attributes.uri.url
              return (
                <div className='bullet-cont column col-sm-4 col-12 text-center'>
                  <div className='d-table-cell'>
                    <img alt='icon' src={image_url} />
                  </div>
                  {bullet ? (
                    <h2
                      className={
                        index == 1
                          ? 'head second-head secondarycolor'
                          : 'head other-head secondarycolor'
                      }
                    >
                      <div>{bullet[index].first}</div>
                      <div> {bullet[index].second}</div>
                    </h2>
                  ) : (
                    ''
                  )}
                  {data.attributes.body ? (
                    <p className='brandon'>
                      {data.attributes
                        ? HtmlParser(data.attributes.body.value)
                        : ''}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              )
              // }
            })
            : ''
          : ''}
      </div>
      <div className='container mb-6 my-5 frame-cards-container'>
        <h2 className='art-love gallery-love-heading text-center mt-3 mt-sm-2 secondarycolor'>
          In Love With Gallery Walls
        </h2>
        <p className='px-0 px-lg-5 custom-frame-details brandon pb-5  text-center desk-only'>
          Rendering your spaces with a touch of thoughtfulness and artistic
          allure, gallery walls
          <br />
          are a great way to showcase your treasured photos, art prints and
          more.
        </p>
        <p className='px-0 px-sm-5 font-12 custom-frame-details brandon pb-5  text-center mob-only'>
          Rendering your spaces with a touch of thoughtfulness and
          <br />
          artistic allure, gallery walls are a great way to showcase your
          <br />
          treasured photos, art prints and more.
          <br />
        </p>
        <div className='frame-cards gallery-wall-carousel desk-only'>
          {state.galleryWalls ? (
            state.galleryWalls.data ? (
              <Slider {...settingsg}>
                {state.galleryWalls.data.map((data, index) => {
                  const img = state.galleryWalls.included
                  // console.log(data.relationships.field_gallery_thumbnail.data.id, 'imgs')
                  const get = Object.values(img).filter(
                    x =>
                      x.id ===
                      data.relationships.field_gallery_thumbnail.data?.id
                  )
                  let path = data.attributes?.field_path_alias
                  // console.log(get[0].attributes.uri.url, 'geticon-uri')
                  return (
                    <Link
                      to={`${path}/`}
                      className='carousel item px-sm-3 px-2 inner'
                    >
                      <div className='art-carousel-item p-3 pb-4'>
                        <div className='image'>
                          {get[0] ? (
                            <img
                              alt='icon'
                              src={base_url + get[0].attributes.uri.url}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                        {data.attributes ? (
                          <div className=' mt-3 frame-card-title GT'>
                            {data.attributes.title ? data.attributes.title : ''}
                          </div>
                        ) : (
                          ''
                        )}
                        {data.attributes ? (
                          <p className='frame-card-sub brandon'>
                            <span>
                              {data.attributes.field_number_of_frame
                                ? data.attributes.field_number_of_frame
                                : ''}
                              &nbsp;Frames |{' '}
                            </span>
                            <span>
                              {data.attributes.field_layout_size
                                ? data.attributes.field_layout_size
                                : ''}
                            </span>
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                    </Link>
                  )
                })}
              </Slider>
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </div>
        <div className='frame-cards gallery-wall-carousel mob-only'>
          {state.galleryWalls ? (
            state.galleryWalls.data ? (
              <Slider {...settings1}>
                {state.galleryWalls.data.map((data, index) => {
                  const img = state.galleryWalls.included
                  // console.log(data.relationships.field_gallery_thumbnail.data.id, 'imgs')
                  const get = Object.values(img).filter(
                    x =>
                      x.id ===
                      data.relationships.field_gallery_thumbnail.data?.id
                  )
                  let path = data.attributes?.field_path_alias
                  // console.log(get[0].attributes.uri.url, 'geticon-uri')
                  return (
                    <Link
                      to={`${path}/`}
                      className='carousel item px-sm-3 px-2 inner'
                    >
                      <div className='art-carousel-item p-3'>
                        <div className='image'>
                          {get[0] ? (
                            <img
                              alt='icon'
                              src={base_url + get[0].attributes.uri.url}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                        {data.attributes ? (
                          <div className=' mt-3 frame-card-title GT'>
                            {data.attributes.title ? data.attributes.title : ''}
                          </div>
                        ) : (
                          ''
                        )}
                        {data.attributes ? (
                          <p className='frame-card-sub brandon'>
                            <span>
                              {data.attributes.field_number_of_frame
                                ? data.attributes.field_number_of_frame
                                : ''}
                              &nbsp;Frames&nbsp; | &nbsp;
                            </span>
                            <span>
                              {data.attributes.field_layout_size
                                ? data.attributes.field_layout_size
                                : ''}
                            </span>
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                    </Link>
                  )
                })}
              </Slider>
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </div>
      </div>
      <div className='view-store-banner p-relative desk-only'>
        {Ucontext.state.homeBlock2 ? (
          <Slider {...bannersettings}>
            {Ucontext.state.homeBlock2.included
              ? Ucontext.state.homeBlock2.included.map(data => {
                return (
                  <div className='viewstore-banner-img'>
                    <div
                      src={base_url + data.attributes.uri.url}
                      className='shopart-bannerimg'
                      style={{
                        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url(${base_url + data.attributes.uri.url
                          }) no-repeat`,
                        height: '100%'
                      }}
                    />
                  </div>
                )
              })
              : ''}
          </Slider>
        ) : (
          ''
        )}
        {
          <div className='flg-ship-container'>
            <div className='flg-ship container GT py-1'>
              Flagship Store <br />
              Kochi
            </div>
          </div>
        }
      </div>
      <div className='view-store-banner p-relative mob-only'>
        {Ucontext.state.homeBlock2 ? (
          <Slider {...bannersettings}>
            {Ucontext.state.homeBlock2.included
              ? Ucontext.state.homeBlock2.included.map(data => {
                return (
                  <div className='viewstore-banner-img'>
                    <div
                      src={base_url + data.attributes.uri.url}
                      className='shopart-bannerimg'
                      style={{
                        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(${base_url + data.attributes.uri.url
                          }) no-repeat`,
                        height: '100%',
                        backgroundSize: '100% 100%'
                      }}
                    />
                  </div>
                )
              })
              : ''}
          </Slider>
        ) : (
          ''
        )}
        <div className='flg-ship-container'>
          <div className='flg-ship container GT py-1'>
            Flagship Store <br />
            Kochi
          </div>
        </div>
      </div>
      <div className='three-head'>
        <div className='container'>
          <div className='art-love curated-row d-flex justify-content-between lower-art-love mb-1'>
            <div>
              {/*    <img src='/assets/curated.png' /> */}
              <p>
                Curated
                <br /> Art
              </p>
            </div>
            <div>
              {/* <img src='/assets/meticulous.png' /> */}
              <p>
                Meticulously
                <br /> Crafted Frames
              </p>
            </div>
            <div>
              {/* <img src='/assets/global.png' /> */}
              <p>
                Global Artist
                <br /> Community
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='view-store-banner view-store-banner-2 p-relative mob-only bg-white'>
        <Slider {...bannersettings}>
          {custom
            ? custom.map(data => {
              return (
                <div className='viewstore-banner-img'>
                  <div
                    className=''
                    style={{
                      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url(${data.image})`,
                      backgroundSize: '100% 100%'
                    }}
                  />
                </div>
              )
            })
            : ''}
        </Slider>
        <div className='flg-ship-container'>
          <div className='flg-ship container GT py-1'>
            Custom Framing
            <br /> Studio
          </div>
        </div>
      </div>
      <div className='view-store-banner view-store-banner-2  p-relative desk-only bg-white'>
        <Slider {...bannersettings}>
          {custom
            ? custom.map(data => {
              return (
                <div
                  className='viewstore-banner-img'
                  style={{
                    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))`
                  }}
                >
                  <div
                    className=''
                    style={{
                      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url(${data.image})`,
                      height: '100%'
                    }}
                  />
                </div>
              )
            })
            : ''}
        </Slider>
        <div className='flg-ship-container'>
          <div className='flg-ship container GT py-1'>
            Custom Framing
            <br /> Studio
          </div>
        </div>
      </div>
      <div className='start-frame-banner mixed-media-banner'>
        <div className='start-frame-banner-img-container'>
          <img src="/assets/Mixed-Media.png" />
        </div>
        <div className='button p-absolute'>
          <Link
            to="/mixed-media/"
            onClick={() => {
              localStorage.setItem('artmode', 'frame')
              localStorage.setItem('flowtype', 'framing')
            }}
            className='banner-button'
          >
            Mixed Media
          </Link>
        </div>
      </div>
      <div className='view-store-banner view-store-banner-2 p-relative mob-only'>
        <Slider {...bannersettings}>
          {design
            ? design.map(data => {
              return (
                <div className='viewstore-banner-img'>
                  <div
                    className=''
                    style={{
                      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url(${data.image})`,
                      backgroundSize: '100% 100%'
                    }}
                  />
                </div>
              )
            })
            : ''}
        </Slider>
        <div className='flg-ship-container'>
          <div className='flg-ship container GT py-1'>
            Design Studio
          </div>
        </div>
      </div>
      <div className='view-store-banner view-store-banner-2  p-relative desk-only'>
        <Slider {...bannersettings}>
          {design
            ? design.map(data => {
              return (
                <div
                  className='viewstore-banner-img'
                  style={{
                    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))`
                  }}
                >
                  <div
                    className=''
                    style={{
                      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url(${data.image})`,
                      height: '100%'
                    }}
                  />
                </div>
              )
            })
            : ''}
        </Slider>
        <div className='flg-ship-container'>
          <div className='flg-ship container GT py-1'>
            Design Studio
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default Home
