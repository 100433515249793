import React, { useState } from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/header/header";
// import "./Partnerapply.css";
import { useHistory } from "react-router"
import axios from "axios";
import { useAlert } from "react-alert";
import { base_url } from "../../helpers/baseURL";
import Loader from "../../components/checkout/loader";
import "./Designart.css"


export const Designwall = () => {
    const history = useHistory()
    const alert = useAlert();
    const [loader, setloader] = useState(false);
    const [imgdata, setimg] = useState("");



    const convertimg = (img) => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("img/png");
        setimg(dataURL.replace(/^data:image\/(png|jpg);base64,/, ""));

    }


    const formsubmit = (e) => {

        setloader(true)
        const fname = document.getElementById("fname").value;
        const lname = document.getElementById("lname").value;
        const email = document.getElementById("email").value;
        const phone = document.getElementById("phone").value;
        const address = document.getElementById("address").value;
        const city = document.getElementById("city").value;
        const state = document.getElementById("state").value;
        const pin = parseInt(document.getElementById("pin").value);
        const about = document.getElementById("about").value;
        const img = document.getElementById("img").value;
        console.log(fname)
        console.log(lname)
        console.log(email)
        console.log(about)
        console.log(img)
        console.log(phone)
        console.log(address)
        console.log(city)
        console.log(state)
        console.log(pin)

        var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
        var mobileno = /^((\\+91-?)|0)?[0-9]{10}$/;



        if (fname && about && phone && address && city && state && pin && phone && email) {

            if ((!phone.match(phoneno)) || (!phone.match(mobileno))) {
                setloader(false)
                alert.show("Enter Valid Phone Number")

            }
            else {

                if (!img) {
                    setloader(false)
                    alert.show("Photo not uploaded")
                }
                else {
                    var axios = require('axios');
                    var data = img;

                    var config = {
                        method: 'post',
                        url: `${base_url}/jsonapi/media/image/field_media_image`,
                        headers: {
                            'Accept': 'application/vnd.api+json',
                            'Content-Type': 'application/octet-stream',
                            'X-CSRF-Token': 'anOsmfJ2U1DlJP6DKwIKZ1IAP62zC2_rynvGLwdREFQ',
                            'Content-Disposition': 'file;filename="maxresdefault.jpg"',
                            'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImM1YWNiYmYzNjNjNjlkZDU4YmIxMDE0NTY5MGEwZDNhNWFkYjg1NmFhNGQxYjlmNzNmZjhhOTZhZDYxMDEyMzE1Yzk2MWQ4ZTRjYTgzYTc5In0.eyJhdWQiOiI4ZDUyYWFhYS1lNjgwLTQzYjgtOGZiZS0zNGIzYTAxNTIwYmIiLCJqdGkiOiJjNWFjYmJmMzYzYzY5ZGQ1OGJiMTAxNDU2OTBhMGQzYTVhZGI4NTZhYTRkMWI5ZjczZmY4YTk2YWQ2MTAxMjMxNWM5NjFkOGU0Y2E4M2E3OSIsImlhdCI6MTYxNzY4MTcwOSwibmJmIjoxNjE3NjgxNzA5LCJleHAiOjE2MjA4OTEzMDksInN1YiI6IjEiLCJzY29wZXMiOlsiYXV0aGVudGljYXRlZCIsInVzZXIiXX0.ukbSQMsiugiqkzeB3DYX8wPUmg4JeDUf822TUqtCplIYIC_P4kPP-rq1YnD4pPfWMQXWwqCVDvmUmWwJQVcj_cJ5rr1du6Ux4D8vlAyrsWukwYRoPmUlTfpkmJzmtYQO9LzKGoeKJJPOrYhuCQpF2LeoRCJpgqrATb53R4VvOxOM-T8naFNDdD_CRWNbsCLkAI8Vq2oM_pHfyn4-vRVs0iSW52Ogm53LvjxPqokhU8n7Jzu6bpY2MUdm1TciHaKm9Rw5PEMJTWSe9DJMKNPtvICKEz4veSs0hCLwLb4TO35cp-EXF3GoS_e560UdG3xavDvLoNBDkL1MHbFDkyXrmA',
                            'Cookie': 'SSESSec5a92862a48f121bdb88ebb0ed1b008=4CYfssdwKWaZSX3I8fmNMbuuFlNeAOEtLO7Re0aymuA'
                        },
                        data: data
                    };

                    axios(config)
                        .then(function (response) {
                            console.log(JSON.stringify(response.data.data.id));
                            var data = JSON.stringify({
                                "data": {
                                    "type": "paragraph--author_data",
                                    "attributes": {
                                        "parent_id": "39",
                                        "parent_type": "node",
                                        "parent_field_name": "field_image_of_art",
                                        "field_published": false
                                    },
                                    "relationships": {
                                        "field_image_of_form": {
                                            "data": {
                                                "type": "file--file",
                                                "id": response.data.data.id
                                            }
                                        }
                                    }
                                }
                            });

                            var config = {
                                method: 'post',
                                url: `${base_url}/jsonapi/paragraph/form_image`,
                                headers: {
                                    'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjcwZTExMzAwOGNlNjAxZjc4ZGQxOWZmY2ExOWYzMzU5NzI4YzE3OTU0Y2U0MzAyMDQzODkwYzMxZTIyNWU3ZDdkNmE1MmVlOTczNGM4ZTc3In0.eyJhdWQiOiJhYjE1NzU1Yy00N2Y3LTQ2OTktYjMwNi03NWY2YTU4YzAwYjciLCJqdGkiOiI3MGUxMTMwMDhjZTYwMWY3OGRkMTlmZmNhMTlmMzM1OTcyOGMxNzk1NGNlNDMwMjA0Mzg5MGMzMWUyMjVlN2Q3ZDZhNTJlZTk3MzRjOGU3NyIsImlhdCI6MTYzNDY0NTY2OSwibmJmIjoxNjM0NjQ1NjY5LCJleHAiOjE2MzQ2NDc0NjksInN1YiI6IjgzNiIsInNjb3BlcyI6WyJhdXRoZW50aWNhdGVkIl19.rdAQ8qqHFvaUUPQmRORCnzMo9icgDFMQEiW3ADC7aNwpfi1YAuRsoZig_cWDxbob828lb6WuFhrEbBBfzEC-8JFvmN8fHLLdesnsd44jb6eR6CTxyHSZup8B8r2ItZ_3GQXpx6GMS4Qtql9dfSADLFxBHuyb_nl3vuLcPWUDgfF6m5RtqF5ENZa8AJTCOw-UQb70K2fN7-D_ehOilZNmUUkmiC1POPVrZxvG2iCg__QKB906kZM_Uc5UQ4mhss-LkfrM-EicXZV337JY-zBzVQqAk31my31o4LouuzTfwXqYd24WfEM_hBM7NZ64XWQEyfw2wuZ1VmF2R781NMxxhA',
                                    'Content-Type': 'application/vnd.api+json',
                                    'Accept': 'application/vnd.api+json',
                                    'Cookie': 'SSESSec5a92862a48f121bdb88ebb0ed1b008=4CYfssdwKWaZSX3I8fmNMbuuFlNeAOEtLO7Re0aymuA'
                                },
                                data: data
                            };

                            axios(config)
                                .then(function (response) {
                                    console.log(JSON.stringify(response.data.data.id));
                                    var data = JSON.stringify({
                                        "data": {
                                            "type": "contact_message--art_advisory",
                                            "attributes": {
                                                "name": fname,
                                                "mail": email,
                                                "subject": "art advisory",
                                                "message": about,
                                                "field_address_gallery_wall": address,
                                                "field_city_gallery_wall": city,
                                                "field_last_name_gallery_wall": lname,
                                                "field_phone_number_gallery_wall": phone,
                                                "field_pin_code_gallery_wall": pin,
                                                "field_state_gallery_wall": state
                                            },
                                            "relationships": {
                                                "field_gallery_wall_image": {
                                                    "data": [
                                                        {
                                                            "type": "paragraph--form_image",
                                                            "id": response.data.data.id,
                                                            "meta": {
                                                                "target_revision_id": 1
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        }
                                    });

                                    var config = {
                                        method: 'post',
                                        url: `${base_url}/jsonapi/contact_message/gallery_wall_consultation`,
                                        headers: {
                                            'Accept': 'application/vnd.api+json',
                                            'Content-Type': 'application/vnd.api+json',
                                            'Cookie': 'SSESSec5a92862a48f121bdb88ebb0ed1b008=4CYfssdwKWaZSX3I8fmNMbuuFlNeAOEtLO7Re0aymuA'
                                        },
                                        data: data
                                    };

                                    axios(config)
                                        .then(function (response) {
                                            console.log("success");
                                            setloader(false)
                                            alert.show("Form submitted", { type: "success" })
                                            document.getElementById("designart-form").reset();
                                            history.push("/")
                                        })
                                        .catch(function (error) {
                                            setloader(false)
                                            console.log(error)
                                            alert.show("Form not submitted")
                                        });


                                })
                                .catch(function (error) {
                                    setloader(false)
                                    alert.show("Photo not uploaded")
                                });
                        })
                        .catch(function (error) {
                            setloader(false)
                            alert.show("Photo not uploaded");
                        });
                }

            }
        }
        else {
            setloader(false)
            alert.show("Enter All Details")
        }



    }

    return (
        <div className="designart-container">
            <div className="partner-container designart-conrtainer">
                <Header />
                {loader ? <Loader /> : ""}
                <div className="header-container">
                    <div className="selectBack pt-3 brandon d-flex align-self-left" onClick={() => history.goBack()}>
                        <img src="/assets/arrow-left.webp" className="selectBackIcon" alt="thumb"></img>
                        <p className='ml-2'>Back</p>
                    </div>
                </div>
                <div className="container partnerapply-container">
                    <div className="partner-title art-love text-center">
                        Design Services - Gallery Wall Consultation
                    </div>
                    <div className="partner-subtitle partner-subtitle1 text-center brandon">
                        Please fill out the form below
                    </div>
                    <form id="designart-form" className="d-flex flex-column partner-form mx-auto" onSubmit={(e) => { e.preventDefault(); formsubmit(e) }}>
                        <input name="First Name" id="fname" className="partner-input" placeholder="First Name" />
                        <input name="Last Name" id="lname" className="partner-input" placeholder="Last Name" />
                        <input name="Email" id="email" className="partner-input" placeholder="Email" type={"email"} />
                        <input name="Phone Number" id="phone" className="partner-input" placeholder="Phone Number" />
                        <input name="Address" id="address" className="partner-input" placeholder="Address" />
                        <div className="d-lg-flex text-center designartaddress-container mx-auto justify-content-between">

                            <input name="City" id="city" className="partner-input mr-0 ml-0" placeholder="City" />
                            <input name="State" id="state" className="partner-input mr-0" placeholder="State" />
                            <input name="Pin Code" id="pin" className="partner-input mr-0" placeholder="Pin Code" />
                        </div>
                        <textarea type={"text"} name="about" id="about" className="partner-input partner-inputmsg" placeholder="Tell us about your project (please include area dimensions, wall colours, your art style preferences, and any other details about the project)" />
                        <div className="">
                            <label for="img" className="partner-input mb-0 d-flex justify-content-center align-items-center brandon mb-0 designupload-text">
                                <img className="designupload-img" src="/assets/design/upload.png" />
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                Upload Photo
                            </label>
                            <input type="file" id="img" name="img" accept="image/*" className="d-none" onChange={(e) => {
                                alert.show("Photo Uploaded");
                                convertimg(e.target);
                            }} />
                        </div>
                        <div className="brandon mx-auto designart-note">(Please add a note to each upload mentioning the particular space, for example living room, dining area, bedroom etc.)</div>
                        <input type="submit" className="partner-submit text-uppercase" />
                    </form>
                </div>
                <Footer />
            </div>
        </div>
    )
}