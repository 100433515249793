import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Phone from '../../assets/phone.png'
import Return from '../../assets/return.png'
import Shipping from '../../assets/shipping.png'

const ReviewOrder = (props) => {
    useEffect(() => {
        console.log("data of order review", props.data)
    }, [])
    let address = props.data?.shipping_id?.data
    let delivery = props.data?.delivery_id?.data
    return (
        <div className='d-flex flex-column h-100 pb-5'>
            <div className="pricetable">
                <h6 className="checkout-side-head GT-bold">Order review</h6>
                {address ? <div className="table-list">
                    <div className='table-row'>
                        <div className='column1'>
                            <h6 className=' brandon'>Billing</h6>
                            <div className='brandon'>
                                {address?.attributes?.address?.given_name} {address?.attributes?.address?.family_name}<br />
                                {address.attributes ? address.attributes.field_contact_name ? <span>
                                    {address.attributes.field_contact_name} <br />
                                </span> : "" : ''}
                                {address.attributes ? address.attributes.field_gstin ? <span>
                                    {address.attributes.field_gstin} <br />
                                </span> : "" : ''}
                                {address.attributes ? address.attributes.field_house_apartment_number : ''}<br />
                                {address.attributes ? address.attributes.address ? address.attributes.address.address_line1 ? address.attributes.address.address_line1 : "" : '' : ''}
                                {address.attributes ? address.attributes.address ? address.attributes.address.locality ? ", " + address.attributes.address.locality : '' : "" : ''} <br />
                                {address.attributes ? address.attributes.field_pincode : ''} <br />
                                {address.attributes ? address.attributes.field_state : ''} <br />
                                {address.attributes ? address.attributes.field_email : ''} <br />
                                {address.attributes ? address.attributes.field_phone_number : ''}
                            </div>
                            <h6 className=' brandon mt-3'>Delivery</h6>
                            {address?.id === delivery?.id ?
                                <div className='brandon'>
                                    Same as Billing Address
                                </div> :
                                delivery ? <div className='brandon'>
                                    {delivery?.attributes.address?.given_name} {delivery?.attributes.address?.family_name}<br />
                                    {delivery.attributes ? delivery.attributes.field_phone_number : ''}<br />
                                    {delivery.attributes ? delivery.attributes.field_house_apartment_number : ''}<br />
                                    {delivery.attributes ? delivery.attributes.address ? delivery.attributes.address.address_line1 ? delivery.attributes.address.address_line1 : "" : '' : ''}
                                    {delivery.attributes ? delivery.attributes.address ? delivery.attributes.address.locality ? ", " + delivery.attributes.address.locality : '' : "" : ''} <br />
                                    {delivery.attributes ? delivery.attributes.field_pincode : ''} <br />
                                    {delivery.attributes ? delivery.attributes.field_state : ''} <br />
                                </div>
                                    : ""}
                        </div>
                        <div className='column2'>
                            <Link to='/checkout/shipping/'>Edit</Link>
                        </div>
                    </div>
                </div> : ""}
                {<div className='contact-us mt-4 p-0'>
                    <div className='row align-items-center'>
                        <img className='rev-stats' src={Phone} />
                        <div className='brandon'>
                            <h6 className='mb-0'>Call us</h6>
                            <p>0484 270 0090</p>
                        </div>
                    </div>
                    <div className='row align-items-center mt-4'>
                        <img className='rev-stats' src={Shipping} />
                        <div className='brandon'>
                            <h6 className='mb-0'>Shipment Details</h6>
                            <p>Shipment details will be updated once the order is dispatched.</p>
                        </div>
                    </div>
                    {/* <div className=''>
                        <div className='row'>
                            <img className='rev-stats' src={Return} />
                            <div className=''>Changed your mind?</div>
                        </div>
                        <div className='h9'>
                            <p>Rest assured, we offere free returns within 30 days</p>
                        </div>
                    </div> */}
                </div>}
            </div>
            <Link to="/" className="black-btn brandon bgsecondary mt-3 whitecolor d-flex justify-content-center align-items-center">
                CONTINUE SHOPPING
            </Link>
        </div>
    );
}

export default ReviewOrder;
