



import HTMLReactParser from 'html-react-parser';
import React, { useContext, useEffect, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Popup from 'reactjs-popup';
import { Context as ArtContext } from '../../context/ArtContext';


const FrameEditPreview = (props) => {
    const [updated, setupdateheight] = useState("")
    const { state, zoomOpen } = useContext(ArtContext);
    const [width, setwidth] = useState(200);
    const [padding, setpadding] = useState("");
    const [height, setheight] = useState();
    const [sidewidth, setSidewidth] = useState("28.6667px");
    // const [sideheight, setSideheight] = useState("387px");
    const [frame, setFrame] = useState(state.frontFrame);
    const [framewidth, setframewidth] = useState(21.5);
    const [picture, setPicture] = useState('');
    const [sideframe, setSideFrame] = useState(state.sideFrame);
    const [padColor, setPadColor] = useState("#fff");
    const [crop, setCrop] = useState({ aspect: 16 / 9 });
    console.log("all props", props, "and padding", state.padding)
    useEffect(() => {
        console.log(`working padding final ${padding}`)
        console.log(`working framewidth final ${framewidth}`)
    }, [padding, framewidth])
    useEffect(() => {
        console.log('value', props.value)
        console.log("cartpreview", props.props)
        console.log(localStorage.getItem('ratio'))
        if (props.value) {
            if (props.place === "single_page") {
                if (props.framing.depth) {
                    if (props.framing.papertype === "Paper") {
                        console.log("working depth", props.framing.depth, "padding", state.padding, "framesize", props.framing.frame)
                        console.log("working final depth", props.framing.depth + 2 * state.padding + 2 * props.framing.frame)
                        let finaldepth = props.framing.depth + 2 * state.padding + 2 * props.framing.frame
                        let padding = (props.value / Number(finaldepth)) * state.padding
                        let framewidth = (props.value / Number(finaldepth)) * (props.framing.frame)
                        let width = (props.value / Number(finaldepth)) * props.framing.depth
                        console.log("working final calculated: depth", width, "or", "padding", padding, "framesize", framewidth)
                        if (width > 0) {
                            setwidth(width)
                            setheight((width * (1 / localStorage.getItem('ratio'))))
                            setpadding(padding)
                            setframewidth(framewidth)
                        }
                        else {
                            setwidth(50)
                            setheight(50 * (1 / localStorage.getItem('ratio')))
                        }
                        console.log("final padding and frame", padding, framewidth, "and width", (props.value - (2 * padding) - framewidth))
                        setupdateheight("with props.value")
                    }
                    else {
                        setwidth(props.value)
                        setupdateheight("with props.value")
                        setheight(props.value * (1 / localStorage.getItem('ratio')))
                    }
                }
            }
            else {
                setwidth(props.value)
                setupdateheight("with props.value")
                setheight(props.value * (1 / localStorage.getItem('ratio')))
            }
            console.log("height in props.value", props.value * (1 / localStorage.getItem('ratio')))
            if (props.value == 40)
                setSidewidth("4px")
            else if (props.value == 80)
                setSidewidth("7px")
            else if (props.value == 150)
                setSidewidth("11px")
        }
        if (props.image)
            setPicture(props.image)
        if (props.props) {
            if (props.image)
                setPicture(props.image)
            else if (props.props.artid) {
                setPicture(props.props.artid)
            }
            if (props.props.ratio) {
                setupdateheight("with props.props.ratio")
                setheight(props.value * (1 / Number(props.props.ratio)))
                console.log("ratio in props.props", props.value, props.value * (1 / props.props.ratio))
            }
            if (props.props.frameid) {
                setFrame(props.props.frameid.front)
                setSideFrame(props.props.frameid.side)
            }
            if (props.props.padding) {
                if (localStorage.getItem("papertype") == "Paper") {
                    setpadding((props.value / props.props.width) * props.props.padding)
                }
                else {
                    if (props.props.width < 13)
                        setpadding((props.value / Number(props.props.width)) * 0.3)
                    else if (props.props.width <= 28)
                        setpadding((props.value / props.props.width) * 0.2)
                    else
                        setpadding((props.value / props.props.width) * 0.4)
                }
                console.log("padding", (props.value / props.props.width) * props.props.padding)
            }
            if (props.props.depth) {
                console.log("padding value", Number(props.props.depth), (props.value / state.ratio.width) * props.props.padding)
                if (localStorage.getItem("papertype") == "Paper") {
                    if (props.props.padding || props.props.padding == 0)
                        setpadding((props.value / Number(props.props.depth)) * props.props.padding)
                    else
                        setpadding((props.value / Number(props.props.depth)) * state.padding)
                }
                else {
                    if (props.props.depth < 13)
                        setpadding((props.value / Number(props.props.depth)) * 0.3)
                    else if (props.props.depth <= 28)
                        setpadding((props.value / Number(props.props.depth)) * 0.2)
                    else
                        setpadding((props.value / Number(props.props.depth)) * 0.4)
                }
            }
        }
        if (props.place === "shopart") {
            setpadding((props.value / Number(props.framing.depth)) * props.props.padding)
        }
        if (props.framing) {
            if (props.framing.depth) {
                console.log("padding value", Number(props.framing.depth), (props.value / state.ratio.width) * state.padding)
                if (localStorage.getItem("papertype") == "Paper") {
                    if (props.place !== "single_page")
                        if (props.props) {
                            props.props.padding || props.props.padding == 0 ?
                                setpadding((props.value / Number(props.framing.depth)) * props.props.padding)
                                : setpadding((props.value / Number(props.framing.depth)) * state.padding)
                        }
                        else {
                            setpadding((props.value / Number(props.framing.depth)) * state.padding)
                        }
                }
                else {
                    if (props.framing.depth < 7)
                        setpadding((props.value / Number(props.framing.depth)) * 0.3)
                    else if (props.framing.depth <= 20.4)
                        setpadding((props.value / Number(props.framing.depth)) * 0.2)
                    else
                        setpadding((props.value / Number(props.framing.depth)) * 0.4)
                }
            }
            if (props.framing.frame) {
                console.log("framewidth", props.framing.frame, (props.value / Number(props.framing.depth)) * props.framing.frame)
                setSidewidth(`${(props.value / Number(props.framing.depth)) * props.framing.frame}px`)
                if (props.place !== "single_page" || localStorage.getItem("papertype") == "Canvas")
                    setframewidth((props.value / Number(props.framing.depth)) * (props.framing.frame / 2))
                console.log("product frame width", (props.value / Number(props.framing.depth)) * (props.framing.frame / 2), props.framing.frame)
            }
        }
    }, [props])

    useEffect(() => {
        console.log("changed height in frameeditpreview in ", updated, "as: ", height)
    }, height)
    React.useEffect(() => {

        // var file = JSON.parse(localStorage.getItem('showimage'))
        // let reader = new FileReader()
        // reader.readAsDataURL(file)
        // reader.onload = () => {
        //     setPicture(reader.result)
        // };
        console.log('final', state.cropImg)
        if (localStorage.getItem("user_upload")) {
            setPicture(localStorage.getItem('cropped'))
            localStorage.getItem('ratio') ? setheight(230 * (1 / localStorage.getItem('ratio'))) : setheight(230)
        }
        else {
            if (props.image) {
                setPicture(props.image)
                console.log("firing")
            }
            else if (state.artData) {
                setPicture(state.artData.img)
                console.log('artData.img', state.artData.img)
            }
            else {
                setPicture(localStorage.getItem('showimage'))
                setupdateheight("art 1")
                localStorage.getItem('ratio') ? setheight(230 * (1 / localStorage.getItem('ratio'))) : setheight(230)
            }
        }

    }, []);
    React.useEffect(() => {
        setFrame(state.frontFrame)
    }, [state.frontFrame])
    React.useEffect(() => {
        if (props.props) {
            props.props.padding || props.props.padding == 0 ? setpadding(props.props.padding) : setpadding(state.padding)
        }
        else
            setpadding(state.padding)
    }, [state.padding])
    return (
        <div className="frame-edit-preview-edit">
            <div className="App">
                <div id="frame-container">
                    <div id="frame-3d" className="">
                        <div className="three_d_box_container three_d_box_container_10_1 panels-backface-invisible"
                            style={{ width: width + (2 * padding) + (2 * framewidth), height: height + (2 * padding) + (2 * framewidth), transition: `transform 1s ease 0s`, opacity: 1 }}
                        >
                            <figure className="three_dimensional_frame three_dimensional_frame_10_1 front"
                                style={{ borderImageSlice: 85, borderStyle: "solid", borderWidth: `${framewidth}px`, borderImageSource: `url(${frame})`, borderImageWidth: `${framewidth}px`, borderImageRepeat: 'stretch', width: width + (2 * padding), height: height + (2 * padding), background: "transparent", boxShadow: "rgba(0, 0, 0, 0.7) 0px 2px 5px inset", transform: 'translateZ(0px)' }}
                            >
                                {/* <div className="overlay_gradient overlay_gradient_10_1"
                                    style={{ opacity: 1, width: width, height: height }} >
                                </div> */}
                            </figure>
                            {/* <figure className="float_mount three_d_face">
                                <div className="back_float_mount_shadows">
                                </div>
                            </figure> */}
                            <figure className="front_mount three_d_face"
                                style={{ background: `url(${picture}) center ${padding}px / ${width}px no-repeat ${localStorage.getItem("papertype") == "Canvas" ? "#333333" : state.padColor}`, width: width + (2 * padding), height: height + (2 * padding), top: framewidth, left: framewidth, boxShadow: `${localStorage.getItem("papertype") == "Canvas" ? "#333333" : state.padColor} 0px 0px 0px inset, ${localStorage.getItem("papertype") == "Canvas" ? "#333333" : state.padColor} 0px 0px 0px ${padding}px inset` }}></figure>
                            {/* <figure className="right three_d_face"
                                style={{ background: `url(${sideframe}) center 48% / 60.2px no-repeat rgb(51, 51, 51)`, width: sidewidth, height: height + (2*framewidth), top: 0, left: 2 * padding + width + 12.33 }}>
                            </figure> */}
                            {/* <figure className="left three_d_face">
                            </figure> */}
                            {/* <figure className="back three_d_face"
                                style={{ borderImageSlice: 85, borderStyle: "solid", borderWidth: framewidth, borderImageSource: `url(${frame})`, borderImageWidth: framewidth, borderImageRepeat: 'stretch', width: (2 * padding) + width - 2, height: height + (2 * padding), background: `rgb(51, 51, 51)`, boxShadow: "rgba(0, 0, 0, 0.4) 0px 1px 20px 1px inset", transform: 'rotateY(0deg) translateZ(-28.6667px) translateY(0px) translateX(0px)' }}>
                                <div className="back_frame_shadows"
                                    style={{ opacity: 1, top: -framewidth, left: -framewidth, width: width + (2 * padding), height: height + (2 * padding) }}
                                >    
                                </div>
                            </figure> */}
                            {/* <figure className="top three_d_face"></figure>
                            <figure className="bottom three_d_face"></figure>
                            <figure className="top_inner three_d_face"></figure> */}
                            {/* <figure className="right_inner three_d_face"
                                style={{ background: `url(${sideframe}) round`, width: sidewidth, height: (2 * padding) + height + (framewidth*2), top: 0, left: (width + (2 * padding) + (framewidth/2)) }}>
                            </figure> */}
                            {/* <figure className="bottom_inner three_d_face"></figure>
                            <figure className="left_inner three_d_face"></figure>
                            <div className="front_frame_shadows"></div> */}
                        </div>
                    </div>
                    <div className="details brandon">
                        {state.framedata ? state.framedata.attributes ? state.framedata.attributes.body ? HTMLReactParser(state.framedata.attributes.body.value) : "" : '' : ""}
                    </div>
                    <div className="zoom-button-container">
                        <img src="/assets/zoom.png" className="zoom-button" onClick={() => zoomOpen(true)} />
                    </div>
                    {/* <div id="controls" className="col-sm-6">
                        <h4>Frames</h4>
                        <div className="frame-select">
                            {frameArray.map((item, index) => {
                                return <div className="select-thumbs" onClick={() => { setFrame(item.frame); setSideFrame(item.sideframe) }}>
                                    <img alt="icon" src={item.thumbnail} />
                                </div>
                            })}
                        </div>
                        <h4>Pictures</h4>
                        <div className="frame-select">
                            {picArray.map((item, index) => {
                                return <div className="select-thumbs" onClick={() => { setPicture(item) }}>
                                    <img alt="icon" src={item} />
                                </div>
                            })}
                        </div>

                        <h4>padding</h4>
                        <div className="size-input">
                            <input type="number" onChange={(e) => setpadding(Number(e.target.value))} value={padding} />
                        </div>
                        <h4>Pad color</h4>
                        <div className="frame-select">
                            {padColorArray.map((item, index) => {
                                return <div className="select-thumbs" style={{ backgroundColor: item, border: padColor === item ? "2px solid blue" : "1px solid grey" }} onClick={() => { setPadColor(item) }}>
                                </div>
                            })}
                        </div>
                        <h4>Dimensions</h4>
                        <div className="size-input">
                            <span>height</span>
                            <input type="number" onChange={(e) => setheight(Number(e.target.value))} value={height} />
                            <span>width</span>
                            <input type="number" onChange={(e) => setwidth(Number(e.target.value))} value={width} />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default FrameEditPreview;