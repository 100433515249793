import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useHistory, useParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Footer from '../../components/Footer/footer';
import Header from '../../components/header/header';
import './art.css'
import { Context as ArtContext } from '../../context/ArtContext';
import { base_url } from '../../helpers/baseURL';
import axios from 'axios';
import Loading from "react-js-loader";
import createHistory from 'history/createBrowserHistory'
import { MetaTags } from "react-meta-tags"


const history = createHistory()

history.listen((location, action) => {
    console.log('history in arts', location, action)
});
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}


const PerfectPairs = (props) => {
    const { state, getPerfectPairs, getFrameFilters, setArtFilters, setoffseta, setcounta, setCropImg, getSingleArt } = useContext(ArtContext);
    const query = useQuery()
    const historys = useHistory()
    const location = useLocation()
    let params = useParams();
    const prevLocationRef = useRef(location);
    const updateSearchParam = (paramName, paramValue) => {
        // Update the search parameter in the URL
        query.set(paramName, paramValue);

        // Get the updated search string
        const updatedSearchString = query.toString();
        historys.replace({
            search: updatedSearchString,
        });
    };
    React.useEffect(() => {
        setCropImg("")
        console.log("first calling")
        console.log("pathname", location.pathname)
        if (params.id)
            setOffset(Number(params.id) * 30 - 30)

        else {
            console.log("else is working here")
            setOffset(0)
            if (query.size) {
                clearall()
                if (query.get("theme"))
                    addtoselect({ "value": query.get("theme"), "attribute": "field_collections" })
                else if (query.get("style"))
                    addtoselect({ "value": query.get("style"), "attribute": "field_art_style" })
                else if (query.get("space"))
                    addtoselect({ "value": query.get("space"), "attribute": "field_space" })
                else if (query.get("artist")) {
                    addtoselect({ "value": query.get("artist"), "attribute": "field_artist" })
                }
            }
            else
                history.push(`/perfect-pairs/`)
        }
        getFrameFilters(state.frameFilter)
        // localStorage.removeItem("showimage")
        // localStorage.removeItem("ratio")
        localStorage.removeItem("user_upload")
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
        setcounta(0)
    }, []);

    const { onClickOutside } = props;

    const [themes, openTheme] = useState("false")
    const [orients, openOrient] = useState("false")
    const [colours, openColour] = useState("false")

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref1.current && !ref1.current.contains(event.target)) {
                onClickOutside && onClickOutside();
                openColour(false)
                openTheme(false)
                setFilter("0");
                openOrient(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);
    const [count, setCount] = useState()
    const [filter, setFilter] = useState("0");
    const [modalState, setModal] = useState(false)
    const [selectedArt, setSelectedArt] = useState()

    const [srch, setsrch] = useState('')
    const [offset, setOffset] = useState(null)
    const [flag, setflag] = useState(1)
    const [selected1, setSelect1] = useState([]);

    React.useEffect(() => {
        if (srch == "") {
            setflag(flag + 1)
        }
    }, [srch])
    React.useEffect(() => {
        console.log('calling API with offset', offset, ", flag ", flag)
        if (offset !== null) {
            if (location.pathname.includes("/art/theme")) {
                getPerfectPairs([{ "value": params.theme, "attribute": "field_collections" }], srch, offset)
            }
            else {
                let filters = sessionStorage.getItem("artFilters") ? JSON.parse(sessionStorage.getItem("artFilters")) : []
                getPerfectPairs(filters, srch, offset)
            }
        }
    }, [flag, offset, sessionStorage.getItem("artFilters")]);
    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            console.log('History change:', location.pathname, action);
            let regex = /^\/art\/\d+\/?$/;
            console.log("page matches ", regex.test(location.pathname))
            let page = location.pathname.match(/\d+/g)?.length ? location.pathname.match(/\d+/g)[0] : ""
            if (sessionStorage.getItem("artFilters"))
                setArtFilters(JSON.parse(sessionStorage.getItem("artFilters")))
            setOffset(page ? Number(page) * 30 - 30 : 0)
            window.scrollTo({
                top: 0,
                behavior: "smooth",
                left: 0
            })
            let filters = sessionStorage.getItem("artFilters") ? JSON.parse(sessionStorage.getItem("artFilters")) : []
            console.log("params changed", filters, page)
            // Perform any actions you want in response to the history change
        });

        // Cleanup function to remove the listener when the component unmounts
        return () => {
            unlisten();
        };
    }, []);
    React.useEffect(() => {
        console.log("offset changed to", offset)
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [offset])
    const addtoselect = (item) => {
        console.log("offset 0 calling 1")
        setcounta(0)
        setflag(flag + 1)
        setOffset(0)
        let filters = sessionStorage.getItem("artFilters") ? JSON.parse(sessionStorage.getItem("artFilters")) : []
        console.log('selected and item', filters, item)
        selectArtFilter(item, filters)
    }
    const removetoselect = (item) => {
        setcounta(0)
        console.log("offset 0 calling 2")
        setflag(flag + 1)
        setOffset(0)
        let filters = sessionStorage.getItem("artFilters") ? JSON.parse(sessionStorage.getItem("artFilters")) : []
        console.log('selected and item', filters, item)
        deselectArtFilter(item, filters)
    }
    const clearall = () => {
        setcounta(0)
        console.log("offset 0 calling 3")
        setSelect1([])
        setArtFilters([])
        sessionStorage.setItem("artFilters", `[]`)
        setOffset(0)
        history.push("/perfect-pairs/")
        setflag(flag + 1)
    }


    const handlePageClick = (event) => {
        var temp = event.selected + 1
        console.log("anwar", event.selected);
        if (location.pathname.includes("/art/theme"))
            history.push(`/art/theme/${params.theme}/${temp == 1 ? "" : `${temp}/`}`)
        else
            history.push(`/art/${temp == 1 ? "" : `${temp}/`}`)
    };

    const SetPage = (operation, offset) => {
        console.log("set page calling", operation, offset)
        let page = Math.ceil(offset / 30) + 1
        if (location.pathname.includes("/art/theme"))
            history.push(`/art/theme/${page == 1 ? "" : `${page}/`}`)
        else
            history.push(`/perfect-pairs/${page == 1 ? "" : `${page}/`}`)
    }


    const selectArtFilter = (item, selected) => {
        let arr = []
        arr = selected
        console.log('artFilter item:', item, "selected", selected)
        const index = arr.findIndex(x => x.value === item.value);
        if (index > -1) {
            // arr.splice(index, 1);
            // setSelect1(arr)
        }
        else {
            arr.push(item)
            setSelect1(arr)
            setArtFilters(arr)
            sessionStorage.setItem("artFilters", JSON.stringify(arr))
            history.push("/perfect-pairs/")
        }
    }

    const deselectArtFilter = (item, selected) => {
        let arr = selected
        console.log('artFilter item:', item, "selected", selected)
        const index = arr.findIndex(x => x.value === item.value);
        if (index > -1) {
            arr.splice(index, 1);
            setSelect1(arr)
            setArtFilters(arr)
            sessionStorage.setItem("artFilters", JSON.stringify(arr))
            history.push("/perfect-pairs/")
        }
    }



    const [visible, setvisible] = useState(false)

    const setsearch = () => {
        updateSearchParam('search', srch);
        console.log("offset 0 calling 4")
        setcounta(0)
        setflag(flag + 1)
    }
    return (
        <div id="frame-select">
            <MetaTags>
                <title>Online Art Gallery: Buy Art, Art Prints, Wall Art & Posters at Espravo</title>
                <meta name="keywords" content="art, art work, canvas art, art online, online art gallery,wall paintings online,buy art online, buy posters online, buy wall art, photo frame art, photo wall art, buy art prints," />
                <meta name="description" content="Buy art, wall paintings, art prints, canvas prints & transform your living space with Espravo's well curated collection of state.artList.included.find(x => x.id === pair.id). Shop Art Online Now!" />
            </MetaTags>
            <div id="select_size">
                <div className={visible ? 'dont-scroll' : ''} >
                    <Header />
                    <div className="container">
                        <h2 className="artTitle main-heading pt-5 pb-sm-4 pb-3 mb-0 text-center blackmain">
                            Shop Art Prints
                        </h2>

                        <div className='filterContainer pb-sm-3 pb-2 col-12 mx-auto col-md-12 col-sm-12 row px-0 justify-content-between' ref={ref1}>
                            <div className='filters px-0 col-6 d-flex'>
                                <div className='filterCat brandon mr-4 px-0 position-relative' >
                                    <div className='px-0 d-flex align-items-center' onClick={(e) => {
                                        filter !== "1" ?
                                            setFilter("1") :
                                            setFilter("0");
                                        openTheme(true)
                                    }}
                                    >
                                        <p className='brandon filter-head'>Theme</p>
                                        <img src="/assets/imagesA/artDown arrow.png" className={filter === "1" ? "ml-3 artDown-arrow active" : "ml-3 artDown-arrow"} />
                                    </div>

                                    {
                                        filter === "1" && themes ? <div className='col-12 px-0 position-absolute filterItems-container' onClick={(e) => { setFilter("0"); }}>
                                            {state.frameFilter ?
                                                <div className='thot-container theme-container'>
                                                    <p className='tri'>▲</p>
                                                    {state.frameFilter.collection ?
                                                        <div className="colors mt-0">
                                                            <div className="filter-boxes">
                                                                {state.frameFilter.collection.map((collection, ind) => {
                                                                    return (
                                                                        <div className="filter-box">
                                                                            <div onClick={() => addtoselect({ "value": collection.attributes.name, "attribute": "field_collections" })} className="left-side">
                                                                                <p>{collection.attributes.name}</p>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        : ''}
                                                </div>

                                                : ""}
                                        </div> : ""
                                    }
                                </div>
                                <div className='filterCat brandon px-2 mr-4 position-relative'>
                                    <div className='col-12 px-0 d-flex  align-items-center' onClick={(e) => {
                                        filter !== "2" ?
                                            setFilter("2") :
                                            setFilter("0");
                                        openOrient(true)
                                    }}
                                        ref={ref2}>
                                        <p className='brandon filter-head' onClick={() => { openOrient(true) }}>Orientation</p>
                                        <img src="/assets/imagesA/artDown arrow.png" className={filter === "2" ? "ml-3 artDown-arrow active" : "ml-3 artDown-arrow"} />
                                    </div>
                                    {
                                        filter === "2" && orients ? <div className='col-12 px-0 position-absolute filterItems-container'>
                                            {state.frameFilter.orientation ?
                                                <div className='thot-container'>
                                                    <p className='tri'>▲</p>
                                                    <div className="colors mt-0">
                                                        <div className="filter-boxes">
                                                            {state.frameFilter.orientation.map((orientation, ind) => {
                                                                return (
                                                                    <div className="filter-box">
                                                                        <div onClick={() => { addtoselect({ "value": orientation.attributes.name, "attribute": "field_orientation" }); setFilter("0") }} className="left-side">
                                                                            <p className='brandon'>{orientation.attributes.name}</p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''}
                                        </div>
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className='searchFilters d-none col-4 col-lg-3 col-xl-2 px-2 d-md-flex justify-content-between align-items-center'>
                                <input type='text' placeholder='Search' value={srch} onKeyPress={(e) => e.key === 'Enter' && setsearch()} onChange={(e) => setsrch(e.target.value)} className='searchFilter-input brandon py-1' />
                                <img src='/assets/imagesA/artSearch.png' onClick={() => setsearch()} className='artSearch-icon' />
                            </div>
                        </div>


                        <div className='d-flex flex-wrap mt-3 mb-sm-5 mb-3'>
                            <div className='activeFilter-container d-flex flex-wrap col-md-10 col-7 px-0'>
                                {
                                    sessionStorage.getItem("artFilters") ? JSON.parse(sessionStorage.getItem("artFilters"))[0] ?
                                        <div className='d-flex flex-wrap align-items-center'>
                                            {
                                                JSON.parse(sessionStorage.getItem("artFilters")).map((item, ind) => {
                                                    return (
                                                        <div className='activeFilter-box pr-2 py-1 pl-1 d-flex align-items-center mr-3'>
                                                            <div className="brandon">{item.value}</div>
                                                            <img src="/assets/imagesA/activeFilter close.png" className='activeFilter-close ml-2 mt-1'
                                                                onClick={(e) => {
                                                                    removetoselect(item, state.selectedartFilters)
                                                                }}
                                                            ></img>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className='brandon clear-active' onClick={() => clearall()}><u>Clear All</u></div>
                                        </div>

                                        :
                                        "" : ""
                                }
                            </div>
                            {
                                !state.selectedartFilters[0] && !srch && count ?
                                    <div className='d-none d-sm-flex justify-content-between col-12 px-0'>
                                        {state.counta ? <div className='col-2 ml-auto justify-content-end px-0 text-right brandon filteredResults'>
                                            {offset == 0 ? "" : <img src="/assets/countright.png" onClick={(e) => { SetPage("prev", offset - 30) }} className='page-icon left-icon' />}&nbsp;   Page&nbsp;{offset ? Math.ceil(offset / 30 + 1) : "1"}&nbsp; of {state.counta} {state.nexta ? <img src="/assets/countright.png" onClick={(e) => { SetPage("next", offset + 30) }} className='page-icon right-icon' /> : ""}
                                        </div> : ""}
                                    </div>
                                    : <div className='d-none d-sm-flex justify-content-between col-12 px-0'>
                                        {state.counta ? <div className='col-2 ml-auto justify-content-end px-0 text-right brandon filteredResults'>
                                            {offset == 0 ? "" : <img src="/assets/countright.png" onClick={(e) => { SetPage("prev", offset - 30) }} className='page-icon left-icon' />}&nbsp;   Page&nbsp;{offset ? Math.ceil(offset / 30 + 1) : "1"}&nbsp; of {state.counta} {state.nexta ? <img src="/assets/countright.png" onClick={(e) => { SetPage("next", offset + 30) }} className='page-icon right-icon' /> : ""}
                                        </div> : ""}
                                    </div>
                            }
                            {state.counta ?
                                <div className='d-flex d-sm-none justify-content-between mob-only col-12 px-0'>
                                    <div className='col-4 d-flex justify-content-start mr-0     px-0 text-right brandon filteredResults'>
                                        <p className='justify-content-start'>{offset == 0 ? "" : <img src="/assets/countright.png" onClick={(e) => { SetPage("prev", offset - 30) }} className='page-icon left-icon' />}&nbsp;   Page&nbsp;{offset ? Math.ceil(offset / 30 + 1) : "1"}&nbsp; of {state.counta} {state.nexta ? <img src="/assets/countright.png" onClick={(e) => { SetPage("next", offset + 30) }} className='page-icon right-icon' /> : ""}
                                        </p>
                                    </div>
                                    <div className='col-8 d-flex d-sm-none justify-content-end pr-0'>
                                        <div className='searchFilters d-flex justify-content-end align-items-center pr-1'>
                                            <input type='text' placeholder='Search' onKeyPress={(e) => e.key === 'Enter' && setsearch()} value={srch} onChange={(e) => setsrch(e.target.value)} className='searchFilter-input brandon py-sm-1 py-0' />
                                            <img src='/assets/imagesA/artSearch.png' onClick={() => setsearch()} className='artSearch-icon align-item-center' />
                                        </div>
                                    </div>
                                </div>
                                : ""
                            }

                        </div>
                        <div className="frame-container px-0">
                            <div className="frame-list art-list col-12 px-0">
                                <div className='row'>
                                    {state.artList ? state.artList.data.length ? state.artList.data.map((frame, ind) => {
                                        let image = state.artList.included.filter(item => item.id === frame.relationships.field_image.data?.id)
                                        let artist = frame.relationships.field_artist.data.length ? state.artList.included.filter(item => item.id === frame.relationships.field_artist.data[0].id) : []
                                        artist = artist.length ? artist[0].attributes.name : ""
                                        let final_artist = (artist.split(",").reverse()).toString()
                                        let media = image.length ? state.artList.included.filter(x => x.id === image[0].relationships.field_media_image.data?.id).length ? state.artList.included.filter(x => x.id === image[0].relationships.field_media_image.data?.id) : "" : ""
                                        // console.log("image", media[0])
                                        let path = frame.attributes?.field_path_alias ? frame.attributes.field_path_alias : ""
                                        return <>
                                            <Link to={`${path}/`} onClick={() => { setModal(true); setSelectedArt(frame.id); localStorage.setItem("art_back_path", `/art/${location.pathname.includes("/art/theme/") ? `theme/${params.theme}/` : ""}${Math.ceil(offset / 30 + 1) == 1 ? "" : Math.ceil(offset / 30 + 1)}`) }} className="frame-ind border-cancel cursor mb-4 col-md-6 col-6">
                                                <div className="frame-item bordering">
                                                    {frame.relationships.field_image.data ?
                                                        media.length ? media[0].attributes.image_style_uri.length ? < img alt="icon" className='image-hi' src={media[0].attributes.image_style_uri.find(obj => obj.listing)?.listing} /> :
                                                            <img alt="icon" className='image-hi' src="assets/images/dummy.jpeg" /> :
                                                            <img alt="icon" className='image-hi' src="assets/images/dummy.jpeg" /> :
                                                        <img alt="icon" className='image-hi' src="assets/images/dummy.jpeg" />
                                                    }
                                                </div>
                                                <div className="name-price brandon pt-lg-2 pb-1 pt-sm-3">
                                                    <div className="custName name w-100 text-center">
                                                        {frame.attributes.title}
                                                    </div>
                                                </div>
                                                <p className='brandon custArtist artist-name d-flex justify-content-center'>{final_artist.replace(",", " ")}</p>
                                                {/* <p className="frame-sub">Clean black gallery frame</p> */}
                                            </Link>
                                            {
                                                frame.relationships.field_pair.data.map(pair => {
                                                    // let art = state.artList.included.find(x => x.id === pair.id)
                                                    let image = state.artList.included.filter(item => item.id === state.artList.included.find(x => x.id === pair.id).relationships.field_image.data?.id)
                                                    let artist = state.artList.included.find(x => x.id === pair.id).relationships.field_artist.data.length ? state.artList.included.filter(item => item.id === state.artList.included.find(x => x.id === pair.id).relationships.field_artist.data[0].id) : []
                                                    artist = artist.length ? artist[0].attributes.name : ""
                                                    let final_artist = (artist.split(",").reverse()).toString()
                                                    let media = image.length ? state.artList.included.filter(x => x.id === image[0].relationships.field_media_image.data?.id).length ? state.artList.included.filter(x => x.id === image[0].relationships.field_media_image.data?.id) : "" : ""
                                                    // console.log("image", media[0])
                                                    return <Link to={`${path}/`} onClick={() => { setModal(true); setSelectedArt(state.artList.included.find(x => x.id === pair.id).id); localStorage.setItem("art_back_path", `/art/${location.pathname.includes("/art/theme/") ? `theme/${params.theme}/` : ""}${Math.ceil(offset / 30 + 1) == 1 ? "" : Math.ceil(offset / 30 + 1)}`) }} className="frame-ind border-cancel cursor mb-4 col-md-6 col-6">
                                                        <div className="frame-item bordering">
                                                            {state.artList.included.find(x => x.id === pair.id).relationships.field_image.data ?
                                                                media.length ? media[0].attributes.image_style_uri.length ? < img alt="icon" className='image-hi' src={media[0].attributes.image_style_uri.find(obj => obj.listing)?.listing} /> :
                                                                    <img alt="icon" className='image-hi' src="assets/images/dummy.jpeg" /> :
                                                                    <img alt="icon" className='image-hi' src="assets/images/dummy.jpeg" /> :
                                                                <img alt="icon" className='image-hi' src="assets/images/dummy.jpeg" />
                                                            }
                                                        </div>
                                                        <div className="name-price brandon pt-lg-2 pb-1 pt-sm-3">
                                                            <div className="custName name w-100 text-center">
                                                                {state.artList.included.find(x => x.id === pair.id).attributes.title}
                                                            </div>
                                                        </div>
                                                        <p className='brandon custArtist artist-name d-flex justify-content-center'>{final_artist.replace(",", " ")}</p>
                                                        {/* <p className="frame-sub">Clean black gallery frame</p> */}
                                                    </Link>
                                                })
                                            }</>
                                    }) :
                                        <div className="no-products">
                                            <h3 className='brandon'>No Art Available</h3>
                                        </div>

                                        : <div className='container'> <Loading type="spinner-default" bgColor={"#808080"} title={"spinner-circle"} size={60} /></div>}

                                </div>
                                {state.artList ? state.artList.data.length && state.counta ?
                                    <div className='container brandon'>
                                        {state.counta > 1 ?
                                            <ReactPaginate
                                                nextLabel={<img src="/assets/countright.png" className='page-icon right-icon' />}
                                                onPageChange={handlePageClick}
                                                onClick={handlePageClick}
                                                pageRangeDisplayed={5}
                                                marginPagesDisplayed={1}
                                                pageCount={state.counta}
                                                previousLabel={<img src="/assets/countright.png" className='page-icon left-icon' />}
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                breakLabel="..."
                                                breakClassName="dots"
                                                breakLinkClassName="page-link"
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                activeLinkClassName="activePage"
                                                renderOnZeroPageCount={null}
                                                forcePage={Math.ceil((offset / 30))}
                                            />
                                            :
                                            ""}
                                    </div>
                                    : "" : ''}
                            </div>
                        </div>
                        {<div style={{ height: "25px" }}></div>}
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default PerfectPairs;
