import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import Footer from '../../components/Footer/footer';
import Header from '../../components/header/header';
import filter from '../../assets/Filter.png'
import search from '../../assets/Search.png'
import framepic from '../../assets/Rectangle 9 copy.png'
import Review from '../../components/checkout/review'
import { Context as ArtContext } from '../../context/ArtContext';
import './frame.css'
import Filter from '../../components/filter/filter';
import { base_url } from '../../helpers/baseURL';
import FrameEditPreview from '../../components/frame/frameEditPreview';
import matte2 from '../../assets/Rectangle 27.png'
import axios from 'axios';
import Loading from "react-js-loader";


const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const FrameSelect = () => {
    const { state, getFrames, getFrameFilters, calculatePrice, getSingleFrame, getSingleArt } = useContext(ArtContext);
    let params = useParams()
    const query = useQuery()
    const history = useHistory();
    const [image, setImage] = useState("")
    const [loaded, setloaded] = useState("")
    const [value, setValue] = useState()
    const [ratio, setRatio] = useState("")
    React.useEffect(() => {
        console.log("query params", query.get("type"))
        if (params.id && query.get("type") === "art") {
            console.log("art image is loading")
            getSingleArt(params.id)
                .then(res => {
                    const img = new Image();
                    img.src = res.url
                    img.onload = function () {
                        const imgWidth = img.naturalWidth;
                        const imgHeight = img.naturalHeight;
                        localStorage.setItem("ratio", imgWidth / imgHeight)
                        setRatio(imgWidth / imgHeight)

                    };
                    setImage(res.url)
                    localStorage.setItem("showimage", res.url)
                    console.log("selected image", res.url)
                })
                .catch(err => {
                    history.push('/404s')
                })
        }
        else if (params.id && query.get("type") === "upload") {
            console.log("upload image is loading")
            axios.get(base_url + `/jsonapi/file/file/${params.id}`)
                .then(
                    res => {
                        console.log("cropped get image", base_url + res.data.data.attributes.uri.url)
                        setImage(base_url + res.data.data.attributes.uri.url)
                        localStorage.setItem("showimage", base_url + res.data.data.attributes.uri.url)
                        const img = new Image();

                        img.src = base_url + res.data.data.attributes.uri.url;

                        img.onload = function () {
                            setloaded(true)
                            console.log("image is loaded")
                            const imgWidth = img.naturalWidth;
                            const imgHeight = img.naturalHeight;
                            setRatio(imgWidth / imgHeight)
                            localStorage.setItem("ratio", imgWidth / imgHeight)
                            console.log("ratio needed", imgWidth / imgHeight)
                        };
                    })
                .catch(err => {

                })
        }
    }, [])
    React.useEffect(() => {
        if (localStorage.getItem('ratio') && window.innerWidth > 575 && JSON.parse(localStorage.getItem("artData")).papertype) {
            if (localStorage.getItem('ratio') < 0.52)
                JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? setValue(100) : setValue(90)
            else if (localStorage.getItem('ratio') >= 0.52 && localStorage.getItem('ratio') < 0.72)
                JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? setValue(210) : setValue(130)
            else if (localStorage.getItem('ratio') >= 0.72 && localStorage.getItem('ratio') < 1)
                JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? setValue(170) : setValue(150)
            else if (localStorage.getItem('ratio') >= 1 && localStorage.getItem('ratio') < 1.4)
                JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? setValue(220) : setValue(150)
            else if (localStorage.getItem('ratio') >= 1.4)
                JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? setValue(240) : setValue(150)
        }
        else if (localStorage.getItem('ratio') && window.innerWidth < 575) {
            if (localStorage.getItem('ratio') < 0.52)
                JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? setValue(70) : setValue(60)
            else if (localStorage.getItem('ratio') >= 0.52 && localStorage.getItem('ratio') < 0.72)
                JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? setValue(80) : setValue(70)
            else if (localStorage.getItem('ratio') >= 0.72 && localStorage.getItem('ratio') < 1)
                JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? setValue(90) : setValue(80)
            else if (localStorage.getItem('ratio') >= 1 && localStorage.getItem('ratio') < 1.4)
                JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? setValue(100) : setValue(90)
            else if (localStorage.getItem('ratio') >= 1.4)
                JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? setValue(120) : setValue(100)
        }
        getFrames()
        getFrameFilters(state.frameFilter)
        getSingleFrame()
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    }, []);
    const [flag, setflag] = useState(1)
    const [visible, setvisible] = useState(false)
    const [srch, setsrch] = useState('')
    const [selected1, setSelect1] = useState([]);
    const imageheight = document.getElementById("frame-item")
    const addtoselect = (item) => {
        setflag(flag + 1)
        selectFilter(item, selected1)
    }
    const clearall = () => {
        setSelect1([])
        setflag(flag + 1)
    }
    const selectFilter = (item, selected) => {
        let arr = selected
        const index = arr.findIndex(x => x.value === item.value);
        if (index > -1) {
            arr.splice(index, 1);
            setSelect1(arr)
        }
        else {
            arr.push(item)
            setSelect1(arr)
        }
    }
    const setsearch = (txt) => {
        setsrch(txt)
    }
    React.useEffect(() => {
        getFrames(selected1, srch)
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    }, [flag, srch]);

    const Filter = (props) => {
        return (
            state.frameFilter ?
                <div id="filter">
                    {state.frameFilter.colour ?
                        <div className="colors">
                            <h4>Colors</h4>
                            <div className="color-boxes d-flex align-items-center">
                                {state.frameFilter.colour.map((colour, ind) => {
                                    return (
                                        <div onClick={() => addtoselect({ "value": colour.attributes.name, "attribute": "field_colour" })} style={{ backgroundColor: colour.attributes.name }} className={selected1.filter((item) => {
                                            return item.value === colour.attributes.name
                                        }).length ? "first box" : "box"}>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        : ''}
                    {state.frameFilter.Style && props.type !== "frame" ?
                        <div className="colors">
                            <h4>Frame Style</h4>
                            <div className="filter-boxes">
                                {state.frameFilter.Style.map((style, ind) => {
                                    return (
                                        <div className="filter-box">
                                            <div onClick={() => addtoselect({ "value": style.attributes.name, "attribute": "field_style" })} className="left-side">
                                                <span style={{
                                                    backgroundColor: selected1.filter((item) => {
                                                        return item.value === style.attributes.name
                                                    }).length ? "#000" : ''
                                                }} className="filter-checkbox"></span>
                                                <p>{style.attributes.name}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        : ''}
                    {state.frameFilter.collection && props.type !== "frame" ?
                        <div className="colors">
                            <h4>Collections</h4>
                            <div className="filter-boxes">
                                {state.frameFilter.collection.map((collection, ind) => {
                                    return (
                                        <div className="filter-box">
                                            <div onClick={() => addtoselect({ "value": collection.attributes.name, "attribute": "field_collection" })} className="left-side">
                                                <span style={{
                                                    backgroundColor: selected1.filter((item) => {
                                                        return item.value === collection.attributes.name
                                                    }).length ? "#000" : ''
                                                }} className="filter-checkbox"></span>
                                                <p>{collection.attributes.name}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        : ''}
                    {state.frameFilter.frame_type && props.type === "frame" ?
                        <div className="colors">
                            <h4>Type</h4>
                            <div className="filter-boxes">
                                {state.frameFilter.frame_type.map((collection, ind) => {
                                    return (
                                        <div className="filter-box">
                                            <div onClick={() => addtoselect({ "value": collection.attributes.name, "attribute": "field_frame_type" })} className="left-side">
                                                <span style={{
                                                    backgroundColor: selected1.filter((item) => {
                                                        return item.value === collection.attributes.name
                                                    }).length ? "#000" : ''
                                                }} className="filter-checkbox"></span>
                                                <p>{collection.attributes.name}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        : ''}

                </div>
                : null
        );
    }
    return (
        <div id="frame-select">
            <div id="select_size">
                <div style={{ display: visible ? "block" : "none" }} className="container filter-box-container mob">
                    <Filter type="frame" />
                    <div className="btns">
                        <button onClick={() => setvisible(false)} className="bgsecondary whitecolor">
                            Done
                        </button>
                        <button className="secondarycolor bgwhite clear-all" onClick={() => clearall()}>
                            Clear All
                        </button>
                    </div>
                </div>
                <div className={visible ? 'dont-scroll' : ''} >
                    <Header />
                    <div className="bgcolor-pages pt-3 pb-5">


                        <div className="header-container mb-3">
                            <Link className="mb-1 selectBack brandon d-flex align-items-center" to={localStorage.getItem("back")}>
                                <img src="/assets/arrow-left.webp" className="selectBackIcon" alt="thumb"></img>
                                <p className='ml-2'>Back</p>
                            </Link>
                            <div className="main-heading mb-2 mt-4 text-center dark-main">
                                Choose your frame
                            </div>
                            <p className="brandon frame-select-head2 secondarycolor mx-5 px-sm-5 mb-5 text-center">
                                A wide spectrum of frames from our Gallery, Rustic,<br />
                                Natural and Classic collections.
                            </p>
                            {/* <div className="filter-search-box">
                            <div onClick={() => setvisible(true)} className="filter-btn">
                                <img alt="icon" src={filter} />
                                <p className="sub-title">Filter</p>
                            </div>
                            <div className="search-box">
                                <input value={srch} onChange={(e) => setsearch(e.target.value)} placeholder="Search" />
                                <img alt="icon" src={search} />
                            </div>
                        </div> */}
                            <div className="frame-container px-0">
                                {/* <div className="filter-box-container">
                                <Filter type="frame" />
                            </div> */}
                                {state.frameList ? state.frameList.data.length ?
                                    <div className={state.frameList.data.length === 1 || state.frameList.data.length % 3 === 1 ? "frame-list frame-only-list" : "frame-list frame-only-list mx-auto"} style={{ justifyContent: state.frameList.data.length === 1 || state.frameList.data.length % 3 === 1 ? "center" : "", marginRight: state.frameList.data.length === 1 || state.frameList.data.length % 3 === 1 ? "2%" : "" }}>
                                        {state.frameList.data.map((frame, ind) => {
                                            if (frame.attributes.field_frame_or_image == "image") {
                                                let frontframearray
                                                frontframearray = frame.relationships.field_thumbnail.data ? state.frameList.included.filter(item => item.id === frame.relationships.field_thumbnail.data.id) : ""
                                                return (
                                                    <Link to="#" className="frame-ind frame-image mb-4">
                                                        <img className='only-image' alt="icon" src={frontframearray ? base_url + frontframearray[0].attributes.uri.url : ""} />
                                                    </Link>
                                                )
                                            }
                                            else {
                                                let frontframearray = state.frameList.included.filter(item => item.id === frame.relationships.field_front.data.id)
                                                let sideframearray = state.frameList.included.filter(item => item.id === frame.relationships.field_side.data.id)
                                                let data = { "front": base_url + frontframearray[0].attributes.uri.url, "side": base_url + sideframearray[0].attributes.uri.url }
                                                return (
                                                    <Link to={`/frame-edit/${frame.id}?id=${params.id}&type=${query.get("type")}`} className="frame-ind mb-4">
                                                        <div id="frame-item" className="frame-item">
                                                            {data && image && value ? window.innerWidth < 575 ?
                                                                <FrameEditPreview value={value} props={{ "frameid": data, "padding": JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? 0 : 6.5, "width": 32, "artid": image, "ratio": ratio }} framing={{ "depth": 32, "frame": JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? 2 : 3, "ratio": ratio }} /> :

                                                                <FrameEditPreview value={value} props={{ "frameid": data, "padding": JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? .5 : 6.5, "width": 32, "artid": image, "ratio": ratio }} framing={{ "depth": 32, "frame": JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? 2 : 3, "ratio": ratio }} /> :
                                                                <FrameEditPreview value={value} props={{ "frameid": data, "padding": JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? .5 : 6.5, "width": 32, "artid": image, "ratio": ratio }} framing={{ "depth": 32, "frame": JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? 2 : 3, "ratio": ratio }} />
                                                                // <img alt="icon" src="assets/images/dummy.jpeg" />
                                                            }
                                                            <div className="name-price">
                                                                <p className="sub-heading GT text-center">
                                                                    {frame.attributes.title}
                                                                </p>
                                                                <p className="brandon text-center">
                                                                    {frame.attributes ? frame.attributes.field_description ? frame.attributes.field_description.value ? frame.attributes.field_description.value : "" : '' : ""}
                                                                </p>
                                                                {/* <p className="price">
                                                &#8377; 2000
                                            </p> */}
                                                            </div>
                                                        </div>

                                                    </Link>
                                                )
                                            }
                                        })
                                        }
                                    </div> :
                                    <div className="no-products">
                                        <h3>No Frames Available</h3>
                                    </div>
                                    : <div className='container'> <Loading type="spinner-default" bgColor={"#808080"} size={60} /></div>}

                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default FrameSelect;
