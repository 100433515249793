import React, { useContext, useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FrameEditPreview from './frameEditPreview';
import FramePopup from './framePopup'
import './frameedit.css'
import ReactPlayer from 'react-player'
import { Context as ArtContext } from '../../context/ArtContext';
import { base_url } from '../../helpers/baseURL';
import Popup from 'reactjs-popup';

const FramePreview = (props) => {
    const { state, setCarousel, zoomOpen } = useContext(ArtContext);
    const [width, setwidth] = useState()
    const [selected, setselected] = useState(1)
    const [play, setPlay] = useState(false)
    const [url, setURL] = useState("")
    const [value, setValue] = useState()
    const [backimg, setBackImg] = useState()
    const [inch, setInch] = useState()
    const [top, setTop] = useState()
    const [left, setLeft] = useState()
    const [zoom, openZoom] = useState(false)
    const [right, setRight] = useState()
    const [orientation, setOrient] = useState("")
    React.useEffect(() => {
        setCarousel(selected, window.innerWidth)
    }, [selected]);
    React.useEffect(() => {
        console.log("selected width", props.width.art)
        console.log("papertype", localStorage.getItem("papertype"))
        let wid = document.getElementById("walls").clientWidth - 30
        let finalwidth = wid * 0.77
        console.log("wall width", finalwidth)
        if (localStorage.getItem("ratio")) {
            if (localStorage.getItem("ratio") == 1) {
                setOrient("square")
                //square image
                if (localStorage.getItem("papertype") == "Paper") {
                    if (props.width.art < 24)
                        setTop("7%")
                    else if (props.width.art = 24)
                        setTop("6%")
                    else
                        setTop("4%")
                }
                else {
                    if (props.width.art <= 24)
                        setTop("11%")
                    else if (props.width.art > 24 && props.width.art <= 32)
                        setTop("8%")
                    else
                        setTop("5%")
                }
                setLeft("0")
                setRight("0")
                setBackImg("/assets/images/backimg3.jpg")//80inch width wall
                if (window.innerWidth > 1200)
                    setInch(finalwidth / 80)
                else if (window.innerWidth > 992)
                    setInch(finalwidth / 80)
                else if (window.innerWidth < 575)
                    setInch((window.innerWidth - 95) / 75)
                else
                    setInch((window.innerWidth - 95) / 75)
            }
            else if (localStorage.getItem("ratio") < 1) {
                //vertical image
                if (localStorage.getItem("papertype") == "Paper") {
                    if (props.width.art <= 18)
                        setTop("7%")
                    else if (props.width.art > 18 && props.width.art <= 26)
                        setTop("5%")
                    else
                        setTop("3%")
                }
                setLeft("-25%")
                setRight("0")
                setBackImg("/assets/images/backimg.jpg")
                if (window.innerWidth > 1200)
                    setInch(finalwidth / 60) //60 inch width wall
                else if (window.innerWidth > 992)
                    setInch(finalwidth / 60)
                else if (window.innerWidth < 575)
                    setInch((window.innerWidth - 95) / 60)
                else
                    setInch((window.innerWidth - 95) / 60)
            }
            else {
                //horizontal image
                if (localStorage.getItem("papertype") == "Paper") {
                    if (props.width.art / localStorage.getItem("ratio") >= 31)
                        setTop("6%")
                    else if (props.width.art / localStorage.getItem("ratio") < 31 && props.width.art / localStorage.getItem("ratio") >= 26)
                        setTop("7%")
                    else if (props.width.art / localStorage.getItem("ratio") < 26 && props.width.art / localStorage.getItem("ratio") >= 22)
                        setTop("8%")

                    else if (props.width.art / localStorage.getItem("ratio") < 22 && props.width.art / localStorage.getItem("ratio") >= 16)
                        setTop("13%")
                    else
                        setTop("18%")
                }
                else {
                    if (props.width.art / localStorage.getItem("ratio") >= 31)
                        setTop("6%")
                    else if (props.width.art / localStorage.getItem("ratio") < 31 && props.width.art / localStorage.getItem("ratio") >= 26)
                        setTop("9%")
                    else if (props.width.art / localStorage.getItem("ratio") < 26 && props.width.art / localStorage.getItem("ratio") >= 22)
                        setTop("10%")

                    else if (props.width.art / localStorage.getItem("ratio") < 22 && props.width.art / localStorage.getItem("ratio") >= 16)
                        setTop("15%")
                    else
                        setTop("20%")
                }
                setLeft("0")
                setRight("11%")
                setBackImg("/assets/images/backimg2.jpg")
                if (window.innerWidth > 1200)
                    setInch(finalwidth / 90) //90 inch width wall
                else if (window.innerWidth > 992)
                    setInch(finalwidth / 90)
                else if (window.innerWidth < 575)
                    setInch((window.innerWidth - 95) / 90)

                else
                    setInch((window.innerWidth - 95) / 90)
            }
        }
    }, [state.ratio])
    React.useEffect(() => {
        console.log("selected width", props.width.art)
        console.log("papertype", localStorage.getItem("papertype"))
        if (localStorage.getItem('ratio') && window.innerWidth > 575 && JSON.parse(localStorage.getItem("artData")).papertype) {
            if (localStorage.getItem('ratio') < 0.40)
                JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? setValue(120) : setValue(100)
            else if (localStorage.getItem('ratio') >= 0.40 && localStorage.getItem('ratio') < 0.52)
                JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? setValue(175) : setValue(140)
            else if (localStorage.getItem('ratio') >= 0.52 && localStorage.getItem('ratio') < 0.72)
                JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? setValue(290) : setValue(175)
            else if (localStorage.getItem('ratio') >= 0.72 && localStorage.getItem('ratio') < 1)
                JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? setValue(260) : setValue(220)
            else if (localStorage.getItem('ratio') >= 1 && localStorage.getItem('ratio') < 1.4)
                JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? setValue(340) : setValue(280)
            else if (localStorage.getItem('ratio') >= 1.4)
                JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? setValue(400) : setValue(320)
        }
        else if (localStorage.getItem('ratio') && window.innerWidth < 575) {
            if (localStorage.getItem('ratio') < 0.52)
                setValue(100)
            else if (localStorage.getItem('ratio') >= 0.52 && localStorage.getItem('ratio') < 0.72)
                setValue(120)
            else if (localStorage.getItem('ratio') >= 0.72 && localStorage.getItem('ratio') < 1)
                setValue(160)
            else if (localStorage.getItem('ratio') >= 1 && localStorage.getItem('ratio') < 1.4)
                JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? setValue(180) : setValue(160)
            else if (localStorage.getItem('ratio') >= 1.4)
                JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? setValue(200) : setValue(160)
        }
        if (state.framedata) {
            console.log('frame-Data', state.framedata)
        }
        if (state.frameVideo) {
            const get = state.frameVideo ? state.frameVideo[0].attributes ? state.frameVideo[0].attributes.field_media_oembed_video : "" : ""
            const url = get.replace("https://youtu.be", `https://www.youtube.com/embed`);
            console.log('framevideo', url)
            setURL(url)
        }
    }, [])
    return (
        <div>
            <div className="frame_prev_mob mob-only">

                <div className={selected === 1 ? "main selected-2 p-relative" : "main"}>
                    {selected === 1 ?
                        <FrameEditPreview value={value} framing={{ "depth": props.width.art, "frame": JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? props.width.art > 24 ? (props.width.frame + 0.8) : (props.width.frame + 0.6) : props.width.frame }} />
                        : ""}
                    {selected === 2 ? <div id="wall" className={`frame-edit-preview-container wall-placed-image ${orientation}`}>
                        <img src={backimg} className='frame-edit-preview' />
                        <img className='water-mark' src='/assets/watermark.png' />
                        <div className='frame-edit-preview-img' style={{ top: top, left: left, right: right }}> <FrameEditPreview value={state.ratio.width * inch} framing={{ "depth": props.width.art, "frame": props.width.frame }} /></div>
                    </div>
                        : ""}
                    {selected == 3 ? <div className="frame-edit-preview-container frame-edit-preview-container-awr bg-color">
                        {state.thumbnail ?
                            <img alt="icon" src={state.thumbnail} /> :
                            <img alt="icon" src="assets/images/dummy.jpeg" />
                        }
                    </div>
                        : ""}
                    {selected === 4 ?
                        <div className="frame-edit-preview-container frame-edit-preview-container-awr bg-color">
                            {state.sideFrame ?
                                <img alt="icon" src={state.sideFrame} /> :
                                <img alt="icon" src="assets/images/dummy.jpeg" />
                            }
                            {/* {state.frontFrame?<img src={state.frontFrame} alt="thumb" />:<img src={selected} alt="thumb" />} */}
                        </div> : ""
                    }
                    {/* state.frameVideo ? state.frameVideo[0].attributes ? state.frameVideo[0].attributes.field_media_oembed_video ? <ReactPlayer url={state.frameVideo[0].attributes.field_media_oembed_video} onPause={() => setPlay(false)} height='100%' width='100%' playing={play} /> : "No Video" : "No Video" : "No Video" */}

                </div>
                <div className="d-flex justify-content-between thumb-cont">
                    <div onClick={() => setselected(1)} className={selected === 1 || !state.zoom ? "col-sm-3 thumb active" : "col-sm-3 thumb"}>
                        <img src="/assets/images/thumb1.png" alt="thumb" />
                    </div>
                    <div onClick={() => setselected(2)} className={selected === 2 ? "col-sm-3 thumb active" : "col-sm-3 thumb"}>
                        <img src="/assets/images/thumb2.png" alt="thumb" />
                    </div>
                    <div onClick={() => setselected(3)} className={selected === 3 ? "thumb mb-2 active" : "thumb mb-2"}>
                        <img src="/assets/images/thumb3.png" alt="thumb mb-2" />
                    </div>
                    <div onClick={() => setselected(4)} className={selected === "/assets/images/thumb3.png" ? "col-sm-3 thumb active" : "col-sm-3 thumb"}>
                        <img src="/assets/images/thumb4.png" alt="thumb" />
                    </div>
                    {state.frameVideo ? <div onClick={() => setselected(5)} className={selected === 4 ? "col-sm-3 thumb active" : "col-sm-3 thumb"}>
                        <img src="/assets/images/video.jpg" className='vid-img' alt="thumb" />
                    </div> : ''}
                </div>
            </div>

            <div id="frame_preview" className="desk-only row">
                <div className="col-sm-2 px-0 thumb-cont">
                    <div onClick={() => setselected(1)} className={selected === 1 && !state.zoom ? "thumb mb-2 active" : "thumb mb-2"}>
                        <img src="/assets/images/thumb2.png" alt="thumb mb-2" />
                    </div>
                    <div onClick={() => setselected(2)} className={selected === 2 ? "thumb mb-2 active" : "thumb mb-2"}>
                        <img src="/assets/images/thumb1.png" alt="thumb mb-2" />
                    </div>
                    <div onClick={() => setselected(3)} className={selected === 3 ? "thumb mb-2 active" : "thumb mb-2"}>
                        <img src="/assets/images/thumb3.png" alt="thumb mb-2" />
                    </div>
                    <div onClick={() => setselected(4)} className={selected === 4 ? "thumb mb-2 active" : "thumb mb-2"}>
                        <img src="/assets/images/thumb4.png" alt="thumb mb-2" />
                    </div>
                    {state.frameVideo ? <div onClick={() => setselected(5)} className={selected === 5 ? "thumb mb-2 active" : "thumb mb-2"}>
                        <img src="/assets/images/video.jpg" className='vid-img' alt="thumb mb-2" />
                    </div> : ''}
                </div>
                <div id="walls" className={selected === 1 ? "main selected-2 col-sm-10" : selected === 2 ? "main position-relative col-sm-10" : "main col-sm-10"}>
                    {selected === 1 ?
                        <FrameEditPreview value={value} padding={JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? 1 : ""} framing={{ "depth": props.width.art, "frame": JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? props.width.art >= 40 ? (props.width.frame + 1) : props.width.art < 40 && props.width.art > 24 ? (props.width.frame + 0.8) : props.width.art <= 16 ? (props.width.frame + 0.4) : (props.width.frame + 0.6) : props.width.frame }} />
                        : ""}
                    {selected === 2 ? <div className={`frame-edit-preview-container wall-placed-image ${orientation}`} >
                        <img src={backimg} className='frame-edit-preview' />
                        <img className='water-mark' src='/assets/watermark.png' />
                        <div className='frame-edit-preview-img' style={{ top: top, left: left, right: right }}> <FrameEditPreview value={state.ratio.width * inch} framing={{ "depth": props.width.art, "frame": props.width.frame }} /></div>
                    </div>
                        : ""}
                    {selected == 3 ? <div className="frame-edit-preview-container bg-color">
                        {state.thumbnail ?
                            <img alt="icon" className='thumbnail-img' src={state.thumbnail} /> :
                            <img alt="icon" className='thumbnail-img' src="assets/images/dummy.jpeg" />
                        }
                    </div>
                        : ""}
                    {selected === 4 ?
                        <div className="frame-edit-preview-container bg-color">
                            {state.sideFrame ?
                                <img alt="icon" className='thumbnail-img' src={state.sideFrame} /> :
                                <img alt="icon" className='thumbnail-img' src="assets/images/dummy.jpeg" />
                            }
                            {/* {state.frontFrame?<img src={state.frontFrame} alt="thumb" />:<img src={selected} alt="thumb" />} */}
                        </div>
                        : ""
                        // state.frameVideo ? state.frameVideo[0].attributes ? state.frameVideo[0].attributes.field_media_oembed_video ? <ReactPlayer url={state.frameVideo[0].attributes.field_media_oembed_video} onPause={() => setPlay(false)} height='100%' width='100%' playing={play} /> : "No Video" : "No Video" : "No Video"
                    }

                </div>
                {state.zoom ? <div className='overlay zoom-image-overlay'></div> : ""}
                <Popup className="zoompopup" open={state.zoom} onClose={() => zoomOpen(false)}>
                    <div className='d-flex zoompopup-container flex-column' style={{ width: `${window.innerWidth < 575 ? window.innerWidth - 40 : value * 3}px` }}>
                        <img className="zoom-close cursor ml-auto mb-3 d-flex justify-content-end" role="button" src="/assets/contactclose.png" onClick={() => zoomOpen(false)} />
                        {window.innerWidth < 575 ?
                            <FramePopup value={value * 1.2} padding={JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? 1 : ""} framing={{ "depth": props.width.art, "frame": JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? props.width.art >= 40 ? (props.width.frame + 1) : props.width.art < 40 && props.width.art > 24 ? (props.width.frame + 0.8) : props.width.art <= 16 ? (props.width.frame + 0.4) : (props.width.frame + 0.6) : props.width.frame }} />
                            : <FramePopup value={value * 1.45} padding={JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? 1 : ""} framing={{ "depth": props.width.art, "frame": JSON.parse(localStorage.getItem("artData")).papertype == "Canvas" ? props.width.art >= 40 ? (props.width.frame + 1) : props.width.art < 40 && props.width.art > 24 ? (props.width.frame + 0.8) : props.width.art <= 16 ? (props.width.frame + 0.4) : (props.width.frame + 0.6) : props.width.frame }} />
                        }
                    </div>
                </Popup>
            </div>
        </div >
    );
}

export default FramePreview;
