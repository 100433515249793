export const TokenCheck = () => {
    let localdata = localStorage.getItem('starter_data')
    // localdata=true
    return new Promise((resolve, reject) => {
        if (localdata) {
            // if (localdata&&localdata.token) {
            resolve(true)
        }
        else {
            resolve(false)
        }
    })
}