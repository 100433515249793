import React, { useContext, useEffect, useState } from 'react';
import {
    Link,
    useParams,
    matchPath,
    useHistory
} from 'react-router-dom';
import Footer from '../../components/Footer/footer';
import Header from '../../components/header/header';
import arrow from '../../assets/Footer Right.png'
import 'react-accessible-accordion/dist/fancy-example.css';
import './profile.css'
import ProfileEdit from '../../components/profiledetails/profileedit';
import PassWord from '../../components/profile/password';
import PersonalData from '../../components/profile/personalData';
import ShippingList from '../../components/profiledetails/shippingList';
import Myorders from '../../components/profiledetails/myOrders';
import Openorders from '../../components/profiledetails/openOrder';
import Orderlist from '../../components/profiledetails/orderList';
import OrderDetails from '../../components/profiledetails/orderDetails';
import SavedAddress from './SavedAddress';
import axios from 'axios';
import { Context as ArtContext } from '../../context/ArtContext';
import { Context as UserContext } from '../../context/UserContext';
import { base_url } from '../../helpers/baseURL';
import { useAlert } from 'react-alert';
import Wishlist from './wishlist';

const ProfileDetails = () => {
    const historys = useHistory()
    const { state, RefreshToken } = useContext(ArtContext);
    let UContext = useContext(UserContext)
    const { id, getid, order_no } = useParams();
    const alert = useAlert()
    const [url_params, setUrlparams] = useState("profile");
    const [ordernos, setorderno] = useState("");
    useEffect(() => {
        // alert(id
        console.log("url params is", order_no, "and id is", id)
        setUrlparams(id)
    }, [id]);
    useEffect(() => {

        if (!localStorage.getItem('userdat'))
            window.location.replace('/403')
        else {
            let userdat = localStorage.getItem('userdat') ? JSON.parse(localStorage.getItem('userdat')) : null
            // UContext.getWishlist()
            axios.get(`${base_url}/jsonapi/user/user/${userdat?.details.id}`, {
                headers: {
                    "Authorization": `Bearer ${userdat?.token}`
                }
            })
                .then(res => {
                    if (res.data.data.attributes?.name) {

                    }
                    else {
                        RefreshToken(userdat?.refresh_token)
                            .then(res => {

                            })
                            .catch(err => {

                            })
                    }
                    console.log("data on get user", res.data.data)
                })
                .catch(err => {
                    console.log("error on get user", err)
                })
        }
    }, []);


    return (
        <div id="profile-edit" className="profile-edit">
            <Header />
            <div className="mobile-div container">
                <div
                    className="container profile-header">
                    <img alt="icon" src={arrow} />
                    <Link to={url_params === "orders" && order_no ? '/account/orders/' : '/account/'}>
                        {url_params === "profile" || url_params === "passwordchange" ?
                            <p className="small-bold">My Account</p>
                            : url_params === "shippinglist" ?
                                <p className="small-bold">Shipping Details</p>
                                : url_params === "orders" && order_no ?
                                    <p className="small-bold">Back To Orders</p>
                                    : url_params === "orders" ?
                                        <p className="small-bold">Order History</p>
                                        : url_params === "saved_address" ?
                                            <p className="small-bold">Saved Address</p>
                                            : url_params === "wishlist" ?
                                                <p className="small-bold">Wishlist</p>
                                                : historys.push("/404a")}
                    </Link>
                </div>
                {url_params === "profile" ?
                    <div className='container'>
                        <PersonalData />
                    </div>
                    : url_params === "passwordchange" ?
                        <ProfileEdit />
                        : url_params === "orders" && order_no ?
                            <OrderDetails />
                            : url_params === "orders" ?
                                <Myorders />
                                : url_params === "saved_address" ?
                                    <SavedAddress /> :
                                    url_params === "wishlist" ?
                                        <Wishlist list={UContext.state?.wishlist} />
                                        : historys.push("/404a")}
            </div>

            <div className="container my-5 desk-only">
                <div>
                    {
                        // url_params === "profile" || url_params === "passwordchange" ?
                        //     <div className="Desktop-buttons">
                        //         <Link to={'/account/profile/'}><button style={{
                        //             color: url_params === "profile" ? "#1D1F22" : "#72757E",
                        //             borderBottom: url_params === "profile" ? "solid #1D1F22" : "none"

                        //         }}>
                        //             Personal data</button></Link>
                        //         <Link to={'/profile-details/passwordchange'}><button style={{
                        //             color: url_params === "passwordchange" ? "#1D1F22" : "#72757E",
                        //             borderBottom: url_params === "passwordchange" ? "solid #1D1F22" : "none"
                        //         }}>
                        //             Password change</button></Link>
                        //     </div>
                        //     :
                        url_params === "shippinglist" ?
                            <div className="Desktop-buttons">
                                <Link to={'/profile-details/shippinglist'}>
                                    <h4 className='checkout-heading d-none d-sm-block GT-bold mb-sm-2 mb-sm-4' >
                                        Shipping details
                                    </h4>
                                </Link>
                            </div>
                            : url_params === "orders" && order_no ?
                                <div className="Desktop-buttons">
                                    <Link to={'/account/orders/'}>
                                        <h4 className='checkout-heading d-none d-sm-block GT-bold mb-sm-2 mb-sm-4' >
                                            Back To Orders
                                        </h4>
                                    </Link>
                                </div>
                                : url_params === "orders" ?
                                    <div className="Desktop-buttons">
                                        <Link to={'/account/orders/'}>
                                            <h4 className="checkout-heading d-none d-sm-block GT-bold mb-sm-2 mb-sm-4">
                                                My Orders
                                            </h4>
                                        </Link>
                                    </div>
                                    : null}
                    <div>
                        {url_params === "profile" ?
                            <PersonalData />
                            : url_params === "passwordchange" ?
                                <PassWord />
                                : url_params === "orders" && order_no ?
                                    <Openorders />
                                    : url_params === "orders" ?
                                        <Myorders />
                                        : url_params === "saved_address" ?
                                            <SavedAddress />
                                            : url_params === "wishlist" ?
                                                <Wishlist list={UContext.state?.wishlist} />
                                                : null}
                    </div>
                    {/* <div className="container mt-3 small-normal policy-text">
                        At Espravo, we attach great importance to privacy issues and are committed to protecting the personal data of our users. Learn more about how we care and use your personal data in the Privacy Policy.
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default ProfileDetails;
