import axios from "axios"
import { base_url } from "../../helpers/baseURL"

export const checkoutPrice = (details) => new Promise((resolve, reject) => {
    console.log("details of art is", details)
    let item = details.framesize, finalsize = details.framesize
    let rat = item.split("X"),
        framerat = finalsize.split("X")
    console.log("ratt", rat)
    let rat2 = Number(rat[0].replace(' ', '')) / Number(rat[1].replace(' ', ''))
    console.log("ratt", rat2)
    let payload = { ratio: rat2, text: item, width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }
    let framepayload = { width: Number(framerat[0].replace(' ', '')), height: Number(framerat[1].replace(' ', '')) }
    console.log("ratt2", payload)
    console.log('size selected', item)
    let sqi = payload.width * payload.height
    let framesqi = finalsize ? framepayload.height * framepayload.width : 0
    console.log("framesqi calling with final", finalsize, framesqi)
    let price
    if (details.field_print_or_frame === "frame")
        calculatePrice(framesqi, 'frame', details.papertype, details.user_upload)
            .then(resp => {
                resolve(resp.payload)
            })
    else
        calculatePrice(sqi, null, details.papertype)
            .then(resp => {
                resolve(resp.payload)
            })
})

const calculatePrice = (sqi, frame, paper, upload) => new Promise((res, rej) => {
    console.log("calculating price type:", upload ? "uplaod" : "art", ",paper:", paper, ",frame:", frame ? "framed" : "print", ",sqi:", sqi)
    let type = 0
    let final_price
    console.log('sqi', sqi)
    let details = []
    axios.get(base_url + `/api/getprice`)
        .then(resp => {
            console.log('response from price', resp.data.data[0])
            details = resp.data.data[0]
            if (details) {
                if (sqi < 256) {
                    const arr = details.map(size => {
                        if (size.size == "< 256 SQI") {
                            if (upload) {
                                if (frame) {
                                    type = paper == 'Canvas' ? Number(size.canvas_print_frame) : Number(size.paper_print_frame)
                                    let price = Math.round(sqi * type)
                                    console.log('price', price, type, sqi, paper)
                                    final_price = { type: 'set_price', payload: price };
                                    res(final_price)
                                }
                                else {
                                    type = paper == 'Canvas' ? Number(size.canvas_print) : Number(size.paper_print)
                                    let price = Math.round(sqi * type)
                                    console.log('price', price, type, sqi, paper)
                                    final_price = { type: 'set_price', payload: price };
                                    res(final_price)
                                }
                            }
                            else {
                                if (frame) {
                                    type = paper == 'Canvas' ? Number(size.fine_art_canvas_print_frame) : Number(size.fine_art_paper_print_frame)
                                    let price = Math.round(sqi * type)
                                    console.log('price', price, type, sqi, paper)
                                    final_price = { type: 'set_price', payload: price };
                                    res(final_price)
                                }
                                else {
                                    type = paper == 'Canvas' ? Number(size.fine_art_canvas_print) : Number(size.fine_art_paper_print)
                                    let price = Math.round(sqi * type)
                                    console.log('price', price, type, sqi, paper)
                                    final_price = { type: 'set_price', payload: price };
                                    res(final_price)
                                }
                            }
                        }
                    })

                }
                else if (sqi >= 256 && sqi <= 1296) {
                    const arr = details.map(size => {
                        if (size.size == "256 - 1296 SQI") {
                            if (upload) {
                                if (frame) {
                                    type = paper == 'Canvas' ? Number(size.canvas_print_frame) : Number(size.paper_print_frame)
                                    let price = Math.round(sqi * type)
                                    console.log('price', price, type, sqi, paper)
                                    final_price = { type: 'set_price', payload: price };
                                    res(final_price)
                                }
                                else {
                                    type = paper == 'Canvas' ? Number(size.canvas_print) : Number(size.paper_print)
                                    let price = Math.round(sqi * type)
                                    console.log('price', price, type, sqi, paper)
                                    final_price = { type: 'set_price', payload: price };
                                    res(final_price)
                                }
                            }
                            else {
                                if (frame) {
                                    type = paper == 'Canvas' ? Number(size.fine_art_canvas_print_frame) : Number(size.fine_art_paper_print_frame)
                                    let price = Math.round(sqi * type)
                                    console.log('price', price, type, sqi, paper)
                                    final_price = { type: 'set_price', payload: price };
                                    res(final_price)
                                }
                                else {
                                    type = paper == 'Canvas' ? Number(size.fine_art_canvas_print) : Number(size.fine_art_paper_print)
                                    let price = Math.round(sqi * type)
                                    console.log('price', price, type, sqi, paper)
                                    final_price = { type: 'set_price', payload: price };
                                    res(final_price)
                                }
                            }
                        }
                    })
                    console.log("256-1296", details[1])

                }
                else {
                    const arr = details.map(size => {
                        if (size.size == "> 1296 SQI") {
                            if (upload) {
                                if (frame) {
                                    type = paper == 'Canvas' ? Number(size.canvas_print_frame) : Number(size.paper_print_frame)
                                    let price = Math.round(sqi * type)
                                    console.log('price', price, type, sqi, paper)
                                    final_price = { type: 'set_price', payload: price };
                                    res(final_price)
                                }
                                else {
                                    type = paper == 'Canvas' ? Number(size.canvas_print) : Number(size.paper_print)
                                    let price = Math.round(sqi * type)
                                    console.log('price', price, type, sqi, paper)
                                    final_price = { type: 'set_price', payload: price };
                                    res(final_price)
                                }
                            }
                            else {
                                if (frame) {
                                    type = paper == 'Canvas' ? Number(size.fine_art_canvas_print_frame) : Number(size.fine_art_paper_print_frame)
                                    let price = Math.round(sqi * type)
                                    console.log('price', price, type, sqi, paper)
                                    final_price = { type: 'set_price', payload: price };
                                    res(final_price)
                                }
                                else {
                                    type = paper == 'Canvas' ? Number(size.fine_art_canvas_print) : Number(size.fine_art_paper_print)
                                    let price = Math.round(sqi * type)
                                    console.log('price', price, type, sqi, paper)
                                    final_price = { type: 'set_price', payload: price };
                                    res(final_price)
                                }
                            }
                        }
                    })
                }
            }
        })
        .catch(err => {
            console.log("error is", err)
        })
    console.log('paper_type', paper)
})