import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cartitem from '../../assets/cartitem.png'
import dotmenu from '../../assets/3dot.png'
import Price from '../../helpers/price';
import FrameEditPreview from '../frame/frameEditPreview';
import CartFramePreview from '../frame/cartFramePreview';
import axios from 'axios';
import { base_url } from '../../helpers/baseURL';

const CartItem = (props) => {
    const [image, setimage] = useState(null)
    const [frames, setframes] = useState("")
    const [value, setValue] = useState(0)
    const [layout, setLayout] = useState()

    let art_price = props.data.art_price ? props.data.art_price : 0
    let frame_price = props.data.price ? props.data.price : 0
    let price = art_price + frame_price
    console.log("cart-item", props.data)
    useEffect(() => {
        if (props.data.gallery_id) {
            axios.get(base_url + `/jsonapi/commerce_product/gallery_wall/${props.data.gallery_id}?include=field_gallery_image,field_gallery_thumbnail`)
                .then(
                    res => {
                        console.log(res.data, "getting gallery var")
                        setimage(base_url + res.data.included.filter(item => item.type === "file--file")[0].attributes.uri.url)
                        setLayout(res.data.data.attributes.field_layout_size)
                    })
                .catch(err => {

                })
        }
        if (props.data.gifting_id) {
            axios.get(base_url + `/jsonapi/commerce_product/gifting/${props.data.gifting_id}?include=field_gifting_image`)
                .then(
                    res => {
                        console.log(res.data, "getting gifting var")
                        setimage(base_url + res.data.included.filter(item => item.type === "file--file")[0].attributes.uri.url)
                        setLayout(res.data.data.attributes.field_layout_size)
                    })
                .catch(err => {

                })
        }
        if (!props.data.user_upload) {
            axios.get(base_url + `/jsonapi/commerce_product/Default/${props.data.art_id}?include=field_image.field_media_image`)
                .then(
                    res => {
                        console.log(res.data, "getting artss var")
                        setimage(base_url + res.data.included.filter(item => item.type === "file--file")[0].attributes.uri.url)
                    })
                .catch(err => {

                })
        }
        else {
            console.log("cropped image", props.data.cropped_image)
            axios.get(base_url + `/jsonapi/file/file/${props.data.cropped_image}`)
                .then(
                    res => {
                        console.log("cropped get image", base_url + res.data.data.attributes.uri.url)
                        setimage(base_url + res.data.data.attributes.uri.url)
                    })
                .catch(err => {

                })
        }
        if (props.data.varid) {
            console.log("mating in cart", props.data.matting, (props.data.width / props.data.height))
            if ((props.data.width / props.data.height) <= 0.40)
                props.data.matting == "0" ? setValue(70) : setValue(60)
            else if ((props.data.width / props.data.height) > 0.40 && (props.data.width / props.data.height) < 0.52)
                props.data.matting == "0" ? setValue(100) : setValue(80)
            else if ((props.data.width / props.data.height) >= 0.52 && (props.data.width / props.data.height) < 0.72)
                props.data.matting == "0" ? setValue(120) : setValue(100)
            else if ((props.data.width / props.data.height) >= 0.72 && (props.data.width / props.data.height) < 1)
                props.data.matting == "0" ? setValue(130) : setValue(120)
            else if ((props.data.width / props.data.height) >= 1 && (props.data.width / props.data.height) < 1.4)
                props.data.matting == "0" ? setValue(140) : setValue(130)
            else if ((props.data.width / props.data.height) >= 1.4)
                props.data.matting == "0" ? setValue(140) : setValue(140)
            axios.get(base_url + `/jsonapi/commerce_product_variation/Frame/${props.data.varid}?include=product_id,product_id.field_front,product_id.field_side`)
                .then(
                    res => {
                        let frontframearray = res.data.included.filter(item => item.id === res.data.included[0].relationships.field_front.data.id)
                        let sideframearray = res.data.included.filter(item => item.id === res.data.included[0].relationships.field_side.data.id)
                        setframes({ "front": base_url + frontframearray[0].attributes.uri.url, "side": base_url + sideframearray[0].attributes.uri.url })
                        console.log("framess", base_url + frontframearray[0].attributes.uri.url, base_url + sideframearray[0].attributes.uri.url)
                    })
                .catch(err => {

                })

        }
    }, [props.data])
    // if (props.data.width && props.data.field_print_or_frame == "frame" && props.data.matting) {
    //     if ((props.data.width / props.data.height) < 0.52)
    //         props.data.matting == "0" ? setValue(100) : setValue(80)
    //     else if ((props.data.width / props.data.height) >= 0.52 && (props.data.width / props.data.height) < 0.72)
    //         props.data.matting == "0" ? setValue(120) : setValue(100)
    //     else if ((props.data.width / props.data.height) >= 0.72 && (props.data.width / props.data.height) < 1)
    //         props.data.matting == "0" ? setValue(130) : setValue(120)
    //     else if ((props.data.width / props.data.height) >= 1 && (props.data.width / props.data.height) < 1.4)
    //         props.data.matting == "0" ? setValue(160) : setValue(140)
    //     else if ((props.data.width / props.data.height) >= 1.4)
    //         props.data.matting == "0" ? setValue(200) : setValue(160)
    // }

    let details = { "ratio": props.data.ratio ? props.data.ratio : (props.data.width / props.data.height), "artid": image, "width": props.data.width, "padding": props.data.matting, "frameid": frames, "upload": props.data.user_upload, "cropped": props.data.cropped_image }
    return (
        <div id="cart-item" className="cart-main">
            <div className="cart-items-container">
                <div className="cart-item">
                    <div className="cart-item-img p-sm-3 p-2" style={{ backgroundColor: props.data.field_print_or_frame != "frame" ? "rgb(240, 240, 240)" : "" }}>
                        {image ? props.data.field_print_or_frame == "frame" ?
                            <CartFramePreview value={value} props={details} framing={{ "depth": 32, "frame": 3 }} /> :
                            <img src={image} /> : ""}
                    </div>
                    <div className="cart-item-details">
                        <div className="cart-item-titleprice">
                            <div>
                                {!props.data.gallery_id && !props.data.gifting_id ? <div className="cart-item-title">
                                    {props.data.user_upload ? `Custom framing on ${props.data.papertype}` : ""}
                                    {!props.data.user_upload ? props.data.field_print_or_frame == "frame" ? `Framed Print on ${props.data.papertype}` : `Print on ${props.data.papertype}` : ""}
                                </div> : <div className="cart-item-title">{props.data.title}</div>}
                                <div className="cart-item-price">
                                    {!props.data.user_upload ? props.data.art_title ? props.data.art_title : "" : ""}
                                </div>
                                <div className="cart-item-price">
                                    {props.data.field_print_or_frame == "frame" ? `Framed on ${props.data.title}` : ""}
                                </div>

                                <div className="cart-item-price">
                                    &#8377; {<Price value={props.data.total_price} />}
                                </div>
                            </div>
                            {/* {props.menu ?
                                <img alt="icon" src={dotmenu} />
                                : ''} */}
                        </div>
                        {props.data.gallery_id ? <div style={{ height: "20px" }}></div> : <div className="cart-item-style-container">
                            {/* <div className="cart-item-style">
                                <p className="cart-item-title-grey">
                                    Dimensions
                                </p>
                                <p className="cart-item-details-1">
                                    {props.data.dimension ? props.data.dimension : ""}
                                </p>
                            </div> */}
                            {/* <div className="cart-item-style">
                                <p className="cart-item-title-grey">
                                    Mat Style
                                </p>
                                <p className="cart-item-details">
                                    {props.data.matting ? props.data.matting !== "0" ? props.data.padcolor : "None" : "None"}
                                </p>
                            </div> */}
                        </div>}
                        <div className="cart-item-style">
                            {props.data.gallery_id ? <div className="cart-item-price mb-3">{layout ? `The ${layout} Grid Gallery Wall` : ""}</div> : ""}
                        </div>
                        {props.data.gallery_id || props.data.gifting_id ? "" : <div className="cart-item-style-container">
                            <div className="cart-item-style">
                                <div className="cart-item-title-grey">
                                    Dimensions
                                </div>
                                {props.data.field_print_or_frame == "frame" ?
                                    <div className="cart-item-details-1">
                                        {props.data.framesize ? props.data.framesize : ""}
                                    </div> :
                                    <div className="cart-item-details-1">
                                        {props.data.dimension ? props.data.dimension : ""}
                                    </div>
                                }
                            </div>
                        </div>}
                        {/* <p className="cart-item-title-grey brown">
                            Add Special Instructions
                        </p> */}
                    </div>
                </div>
            </div>

        </div>
    );
}

export default CartItem;
